import styled from 'styled-components';

import { Form } from '@contexts/ReactFormContext';

export const Container = styled(Form)`
  width: 100%;
  max-width: 320px;

  margin: auto;

  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    margin-bottom: 16px;
  }

  > div + div {
    margin-top: 16px;
  }
`;
