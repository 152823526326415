import React, {
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';

import { ChevronLeft, ChevronRight } from '@assets/icons';
import { useClickAway } from '@hooks/useClickAway';

import { ArrowLeft, ArrowRight, Card, Container } from './styles';

export interface IModalRef {
  open: () => void;
  close: () => void;
}

interface IModalProps {
  size: 'small' | 'medium' | 'large' | 'custom';
  hasNavigation?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.ForwardRefRenderFunction<IModalRef, IModalProps> = (
  { size, hasNavigation, onClose, children },
  ref,
) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose }));

  useClickAway(cardRef, handleClose, { enabled: isOpen });

  return (
    <Container isOpen={isOpen}>
      <Card ref={cardRef} size={size}>
        {hasNavigation && (
          <ArrowLeft type="button">
            <ChevronLeft />
          </ArrowLeft>
        )}

        {children}

        {hasNavigation && (
          <ArrowRight type="button">
            <ChevronRight />
          </ArrowRight>
        )}
      </Card>
    </Container>
  );
};

const Component = React.forwardRef(Modal);

export { Component as Modal };
