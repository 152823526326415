import React from 'react';

import { Container } from './styles';

interface IBadgeProps {
  color?: string;
}

const Badge: React.FC<IBadgeProps> = ({ color = '#ffffff', children }) => {
  return <Container color={color}>{children}</Container>;
};

export { Badge };
