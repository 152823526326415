import styled, { css } from 'styled-components';

interface IContainerProps {
  isActive: boolean;
  staticColor?: boolean;
}

const containerVariations = {
  active: css`
    background: #ffffff;
  `,
  inactive: css`
    background: #292f4d;
  `,
};

export const Container = styled.div<IContainerProps>`
  ${({ theme, isActive, staticColor }) => css`
    width: 42px;
    height: 20px;

    border-radius: 16px;

    margin: 4px 0;
    padding: 4px;

    position: relative;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 400ms;

    input {
      display: none;
    }

    span {
      color: ${theme.colors.text.title};
      font-size: 16px;
      font-weight: normal;
      white-space: nowrap;

      position: absolute;
      left: 54px;
    }

    ${isActive ? containerVariations.active : containerVariations.inactive}

    ${staticColor &&
    css`
      background: ${theme.colors.activities.info};
    `}
  `}
`;

interface IBallProps {
  isActive: boolean;
  staticColor?: boolean;
}

const ballVariations = {
  active: css`
    background: #00b0ff;

    left: 24px;
  `,
  inactive: css`
    background: #ffffff;

    left: 4px;
  `,
};

export const Ball = styled.div<IBallProps>`
  ${({ isActive, staticColor }) => css`
    width: 14px;
    height: 14px;

    border-radius: 100%;

    position: absolute;

    transition: all 400ms;

    ${isActive ? ballVariations.active : ballVariations.inactive}

    ${staticColor &&
    css`
      background: #ffffff;
    `}
  `}
`;
