import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import * as Icons from '@assets/icons';
import { Button, Input } from '@components/atoms';
import { IFormHandles } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { IAuthRequestForgotPassword } from '@interfaces/IAuthentication';
import { sendEmail } from '@services/apis/EmailService';

import {
  Container,
  Left,
  Login,
  Unform,
  Right,
  BoxRight,
  Title,
  IconBox,
} from './styles';

const ForgotPassword: React.FC = () => {
  const [t] = useTranslation();

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const history = useHistory();

  const handleSubmit = useCallback(
    async (formData: IAuthRequestForgotPassword) => {
      const schema = Yup.object().shape({
        email: Yup.string().required('Informe seu e-mail!'),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: t('FORGOT_PASSWORD_SUCCESS'),
          error: t('FORGOT_PASSWORD_FAILURE'),
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          await sendEmail(formData.email);
        },
      });
    },
    [addToast, t],
  );

  return (
    <Container>
      <Left>
        <IconBox>
          <Icons.BackButton
            onClick={() => history.push(`/`)}
            cursor="pointer"
          />
        </IconBox>
        <Login>
          <Icons.DevstreamLogoLight />

          <Unform ref={formRef} onSubmit={handleSubmit}>
            <strong>Recuperar Senha</strong>

            <Input label="E-mail" name="email" placeholder="Inserir email" />

            <Button type="submit" fullWidth>
              Enviar Email
            </Button>
          </Unform>
        </Login>
      </Left>

      <Right>
        <BoxRight>
          <Title>
            <strong>+ Segurança para sua conta</strong>

            <p>
              Informe o email da sua conta e lhe enviaremos um link com as
              instruções para alterar sua senha
            </p>
          </Title>
          <Icons.DevstreamForgotPassword />
        </BoxRight>
      </Right>
    </Container>
  );
};

export { ForgotPassword };
