import React, {
  ChangeEvent,
  KeyboardEvent,
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { v4 } from 'uuid';

import { useClickAway } from '@hooks/useClickAway';
import { useUnform } from '@hooks/useUnform';

import { Tag } from '../Tag';

import {
  Container,
  LabelContainer,
  ErrorMessage,
  TagsList,
  SelectOption,
  Input,
  OptionsContainer,
  Option,
  NotFound,
  Checkbox,
} from './styles';

export type ITag = {
  label: string;
  value: string | number;
  isSelected?: boolean;
};

export interface ITagsProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onSelect'> {
  name: string;
  label?: string;
  tags?: ITag[];
  valueToReturn?: 'label' | 'value';
  defaultValue?: any;
  errorMessage?: string;
  mode?: 'light' | 'dark';
  minHeight?: string;
  maxHeight?: string;
  notFoundMessage?: string;
  searchable?: boolean;
  onSelect?: (tags: ITag[]) => void;
}

const Tags: React.FC<ITagsProps> = ({
  name,
  label,
  tags: tagsPassed,
  defaultValue,
  placeholder,
  disabled,
  errorMessage,
  valueToReturn,
  mode,
  minHeight,
  maxHeight,
  multiple = true,
  notFoundMessage,
  searchable = false,
  onSelect,
  ...rest
}) => {
  const [t] = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const unform = useUnform(name);

  const [isSearched, setIsSearched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState(tagsPassed || []);
  const [selectedValues, setSelectedValues] = useState<ITag[]>([
    { label: placeholder || '', value: 0 },
  ]);

  const handleSelectFocus = useCallback(() => {
    if (disabled) return;
    setIsFocused(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(selectedValues[0]?.value > 0);

    setInputValue('');

    if (onSelect) {
      onSelect(selectedValues);
      setSelectedValues([{ label: placeholder || '', value: 0 }]);
      setIsFilled(false);
    }
  }, [onSelect, placeholder, selectedValues]);

  const handleAdd = (e: KeyboardEvent<HTMLInputElement>) => {
    if (searchable) return;

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (inputValue.length === 0) {
        setSelectedValues(prevSelectedValues => prevSelectedValues);

        return;
      }

      if (selectedValues[0]?.value > 0) {
        setSelectedValues([
          ...selectedValues,
          { label: inputValue, value: selectedValues.length + 1 },
        ]);

        setInputValue('');

        return;
      }

      if (inputValue.length === 0 && selectedValues[0]?.value === 0) {
        setSelectedValues(prevSelectedValues => prevSelectedValues);

        return;
      }

      setSelectedValues(prevSelectedValues => [
        { label: inputValue, value: prevSelectedValues.length },
      ]);

      setInputValue('');
    }

    setTimeout(() => {
      if (inputRef.current) inputRef.current?.focus();
    }, 500);
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setInputValue(value);

      if (tagsPassed) {
        if (value.length >= 1) {
          setIsSearched(true);

          const foundedOptions = tagsPassed.filter(item =>
            item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
          );

          setTags(foundedOptions);
        } else {
          setIsSearched(false);
          setTags(tagsPassed);
        }
      }
    },
    [tagsPassed],
  );

  const handleSelect = useCallback(
    (value: ITag) => {
      setSelectedValues(prevValues => {
        if (multiple && prevValues[0]?.value > 0) {
          return [...prevValues, value];
        }

        return [value];
      });
      setIsFocused(false);
      setIsFilled(true);
      setIsSearched(false);
      if (tagsPassed) setTags(tagsPassed);
      if (unform) unform.formRef.current?.setFieldError(name, '');
    },
    [multiple, name, tagsPassed, unform],
  );

  const handleDeselect = useCallback(
    (value: ITag) => {
      setSelectedValues(prevValues => {
        if (prevValues.length === 1) {
          return [{ label: placeholder || '', value: 0 }];
        }

        return [...prevValues.filter(item => item.value !== value.value)];
      });

      setIsFocused(false);
      setIsFilled(false);
    },
    [placeholder],
  );

  const handleCloseOnClick = useCallback(() => {
    handleSelectBlur();
  }, [handleSelectBlur]);

  useLayoutEffect(() => {
    if (tagsPassed) {
      setTags(tagsPassed);

      setSelectedValues(() => {
        return tagsPassed?.filter(tagPassed => tagPassed.isSelected);
      });
    }
  }, [tagsPassed]);

  useEffect(() => {
    if (unform) {
      unform.registerField({
        name: unform.fieldName,
        ref: selectRef.current,
        getValue: (ref: HTMLSelectElement) => {
          if (!valueToReturn) {
            if (ref.selectedOptions[0]?.value === '0') return undefined;

            return selectedValues;
          }

          if (selectedValues[0]?.value === 0) return undefined;

          const values = selectedValues.map(item => item[valueToReturn]);

          return values.length > 0 ? [...values] : undefined;
        },
        setValue: (ref: HTMLSelectElement, value: any) => {
          if (value?.length > 0) {
            const initialSelected: ITag[] = [];

            value.forEach((tagMapped: number) => {
              const founded = tags.find(tag => tag.value === Number(tagMapped));
              if (founded) initialSelected.push(founded);
            });

            if (initialSelected.length > 0) {
              setSelectedValues([...initialSelected]);
              setIsFilled(true);
            }
          }

          ref.value = value;
        },
        clearValue: () => {
          setSelectedValues([{ label: placeholder || '', value: 0 }]);
          setIsFilled(false);
        },
      });
    }
  }, [placeholder, selectedValues, tags, unform, valueToReturn]);

  useClickAway(containerRef, handleCloseOnClick, { enabled: isFocused });

  useEffect(() => {
    if (defaultValue && tagsPassed) {
      const foundedOption = tagsPassed.filter(item =>
        String(item.value)
          .toLocaleLowerCase()
          .includes(String(defaultValue).toLocaleLowerCase()),
      );

      if (foundedOption) setSelectedValues([...foundedOption]);
    }
  }, [defaultValue, tagsPassed]);

  return (
    <Container
      id={`tags-${name}`}
      ref={containerRef}
      isErrored={(!!errorMessage || !!unform?.error) && !isFilled}
      isFocused={isFocused && searchable}
      isFilled={
        isFilled ||
        (defaultValue && !!unform?.defaultValue) ||
        selectedValues[0]?.value > 0
      }
      disabled={disabled}
      onClick={handleSelectFocus}
      mode={mode}
      minHeight={minHeight}
      maxHeight={maxHeight}
      className="tags"
    >
      {label && (
        <LabelContainer
          isFocused={isFocused}
          isFilled={
            isFilled ||
            (defaultValue && !!unform?.defaultValue) ||
            selectedValues[0]?.value > 0
          }
          disabled={disabled}
        >
          {label}
        </LabelContainer>
      )}

      <select
        {...rest}
        ref={selectRef}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        <option value={selectedValues[0]?.value || 0}>
          {selectedValues[0]?.label || placeholder}
        </option>
      </select>

      <TagsList
        isFocused={isFocused && searchable}
        isFilled={
          isFilled ||
          (defaultValue && !!unform?.defaultValue) ||
          selectedValues[0]?.value > 0
        }
        onClick={() => inputRef.current?.focus()}
      >
        {selectedValues[0]?.value > 0 &&
          selectedValues.map(selectedValue => (
            <SelectOption
              key={selectedValue.value}
              onBlur={handleSelectBlur}
              onClick={handleSelectFocus}
            >
              <Tag
                isSelected
                label={selectedValue.label || placeholder || ''}
                onClick={() => handleDeselect(selectedValue)}
              />
            </SelectOption>
          ))}

        {!isFocused ? (
          <SelectOption onBlur={handleSelectBlur}>{placeholder}</SelectOption>
        ) : (
          <Input
            ref={inputRef}
            type="text"
            autoFocus
            placeholder={placeholder}
            // onKeyPress={handleAdd}
            onKeyDown={handleAdd}
            value={inputValue}
            onChange={handleChange}
          />
        )}
      </TagsList>

      <OptionsContainer
        isErrored={(!!errorMessage || !!unform?.error) && !isFilled}
        isFocused={isFocused && searchable}
        isFilled={
          isFilled ||
          (defaultValue && !!unform?.defaultValue) ||
          selectedValues[0]?.value > 0
        }
      >
        {tags.length === 0 ? (
          <NotFound>
            <span>{notFoundMessage || t('NOT_FOUND')}</span>
          </NotFound>
        ) : (
          tags.map(option => (
            <Option
              key={v4()}
              isSearched={isSearched}
              isMultiple={multiple}
              isSelected={
                option ===
                selectedValues.find(item => item.value === option.value)
              }
              mode={disabled && mode === 'dark' ? 'dark' : 'light'}
              onClick={() => {
                const isSelectedOption =
                  option ===
                  selectedValues.find(item => item.value === option.value);

                if (!isSelectedOption) handleSelect(option);
                else handleDeselect(option);
              }}
            >
              <Checkbox
                isSelected={
                  option ===
                  selectedValues.find(item => item.value === option.value)
                }
              />

              <span>{option.label}</span>
            </Option>
          ))
        )}
      </OptionsContainer>

      {!!(errorMessage || unform?.error) && !isFilled && (
        <ErrorMessage>{errorMessage || unform?.error}</ErrorMessage>
      )}
    </Container>
  );
};

export { Tags };
