import React, { useRef, useMemo, useState, useCallback } from 'react';

import { useContextSelector } from 'use-context-selector';

import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { useToast } from '@hooks/useToast';
import { ISummaryActivity } from '@interfaces/IProject';
import { ChatBotService } from '@services/apis/ChatBotService';

import { IComponentsProps } from '../../types';
import { Accomplished } from './Accomplished';
import { Impediment } from './Impediment';
import { Planned } from './Planned';

import { Message } from '../../../Messages/styles';
import { DailyStatusStyles, Header, Content, Actions } from './styles';

export type IDailyFormData = {
  id?: number;
  summary_activity_id: number;
  project_id: number;
  accomplished: string;
  planned: string;
  impediment: string;
};

const DailyStatus: React.FC<IComponentsProps> = ({
  response: { activities },
  onAnswer,
}) => {
  const { addToast } = useToast();

  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const [formData, setFormData] = useState<IDailyFormData[]>(() => {
    return activities.map(activity => {
      return {
        accomplished: '',
        planned: '',
        impediment: '',
        project_id: activity.project_id,
        summary_activity_id: activity.id,
      };
    });
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAssociating, setIsAssociating] = useState<boolean>(false);
  const [isResponding, setIsResponding] = useState<boolean>(true);
  const [projectSelected, setProjectSelected] = useState<
    ISummaryActivity | undefined
  >(activities[0]);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<
    'ACCOMPLISHED' | 'PLANNED' | 'IMPEDIMENT'
  >('ACCOMPLISHED');

  const projectSelectedIndex = useMemo(() => {
    return activities.findIndex(
      activity => activity.project_id === projectSelected?.project_id,
    );
  }, [activities, projectSelected?.project_id]);

  const isContinue = useMemo(() => {
    return (
      isResponding &&
      !(
        projectSelectedIndex + 1 === activities.length &&
        currentTab === 'IMPEDIMENT'
      )
    );
  }, [activities.length, currentTab, isResponding, projectSelectedIndex]);

  const onCancel = useCallback(async () => {
    try {
      await ChatBotService.cancelConfirmationAdjustedOfWorkedHours({
        summary_activities_ids: activities.map(activity => activity.id),
        worked_date,
      });

      onAnswer({ dailyState: 'ADJUSTED_WORKED_HOURS' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao cancelar a edição das horas trabalhadas!',
      });
    }
  }, [activities, addToast, onAnswer, worked_date]);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);

    if (isContinue) {
      if (currentTab === 'ACCOMPLISHED') {
        setCurrentTab('PLANNED');
        setTextAreaValue('');
      }

      if (currentTab === 'PLANNED') {
        setCurrentTab('IMPEDIMENT');
        setTextAreaValue('');
      }

      if (currentTab === 'IMPEDIMENT') {
        if (projectSelectedIndex + 1 === activities.length) return;

        setCurrentTab('ACCOMPLISHED');
        setTextAreaValue('');

        if (projectSelectedIndex + 1 !== activities.length) {
          setProjectSelected(activities[projectSelectedIndex + 1]);
        }
      }
    } else {
      try {
        const accomplishedAlreadyAnswered = formData.filter(
          item => !!item.accomplished,
        );

        if (formData.length !== accomplishedAlreadyAnswered.length) {
          addToast({
            type: 'warning',
            title: 'Primeiro responda o que você fez ontem!',
          });

          return;
        }

        const plannedAlreadyAnswered = formData.filter(item => !!item.planned);

        if (plannedAlreadyAnswered.length === 0) {
          addToast({
            type: 'warning',
            title: 'Agora responda o que você pretende fazer hoje!',
          });

          return;
        }

        await ChatBotService.answerDaily({
          daily_answers: formData,
          worked_date,
        });

        onAnswer({ dailyState: 'DAILY_ANSWERED' });
      } catch {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao responder sua daily!',
        });
      }
    }

    setIsLoading(false);
  }, [
    activities,
    addToast,
    currentTab,
    formData,
    isContinue,
    onAnswer,
    projectSelectedIndex,
    worked_date,
  ]);

  const tabs = useMemo(() => {
    const props = {
      activities,
      formData,
      setFormData,
      isResponding,
      setIsResponding,
      isAssociating,
      setIsAssociating,
      projectSelected,
      setProjectSelected,
      textAreaValue,
      setTextAreaValue,
      confirmButtonRef,
    };

    const tab = (
      <DailyStatusStyles.ContentTabs>
        <DailyStatusStyles.ContentTab
          type="button"
          isActive={currentTab === 'ACCOMPLISHED'}
          onClick={() => {
            setCurrentTab('ACCOMPLISHED');

            if (isResponding) {
              const index = activities.findIndex(
                activity => activity.project_id === projectSelected?.project_id,
              );

              setTextAreaValue(formData[index].accomplished);
            }
          }}
        >
          Ontem
        </DailyStatusStyles.ContentTab>

        <DailyStatusStyles.ContentTab
          type="button"
          isActive={currentTab === 'PLANNED'}
          onClick={() => {
            setCurrentTab('PLANNED');

            if (isResponding) {
              const index = activities.findIndex(
                activity => activity.project_id === projectSelected?.project_id,
              );

              setTextAreaValue(formData[index].planned);
            }
          }}
        >
          Hoje
        </DailyStatusStyles.ContentTab>

        <DailyStatusStyles.ContentTab
          type="button"
          isActive={currentTab === 'IMPEDIMENT'}
          onClick={() => {
            setCurrentTab('IMPEDIMENT');

            if (isResponding) {
              const index = activities.findIndex(
                activity => activity.project_id === projectSelected?.project_id,
              );

              setTextAreaValue(formData[index].impediment);
            }
          }}
        >
          Impedimento
        </DailyStatusStyles.ContentTab>
      </DailyStatusStyles.ContentTabs>
    );

    return {
      ACCOMPLISHED: <Accomplished {...props}>{tab}</Accomplished>,
      PLANNED: <Planned {...props}>{tab}</Planned>,
      IMPEDIMENT: <Impediment {...props}>{tab}</Impediment>,
    };
  }, [
    activities,
    currentTab,
    formData,
    isResponding,
    isAssociating,
    projectSelected,
    textAreaValue,
  ]);

  return (
    <Message.Interaction>
      <div>
        <Header>
          <strong>Sua Daily Status</strong>
        </Header>

        <Content>{tabs[currentTab]}</Content>

        <Actions>
          <button type="button" onClick={onCancel} className="cancel">
            Cancelar
          </button>

          <button
            ref={confirmButtonRef}
            type="button"
            disabled={isLoading}
            onClick={onConfirm}
            className="save"
          >
            {isContinue ? 'Continuar' : 'Confirmar'}
          </button>
        </Actions>
      </div>
    </Message.Interaction>
  );
};

export { DailyStatus };
