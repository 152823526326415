import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  HiOutlineLogout,
  HiOutlineMenuAlt2,
  HiOutlineMoon,
  HiOutlineUserCircle,
  // HiOutlineUserCircle,
} from 'react-icons/hi';
import { IoNotificationsOutline } from 'react-icons/io5';
// import { useHistory } from 'react-router-dom';

import defaultAvatar from '@assets/images/default-avatar.png';
import { Dropdown, Switcher } from '@components/atoms';
import { Can } from '@components/Can';
import { UserDrawer } from '@components/templates';
import { useAuth } from '@hooks/useAuth';
import { useHeader } from '@hooks/useHeader';
import { useTheme } from '@hooks/useTheme';
import { IUser } from '@interfaces/IUser';
import { getUserLogged } from '@utils/getUserLogged';

import { IDrawerRef } from '../Drawer';

import {
  Container,
  Left,
  Hamburger,
  Right,
  Avatar,
  Notifications,
  SwitcherContainer,
} from './styles';

interface IHeaderProps {
  title: string;
  onFold: () => void;
}

const Header: React.FC<IHeaderProps> = ({ title, onFold }) => {
  const { signOut } = useAuth();
  const { menu, customHeader, onSwitch, setCurrentMenu } = useHeader();
  const toggleTheme = useTheme<() => void>({ prop: 'toggleTheme' });
  const theme = useTheme<'light' | 'dark'>({ prop: 'theme' });

  // const handleChooseWorkedDate = useContextSelector(
  //   ChatbotContext,
  //   state => state.chooseWorkedDate,
  // );

  const userDrawerRef = useRef<IDrawerRef>(null);

  // const history = useHistory();

  const [user, setUser] = useState<IUser>(getUserLogged().user);

  const handleSwitch = useCallback(() => onSwitch(title), [title, onSwitch]);

  const handleSignOut = useCallback(() => {
    sessionStorage.clear();
    signOut();
    setCurrentMenu(0);
    if (theme === 'light') toggleTheme();
  }, [setCurrentMenu, signOut, theme, toggleTheme]);

  // const handleGoToProfile = useCallback(() => {
  //   setCurrentMenu(-1);
  //   history.push(`/profile/${user.id}`);
  // }, [history, setCurrentMenu, user.id]);

  const handleGoToProfile = useCallback(
    () => userDrawerRef.current?.open(),
    [],
  );

  const index = useMemo(
    () => menu.findIndex(item => item.text === title),
    [menu, title],
  );

  useEffect(() => {
    const userLogged = getUserLogged();

    setUser(userLogged.user);
  }, []);

  return (
    <Container>
      <Left>
        <Hamburger onClick={onFold}>
          <HiOutlineMenuAlt2 size={24} />
        </Hamburger>

        {customHeader || <h1>{title}</h1>}

        <Can roles={menu[index]?.roles} permissions={[]}>
          {menu[index]?.labels && !customHeader && (
            <SwitcherContainer>
              <Switcher
                name="enabled"
                labels={menu[index]?.labels}
                checked={menu[index]?.isChecked}
                onSwitch={handleSwitch}
                staticColor
              />
            </SwitcherContainer>
          )}
        </Can>
      </Left>

      <Right>
        <Avatar>
          <div>
            <strong>Olá {user.name}</strong>
            <span>{user.email}</span>
          </div>

          <img src={user.avatar?.url || defaultAvatar} alt={user.name} />
        </Avatar>

        {/* TODO: In development */}
        {process.env.NODE_ENV !== 'production' && (
          <Notifications>
            <button type="button">
              <div />

              <IoNotificationsOutline size={24} />
            </button>
          </Notifications>
        )}

        <Dropdown
          items={[
            {
              text: 'Mudar tema',
              icon: HiOutlineMoon,
              onClick: toggleTheme,
            },
            {
              text: 'Ver perfil',
              icon: HiOutlineUserCircle,
              onClick: handleGoToProfile,
            },
            // {
            //   text: 'Daily Retroativa',
            //   icon: HiChatAlt,
            //   onClick: () => handleChooseWorkedDate('2022-09-16'),
            // },
            {
              text: 'Sair',
              icon: HiOutlineLogout,
              onClick: handleSignOut,
            },
          ]}
        />
      </Right>

      <UserDrawer
        userId={user.id}
        setUserId={() => console.log('')}
        setUsers={() => console.log('')}
        userDrawerRef={userDrawerRef}
      />
    </Container>
  );
};

export { Header };
