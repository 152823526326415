import { IGeneric } from '@interfaces/generic/IGeneric';
import { IQuery } from '@interfaces/generic/IQuery';
import { IGroup } from '@interfaces/IGroup';
import { IOrganogramGroup } from '@interfaces/IOrganogram';
import { api } from '@services/api';
import { getParsedQuery } from '@utils/getParsedQuery';

interface ICreateGroupRequest
  extends Pick<IGroup, 'name' | 'type' | 'parent_group_id' | 'organogram_id'> {
  user_ids?: number[];
}

interface IUpdateGroupRequest {
  id: number;
  type?: string;
  name?: string;
  user_ids?: number[];
}

export async function createGroup(
  formData: ICreateGroupRequest,
): Promise<IOrganogramGroup> {
  const { data } = await api.post('/groups', formData);

  return data;
}
export async function updateGroup(
  formData: IUpdateGroupRequest,
): Promise<IOrganogramGroup> {
  const { data } = await api.put(`/groups/${formData.id}`, {
    ...formData,
  });

  return data;
}

export async function getGroups(query?: IQuery): Promise<IGroup[]> {
  const parsedQuery = getParsedQuery(query);

  const { data } = await api.get<IGroup[]>(`/groups${parsedQuery}`);

  return data;
}

export async function deleteGroup({ id }: Pick<IGeneric, 'id'>): Promise<void> {
  await api.delete(`/groups/${id}`);
}

export async function manageCollaborators(
  group_id: number,
  user_ids: number[],
): Promise<void> {
  await api.post(`/groups/${group_id}/users`, { user_ids });
}
