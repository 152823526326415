import React, {
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import { HiOutlineX } from 'react-icons/hi';

import md5 from 'md5';
import { CSSProperties } from 'styled-components';
import { v4 } from 'uuid';

import { Button } from '@components/atoms';
import { IFormRef } from '@contexts/ReactFormContext';
import { useClickAway } from '@hooks/useClickAway';

import {
  Container,
  Header,
  HeaderTitle,
  HeaderTabs,
  Tab,
  Content,
  Footer,
} from './styles';

export type IDrawerPosition = 'top' | 'right' | 'bottom' | 'left';

type IForm = {
  name: string;
  tab: string;
  ref: React.RefObject<IFormRef>;
};

export interface IDrawerRef {
  open: () => void;
  close: () => void;
}

interface IDrawerProps {
  title: string;
  tabs?: string[];
  hasData?: boolean;
  onClose?: () => void;
  position?: IDrawerPosition;
  children?: React.ReactNode;
}

const Drawer: React.ForwardRefRenderFunction<IDrawerRef, IDrawerProps> = (
  { title, tabs, hasData, onClose, children },
  ref,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const formRefs = useRef<IForm[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(
    tabs ? md5(tabs[0]) : '',
  );

  const handleSubmit = useCallback(() => {
    if (formRefs.current) {
      if (tabs) {
        const formRef = formRefs.current.find(item => item.tab === currentTab);

        if (!formRef?.ref.current?.submit) return;

        formRef?.ref.current?.submit();
      } else {
        if (!formRefs.current[0].ref.current?.submit) return;

        formRefs.current[0].ref.current?.submit();
      }
    }
  }, [currentTab, tabs]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (tabs) setCurrentTab(md5(tabs[0]));
    if (onClose) onClose();
    if (sessionStorage.openDailyChat === 'true') {
      window.history.pushState('', '', `/`);
      window.location.reload();
    }
  }, [tabs, onClose]);

  useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose }));

  useClickAway(containerRef, handleClose, { enabled: isOpen });

  return (
    <Container ref={containerRef} isOpen={isOpen} position="right">
      <Header hasTabs={!!tabs}>
        <HeaderTitle>
          <div>
            <strong>{title}</strong>
          </div>

          <button type="button" onClick={handleClose}>
            <HiOutlineX size={24} color="#ffffff" />
          </button>
        </HeaderTitle>

        {tabs && (
          <HeaderTabs>
            {tabs.map(tab => {
              return (
                <Tab
                  key={md5(tab)}
                  isActive={md5(tab) === currentTab}
                  onClick={() => setCurrentTab(md5(tab))}
                >
                  <span>{tab}</span>
                </Tab>
              );
            })}
          </HeaderTabs>
        )}
      </Header>

      <Content>
        {React.Children.map(children, (item: any, index) => {
          const tabToCompare = tabs ? md5(tabs[index] || '') : '';

          const hiddenTabStyles: CSSProperties = {
            minWidth: currentTab === tabToCompare ? '100%' : '0%',
            minHeight: currentTab === tabToCompare ? '100%' : '0%',
            opacity: currentTab === tabToCompare ? 1 : 0,
            zIndex: currentTab === tabToCompare ? 1 : -1,
            // position: currentTab === tabToCompare ? 'relative' : 'absolute',
          };

          if (formRefs.current) {
            if (item.ref) {
              const { ref: fRef } = item;

              if (tabs) {
                const name = tabs[index];

                const alreadyRegister = formRefs.current.find(
                  formRef => formRef.tab === tabToCompare,
                );

                if (!alreadyRegister) {
                  if (name) {
                    formRefs.current.push({
                      name,
                      tab: tabToCompare,
                      ref: fRef,
                    });
                  }
                }
              } else if (formRefs.current.length === 0) {
                formRefs.current.push({ name: v4(), tab: v4(), ref: fRef });
              }
            }
          }

          return <div style={hiddenTabStyles}>{item}</div>;
        })}
      </Content>

      <Footer>
        {hasData && (
          <Button type="button" actionType="remove" onClick={handleClose}>
            Excluir
          </Button>
        )}

        <Button type="button" actionType="cancel" onClick={handleClose}>
          Cancelar
        </Button>

        <Button type="button" actionType="add" onClick={handleSubmit}>
          {hasData ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Footer>
    </Container>
  );
};

const Component = React.forwardRef(Drawer);

export { Component as Drawer };
