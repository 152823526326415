import React from 'react';

import { KanBanCard } from '@components/atoms';
import { IItem } from '@components/organisms/KanBan';

import { Container, Content, Header } from './styles';

interface IKanBanColumnProps {
  data: { title: string; items: IItem[] };
}

const KanBanColumn: React.FC<IKanBanColumnProps> = ({ data }) => {
  return (
    <Container>
      <Header>
        <strong>{data.title}</strong>
      </Header>

      <Content>
        {data.items.map(item => {
          return <KanBanCard key={item.id} {...item} />;
        })}
      </Content>
    </Container>
  );
};

export { KanBanColumn };
