import React, { useMemo } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { ArrowDown, ArrowUp } from '@assets/icons';
import { IOrganogramGroup } from '@interfaces/IOrganogram';
import { toHMS } from '@utils/toHMS';

import {
  Container,
  Avatar,
  Cost,
  Hours,
  Left,
  Right,
  DefaultAvatar,
} from './styles';

interface ICollaboratorCardProps {
  group: IOrganogramGroup;
  color: string;
  level: number;
}

const CollaboratorCard: React.FC<ICollaboratorCardProps> = ({
  group,
  color,
  level,
}) => {
  const parsedAbbrName = useMemo(() => {
    const names = group.name.split(' ');
    const name = names[0].slice(0, 1);
    const lastName = names[names.length - 1].slice(0, 1);

    return `${name}${lastName}`;
  }, [group.name]);

  const parsedName = useMemo(() => {
    const names = group.name.split(' ');

    if (names.length > 2) {
      const name = names[0];
      const lastName =
        names[names.length - 1].length > 12
          ? `${names[names.length - 1].slice(0, 1)}.`
          : names[names.length - 1];

      return `${name} ${lastName}`;
    }

    return group.name;
  }, [group.name]);

  const parsedCost = useMemo(() => {
    return {
      isPositive: group.values.current.cost > group.values.past.cost,
      cost: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(group.values.current.cost)
        .slice(0, -3),
      percentage:
        group.values.past.cost === 0
          ? 0
          : (
              (group.values.current.cost / group.values.past.cost - 1) *
              100
            ).toFixed(2),
    };
  }, [group]);

  const parsedHours = useMemo(() => {
    return {
      isPositive: group.values.current.hours > group.values.past.hours,
      hours: toHMS(group.values.current.hours),
      percentage:
        group.values.past.hours === 0
          ? 0
          : (
              (group.values.current.hours / group.values.past.hours - 1) *
              100
            ).toFixed(2),
    };
  }, [group]);

  return (
    <Container color={color} level={level} className="collaboratorCard">
      <Left className="left">
        <div>
          {group.avatar_url ? (
            <Avatar src={group.avatar_url} alt={group.name} />
          ) : (
            <DefaultAvatar>
              <strong>{parsedAbbrName}</strong>
            </DefaultAvatar>
          )}

          <div>
            <span>{level > 1 ? parsedName : group.name}</span>
            <strong>{group.info}</strong>
          </div>
        </div>

        <Cost>
          <strong>
            Custo:
            <span>{parsedCost.cost}</span>
          </strong>

          <div>
            {parsedCost.isPositive ? <ArrowUp /> : <ArrowDown />}
            <span>{parsedCost.percentage}%</span>
          </div>
        </Cost>

        <Hours>
          <strong>
            Horas:
            <span>{parsedHours.hours}</span>
          </strong>

          <div>
            {parsedHours.isPositive ? <ArrowUp /> : <ArrowDown />}
            <span>{parsedHours.percentage}%</span>
          </div>
        </Hours>
      </Left>

      <Right>
        <FiChevronRight size={24} color="#fbfbfb" />
      </Right>
    </Container>
  );
};

export { CollaboratorCard as OrganogramCollaboratorCard };
