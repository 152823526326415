import { createGlobalStyle, css } from 'styled-components';

import { AUTH_ORIGIN } from '@constants/auth';

const origin = localStorage.getItem(AUTH_ORIGIN);

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: transparent;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    ${({ theme }) => css`
      background: ${origin === 'electron'
        ? 'transparent'
        : theme.colors.background} !important;

      color: ${theme.colors.text.title};
      -webkit-font-smoothing: antialiased;
    `}
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;

    border-radius: ${origin === 'electron' ? '16px' : '0px'} !important;

    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-y: hidden;

    /* transition: background 400ms, background-color 400ms; */
  }

  body,
  input,
  textarea,
  button {
    ${({ theme }) => css`
      font-family: ${theme.typography.fontFamily};
      font-weight: ${theme.typography.fontWeight.normal};
    `}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }

  li {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const customScroll = (color = '#334b7a') => css`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #0a192f;
    background: ${({ theme }) => theme.colors.background};
    border-left: 3.5px solid ${color};
    border-right: 3.5px solid ${color};
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00bfff;
    border-radius: 7px;
    border-left: 2px solid ${color};
    border-right: 2px solid ${color};
  }
`;
