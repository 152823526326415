import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import * as Icons from '@assets/icons';
import { Button, Input } from '@components/atoms';
import { IFormHandles } from '@contexts/ReactFormContext';
import { useAuth } from '@hooks/useAuth';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { IAuthRequest } from '@interfaces/IAuthentication';

import {
  Container,
  Left,
  Login,
  Unform,
  Right,
  Presentations,
  Presentation,
  PresentationTitle,
  Slides,
  SlideDot,
} from './styles';

const SignIn: React.FC = () => {
  const [t] = useTranslation();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const handleSubmit = useCallback(
    async (formData: IAuthRequest) => {
      const schema = Yup.object().shape({
        email: Yup.string().required('Informe seu e-mail!'),
        password: Yup.string().required('Digite sua senha!'),
      });

      trySubmit({
        formRef,
        addToast,
        messages: { success: t('LOGIN_SUCCESS'), error: t('LOGIN_FAILURE') },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          await signIn(formData);
        },
      });
    },
    [addToast, signIn, t],
  );

  useEffect(() => {
    const handle = () => setCurrentSlide(state => (state === 3 ? 1 : ++state));

    const interval = setInterval(handle, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Left>
        <Login>
          <Icons.DevstreamLogoLight />

          <Unform ref={formRef} onSubmit={handleSubmit}>
            <strong>{t('LOGIN_TITLE')}</strong>

            <Input label="E-mail" name="email" placeholder="E-mail" />

            <Input
              label="Senha"
              name="password"
              type="password"
              placeholder="Senha"
            />

            <Button type="submit" fullWidth>
              Confirmar Login
            </Button>
          </Unform>

          <Link to="/forgot_password">Esqueci minha senha</Link>
        </Login>
      </Left>

      <Right>
        <Presentations>
          <Presentation isActive={currentSlide === 1}>
            <PresentationTitle>
              <strong>Gestão de Projetos</strong>

              <p>
                Praesent in ante facilisis, suscipit metus vel, consectetur
                tortor. Pellentesque congue pulvinar nibh. Pellentesque
                imperdiet convallis enim.
              </p>
            </PresentationTitle>

            <Icons.DevstreamLoginSlide1 />
          </Presentation>

          <Presentation isActive={currentSlide === 2}>
            <PresentationTitle>
              <strong>Gestão de Tempo</strong>

              <p>
                Praesent in ante facilisis, suscipit metus vel, consectetur
                tortor. Pellentesque congue pulvinar nibh. Pellentesque
                imperdiet convallis enim.
              </p>
            </PresentationTitle>

            <Icons.DevstreamLoginSlide2 />
          </Presentation>

          <Presentation isActive={currentSlide === 3}>
            <PresentationTitle>
              <strong>Gestão de Equipe</strong>

              <p>
                Praesent in ante facilisis, suscipit metus vel, consectetur
                tortor. Pellentesque congue pulvinar nibh. Pellentesque
                imperdiet convallis enim.
              </p>
            </PresentationTitle>

            <Icons.DevstreamLoginSlide3 />
          </Presentation>
        </Presentations>

        <Slides>
          <SlideDot isActive={currentSlide === 1} />

          <SlideDot isActive={currentSlide === 2} />

          <SlideDot isActive={currentSlide === 3} />
        </Slides>
      </Right>
    </Container>
  );
};

export { SignIn };
