import React, { useRef, useState, useCallback } from 'react';
import {
  HiReply,
  HiOutlineClock,
  HiOutlineX,
  HiOutlineCheck,
} from 'react-icons/hi';

import { useClickAway } from '@hooks/useClickAway';
import { useToast } from '@hooks/useToast';
import { ISummaryActivity } from '@interfaces/IProject';
import { ChatBotService } from '@services/apis/ChatBotService';

import { hmsToSeconds } from '../../../../functions/hmsToSeconds';
import { secondsToHMS } from '../../../../functions/secondsToHMS';
import { IComponentsProps } from '../../../types';

import { Container, InputGroup, ConfirmationDialog, Button } from './styles';

type IValue = { seconds: number; formatted: string; percentual: number };
type IOnChangeValue = { formatted?: string; percentage?: number };

interface IProjectCorrectionProps extends Pick<IComponentsProps, 'onAnswer'> {
  activity: ISummaryActivity;
  max: number;
  rest: number;
  isSubmitting: boolean;
  onCorrect: (activity: ISummaryActivity) => void;
  onUndo: (activity: ISummaryActivity) => void;
}

const ProjectCorrection: React.FC<IProjectCorrectionProps> = ({
  activity,
  max,
  rest,
  isSubmitting,
  onCorrect,
  onUndo,
  onAnswer,
}) => {
  const { addToast } = useToast();

  const containerRef = useRef<HTMLDivElement>(null);
  const percentageRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<IValue>({
    formatted: secondsToHMS(
      activity.checked_worked_hours
        ? activity.checked_worked_hours
        : activity.original_worked_hours,
    ),
    seconds: activity.checked_worked_hours
      ? activity.checked_worked_hours
      : activity.original_worked_hours,
    percentual:
      ((activity.checked_worked_hours
        ? activity.checked_worked_hours
        : activity.original_worked_hours) /
        max) *
      100,
  });
  const [summary, setSummary] = useState<ISummaryActivity>(activity);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isUndoing, setIsUndoing] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const handleChangeValue = useCallback(
    ({ formatted, percentage }: IOnChangeValue) => {
      if (formatted) {
        const valueArray = formatted.split(':');
        const maxArray = secondsToHMS(rest).split(':');

        const valueHour = Number(valueArray[0]);
        const maxHour = Number(maxArray[0]);

        if (valueHour === maxHour) {
          const valueMinute = Number(valueArray[1]);
          const maxMinute = Number(maxArray[1]);
          const valueSecond = Number(valueArray[2]);
          const maxSecond = Number(maxArray[2]);

          setValue(state => {
            const stateArray = state.formatted.split(':');

            if (Number(stateArray[0]) + 1 === Number(maxArray[0])) {
              return {
                seconds: hmsToSeconds(formatted),
                formatted,
                percentual: (hmsToSeconds(formatted) / max) * 100,
              };
            }

            if (valueMinute <= maxMinute && valueSecond <= maxSecond) {
              return {
                seconds: hmsToSeconds(formatted),
                formatted,
                percentual: (hmsToSeconds(formatted) / max) * 100,
              };
            }

            return state;
          });
        } else {
          setValue({
            seconds: hmsToSeconds(formatted),
            formatted,
            percentual: (hmsToSeconds(formatted) / max) * 100,
          });
        }
      }

      if (percentage) {
        setValue({
          seconds: (max * percentage) / 100,
          formatted: secondsToHMS((max * percentage) / 100),
          percentual: percentage,
        });
      }

      if ((percentage && percentage < 0) || percentage === 0) {
        setValue({ seconds: 0, formatted: secondsToHMS(0), percentual: 0 });
      }

      if ((percentage && percentage > 100) || percentage === 100) {
        setValue({
          seconds: max,
          formatted: secondsToHMS(rest),
          percentual: 100,
        });
      }
    },
    [max, rest],
  );

  const handleChangeTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeValue({ formatted: e.target.value });
    },
    [handleChangeValue],
  );

  const handleChangePercentage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const restPercent = (rest / max) * 100;

      handleChangeValue({
        percentage:
          Number(e.target.value) <= restPercent
            ? Number(e.target.value)
            : restPercent,
      });
    },
    [handleChangeValue, max, rest],
  );

  const handleSave = useCallback(async () => {
    try {
      if (value.seconds > 0) {
        await ChatBotService.adjustUniqueWorkedHours({
          activities: [
            {
              summary_activity_id: summary.id,
              adjusted_worked_hours: value.seconds,
            },
          ],
        });

        setSummary({ ...summary, checked_worked_hours: value.seconds });
        onCorrect({ ...summary, checked_worked_hours: value.seconds });
      }

      setIsEditing(false);
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao confirmar suas horas trabalhadas!',
      });
    }
  }, [summary, value.seconds, onCorrect, addToast]);

  const handleUndo = useCallback(async () => {
    try {
      await ChatBotService.undoAdjustUniqueWorkedHours({
        activities: [{ summary_activity_id: summary.id }],
      });

      setIsEditing(false);
      setIsUndoing(false);
      setSummary({ ...summary, checked_worked_hours: undefined });
      onUndo(summary);
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao desfazer a confirmação das suas horas!',
      });
    }
  }, [addToast, onUndo, summary]);

  const handleRemove = useCallback(async () => {
    try {
      await ChatBotService.removeSummaryActivity({
        summary_activity_id: summary.id,
      });

      setIsRemoving(false);

      onAnswer({ dailyState: 'INIT' });
      onAnswer({ dailyState: 'NOT_CONFIRMED_WORKED_HOURS' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao desfazer a confirmação das suas horas!',
      });
    }
  }, [addToast, onAnswer, summary.id]);

  useClickAway(containerRef, handleSave, { enabled: isEditing });

  return (
    <Container ref={containerRef} isChecked={!!summary.checked_worked_hours}>
      <label htmlFor="hours">{summary.project.name}</label>

      {isUndoing || isRemoving ? (
        <ConfirmationDialog>
          <span>Deseja {isUndoing ? 'desfazer' : 'remover'} ?</span>

          <div>
            <button
              type="button"
              className="yes"
              onClick={isUndoing ? handleUndo : handleRemove}
            >
              Sim
            </button>

            <button
              type="button"
              className="no"
              onClick={() => {
                if (isUndoing) setIsUndoing(false);
                else setIsRemoving(false);
              }}
            >
              Não
            </button>
          </div>
        </ConfirmationDialog>
      ) : (
        <>
          <InputGroup
            percentual={`${value.percentual.toFixed(2)}%`}
            isEditing={isEditing}
            isChecked={!!summary.checked_worked_hours}
          >
            <input
              id="hours"
              type="time"
              step={1}
              min="00:00:00"
              max={secondsToHMS(rest)}
              onChange={handleChangeTime}
              onFocus={() => setIsEditing(true)}
              value={value.formatted}
              disabled={!!summary.checked_worked_hours}
            />

            {summary.checked_worked_hours ? (
              <HiOutlineCheck size={22} />
            ) : (
              <HiOutlineClock size={22} />
            )}
          </InputGroup>

          <InputGroup
            percentual={`${value.percentual.toFixed(2)}%`}
            isEditing={isEditing}
            isChecked={!!summary.checked_worked_hours}
            onClick={() => {
              if (!summary.checked_worked_hours) {
                setIsEditing(true);
                percentageRef.current?.focus();
              }
            }}
            className="percentual"
          >
            <input
              ref={percentageRef}
              type="number"
              min={0}
              max={100}
              onChange={handleChangePercentage}
              value={value.percentual}
              autoFocus={isEditing}
              readOnly={!isEditing}
            />
          </InputGroup>
        </>
      )}

      {summary.checked_worked_hours && !isUndoing && (
        <Button
          type="button"
          onClick={() => setIsUndoing(true)}
          disabled={isSubmitting}
          className="undo"
        >
          <HiReply size={16} />

          <span>Desfazer</span>
        </Button>
      )}

      {!summary.checked_worked_hours && (
        <Button
          type="button"
          onClick={() => setIsRemoving(true)}
          disabled={isSubmitting}
          className="remove"
        >
          <HiOutlineX size={16} />

          <span>Remover</span>
        </Button>
      )}
    </Container>
  );
};

export { ProjectCorrection };
