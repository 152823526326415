import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { HttpQuery, IParsedQuery } from '@helpers/HttpQueryHelper';
import { IQuery } from '@interfaces/generic/IQuery';
import {
  IUser,
  IUserFormData,
  IUserHourCost,
  IUserValuesFormData,
} from '@interfaces/IUser';
import { api } from '@services/api';
import { getParsedQuery } from '@utils/getParsedQuery';

export async function getUsers(query?: IQuery): Promise<IUser[]> {
  const parsedQuery = getParsedQuery(query);

  const { data } = await api.get<any>(`/users${parsedQuery}`);

  return data.data;
}

export async function createUser(formData: IUserFormData): Promise<IUser> {
  const { data } = await api.post<IUser>('/users', {
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    user_role_id: formData.user_role_id,
    password: formData.password,
    password_confirmation: formData.password_confirmation,
    groups_ids: formData.groups_ids,
    projects_ids: formData.projects_ids,
    is_hour: formData.is_hour,
    cost: formData.cost,
    from: formData.dates[0],
    to: formData.dates[1],
  });

  return data;
}

export async function getUser({ id }: Pick<IUser, 'id'>): Promise<IUser> {
  const { data } = await api.get<IUser>(`/users/${id}`);

  return data;
}

export async function updateUser(
  userId: number,
  formData: IUserFormData,
): Promise<IUser> {
  const { data } = await api.put<IUser>(`/users/${userId}`, {
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    enabled: formData.enabled,
    user_role_id: formData.user_role_id,
    groups_ids: formData.groups_ids,
    projects_ids: formData.projects_ids,
  });

  return data;
}

/**
 * HOUR COST
 */
export async function createUserCost(
  userId: number,
  formData: IUserValuesFormData,
): Promise<IUserHourCost> {
  const foundedUser = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

  const user = foundedUser ? JSON.parse(foundedUser) : {};

  const { data } = await api.post<IUserHourCost>(`/users/${userId}/costs`, {
    user_responsible_id: Number(user.id),
    cost: formData.cost,
    is_hour: formData.is_hour,
    from: formData.dates[0],
    to: formData.dates[1],
  });

  return data;
}

interface IListResponse {
  page: number;
  limit: number;
  total: number;
  data: any[];
}

interface IListProps {
  query?: IParsedQuery;
  relations?: string[];
}

interface IShowProps {
  id: number;
  relations?: string[];
}

class UsersService {
  public async list(params: IListProps): Promise<IListResponse> {
    const parsedQuery = HttpQuery.getParsedQueryString(params.query);

    const options = params.relations
      ? { headers: { relations: params.relations.join(',') } }
      : undefined;

    const { data } = await api.get<any>(`/users${parsedQuery}`, options);

    return data;
  }

  public async show(params: IShowProps): Promise<IUser> {
    const options = params.relations
      ? { headers: { relations: params.relations.join(',') } }
      : undefined;

    const { data } = await api.get<IUser>(`/users/${params.id}`, options);

    return data;
  }
}

const INSTANCE = new UsersService();

export { INSTANCE as UsersService };
