import React from 'react';

// import { useHeader } from '@hooks/useHeader';

import { Container } from './styles';

const HourCorrection: React.FC = () => {
  // const { getCurrentInfo } = useHeader();

  return (
    <Container>
      <h1>Correção de horas aqui</h1>
    </Container>
  );
};

export { HourCorrection };
