import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { IAlert, IProps } from '../types';

import { alertVariations } from '../styles';

const responsive = {
  Container: css<IContainerProps>`
    ${({ isAlert }) => css`
      @media screen and (max-width: 768px) {
        max-width: calc(100vw - 16px - 16px);

        right: 16px;
        bottom: ${isAlert ? 'calc(16px + 42px + 16px)' : 'calc(36px + 16px)'};
      }

      @media screen and (max-width: 280px) {
        max-width: calc(100vw - 16px - 16px);

        right: 16px;
        bottom: ${isAlert ? 'calc(16px + 42px + 16px)' : 'calc(36px + 16px)'};
      }
    `}
  `,
  Header: css`
    @media screen and (max-width: 768px) {
      padding: 16px;
    }

    @media screen and (max-width: 280px) {
      padding: 16px;

      strong {
        font-size: 0.875rem;
      }
    }
  `,
  Footer: css`
    @media screen and (max-width: 768px) {
      padding: 8px;

      gap: 8px;

      div {
        gap: 8px;
      }
    }

    @media screen and (max-width: 280px) {
      padding: 8px;

      gap: 8px;

      div {
        gap: 8px;
      }
    }
  `,
  ReplyNowButton: css`
    @media screen and (max-width: 768px) {
      padding: 8px 0;

      span {
        &.web {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }

    @media screen and (max-width: 280px) {
      padding: 8px 0;
      font-size: 0.75rem;

      span {
        &.web {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }
  `,
  ReplyLaterButton: css`
    @media screen and (max-width: 768px) {
      padding: 8px 0;

      span {
        &.web {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }

    @media screen and (max-width: 280px) {
      padding: 8px 0;
      font-size: 0.75rem;

      span {
        &.web {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }
  `,
};

type IContainerProps = IProps;

export const Container = styled.div<IContainerProps>`
  ${({ theme, isAlert }) => css`
    width: 100vw;
    max-width: 24vw;
    max-width: 400px;

    border-radius: 8px 8px 0 8px;
    background: ${theme.colors.cards};
    box-shadow: 0px 6px 16px ${theme.colors.shadow};

    position: absolute;
    right: 36px;
    bottom: ${isAlert ? 'calc(72px + 36px + 24px)' : 'calc(36px + 16px)'};

    visibility: ${isAlert ? 'visible' : 'hidden'};
    opacity: ${isAlert ? 1 : 0};

    pointer-events: ${isAlert ? 'initial' : 'none'};

    transition: all 400ms;

    ${responsive.Container}
  `}
`;

interface IHeaderProps {
  alert: IAlert;
}

export const Header = styled.header<IHeaderProps>`
  ${({ alert }) => css`
    border-radius: 8px 8px 0 0;
    background: ;

    padding: 24px 24px;

    gap: 8px;
    display: flex;
    flex-direction: column;

    ${alertVariations[alert]}
    ${responsive.Header}
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    border-radius: 0 0 0 8px;
    background: ${theme.colors.asideMenu};

    padding: 16px 24px;

    gap: 24px;
    display: flex;

    > span {
      color: ${theme.colors.text.title};
      text-align: center;

      display: none;
    }

    ${responsive.Footer}
  `}
`;

export const ReplyNowButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: fit-content;

    border: 1px solid ${theme.colors.activities.info};
    border-radius: ${theme.borders.radii[100]};
    background: ${theme.colors.activities.info};

    color: ${theme.colors.white};
    font-weight: bold;
    font-size: ${theme.typography.fontSize.normal};

    padding: 10px 16px;

    display: flex;
    justify-content: center;

    transition: all 400ms;

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      border-color: ${theme.colors.activities.info};
      background: ${transparentize(0.6, theme.colors.activities.info)};

      color: ${theme.title === 'light'
        ? theme.colors.activities.info
        : theme.colors.white};
    }

    span {
      &.mobile {
        display: none;
      }
    }

    ${responsive.ReplyNowButton}
  `}
`;

export const ReplyLaterButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: fit-content;

    border: 1px solid ${theme.colors.activities.selection};
    border-radius: ${theme.borders.radii[100]};
    background: transparent;

    color: ${theme.colors.activities.selection};
    font-weight: bold;
    font-size: ${theme.typography.fontSize.normal};

    padding: 10px 16px;

    display: flex;
    justify-content: center;

    transition: all 400ms;

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      border-color: ${theme.colors.activities.selection};
      background: ${theme.colors.activities.selection};

      color: ${theme.colors.white};
    }

    span {
      &.mobile {
        display: none;
      }
    }

    ${responsive.ReplyLaterButton}
  `}
`;
