import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid
      ${theme.title === 'light' ? theme.colors.lines : theme.colors.asideMenu};
    border-radius: 8px 8px 8px 0;
    background: ${transparentize(0.4, theme.colors.asideMenu)};

    margin: 24px 16px 0 0;
    padding: 8px 16px 8px 8px;

    position: relative;

    gap: 8px;
    display: flex;

    & + div {
      margin: 28px 16px 0 0;
    }

    label {
      font-weight: bold;
      font-size: 0.875rem;

      position: absolute;
      top: -19px;
      left: 8px;
    }

    > div {
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;

    border: 1px solid
      ${theme.title === 'light' ? theme.colors.lines : theme.colors.asideMenu};
    border-radius: 50px;
    background: ${theme.colors.background};

    padding: 2px;

    position: absolute;
    top: 50%;
    right: -11px;

    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(-50%);

    transition: all 200ms;

    &:hover {
      width: 86px;

      &.cancel {
        border-color: ${theme.colors.activities.info};
        background: ${theme.colors.activities.info};
      }

      span {
        display: block;
      }

      svg {
        stroke: ${theme.colors.white};

        path {
          fill: ${theme.colors.white};
        }
      }
    }

    span {
      color: ${theme.colors.white};

      display: none;
    }

    svg {
      stroke: ${theme.title === 'light'
        ? theme.colors.lines
        : theme.colors.asideMenu};

      path {
        fill: ${theme.title === 'light'
          ? theme.colors.lines
          : theme.colors.asideMenu};
      }
    }
  `}
`;
