import React, { useEffect, useState, useRef } from 'react';

import { Checkbox, Close, Container, FilterSelect, Label } from './styles';

export interface IFloaterItem {
  id: Number;
  label: String;
  selectedAt: Number;
  selected: Boolean;
  avatar?: String;
}

interface ISelectFloaterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  isVisible: Boolean;
  label: String;
  labelPlural: String;
  itens: IFloaterItem[];
  setIsVisible: Function;
  setSelectedItens?: Function;
}

const SelectFloater: React.FC<ISelectFloaterProps> = ({
  className,
  label,
  labelPlural,
  isVisible = false,
  setIsVisible,
  itens = [],
  setSelectedItens,
  ...rest
}) => {
  const [listLoaded, setListLoaded] = useState<Boolean>(false);
  const [sorting, setSorting] = useState<Boolean>(false);
  const [itemList, setItemList] = useState<IFloaterItem[]>([]);
  const [filtredList, setFiltredList] = useState<IFloaterItem[]>([]);
  const [selectedList, setSelectedList] = useState<IFloaterItem[]>([]);
  const [sortedList, setSortedList] = useState<IFloaterItem[]>([]);
  const [inputTimeout, setInputTimeout] = useState<any>(false);

  useEffect(() => {
    if (!listLoaded) {
      setItemList(itens);
      setFiltredList(itens);
      setListLoaded(true);
    }
  }, [itens, listLoaded]);

  useEffect(() => {
    if (sorting) {
      return;
    }
    setSorting(true);
    const sorted: IFloaterItem[] = selectedList.sort((a, b) => {
      return a.selectedAt > b.selectedAt ? 1 : -1;
    });
    setSortedList(sorted);
    setSorting(false);
  }, [selectedList, sorting]);

  useEffect(() => {
    const selLista: IFloaterItem[] = [];
    setFiltredList(itemList);
    for (let key = 0; key < itemList.length; key++) {
      const tempItem = itemList[key];
      if (tempItem.selected) {
        selLista.push(tempItem);
      }
    }
    setSelectedList(selLista);
    if (setSelectedItens != null) {
      setSelectedItens(selLista);
    }
  }, [itemList]);

  const inputREF = useRef<HTMLInputElement>(null);

  const handleSelect = (compareID: String, selected: Boolean) => {
    const novaLista: IFloaterItem[] = [];
    const sel: IFloaterItem[] = [];

    for (let key = 0; key < itemList.length; key++) {
      const novoItem = itemList[key];
      const nItemID = novoItem.id.toString();
      if (nItemID === compareID) {
        const dt = new Date();
        novoItem.selected = selected;
        novoItem.selectedAt = dt.getTime();
        sel.push(novoItem);
      }
      novaLista.push(novoItem);
    }
    inputREF.current!.value = '';
    setItemList(novaLista);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return <></>;
  }

  return (
    <Container className={className} {...rest}>
      <Close onClick={handleClose}>&#x2715;</Close>
      <Label>Pesquisar por {label}</Label>
      <FilterSelect>
        {sortedList?.map(item => {
          return (
            <span key={item.id.toString()}>
              {item.label}
              <i
                data-id={item.id}
                onClick={event => {
                  const self = event.target as HTMLInputElement;
                  const selfID = self.getAttribute('data-id');
                  handleSelect(String(selfID), false);
                }}
              />
            </span>
          );
        })}
        <input
          ref={inputREF}
          type="text"
          name="inputValue"
          onInput={event => {
            const self = event.target as HTMLInputElement;
            clearTimeout(inputTimeout);
            setInputTimeout(
              setTimeout(() => {
                const novaLista: IFloaterItem[] = [];
                const searchString: string = self.value
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, '');
                for (let key = 0; key < itemList.length; key++) {
                  const novoItem = itemList[key];
                  const itemLabel = novoItem.label
                    .toLowerCase()
                    .replace(/[^a-z0-9]/g, '');
                  const index = itemLabel.indexOf(searchString);
                  if (index >= 0) {
                    novaLista.push(novoItem);
                  }
                }
                setFiltredList(novaLista);
              }, 250),
            );
          }}
        />
      </FilterSelect>
      <div data-label={labelPlural}>
        <ul>
          {filtredList?.map(item => {
            const inputID = `'input-${label}-${item.id.toString()}'`;
            return (
              <li key={item.id.toString()}>
                <Checkbox>
                  <input
                    type="checkbox"
                    value={item.id.toString()}
                    id={inputID}
                    checked={item.selected === true}
                    onChange={event => {
                      const self = event.target as HTMLInputElement;
                      const selfID = self.value;
                      handleSelect(String(selfID), self.checked);
                    }}
                  />
                  <label htmlFor={inputID} />
                </Checkbox>
                <label htmlFor={inputID}>{item.label}</label>
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
};

const Component = React.memo(SelectFloater);

export { Component as SelectFloater };
