/* eslint-disable prefer-destructuring */
import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import { IProductivityItem } from '@components/atoms/Charts/Productivity';
import { IBarType } from '@components/atoms/Charts/Productivity/styles';
import { ICollaboratorProductivity } from '@interfaces/IDashboard';
import { groupBy } from '@utils/groupBy';

class DashboardHelper {
  public orderByCost(data: IProductivityItem[], by?: IBarType) {
    const ordered = data.sort((a, b) => {
      let aHoursTotal = a.hours.reduce((acc, i) => acc + i, 0) / 3600;
      let bHoursTotal = b.hours.reduce((acc, i) => acc + i, 0) / 3600;

      if (by) {
        if (by === 'productive') {
          aHoursTotal = a.hours[0];
          bHoursTotal = b.hours[0];
        }

        if (by === 'unproductive') {
          aHoursTotal = a.hours[1];
          bHoursTotal = b.hours[1];
        }

        if (by === 'passive') {
          aHoursTotal = a.hours[2];
          bHoursTotal = b.hours[2];
        }

        if (by === 'undefined') {
          aHoursTotal = a.hours[3];
          bHoursTotal = b.hours[3];
        }
      }

      const aTotal = a.value.cost * aHoursTotal;
      const bTotal = b.value.cost * bHoursTotal;

      return aTotal > bTotal ? -1 : aTotal > bTotal ? 1 : 0; // desc
    });

    if (ordered.length === 0) return undefined;

    return ordered;
  }

  public orderByHours(data: IProductivityItem[], by?: IBarType) {
    const ordered = data.sort((a, b) => {
      let aHoursTotal = a.hours.reduce((acc, i) => acc + i, 0) / 3600;
      let bHoursTotal = b.hours.reduce((acc, i) => acc + i, 0) / 3600;

      if (by) {
        if (by === 'productive') {
          aHoursTotal = a.hours[0];
          bHoursTotal = b.hours[0];
        }

        if (by === 'unproductive') {
          aHoursTotal = a.hours[1];
          bHoursTotal = b.hours[1];
        }

        if (by === 'passive') {
          aHoursTotal = a.hours[2];
          bHoursTotal = b.hours[2];
        }

        if (by === 'undefined') {
          aHoursTotal = a.hours[3];
          bHoursTotal = b.hours[3];
        }
      }

      return aHoursTotal > bHoursTotal ? -1 : aHoursTotal > bHoursTotal ? 1 : 0; // desc
    });

    if (ordered.length === 0) return undefined;

    return ordered;
  }

  public orderByDate(data: IProductivityItem[]) {
    const ordered = data.sort((a, b) => {
      return a.date > b.date ? -1 : a.date > b.date ? 1 : 0; // desc
    });

    if (ordered.length === 0) return undefined;

    return ordered;
  }

  public order(
    data: IProductivityItem[],
    type: 'cost' | 'hours' | 'date',
    by?: IBarType,
  ) {
    if (type === 'cost') return this.orderByCost(data, by);
    if (type === 'date') return this.orderByDate(data);

    return this.orderByHours(data, by);
  }

  public collaboratorsProductivity(data: ICollaboratorProductivity[]) {
    const grouped = groupBy(data, item => item.user?.id);

    const rowsAgrouped: IProductivityItem[] = Array.from(grouped.keys()).map(
      item => {
        const userWorkedHour = grouped
          .get(item)
          .find((i: any) => i.user.id === item);

        const foundedCurrentCost = userWorkedHour.user?.costs?.find(
          (cost: any) =>
            parseISO(cost.to) >=
            parseISO(format(new Date(), 'yyyy-MM-dd', { locale })),
        );

        const groupedHours = grouped.get(item)[0];

        const hours = [
          groupedHours.productive,
          groupedHours.unproductive,
          groupedHours.passive,
          groupedHours.undefined,
        ] as [number, number, number, number];

        return {
          id: userWorkedHour.user.id,
          name: userWorkedHour.user.name,
          date: groupedHours.worked_date,
          value: {
            cost:
              Number(foundedCurrentCost?.cost || 0) *
              (hours.reduce((acc, i) => acc + i, 0) / 3600),
            hours: hours.reduce((acc, i) => acc + i, 0) / 3600,
          },
          hours,
        };
      },
    );

    Array.from(grouped.keys()).map(item => {
      let productiveHours = 0;
      let unproductiveHours = 0;
      let passiveHours = 0;
      let undefinedHours = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i].user.id === item) {
          productiveHours += data[i].productive;
          unproductiveHours += data[i].unproductive;
          passiveHours += data[i].passive;
          undefinedHours += data[i].undefined;
        }
      }

      rowsAgrouped.forEach(row => {
        if (row.id === item) {
          row.hours[0] = productiveHours;
          row.hours[1] = unproductiveHours;
          row.hours[2] = passiveHours;
          row.hours[3] = undefinedHours;
        }
      });

      return item;
    });

    return rowsAgrouped;
  }

  public collaboratorProductivityByDate(data: ICollaboratorProductivity[]) {
    const grouped = groupBy(data, item => item.worked_date);

    const rowsAgrouped: IProductivityItem[] = Array.from(grouped.keys()).map(
      item => {
        const userWorkedHour = grouped
          .get(item)
          .find((i: any) => i.worked_date === item);

        const foundedCurrentCost = userWorkedHour.user?.costs?.find(
          (cost: any) =>
            parseISO(cost.to) >=
            parseISO(format(new Date(), 'yyyy-MM-dd', { locale })),
        );

        const groupedHours = grouped.get(item)[0];

        const hours = [
          groupedHours.productive,
          groupedHours.unproductive,
          groupedHours.passive,
          groupedHours.undefined,
        ] as [number, number, number, number];

        return {
          id: userWorkedHour.user.id,
          name: format(parseISO(userWorkedHour.worked_date), 'dd/MM/yyyy', {
            locale,
          }),
          date: groupedHours.worked_date,
          value: {
            cost:
              Number(foundedCurrentCost?.cost || 0) *
              (hours.reduce((acc, i) => acc + i, 0) / 3600),
            hours: hours.reduce((acc, i) => acc + i, 0) / 3600,
          },
          hours,
        };
      },
    );

    return rowsAgrouped;
  }
}

const INSTANCE = new DashboardHelper();

export { INSTANCE as DashboardHelper };
