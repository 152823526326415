import React from 'react';

import { useCan } from '@hooks/useCan';
import { IRoleAlias } from '@interfaces/IRole';

interface ICanProps {
  permissions: string[];
  roles: IRoleAlias[];
}

const Can: React.FC<ICanProps> = ({ permissions, roles, children }) => {
  const useCanSeeComponent = useCan({ permissions, roles });

  if (!useCanSeeComponent) return null;

  return <>{children}</>;
};

export { Can };
