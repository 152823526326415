import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    flex: 1;

    input {
      width: 100%;
      min-height: 42px;

      border-radius: ${theme.borders.radii[100]};
      border: 1px solid ${theme.colors.activities.selection};
      background: ${theme.colors.background};

      color: ${theme.colors.text.subtitle};

      padding: 8px;

      &:focus {
        border: 1px solid ${theme.colors.activities.info};
      }

      &::placeholder {
        color: ${theme.colors.text.subtitle};
      }
    }
  `}
`;

export const Results = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-height: 40vh;

    border: 1px solid ${theme.colors.activities.selection};
    border-radius: ${theme.borders.radii[100]};
    background: ${theme.colors.background};

    position: absolute;
    bottom: calc(100% + 8px);

    flex: 1;
    display: flex;
    flex-direction: column;

    z-index: 1000;

    overflow-y: scroll;

    ${customScroll(theme.colors.background)}
  `}
`;

export const ResultItem = styled.button`
  ${({ theme }) => css`
    width: 100%;

    border: none;
    background: transparent;

    color: ${theme.colors.text.subtitle};

    padding: 8px 16px;

    display: flex;
    align-items: flex-start;

    &:hover {
      background: ${transparentize(0.8, theme.colors.activities.info)};

      color: ${theme.colors.activities.info};

      cursor: pointer;
    }
  `}
`;
