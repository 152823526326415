import React, { useCallback } from 'react';

import { format } from 'date-fns';
import { v4 } from 'uuid';

import defaultAvatar from '@assets/images/default-avatar.png';
import { IModalRef, Modal } from '@components/organisms';
import { useToast } from '@hooks/useToast';
import { IDaily } from '@interfaces/IDaily';
import { updateDailyAnswer } from '@services/apis/DailyAnswersService';
import { toHMS } from '@utils/toHMS';

import {
  ModalContent,
  DailyHeader,
  List,
  Tag,
  Question,
  Daily,
  Button,
} from './styles';

interface IDailyModalProps {
  daily: IDaily | undefined;
  setDaily: React.Dispatch<React.SetStateAction<IDaily | undefined>>;
  dailyModalRef: React.RefObject<IModalRef>;
  closeModal: () => void;
  getDayReference: (date: string) => string;
}

const DailyModal: React.FC<IDailyModalProps> = ({
  dailyModalRef,
  daily,
  setDaily,
  getDayReference,
}) => {
  const { addToast } = useToast();

  const handleFinishImpediment = useCallback(
    async id => {
      const data = { resolved_at: new Date() };

      if (daily && id) {
        const updatedDailyAnswer = await updateDailyAnswer(daily.id, id, data);

        const updatedDailyAnswers = daily.daily_answers.map(item => {
          if (item.id === updatedDailyAnswer.id) return updatedDailyAnswer;

          return item;
        });

        setDaily({ ...daily, daily_answers: updatedDailyAnswers });

        addToast({ title: 'Impeditivo concluído!', type: 'success' });
      }
    },
    [addToast, daily, setDaily],
  );

  return (
    <Modal
      ref={dailyModalRef}
      size="medium"
      hasNavigation={false}
      onClose={() => {
        setDaily(undefined);
      }}
    >
      <ModalContent>
        <DailyHeader>
          <img src={defaultAvatar} alt="" />

          <div>
            <strong>{daily?.user?.name}</strong>

            <span>
              respondido{' '}
              {daily?.created_at
                ? getDayReference(
                    format(new Date(daily?.created_at), 'yyyy-MM-dd'),
                  )
                : '-'}
            </span>
          </div>
        </DailyHeader>

        <List>
          {daily?.daily_answers.map(item => {
            const status =
              item.impediment && item.resolved_at
                ? 'resolved'
                : item.impediment && !item.resolved_at
                ? 'alert'
                : 'default';

            let projectName = 'Projeto não informado';
            let checked_worked_hours;
            let original_worked_hours = 0;

            if (item.summary_activity) {
              projectName = item.summary_activity.project.name;

              if (item.summary_activity.checked_worked_hours)
                checked_worked_hours =
                  item.summary_activity.checked_worked_hours;
              else
                original_worked_hours =
                  item.summary_activity.original_worked_hours;
            }

            return (
              <Daily key={v4()}>
                <Tag status={status}>
                  {projectName}
                  {' - '}
                  {`${toHMS(checked_worked_hours || original_worked_hours)
                    .replace(':', 'h ')
                    .replace(':', 'm ')}s`}
                </Tag>

                {item.accomplished && (
                  <Question>
                    <span>O que você concluiu ontem neste projeto?</span>
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {item.accomplished}
                    </span>
                  </Question>
                )}

                {item.planned && (
                  <Question>
                    <span>O que você pretende fazer hoje?</span>
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {item.planned}
                    </span>
                  </Question>
                )}

                {item.impediment && (
                  <Question>
                    <span>Você tem algum impeditivo?</span>
                    <span style={{ whiteSpace: 'break-spaces' }}>
                      {item.impediment}
                    </span>

                    {!item.resolved_at && (
                      <div>
                        <span>Esse impeditivo já foi resolvido?</span>

                        <div>
                          <Button
                            actionType="add"
                            onClick={() => handleFinishImpediment(item.id)}
                          >
                            Sim
                          </Button>
                        </div>
                      </div>
                    )}
                  </Question>
                )}
              </Daily>
            );
          })}
        </List>
      </ModalContent>
    </Modal>
  );
};

export { DailyModal };
