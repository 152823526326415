import styled, { css } from 'styled-components';

import { Form } from '@contexts/ReactFormContext';

const widthResponsive = css`
  @media (min-width: 0px) and (max-width: 540px) {
    .molecules-forms-user-form_row {
      flex-direction: column;
      gap: 40px;
    }
  }
`;

export const Container = styled(Form).attrs({
  className: 'molecules-forms-user-container',
})`
  width: 100%;
  max-width: 700px;

  padding: 32px;
  margin: 0 auto;

  row-gap: 40px;
  column-gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${widthResponsive}
`;

export const FormRow = styled.div.attrs({
  className: 'molecules-forms-user-form_row',
})`
  width: 100%;

  gap: 32px;
  display: flex;
`;
