import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

export const ScreenshotSearchForm = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 59px;
    padding: calc((59px - 32px) / 2);
    display: flex;
    background-color: ${theme.colors.text.input}2e;
    margin-bottom: 22px;
    border-radius: 10px;
    gap: 10px;

    > em {
      flex: 1;
    }

    > div.datepicker {
      position: relative;
      color: ${theme.colors.text.title};
      background-color: ${theme.colors.activities.selection};
      height: 34px;
      line-height: 34px;
      padding: 0 10px;
      border-bottom: none;
      border-radius: 5px;
      font-weight: normal;
      > input {
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.fontSize.small};
        color: ${theme.colors.text.title};
        width: calc(100% - 40px);
        display: inline-block;
        ::placeholder {
          color: ${theme.colors.text.title};
          opacity: 1; /* Firefox */
        }
        + div {
          border-bottom-color: ${theme.colors.text.title};
        }
        + div {
          border-bottom-color: transparent;
          :before {
            content: '\\2228';
            font-weight: bold;
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSize.large};
            display: inline-block;
            transform: scaleY(-0.6);
            cursor: pointer;
            position: absolute;
            left: -6px;
            top: -12px;
          }
        }
      }
      :after {
        content: '';
        width: 16px;
        height: 16px;
        margin: 0 5px;
        display: inline-block;
        background: transparent
          url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAWklEQVR4nO2UQQrAIAwE93na/7+gPmSk0B5twUSLkjknGbKESNvCjZzq5giAAyj0U4D8JrAMfzh91u3pnyawot8FGh2RQtAiIiKuyCOi4e86XwWW4UD6XHUpKpoCidOiWUh6AAAAAElFTkSuQmCC)
          center center no-repeat;
        background-size: contain;
      }
    }
  `}
`;

export const SQButton = styled.button`
  ${({ theme }) => css`
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    width: 34px;
    text-align: center;
    display: inline-block;
    border: none;
    color: ${theme.colors.text.title};
    background-color: ${theme.colors.activities.selection};
    border-radius: 5px;

    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSize.small};
    font-weight: normal;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    margin-left: 5px;
  `}
`;

export const SFButton = styled.button`
  ${({ theme }) => css`
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    display: inline-block;
    border: none;
    color: ${theme.colors.text.title};
    background-color: ${theme.colors.activities.selection};
    border-radius: 5px;

    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSize.small};
    font-weight: normal;
    letter-spacing: 0.6px;
    cursor: default;
    vertical-align: middle;

    > svg {
      width: ${theme.typography.fontSize.normal};
      height: ${theme.typography.fontSize.normal};
      margin: 0 8px;
      vertical-align: middle;
    }

    :after {
      content: '\\2228';
      font-weight: bold;
      font-family: ${theme.typography.fontFamily};
      font-size: ${theme.typography.fontSize.large};
      padding-left: 3px;
      display: inline-block;
      transform: scaleY(0.6);
      cursor: pointer;
    }
  `}
`;

export const ScreenshotContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;

    gap: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    height: calc(100% - 85px);

    &:after {
      content: '';
      display: block;
      width: 100%;
      min-height: -webkit-fill-available;
    }

    ${customScroll(theme.colors.cards)}
  `}
`;
