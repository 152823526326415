import React from 'react';

import { Container } from './styles';

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Card: React.FC<ICardProps> = ({ className, children, ...rest }) => {
  return (
    <Container className={className} {...rest}>
      {children}
    </Container>
  );
};

const Component = React.memo(Card);

export { Component as Card };
