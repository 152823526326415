import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      height: calc(100% - 76px);

      padding: 24px;
    }
  `,
  TitleArea: css`
    @media (max-width: 768px) {
      display: none;
    }
  `,
  RowCharts: css`
    @media (max-width: 768px) {
      &.half_donuts {
        gap: 8px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      &.productivity_activities {
        flex-direction: column;
      }
    }
  `,
};

export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100% - 92px);

    padding: 24px 48px 32px;

    overflow-y: scroll;

    gap: 32px;
    display: flex;
    flex-direction: column;

    ${customScroll(theme.colors.background)}
    ${responsive.Container}
  `}
`;

export const TitleArea = styled.div`
  margin: -8px 0;

  display: flex;
  justify-content: space-between;

  ${responsive.TitleArea}
`;

export const RowCharts = styled.div`
  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${responsive.RowCharts}
`;
