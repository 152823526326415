import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  ComponentType,
  SelectHTMLAttributes,
  useLayoutEffect,
} from 'react';
import { useMemo } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';

import { colors } from '@components/bosons/colors';
import { useClickAway } from '@hooks/useClickAway';
import { useUnform } from '@hooks/useUnform';

import { Tag } from '../Tag';

import {
  Container,
  LabelContainer,
  IconContainer,
  ErrorMessage,
  OptionsContainer,
  Option,
  SelectOption,
  SearchableOption,
} from './styles';

export type IOption = { label: string; value: string | number };

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options?: IOption[];
  valueToReturn?: 'label' | 'value';
  icon?: ComponentType<IconBaseProps>;
  defaultValue?: any;
  errorMessage?: string;
  mode?: 'light' | 'dark';
}

const Select: React.FC<ISelectProps> = ({
  name,
  label,
  options: optionsPassed,
  valueToReturn = 'label',
  icon: Icon,
  placeholder,
  disabled,
  multiple,
  defaultValue,
  errorMessage,
  mode,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const unform = useUnform(name);

  const [isSearched, setIsSearched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [options, setOptions] = useState(optionsPassed || []);
  const [selectedValues, setSelectedValues] = useState<IOption[]>([
    { label: placeholder || '', value: 0 },
  ]);

  const iconColor = useMemo(() => {
    if ((!!errorMessage || !!unform?.error) && !isFilled)
      return colors.input.errored.border;
    if (disabled && (!!errorMessage || !!unform?.error))
      return colors.input.disabled.border;

    return colors.activities.info;
  }, [errorMessage, unform?.error, isFilled, disabled]);

  const handleSelectFocus = useCallback(() => {
    if (disabled) return;
    setIsFocused(true);
  }, [disabled]);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);

    const val: number = parseInt(
      (selectedValues[0]?.value ?? '').toString(),
      10,
    );
    setIsFilled(val > 0);
  }, [selectedValues]);

  const handleSelect = useCallback(
    (value: IOption) => {
      setSelectedValues(prevValues => {
        if (multiple && parseInt(prevValues[0].value.toString(), 10) > 0) {
          return [...prevValues, value];
        }

        return [value];
      });
      setIsFocused(false);
      setIsFilled(true);
      setIsSearched(false);
      if (optionsPassed) setOptions(optionsPassed);
    },
    [multiple, optionsPassed],
  );

  const handleDeselect = useCallback(
    (value: IOption) => {
      setSelectedValues(prevValues => {
        if (prevValues.length === 1) {
          return [{ label: placeholder || '', value: 0 }];
        }

        return [...prevValues.filter(item => item.value !== value.value)];
      });

      setIsFocused(false);
      setIsFilled(false);
    },
    [placeholder],
  );

  const handleSearchOption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (optionsPassed) {
        if (value.length >= 1) {
          setIsSearched(true);

          const foundedOptions = optionsPassed.filter(item =>
            item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
          );

          setOptions(foundedOptions);
        } else {
          setIsSearched(false);
          setOptions(optionsPassed);
        }
      }
    },
    [optionsPassed],
  );

  const handleCloseOnClick = useCallback(() => {
    handleSelectBlur();
  }, [handleSelectBlur]);

  useLayoutEffect(() => {
    if (optionsPassed) setOptions(optionsPassed);
  }, [optionsPassed]);

  useEffect(() => {
    if (unform?.defaultValue) {
      let selectedValuesLength = 0;

      if (!unform.defaultValue.length)
        unform.defaultValue = [unform.defaultValue];

      setSelectedValues(prevOptions => {
        const foundedOptions: IOption[] = [];

        options?.forEach(option => {
          unform.defaultValue.forEach((defaultOption: number) => {
            if (option.value === Number(defaultOption)) {
              const foundedOption = options.find(
                item => item.value === option.value,
              );
              if (foundedOption) foundedOptions.push(foundedOption);
            }
          });
        });

        if (foundedOptions) {
          if (foundedOptions.length > 0) {
            selectedValuesLength = foundedOptions.length;
            return [...foundedOptions];
          }
        }

        selectedValuesLength = prevOptions.length;

        return prevOptions;
      });

      setIsFilled(selectedValuesLength > 0);

      return;
    }

    setIsFilled(false);
  }, [options, unform?.defaultValue]);

  useEffect(() => {
    if (unform) {
      unform.registerField({
        name: unform.fieldName,
        ref: selectRef.current,
        getValue: (ref: HTMLSelectElement) => {
          if (ref.selectedOptions[0]?.value === '0') return undefined;

          return selectedValues[0].value;
        },
        setValue: (ref: HTMLSelectElement, value: any) => {
          if (value?.length > 0) {
            const initialSelected: IOption[] = [];

            value.forEach((optionMapped: number) => {
              const founded = options.find(
                option => option.value === Number(optionMapped),
              );

              if (founded) initialSelected.push(founded);
            });

            if (initialSelected.length > 0) {
              setSelectedValues([...initialSelected]);
              setIsFilled(true);
            }
          }

          ref.value = value;
        },
        clearValue: ref => {
          setSelectedValues([{ label: placeholder || '', value: 0 }]);
          setIsFilled(false);
          ref.value = '';
        },
      });
    }
  }, [multiple, options, placeholder, selectedValues, unform, valueToReturn]);

  useClickAway(containerRef, handleCloseOnClick, { enabled: isFocused });

  useEffect(() => {
    if (defaultValue && optionsPassed) {
      const foundedOption = optionsPassed.find(item =>
        String(item.value)
          .toLocaleLowerCase()
          .includes(String(defaultValue).toLocaleLowerCase()),
      );

      if (foundedOption) setSelectedValues([foundedOption]);
    }
  }, [defaultValue, optionsPassed]);

  const val: number = parseInt(selectedValues[0]?.value.toString(), 10);

  return (
    <Container
      id={`select-${name}`}
      ref={containerRef}
      isErrored={(!!errorMessage || !!unform?.error) && !isFilled}
      isFocused={isFocused}
      isFilled={
        isFilled ||
        (defaultValue && !!unform?.defaultValue) ||
        parseInt(selectedValues[0]?.value.toString(), 10) > 0
      }
      disabled={disabled}
      mode={mode}
      className="select"
    >
      {label && (
        <LabelContainer
          isFocused={isFocused}
          isFilled={
            isFilled ||
            (defaultValue && !!unform?.defaultValue) ||
            parseInt(selectedValues[0]?.value.toString(), 10) > 0
          }
          disabled={disabled}
        >
          {label}
        </LabelContainer>
      )}

      <select
        {...rest}
        ref={selectRef}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        defaultValue={defaultValue}
      >
        <option value={selectedValues[0]?.value || 0}>
          {selectedValues[0]?.label || placeholder}
        </option>

        {options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {val > 0 && !isFocused ? (
        <SelectOption onBlur={handleSelectBlur} onClick={handleSelectFocus}>
          <Tag
            isSelected
            label={selectedValues[0]?.label || placeholder || ''}
            onClick={() => handleDeselect(selectedValues[0])}
          />
        </SelectOption>
      ) : !isFocused ? (
        <SelectOption onBlur={handleSelectBlur} onClick={handleSelectFocus}>
          {placeholder || 'Escolha uma opção'}
        </SelectOption>
      ) : (
        <SearchableOption
          type="text"
          autoFocus
          placeholder={placeholder}
          onChange={handleSearchOption}
          mode={disabled && mode === 'dark' ? 'dark' : 'light'}
        />
      )}

      <OptionsContainer
        isErrored={(!!errorMessage || !!unform?.error) && !isFilled}
        isFocused={isFocused}
      >
        {options?.map(option => (
          <Option
            key={option.value}
            isSearched={isSearched}
            isMultiple={multiple}
            isSelected={
              option ===
              selectedValues.find(item => item.value === option.value)
            }
            mode={disabled && mode === 'dark' ? 'dark' : 'light'}
            onClick={() => handleSelect(option)}
          >
            {multiple && (
              <Tag
                label={option.label}
                onClick={() => handleDeselect(option)}
              />
            )}

            {!multiple &&
              (isSearched ? (
                <Tag
                  label={option.label}
                  onClick={() => handleDeselect(option)}
                />
              ) : (
                <span>{option.label}</span>
              ))}
          </Option>
        ))}
      </OptionsContainer>

      {Icon ? (
        <IconContainer disabled={disabled}>
          <Icon size={20} color={iconColor} />
        </IconContainer>
      ) : !isFocused ? (
        <IconContainer disabled={disabled}>
          <FiChevronDown
            size={20}
            color={iconColor}
            onClick={() => selectRef.current?.click()}
          />
        </IconContainer>
      ) : isSearched ? (
        <IconContainer disabled={disabled}>
          <FiSearch
            size={20}
            color={iconColor}
            onClick={() => selectRef.current?.click()}
          />
        </IconContainer>
      ) : (
        <IconContainer disabled={disabled}>
          <FiChevronUp
            size={20}
            color={iconColor}
            onClick={() => selectRef.current?.click()}
          />
        </IconContainer>
      )}

      {(errorMessage || unform?.error) && !isFilled && (
        <ErrorMessage>{errorMessage || unform?.error}</ErrorMessage>
      )}
    </Container>
  );
};

export { Select };
