import React, { useMemo, useState, useCallback, createContext } from 'react';

import { setCookie, parseCookies, destroyCookie } from 'nookies';

import {
  AUTH_ORIGIN,
  AUTH_COOKIE_REFRESH_TOKEN,
  AUTH_COOKIE_TOKEN,
  AUTH_LOCAL_STORAGE_USER,
} from '@constants/auth';
import { IAuthRequest } from '@interfaces/IAuthentication';
import { IUser } from '@interfaces/IUser';
import { login } from '@services/apis/AuthService';
import { getJWTPayload } from '@utils/getJWTPayload';

export interface IAuthContext {
  user?: IUser;
  signed: boolean;
  profile: number;
  origin: string | undefined;
  signIn: (formData: IAuthRequest) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function signOut() {
  destroyCookie(undefined, AUTH_COOKIE_TOKEN);
  destroyCookie(undefined, AUTH_COOKIE_REFRESH_TOKEN);

  localStorage.removeItem(AUTH_LOCAL_STORAGE_USER);

  window.location.href = '/login';
}

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>(() => {
    const foundedUser = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

    if (!foundedUser) return undefined;

    return JSON.parse(foundedUser);
  });
  const [signed, setSigned] = useState<boolean>(() => {
    const { 'devstream.token': token } = parseCookies();

    if (token) return true;

    return false;
  });

  const [profile, setProfile] = useState<number>(() => {
    const { 'devstream.token': token } = parseCookies();
    const { role_id } = getJWTPayload(token);

    return role_id;
  });

  const [origin] = useState<string | undefined>(() => {
    const foundedOrigin = localStorage.getItem(AUTH_ORIGIN);

    if (!foundedOrigin) return undefined;

    return foundedOrigin;
  });

  const signIn = useCallback(async (formData: IAuthRequest) => {
    const { data } = await login(formData);

    setCookie(undefined, AUTH_COOKIE_TOKEN, data.token, {
      maxAge: 60 * 60 * 24 * 30, // 30 days
      path: '/',
    });

    setCookie(undefined, AUTH_COOKIE_REFRESH_TOKEN, data.refresh_token, {
      maxAge: 60 * 60 * 24 * 30, // 30 days
      path: '/',
    });

    localStorage.setItem(AUTH_LOCAL_STORAGE_USER, JSON.stringify(data.user));

    setUser(data.user);
    setSigned(true);
    setProfile(getJWTPayload(data.token).role_id);
  }, []);

  const value = useMemo(() => {
    return { user, signed, profile, origin, signIn, signOut };
  }, [user, signed, profile, origin, signIn]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
