import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 300px;
  width: 100%;

  background-color: #292f4d;

  padding: 24px 24px 32px 24px;
  border-radius: 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 24px;
`;

export const HeaderLeft = styled.div`
  ${({ theme }) => css`
    gap: 10px;
    display: flex;
    flex-direction: column;

    > strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.large};
    }

    > div {
      gap: 8px;
      display: flex;
    }
  `}
`;

export const HeaderTitle = styled.strong`
  color: #fff;
  font-size: 1.1rem;
`;

export const HeatmapLabel = styled.div`
  display: flex;
  background-color: #0a192f;
  height: 24px;
  border-radius: 16px;
  padding: 4px 18px;
  gap: 6px;
  align-items: center;

  span {
    color: #fff;
    font-weight: 400;
    font-size: 0.8em;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    div:nth-child(1) {
      border: 1px solid #292f4d;
      background-color: transparent;
      width: 16px;
      height: 16px;
    }

    div:nth-child(2) {
      background: #00b0ff;
      opacity: 0.32;
    }

    div:nth-child(3) {
      background: #00b0ff;
      opacity: 0.64;
    }

    div:nth-child(4) {
      background: #00b0ff;
    }
  }
`;

export const HeatmapLabelSquare = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 2px;
`;

export const HeaderRight = styled.div`
  gap: 8px;
  display: flex;
`;

// Coloquei os componentes acima do tab por conta de me encomodar, estava branco as variaveis e após eu finalizar devo corrigir e colocar la embaixo

export const HeatmapChart = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  color: #666a88;
  font-size: 0.9rem;
`;

export const HeatmapAxisY = styled.div`
  display: flex;
  flex-direction: column;

  padding: 18px 14px 0px 0px;

  gap: 20px;
`;

export const HeatmapAxisX = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: -4px;
`;

interface IHeatmapContentProps {
  columns?: number;
  rowsSize?: number;
}

export const HeatmapContent = styled.div<IHeatmapContentProps>`
  ${({ columns = 53, rowsSize = 17 }) => css`
    height: 100%;
    width: 100%;

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(7, ${rowsSize}px);
    grid-template-columns: repeat(${columns}, 17px);

    row-gap: 2px;
    column-gap: 2px;
  `}
`;

interface IHeatmapSquareProps {
  color?: string;
  isActive?: boolean;
  size?: number;
  type?: 'none' | 'low' | 'medium' | 'high';
}

export const HeatmapSquare = styled.div<IHeatmapSquareProps>`
  ${({ isActive = true, size = 16, type }) => css`
    display: flex;
    border-radius: 1px;

    width: ${size}px;
    height: ${size}px;

    background-color: ${type === 'none'
      ? '#0A192F'
      : type === 'low'
      ? 'rgba(0, 176, 255, 0.32)'
      : type === 'medium'
      ? 'rgba(0, 176, 255, 0.64)'
      : type === 'high'
      ? 'rgba(0, 176, 255, 1)'
      : ''};

    opacity: ${isActive ? 1 : 0};
  `}
`;

// Devo colocar embaixo do componente Header Right

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.button<ITabProps>`
  ${({ isActive }) => css`
  border: 1px solid ${isActive ? '#00B0FF' : '#6C87BF'};
  border-radius: 4px;
  background: ${isActive ? '#00B0FF' : 'transparent'};

  color: ${isActive ? '#fff' : '#6C87BF'};
  font-size: 0.8rem;
  font-weight: 400;

  padding: 8px 16px;

  cursor: pointer;

  transition: all 400ms;

  &:hover {
    border-color: "#00B0FF";
    background: "#00B0FF";

    color: "#fff";
  `}
`;
