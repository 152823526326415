import styled, { css, keyframes } from 'styled-components';

import { Card } from '@components/quarks';

const responsive = {
  Header: css`
    @media (max-width: 768px) {
      > div {
        width: 100%;

        strong {
          align-self: center;
        }
      }
    }
  `,
  Badge: css`
    @media (max-width: 768px) {
      border-radius: 6px;

      gap: 0;
      flex: 1;
      flex-direction: column;
    }
  `,
};

export const Container = styled(Card).attrs({
  className: 'bar-chart_container',
})`
  padding: 24px;

  gap: 48px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div.attrs({
  className: 'bar-chart_header',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${responsive.Header}
`;

export const HeaderLeft = styled.div.attrs({
  className: 'bar-chart_header-left',
})`
  ${({ theme }) => css`
    gap: 10px;
    display: flex;
    flex-direction: column;

    > strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.large};
    }

    > div {
      gap: 8px;
      display: flex;
    }
  `}
`;

export const Badge = styled.div.attrs({
  className: 'bar-chart_badge',
})`
  ${({ theme }) => css`
    border-radius: ${theme.borders.radii[500]};
    background: ${theme.colors.background};

    padding: 4px 12px;

    gap: 8px;
    display: flex;
    align-items: center;

    &:last-of-type {
      span {
        &:last-of-type {
          color: ${theme.colors.hours.unproductive};
        }
      }
    }

    span {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};
      font-weight: ${theme.typography.fontWeight.normal};

      &:last-of-type {
        color: ${theme.colors.hours.productive};
      }
    }

    ${responsive.Badge}
  `}
`;

export const HeaderRight = styled.div.attrs({
  className: 'bar-chart_header-right',
})`
  gap: 8px;
  display: flex;
`;

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.button.attrs({
  className: 'bar-chart_tab',
})<ITabProps>`
  ${({ theme, isActive }) => css`
    border: 1px solid
      ${isActive
        ? theme.colors.activities.info
        : theme.colors.activities.selection};
    border-radius: ${theme.borders.radii[100]};
    background: ${isActive ? theme.colors.activities.info : 'transparent'};

    color: ${isActive ? '#FFFFFF' : theme.colors.activities.selection};
    font-size: ${theme.typography.fontSize.small};
    font-weight: ${theme.typography.fontWeight.normal};

    padding: 8px 16px;

    transition: all 400ms;

    &:hover {
      border-color: ${theme.colors.activities.info};
      background: ${theme.colors.activities.info};

      color: #ffffff;
    }
  `}
`;

export const ChartContainer = styled.div.attrs({
  className: 'bar-chart_chart-container',
})`
  margin-bottom: 80px;

  position: relative;
`;

export const ChartLine = styled.div.attrs({
  className: 'bar-chart_chart-line',
})`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.background};

    color: ${theme.colors.text.input};
    font-size: ${theme.typography.fontSize.small};

    padding: 4px 0;

    display: flex;
    align-items: center;

    &:last-of-type {
      border-bottom: 1px solid #b1c5dd;

      padding-bottom: 14px;
    }
  `}
`;

export const Bars = styled.section.attrs({
  className: 'bar-chart_bars',
})`
  width: 100%;
  height: calc(100% + 80px);

  margin-bottom: -80px;
  padding: 0 48px 80px 48px;

  position: absolute;
  bottom: 0;

  overflow-x: scroll;
  overflow-y: hidden;

  gap: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  user-select: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

interface IChartBarProps {
  height: number;
  wordLength: number;
  isLoading?: boolean;
}

const presence = (height: number) => keyframes`
  0% {
    height: 0%;
  }
  100% {
    height: ${height}%;
  }
`;

export const ChartBar = styled.div.attrs({
  className: 'bar-chart_chart-bar',
})<IChartBarProps>`
  ${({ theme, height, wordLength }) => css`
    width: 40px;
    min-width: 40px;
    height: ${height}%;
    min-height: 4px;

    border-radius: 6px;
    background: ${theme.colors.activities.info}55;

    position: relative;

    animation: ${presence(height)} 800ms ease-in-out;

    transition: all 400ms;

    &:hover {
      background: ${theme.colors.activities.info};

      .components-atoms-tooltip-container {
        opacity: 1 !important;
        visibility: visible !important;
      }

      cursor: pointer;
    }

    span {
      max-width: 80px;

      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-left: -${wordLength * 2}px;

      position: absolute;
      bottom: -50px;

      overflow: hidden;

      transform: rotate(-64deg);

      z-index: 11;
    }
  `}
`;

export const ShimmerChartBar = styled.div.attrs({
  className: 'bar-chart_shimmer-chart-bar',
})<IChartBarProps>`
  ${({ theme, height, wordLength }) => css`
    width: 40px;
    min-width: 40px;
    height: ${height}%;

    border-radius: 6px;
    background: ${theme.title === 'dark'
      ? `${theme.colors.hours.empty}55`
      : `${theme.colors.hours.empty}`};

    margin-bottom: 1px;

    position: relative;

    transition: all 400ms;

    @keyframes barShine {
      0% {
        top: 0px;
      }
      50% {
        top: 50%;
      }
      100% {
        top: 100%;
      }
    }

    &:after {
      content: '';

      width: 100%;
      height: 40px;

      border-radius: 6px;
      background: ${theme.colors.emptyLoading15DEG};

      position: absolute;
      top: 0;

      animation: barShine 800ms infinite;

      z-index: 10;
    }

    &:hover {
      background: ${theme.colors.hours.empty};

      cursor: pointer;
    }

    span {
      max-width: 80px;

      color: ${theme.colors.text.input};
      font-size: ${theme.typography.fontSize.small};
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-left: -${wordLength * 2}px;

      position: absolute;
      bottom: -50px;

      overflow: hidden;

      transform: rotate(-64deg);

      z-index: 11;
    }
  `}
`;

export const TooltipContent = styled.div.attrs({
  className: 'components-atoms-charts-bar-tooltip-content',
})`
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface ITooltipItemProps {
  color: string;
}

export const TooltipItem = styled.div.attrs({
  className: 'components-molecules-tooltips-project-item',
})<ITooltipItemProps>`
  ${({ theme, color = '#232323' }) => css`
    width: 100%;

    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:nth-of-type(1) {
      strong {
        color: ${theme.colors.black};
        font-size: 12px;
        white-space: nowrap;
      }

      p {
        color: ${color};
        font-size: 12px;
        font-weight: bold;
      }
    }
  `}
`;
