import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react';

// import { format, parseISO } from 'date-fns';
// import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { delay } from '@utils/delay';
import { toCurrency } from '@utils/toCurrency';
import { toHMS } from '@utils/toHMS';

import { ProductivityTooltip } from './Tooltip';

import {
  Container,
  Header,
  HeaderLeft,
  HeaderRight,
  HoursCategories,
  HourCategory,
  Bars,
  Bar,
  BackBar,
  IBarType,
  NotFoundData,
  ShimmerLoading,
} from './styles';

export type IProductivityItem = {
  id: number;
  name: string;
  date: string;
  value: { cost: number; hours: number };
  hours: [number, number, number, number];
};

interface IProductivityChartProps {
  type: 'cost' | 'hours';
  onLoad: (by: IBarType | undefined) => IProductivityItem[] | undefined;
}

const ProductivityChart: React.FC<IProductivityChartProps> = ({
  type,
  onLoad,
  children,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const [orderedBy, setOrderedBy] = useState<IBarType>();
  const [chartData, setChartData] = useState<IProductivityItem[]>();

  const max = useMemo(() => {
    const loaded = onLoad(orderedBy);

    if (!loaded) return 0;

    if (type === 'cost') {
      const all = loaded.map(
        item => item.value.cost * item.hours.reduce((a, i) => a + i, 0),
      );

      return all.length > 0 ? all.reduce((a, b) => Math.max(a, b)) : 0;
    }

    const all = loaded.map(item => item.hours.reduce((a, i) => a + i, 0));

    return all.length > 0 ? all.reduce((a, b) => Math.max(a, b)) : 0;
  }, [onLoad, orderedBy, type]);

  const hoursCategories = useMemo(() => {
    const { hours } = colors;

    const types: { label: string; type: IBarType }[] = [
      { label: 'Produtivo', type: 'productive' },
      { label: 'Improdutivo', type: 'unproductive' },
      { label: 'Passivo', type: 'passive' },
      { label: 'Indefinido', type: 'undefined' },
    ];

    return types.map(item => {
      return {
        color: hours[item.type],
        text: item.label,
        isSelected: orderedBy === item.type,
        onClick: () =>
          setOrderedBy(state => (state === item.type ? undefined : item.type)),
      };
    });
  }, [colors, orderedBy]);

  useLayoutEffect(() => {
    setChartData(undefined);

    async function set() {
      await delay(2000);

      const ordered = onLoad(orderedBy);

      setChartData(ordered || []);
    }

    set();
  }, [onLoad, orderedBy, type]);

  const getBarProps = useCallback(
    (hours: number, cost: number, index: number) => {
      const pType = ['productive', 'unproductive', 'passive', 'undefined'];

      const width = ((type === 'cost' ? hours * cost : hours) / max) * 100;

      return { key: v4(), type: pType[index] as IBarType, width };
    },
    [max, type],
  );

  return (
    <Container>
      <Header>
        <HeaderLeft>Custo por Colaborador</HeaderLeft>

        <HeaderRight>{children}</HeaderRight>
      </Header>

      <HoursCategories>
        {hoursCategories.map(hourCategory => {
          return (
            <HourCategory key={hourCategory.color} {...hourCategory}>
              {hourCategory.text}
            </HourCategory>
          );
        })}
      </HoursCategories>

      <Bars>
        {!chartData ? (
          <ShimmerLoading />
        ) : chartData.length === 0 ? (
          <NotFoundData>Nenhum resultado encontrado</NotFoundData>
        ) : (
          chartData.map(item => {
            const hours = `${toHMS(item.hours.reduce((a, i) => a + i, 0))
              .replace(':', 'h ')
              .replace(':', 'm ')}s`;

            return (
              <BackBar
                key={v4()}
                left={item.name}
                right={`${
                  type === 'cost' ? toCurrency(item.value.cost) : hours
                }`}
              >
                <ProductivityTooltip
                  hours={{
                    productive: item.hours[0],
                    unproductive: item.hours[1],
                    passive: item.hours[2],
                    undefined: item.hours[3],
                  }}
                />

                {item.hours.map((hour, index) => {
                  return <Bar {...getBarProps(hour, item.value.cost, index)} />;
                })}
              </BackBar>
            );
          })
        )}
      </Bars>
    </Container>
  );
};

export { ProductivityChart };
