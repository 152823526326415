import React, { useEffect, useRef } from 'react';

import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface ITooltipProps {
  direction?: 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM';
  isVisible?: boolean;
  style?: CSSProperties;
  followCursor?: boolean;
  children: React.ReactNode;
}

const Tooltip: React.FC<ITooltipProps> = ({
  direction = 'TOP',
  isVisible,
  followCursor = false,
  style,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (followCursor) {
      if (containerRef.current) {
        document.addEventListener('mousemove', event => {
          if (direction === 'LEFT' || direction === 'RIGHT') {
            containerRef.current?.setAttribute(
              'style',
              `left: ${
                event.clientX -
                (containerRef.current.getBoundingClientRect().width + 20)
              }px; top: ${event.clientY}px`,
            );
          }

          if (direction === 'TOP' || direction === 'BOTTOM') {
            containerRef.current?.setAttribute(
              'style',
              `left: ${event.clientX}px; top: ${event.clientY - 120}px`,
            );
          }
        });
      }
    }
  }, [direction, followCursor]);

  return (
    <Container
      ref={containerRef}
      direction={direction}
      isVisible={isVisible}
      followCursor={followCursor}
      style={style}
    >
      {children}
    </Container>
  );
};

export { Tooltip };
