import React, { useCallback, useEffect, useState } from 'react';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';

import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { Avatar } from '@components/atoms';
import { Modal, IModalRef } from '@components/organisms';
import { useOrganogram } from '@hooks/useOrganogram';
import { IOrganogramGroup } from '@interfaces/IOrganogram';

import Input from '../Input';

import {
  Checkbox,
  Container,
  Content,
  Footer,
  Header,
  Item,
  List,
} from './styles';

interface IItemProps {
  id: number;
  enabled: boolean;
  title: string;
  subtitle: string;
  image_url: string;
}

interface IManageSubgroupModalProps {
  group: IOrganogramGroup;
  manageSubgroupModalRef: React.RefObject<IModalRef>;
}

const ManageSubgroup: React.FC<IManageSubgroupModalProps> = ({
  group,
  manageSubgroupModalRef,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [internalData, setInternalData] = useState<IItemProps[]>([]);
  const [search, setSearch] = useState<string>('');
  const [groupName, setGroupName] = useState<string>(group.name);
  const { onUpdateSubGroup, users: usersSelect, organograms } = useOrganogram();

  function onSelect(id: number) {
    setSelectedIds(state =>
      state.includes(id) ? state.filter(item => item !== id) : [...state, id],
    );
  }

  const handleCloseModal = useCallback(() => {
    manageSubgroupModalRef.current?.close();
  }, [manageSubgroupModalRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleUpdateSubgroup = useCallback(async () => {
    const data = {
      id: group.id,
      name: groupName,
      user_ids: selectedIds,
    };

    onUpdateSubGroup(data);

    handleCloseModal();
  }, [group.id, groupName, handleCloseModal, onUpdateSubGroup, selectedIds]);

  const users = internalData.filter(item => {
    return item.title.toUpperCase().search(search.toUpperCase()) !== -1;
  });

  useEffect(() => {
    async function load() {
      if (usersSelect) {
        setInternalData(
          usersSelect.map((item: any) => ({
            id: item.id,
            enabled: false,
            title: item.name,
            subtitle: item.email,
            image_url: item?.avatar_url,
          })),
        );
      }
    }

    load();
  }, [usersSelect]);

  useEffect(() => {
    function loadIds() {
      const organogram = organograms.find(
        item => item.id === group.organogram_id,
      );

      if (organogram) {
        const ids = organogram.childs
          ?.filter(
            item =>
              item.parent_group_id === group.id && item.type === 'collaborator',
          )
          .map(item => item.id);

        setSelectedIds(ids);
      }
    }

    loadIds();
  }, [group.id, group.organogram_id, organograms]);

  useEffect(() => {
    setInternalData(prevState => {
      return prevState.map(item => ({
        ...item,
        enabled: selectedIds.includes(item.id),
      }));
    });
  }, [selectedIds]);

  return (
    <Modal ref={manageSubgroupModalRef} size="custom" hasNavigation={false}>
      <Container>
        <Header color="#FFC566">
          <div>
            <h4>Atualizar grupo</h4>

            <div>
              <div />
              <span>{group.name}</span>
            </div>
          </div>

          <HiOutlineX size={20} onClick={() => handleCloseModal()} />
        </Header>

        <Content>
          <div>
            <Input
              label="Nome do Subgrupo"
              placeholder="Nome do Subgrupo"
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />

            <Input
              label="Adicionar colaborador ao subgrupo"
              placeholder="Pesquisar colaborador"
              icon={<HiOutlineSearch size={24} />}
              onChange={debounce(handleSearch, 500)}
            />
          </div>

          <List>
            {users.map((item: IItemProps) => (
              <Item key={v4()}>
                <Checkbox
                  isSelected={item.enabled}
                  onClick={() => onSelect(item.id)}
                />

                <div>
                  <Avatar
                    key={v4()}
                    name="avatar_url"
                    avatar_url={item.image_url}
                    avatarSize={{ width: 40, height: 40 }}
                    iconSize={24}
                    placeholder={item.title.substring(0, 1)}
                    readOnly
                  />

                  <div>
                    <span>{item.title}</span>
                    <span>{item.subtitle}</span>
                  </div>
                </div>
              </Item>
            ))}
          </List>
        </Content>

        <Footer>
          <button type="button" onClick={() => handleCloseModal()}>
            Cancelar
          </button>

          <button type="button" onClick={handleUpdateSubgroup}>
            Atualizar
          </button>
        </Footer>
      </Container>
    </Modal>
  );
};

export { ManageSubgroup };
