import { addDays, addMonths, differenceInDays, subMonths } from 'date-fns';

import { ICalendarItem, ITimelineRow } from '../types';

class TimelineHelper {
  public getCalendarItems(
    selectedMonth: Date,
    prevMonthsQuantity: number,
    nextMonthsQuantity: number,
  ): ICalendarItem[] {
    const calendarItems: ICalendarItem[] = [];
    const prevMonths = subMonths(selectedMonth, prevMonthsQuantity);
    const nextMonths = addMonths(selectedMonth, nextMonthsQuantity + 1);

    const qtd = differenceInDays(nextMonths, prevMonths);

    for (let i = 0; i < qtd; i++) {
      calendarItems.push({
        start: i,
        date: addDays(prevMonths, i),
      });
    }

    return calendarItems;
  }

  public getTimelineData(
    isLoading: boolean,
    data: ITimelineRow[] | undefined,
  ): ITimelineRow[] {
    const timelineData: ITimelineRow[] = [];

    const qtd = !data
      ? 16
      : data.length <= 16
      ? Math.abs(16 - data.length)
      : data.length;

    if (qtd > 16) return data ? [...data] : [];

    for (let i = 0; i < qtd; i++) {
      timelineData.push({
        id: i,
        name: '',
        color: 'red',
        variation: isLoading ? 'LOADING' : 'EMPTY',
        startDate: new Date(),
        endDate: new Date(),
        bars: [],
        summary_activities: [],
      });
    }

    return data ? [...data, ...timelineData] : timelineData;
  }
}

const INSTANCE = new TimelineHelper();

export { INSTANCE as TimelineHelper };
