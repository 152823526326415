import React, { useRef, useState, useCallback, useMemo } from 'react';
// import ApexCharts from 'react-apexcharts';
import { HiOutlineUserAdd } from 'react-icons/hi';

import { useInfiniteQuery } from '@tanstack/react-query';

import defaultAvatar from '@assets/images/default-avatar.png';
import { Button, Search } from '@components/atoms';
import { Filter, IFilterOption } from '@components/atoms/Filter';
import { colors } from '@components/bosons/colors';
import { PageHeader } from '@components/molecules';
// import { Modal } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { InfiniteScrollList, UserDrawer } from '@components/templates';
// import { useHeader } from '@hooks/useHeader';
// import { COLLABORATOR_PROFILE_CHART_OPTIONS } from '@constants/charts';
// import { COLLABORATORS_FILTER_CONFIG_ONLY_USER } from '@constants/filters';
import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { HttpQuery, IFilter, IParsedQuery } from '@helpers/HttpQueryHelper';
import { UsersService } from '@services/apis/UsersService';

import {
  Container,
  User,
  Label,
  // ModalContent,
  // ProfileContainer,
  // ProjectsContainer,
  // ProfileInfo,
  // Badges,
} from './styles';

interface IOptionFilter extends IFilter {
  type?: 'ACTIVE' | 'INACTIVE';
}

const Collaborators: React.FC = () => {
  const userDrawerRef = useRef<IDrawerRef>(null);

  const [isFirstQuery, setIsFirstQuery] = useState<boolean>(true);
  const [userId, setUserId] = useState<number>();
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<IParsedQuery>(
    HttpQuery.getInitialQuery({
      limit: 100,
      sort: [{ order: 'ASC', property: 'name' }],
    }),
  );
  const [filterOptions, setFilterOptions] = useState<IFilterOption[]>([
    {
      color: colors.activities.info,
      label: 'Ativo',
      value: 'ACTIVE',
      is_checked: false,
    },
    {
      color: colors.activities.danger,
      label: 'Inativo',
      value: 'INACTIVE',
      is_checked: false,
    },
  ]);

  const getUsers = useCallback(
    async (params?: any) => {
      let newQuery: IParsedQuery;

      const updatedQuery: IParsedQuery = {
        ...query,
        page: params?.pageParam || 1,
      };

      setQuery(updatedQuery);

      if (isFirstQuery) {
        newQuery = {
          ...query,
          q: [
            {
              entity: 'users',
              value: '1',
              property: 'enabled',
              operator: 'EQUAL',
              rule: 'OR',
            },
          ],
          page: params?.pageParam || 1,
        };

        setIsFirstQuery(false);
      } else {
        newQuery = {
          ...query,
          page: params?.pageParam || 1,
        };
      }

      const users = await UsersService.list({
        query: newQuery,
        relations: [
          'avatar',
          'projects_users',
          'projects_users.project',
          'users_roles',
          'users_roles.role',
          'users_groups',
          'users_groups.group',
          'users_groups.group.organogram',
        ],
      });

      setTotal(users.total);

      const parsedQuery = HttpQuery.getParsedQueryString(updatedQuery);

      window.history.pushState('', '', `/users${parsedQuery}`);

      return users;
    },
    [isFirstQuery, query],
  );

  const { data, isLoading, error, fetchNextPage, refetch } = useInfiniteQuery(
    ['users-list', 1],
    getUsers,
    { getNextPageParam: lastPage => lastPage.page + 1 },
  );

  const results = useMemo(() => {
    const users: any[] = [];

    data?.pages.forEach(page => {
      page.data.forEach((user: any) => {
        users.push(user);
      });
    });

    return users;
  }, [data?.pages]);

  const handleOpenDrawer = useCallback(() => userDrawerRef.current?.open(), []);

  const handleSelectUser = useCallback(
    (user_id: number) => {
      setUserId(user_id);
      handleOpenDrawer();
    },
    [handleOpenDrawer],
  );

  const handleSearch = useCallback(
    async (value: string) => {
      setQuery({
        q: [
          {
            entity: 'users',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'AND',
          },
          {
            entity: 'projects',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'OR',
          },
        ],
        page: 1,
        limit: 100,
        sort: [{ order: 'ASC', property: 'name' }],
      });

      await refetch();
    },
    [refetch],
  );

  const handleGetFilterStatus = useCallback(
    (status: 'ACTIVE' | 'INACTIVE'): IOptionFilter[] => {
      switch (status) {
        case 'ACTIVE':
          return [
            {
              entity: 'users',
              value: '1',
              property: 'enabled',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'ACTIVE',
            },
          ];
        case 'INACTIVE':
          return [
            {
              entity: 'users',
              value: '0',
              property: 'enabled',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'INACTIVE',
            },
          ];
        default:
          return [];
      }
    },
    [],
  );

  const handleFilter = useCallback(
    async (item: any) => {
      setQuery(prevQuery => {
        if (item.value === 'INACTIVE' && item.is_checked) setIsFirstQuery(true);

        let q: any[] = [...prevQuery.q];

        const itemFilters = handleGetFilterStatus(item.value);

        if (item.is_checked) {
          q = prevQuery.q.filter(
            (query_item: any) => query_item?.type !== item.value,
          );
        } else {
          itemFilters.forEach(item_filter => {
            q.push(item_filter);
          });
        }

        return {
          q,
          page: 1,
          limit: 100,
          sort: [{ order: 'ASC', property: 'name' }],
        };
      });

      setFilterOptions(state => {
        return state.map(stateItem => {
          if (stateItem.value === item.value) {
            return { ...item, is_checked: !item.is_checked };
          }

          return stateItem;
        });
      });

      await refetch();
    },
    [handleGetFilterStatus, refetch],
  );

  const userLogged = useMemo(() => {
    const foundedUser = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

    if (foundedUser) {
      return JSON.parse(foundedUser);
    }

    return {};
  }, []);

  const hasMore = useMemo(() => {
    if (userLogged.users_roles[0].role.alias === 'COLLABORATOR') return false;

    if (results.length === total) return false;

    return true;
  }, [results.length, total, userLogged.users_roles]);

  return (
    <Container>
      <PageHeader>
        <Search
          placeholder="Digite o nome de um usuário ou projeto"
          onSearch={handleSearch}
        />
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Filter onSelect={handleFilter} options={filterOptions} />

          <Button
            icon={HiOutlineUserAdd}
            callToAction
            onClick={handleOpenDrawer}
          >
            Adicionar Usuário
          </Button>
        </div>
      </PageHeader>

      <InfiniteScrollList
        dataLength={results.length}
        hasMore={hasMore}
        next={fetchNextPage}
        length={results.length}
        columnWidth={[240, 260]}
        isLoading={isLoading}
      >
        {isLoading ? (
          <strong>Carregando...</strong>
        ) : error ? (
          <strong>Erro!</strong>
        ) : (
          <>
            {data?.pages.map(page => {
              return page.data.map(user => {
                return (
                  <User key={user.id} onClick={() => handleSelectUser(user.id)}>
                    <div>
                      <img
                        src={user.avatar_url || defaultAvatar}
                        alt={user.name}
                      />
                    </div>

                    <div>
                      <strong>{user.name}</strong>
                      <span>
                        {user.users_roles ? user.users_roles[0].role.name : ''}
                      </span>
                      <span>{user.email}</span>
                    </div>

                    <div>
                      {user.groups?.map((item: any) => {
                        const { group } = item;
                        return (
                          <Label
                            key={group.id}
                            color={group.organogram?.color || ''}
                          >
                            {group.name}
                          </Label>
                        );
                      })}
                    </div>
                  </User>
                );
              });
            })}
          </>
        )}
      </InfiniteScrollList>
      {/* <Modal size="medium">
        <ModalContent>
          <ProfileContainer>
            <ProfileInfo>
              <Avatar
                name="avatar_url"
                onChange={e => console.log(e)}
                avatarSize={{ width: '120px', height: '120px' }}
              />

              <div>
                <strong>Caíque Gomes</strong>
                <span>Colaborador</span>
                <span>contato@email.com</span>
              </div>

              <Badges>
                <Label
                  color="#8D9EE9"
                  style={{ color: '#fff', fontWeight: 'normal' }}
                >
                  Design
                </Label>

                <Label
                  color="#8D9EE9"
                  style={{ color: '#fff', fontWeight: 'normal' }}
                >
                  Marketing
                </Label>
              </Badges>
            </ProfileInfo>

            <ApexCharts
              options={{
                ...COLLABORATOR_PROFILE_CHART_OPTIONS,
                xaxis: {
                  ...COLLABORATOR_PROFILE_CHART_OPTIONS.xaxis,
                  categories,
                },
                stroke: { width: 5 },
              }}
              series={[{ name: 'Horas', data: [13, 6, 7, 6, 10, 0.5] }]}
              type="area"
              height={120}
            />
          </ProfileContainer>

          <ProjectsContainer>
            <strong>Projetos relacionados</strong>
          </ProjectsContainer>
        </ModalContent>
      </Modal> */}

      <UserDrawer
        userId={userId}
        setUserId={setUserId}
        setUsers={() => refetch()}
        userDrawerRef={userDrawerRef}
      />
    </Container>
  );
};

export { Collaborators };
