import { IAlert } from '../types';

function getAlert(): IAlert {
  const hours = new Date().getHours();

  if (hours > 10 && hours <= 11) return 'warning';
  if (hours > 11) return 'danger';

  return 'success';
}

export { getAlert };
