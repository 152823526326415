import React, {
  ChangeEvent,
  ComponentType,
  Dispatch,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiPlus, FiEdit, FiUser } from 'react-icons/fi';

import { useTheme } from 'styled-components';

import { useUnform } from '../../../hooks/useUnform';

import { Container } from './styles';

export type AvatarSize = { width: number; height: number };

interface IAvatarRef {
  setFile: Dispatch<any>;
  setPreview: Dispatch<any>;
}

interface IAvatarProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: ComponentType<IconBaseProps>;
  avatar_url?: string;
  avatarSize?: AvatarSize;
  iconSize?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Avatar: React.ForwardRefRenderFunction<IAvatarRef, IAvatarProps> = (
  {
    name,
    icon: Icon,
    avatarSize,
    iconSize,
    avatar_url,
    placeholder,
    accept,
    onChange,
    ...rest
  },
  ref,
) => {
  const unform = useUnform(name);

  const { colors } = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState(
    unform?.defaultValue && unform.defaultValue.id,
  );
  const [preview, setPreview] = useState(unform?.defaultValue || avatar_url);

  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (files) {
        setPreview(URL.createObjectURL(files[0]));

        if (onChange) onChange(e);
      }
    },
    [onChange],
  );

  useLayoutEffect(() => {
    setPreview(avatar_url);
  }, [avatar_url]);

  useImperativeHandle(ref, () => ({ setFile, setPreview }));

  useEffect(() => {
    if (unform) {
      unform.registerField({
        name,
        ref: inputRef.current,
        path: 'dataset.file',
      });
    }
  }, [unform, name]);

  return (
    <Container avatarSize={avatarSize} hasFile={preview}>
      {/* (Icon && */}
      {/* <span>
        {(Icon && <Icon color={colors.white} size={48} />) || (
          <FiUser color={colors.white} size={iconSize || 48} />
        )}
      </span> */}

      {placeholder ? (
        <span>{placeholder}</span>
      ) : Icon ? (
        <span>
          <Icon color={colors.white} size={48} />
        </span>
      ) : (
        <span>
          <FiUser color={colors.white} size={iconSize || 48} />
        </span>
      )}

      <label htmlFor={name}>
        <img src={preview || ''} alt={name} />

        {!rest.readOnly && (
          <>
            <input
              {...rest}
              ref={inputRef}
              id={name}
              type="file"
              accept={accept}
              data-file={file}
              onChange={handleChange}
            />

            <button type="button" onClick={onClick}>
              {!preview ? (
                <FiPlus color={colors.white} size={24} />
              ) : (
                <FiEdit color={colors.white} size={18} />
              )}
            </button>
          </>
        )}
      </label>
    </Container>
  );
};

const Component = forwardRef(Avatar);

export { Component as Avatar };
