import React from 'react';

import { CSSProperties } from 'styled-components';

import { IFeedbackProps } from '@interfaces/components/IInput';

import { Container } from './styles';

interface IContainerProps extends IFeedbackProps {
  cRef?: React.RefObject<HTMLDivElement>;
  type?: React.HTMLInputTypeAttribute;
  themeStyle?: 'light' | 'dark';
  className?: string;
  style?: CSSProperties;
}

const InputContainer: React.FC<IContainerProps> = ({
  cRef,
  type,
  themeStyle = 'light',
  className,
  children,
  style,
  ...rest
}) => {
  return (
    <Container
      ref={cRef}
      themeStyle={themeStyle}
      type={type}
      {...rest}
      className={className}
      style={style}
    >
      {children}
    </Container>
  );
};

export { InputContainer };
