import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineSave,
  HiOutlineSearch,
} from 'react-icons/hi';

import {
  differenceInMonths,
  format,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  subDays,
} from 'date-fns';
import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { DateHelper, IDate, IMonth } from '@helpers/DateHelper';
import { useClickAway } from '@hooks/useClickAway';

import {
  Container,
  Input,
  ExportSelector,
  Tabs,
  Tab,
  Search,
  IsSearching,
  Inner,
  List,
  Item,
  ChooseDateCheckbox,
  ChooseDate,
  DatesSelector,
  DatesInputs,
  DatesHeader,
  CalendarMonths,
  CalendarMonth,
  CalendarDays,
  CalendarDay,
  Actions,
} from './styles';

type ITab = 'CHOOSE_DATA' | 'CHOOSE_DATES';
type IInternalData = { id: number; name: string; is_checked: boolean };
type IValue = { original: Date[]; formatted: string; infoLabel: string };
type IOnExport = { ids: number[]; startDate: Date; endDate: Date };

interface IExportationsProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> {
  language?: 'pt-BR';
  data: { id: number; name: string }[];
  defaultValue?: Date[];
  onSearch: (value: string) => Promise<void>;
  onExport: (params: IOnExport) => Promise<void>;
}

const Exportations: React.FC<IExportationsProps> = ({
  language,
  data,
  onSearch,
  onExport,
  defaultValue,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputFromStartRef = useRef<HTMLInputElement>(null);
  const inputFromEndRef = useRef<HTMLInputElement>(null);

  const monthsScrollRef = useRef<any>(null);

  const parsedDefaultValue: IValue = useMemo(() => {
    if (!defaultValue) {
      return { original: [], formatted: '', infoLabel: '' };
    }

    let formatted = '';

    if (defaultValue.length === 0) formatted = `Todo o período`;

    if (defaultValue.length === 1) {
      formatted = `${defaultValue[0].toLocaleDateString(language)}`;
    }

    if (defaultValue.length === 2) {
      const first = defaultValue[0].toLocaleDateString(language);
      const last = defaultValue[1].toLocaleDateString(language);

      formatted = `${first} - ${last}`;
    }

    if (defaultValue.length === 3) {
      const first = defaultValue[0].toLocaleDateString(language);
      const last = defaultValue[1].toLocaleDateString(language);

      formatted = `${first} - ${last}`;
    }

    if (defaultValue.length === 4) {
      const fromStartComparer = defaultValue[0].toLocaleDateString(language);
      const toEndComparer = defaultValue[3].toLocaleDateString(language);

      formatted = `${fromStartComparer} á ${toEndComparer}`;
    }

    if (inputRef.current) inputRef.current.setAttribute('value', formatted);

    return { original: defaultValue, formatted, infoLabel: '' };
  }, [defaultValue, language]);

  const [value, setValue] = useState<IValue>(parsedDefaultValue);

  const [startDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFocused, setIsFosuced] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isSelectingDates, setIsSelectingDates] = useState<boolean>(false);
  const [tab, setTab] = useState<ITab>('CHOOSE_DATA');
  const [internalData, setInternalData] = useState<IInternalData[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [firstHoveredDate, setFirstHoveredDate] = useState<Date>();
  const [secondHoveredDate, setSecondHoveredDate] = useState<Date>();
  const [selectDatesLabel, setSelectDatesLabel] =
    useState<string>('Personalizado');
  const [defaultDateSelected, setDefaultDateSelected] = useState<string>();

  const [months] = useState<IMonth[]>(() => {
    return DateHelper.getMonths(
      new Date(2000, 1, 1),
      differenceInMonths(new Date(2050, 1, 1), new Date(2000, 1, 1)),
    );
  });

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setIsSearching(false);

    await onSearch(e.target.value);
  }

  function onSelect(id: number) {
    setSelectedIds(state =>
      state.includes(id) ? state.filter(item => item !== id) : [...state, id],
    );
  }

  useEffect(() => {
    setInternalData(
      data.map(item => ({
        ...item,
        is_checked: selectedIds.includes(item.id),
      })),
    );
  }, [data, selectedIds]);

  useClickAway(containerRef, () => setIsOpen(false), { enabled: isOpen });
  useClickAway(searchRef, () => setIsFosuced(false), { enabled: isFocused });

  const defaultDates = [
    { key: 'last-7-days', label: 'Últimos 7 dias', value: 7 },
    { key: 'last-15-days', label: 'Últimos 15 dias', value: 15 },
    { key: 'last-30-days', label: 'Últimos 30 dias', value: 30 },
    { key: 'last-120-days', label: 'Último quadrimestre', value: 120 },
    { key: 'last-365-days', label: 'Último ano', value: 365 },
  ];

  const onSelectDates = useCallback(
    (original: Date[], infoLabel: string) => {
      if (original.length === 1) {
        if (inputFromEndRef.current) inputFromEndRef.current.value = '';
      }

      let formatted = '';

      if (original.length === 0) formatted = `Todo o período`;

      if (original.length === 1) {
        formatted = `${original[0].toLocaleDateString(language)}`;
      }

      if (original.length === 2) {
        const first = original[0].toLocaleDateString(language);
        const last = original[1].toLocaleDateString(language);

        formatted = `${first} - ${last}`;
      }

      if (original.length === 3) {
        const first = original[0].toLocaleDateString(language);
        const last = original[1].toLocaleDateString(language);

        formatted = `${first} - ${last}`;
      }

      if (original.length === 4) {
        const fromStartComparer = original[0].toLocaleDateString(language);
        const toEndComparer = original[3].toLocaleDateString(language);

        formatted = `${fromStartComparer} á ${toEndComparer}`;
      }

      setValue({ original, formatted, infoLabel });
      setSelectDatesLabel(formatted);

      // if (inputRef.current) inputRef.current.setAttribute('value', formatted);
    },
    [language],
  );

  const onClick = useCallback(
    (day: IDate) => {
      setFirstHoveredDate(undefined);
      setSecondHoveredDate(undefined);

      if (value.original.length === 1) {
        onSelectDates([value.original[0], day.date], '');

        if (inputFromEndRef.current) {
          inputFromEndRef.current.value = format(day.date, 'yyyy-MM-dd');
        }
      } else {
        onSelectDates([day.date], '');

        if (inputFromStartRef.current) {
          inputFromStartRef.current.value = format(day.date, 'yyyy-MM-dd');
        }
      }
    },
    [onSelectDates, value.original],
  );

  const getIsSelected = useCallback(
    ({ date }: IDate) => {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      value.original.forEach(selectedDate => {
        selectedDate.setHours(0);
        selectedDate.setMinutes(0);
        selectedDate.setSeconds(0);
        selectedDate.setMilliseconds(0);
      });

      if (value.original.length === 0) return false;

      if (value.original.length === 1) return isEqual(date, value.original[0]);

      if (value.original.length === 2) {
        return (
          isEqual(date, value.original[0]) || isEqual(date, value.original[1])
        );
      }

      if (value.original.length === 3) {
        return (
          isEqual(date, value.original[0]) ||
          isEqual(date, value.original[1]) ||
          isEqual(date, value.original[2])
        );
      }

      if (value.original.length === 4) {
        return (
          isEqual(date, value.original[0]) ||
          isEqual(date, value.original[1]) ||
          isEqual(date, value.original[2]) ||
          isEqual(date, value.original[3])
        );
      }

      return false;
    },
    [value.original],
  );

  const getIsHovered = useCallback(
    ({ date }: IDate) => {
      if (value.original.length === 0) return false;

      if (firstHoveredDate && value.original.length === 1) {
        return (
          isAfter(date, value.original[0]) && isBefore(date, firstHoveredDate)
        );
      }

      if (secondHoveredDate && value.original.length === 3) {
        const first =
          isAfter(date, value.original[0]) && isBefore(date, value.original[1]);

        const second =
          isAfter(date, value.original[2]) && isBefore(date, secondHoveredDate);

        return first || second;
      }

      const first =
        isAfter(date, value.original[0]) && isBefore(date, value.original[1]);

      const second =
        isAfter(date, value.original[2]) && isBefore(date, value.original[3]);

      return first || second;
    },
    [firstHoveredDate, secondHoveredDate, value.original],
  );

  const getProps = useCallback(
    (index: number, day: IDate) => {
      const on = day.isShow
        ? {
            onClick: () => onClick(day),
            onMouseEnter: () => {
              if (value.original.length > 0 && value.original.length < 2) {
                setFirstHoveredDate(day.date);
              }

              if (value.original.length > 2 && value.original.length < 4) {
                setSecondHoveredDate(day.date);
              }
            },
          }
        : {};

      return {
        key: v4(),
        index,
        day,
        hoverColor: '#00B0FF',
        ...on,
        isToday: isToday(day.date),
        isSelected: getIsSelected(day),
        isHovered: getIsHovered(day),
      };
    },
    [getIsHovered, getIsSelected, onClick, value.original],
  );

  const localize = useCallback(() => {
    if (monthsScrollRef.current) {
      const index = months.findIndex(({ date }) => isEqual(date, startDate));

      monthsScrollRef.current.scrollToItem(index, 'start');
    }
  }, [months, startDate]);

  useEffect(() => localize(), [localize]);

  return (
    <Container ref={containerRef} isOpen={isOpen}>
      <Input
        ref={inputRef}
        isOpen={isOpen}
        onFocus={() => setIsOpen(true)}
        placeholder="Exportar Relatório"
        readOnly
      />

      <HiOutlineSave size={24} onClick={() => inputRef.current?.focus()} />

      <ExportSelector isOpen={isOpen} isSelectingDates={isSelectingDates}>
        <Tabs>
          <Tab
            type="button"
            isActive={tab === 'CHOOSE_DATA'}
            onClick={() => setTab('CHOOSE_DATA')}
          >
            Escolher projeto
          </Tab>

          <Tab
            type="button"
            isActive={tab === 'CHOOSE_DATES'}
            onClick={() => setTab('CHOOSE_DATES')}
          >
            Escolher data
          </Tab>
        </Tabs>

        {tab === 'CHOOSE_DATA' && (
          <>
            <Search ref={searchRef} isFocused={isFocused}>
              <input
                type="text"
                placeholder="Pesquisar nome do projeto"
                onKeyDown={() => setIsSearching(true)}
                onKeyUp={debounce(() => setIsSearching(false), 500)}
                onFocus={() => setIsFosuced(true)}
                onChange={debounce(handleSearch, 500)}
              />

              {isSearching ? (
                <IsSearching>
                  <Inner className="one" />

                  <Inner className="two" />

                  <Inner className="three" />
                </IsSearching>
              ) : (
                <HiOutlineSearch size={24} />
              )}
            </Search>

            <List>
              {internalData.map(item => {
                return (
                  <Item key={v4()} onClick={() => onSelect(item.id)}>
                    <input
                      id={`check-item-${item.id}`}
                      type="checkbox"
                      defaultChecked={item.is_checked}
                    />

                    <label htmlFor={`check-item-${item.id}`}>
                      <span>{item.name}</span>
                    </label>
                  </Item>
                );
              })}
            </List>
          </>
        )}

        {tab === 'CHOOSE_DATES' && (
          <>
            <List>
              {defaultDates.map(item => {
                return (
                  <Item key={v4()} className={isSelectingDates ? '' : 'hover'}>
                    <input
                      id={`check-item-${item.key}`}
                      type="radio"
                      name="selected-date"
                      disabled={isSelectingDates}
                      checked={item.key === defaultDateSelected}
                      onChange={() => {
                        setDefaultDateSelected(item.key);
                        onSelectDates(
                          [subDays(new Date(), item.value), new Date()],
                          '',
                        );
                      }}
                    />

                    <label htmlFor={`check-item-${item.key}`}>
                      <span>{item.label}</span>
                    </label>
                  </Item>
                );
              })}
            </List>

            <ChooseDate
              type="button"
              onClick={() => {
                if (isSelectingDates) {
                  setIsSelectingDates(false);
                  setSelectDatesLabel('Personalizado');

                  if (inputFromStartRef.current) {
                    inputFromStartRef.current.value = '';
                  }

                  if (inputFromEndRef.current) {
                    inputFromEndRef.current.value = '';
                  }
                } else {
                  setIsSelectingDates(true);
                  setSelectDatesLabel('Cancelar');
                  localize();
                }

                setDefaultDateSelected(undefined);
                setValue({ original: [], formatted: '', infoLabel: '' });
              }}
            >
              <div
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                {value.original.length > 1 && !defaultDateSelected && (
                  <ChooseDateCheckbox />
                )}

                <strong
                  style={{
                    color: value.original.length > 1 ? '#00bfff' : '#6c87bf',
                  }}
                >
                  {selectDatesLabel}
                </strong>
              </div>

              {isSelectingDates ? (
                <HiOutlineChevronLeft size={16} />
              ) : (
                <HiOutlineChevronRight size={16} />
              )}
            </ChooseDate>
          </>
        )}

        {selectedIds.length > 0 && (
          <Actions>
            <button
              type="button"
              className="cancel"
              onClick={() => {
                setTab('CHOOSE_DATA');
                setSelectDatesLabel('Personalizado');
                setIsOpen(false);
                setSelectedIds([]);
                setIsSelectingDates(false);
                setDefaultDateSelected(undefined);
                setValue({ original: [], formatted: '', infoLabel: '' });

                if (inputFromStartRef.current) {
                  inputFromStartRef.current.value = '';
                }

                if (inputFromEndRef.current) {
                  inputFromEndRef.current.value = '';
                }
              }}
            >
              Cancelar
            </button>

            <button
              type="button"
              className={value.original.length > 1 ? 'export' : 'next_step'}
              onClick={() => {
                if (value.original.length > 1) {
                  onExport({
                    ids: selectedIds,
                    startDate: value.original[0],
                    endDate: value.original[1],
                  });
                } else if (tab === 'CHOOSE_DATES') {
                  setTab('CHOOSE_DATA');
                  setIsSelectingDates(false);
                  setValue({ original: [], formatted: '', infoLabel: '' });
                } else {
                  setTab('CHOOSE_DATES');
                }
              }}
            >
              {value.original.length > 0 && selectedIds.length > 0
                ? 'Exportar'
                : tab === 'CHOOSE_DATES'
                ? 'Voltar'
                : 'Escolher datas'}
            </button>
          </Actions>
        )}

        <DatesSelector isOpen={isSelectingDates}>
          <DatesInputs>
            <input
              ref={inputFromStartRef}
              type="date"
              onChange={e => {
                onSelectDates([new Date(`${e.target.value}T00:00:00`)], '');
              }}
            />
            -
            <input
              ref={inputFromEndRef}
              type="date"
              onChange={e =>
                onSelectDates(
                  [value.original[0], new Date(`${e.target.value}T00:00:00`)],
                  '',
                )
              }
            />
          </DatesInputs>

          <DatesHeader>
            {['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'].map(item => {
              return <span key={v4()}>{item}</span>;
            })}
          </DatesHeader>

          <CalendarMonths
            ref={monthsScrollRef}
            itemData={months}
            itemCount={months.length}
            itemSize={264}
            height={252}
            width={290}
          >
            {({ index, style }) => {
              return (
                <CalendarMonth key={v4()} style={style}>
                  <span>{months[index].label}</span>

                  <CalendarDays>
                    {months[index].days.map((day, dayIndex) => {
                      return (
                        <CalendarDay {...getProps(dayIndex, day)} type="button">
                          <span>{day.date.getDate()}</span>
                        </CalendarDay>
                      );
                    })}
                  </CalendarDays>
                </CalendarMonth>
              );
            }}
          </CalendarMonths>
        </DatesSelector>
      </ExportSelector>
    </Container>
  );
};

export { Exportations };
