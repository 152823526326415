import React, { useMemo, useState, useCallback } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

import { v4 } from 'uuid';

import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { ISummaryActivity } from '@interfaces/IProject';

import { IDailyFormData } from '..';

import { DailyStatusStyles } from '../styles';

interface IImpedimentProps {
  activities: ISummaryActivity[];
  formData: IDailyFormData[];
  setFormData: React.Dispatch<React.SetStateAction<IDailyFormData[]>>;
  isResponding: boolean;
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>;
  projectSelected: ISummaryActivity | undefined;
  setProjectSelected: React.Dispatch<
    React.SetStateAction<ISummaryActivity | undefined>
  >;
  textAreaValue: string;
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
  confirmButtonRef: React.RefObject<HTMLButtonElement>;
}

const Impediment: React.FC<IImpedimentProps> = ({
  activities,
  formData,
  setFormData,
  isResponding,
  setIsResponding,
  projectSelected,
  setProjectSelected,
  textAreaValue,
  setTextAreaValue,
  confirmButtonRef,
  children,
}) => {
  const [hasImpediment, setHasImpediment] = useState<boolean>(false);

  const colors = useTheme<IColors>({ prop: 'colors' });

  const title = useMemo(() => {
    if (isResponding) {
      return (
        <p style={{ maxWidth: '180px' }}>
          Qual o <b>impeditivo</b> no projeto{' '}
          <b>{projectSelected?.project.name}</b> ?
        </p>
      );
    }

    const impediments = formData.filter(item => !!item.impediment);

    return (
      <p>
        {impediments.length >= 1 ? (
          <>
            Possui mais algum <b>impeditivo</b> ?
          </>
        ) : (
          <>
            Possui <b>impeditivos</b> em algum <b>projeto</b> ?
            <br />
            Caso não tenha, clique em continuar.
          </>
        )}
      </p>
    );
  }, [formData, isResponding, projectSelected?.project.name]);

  const onSelect = useCallback(
    (index: number) => {
      const foundAnswer = formData[index];
      const project = activities[index];

      setProjectSelected(project);
      setIsResponding(true);
      setTextAreaValue(foundAnswer?.impediment || '');
    },
    [
      activities,
      formData,
      setIsResponding,
      setProjectSelected,
      setTextAreaValue,
    ],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaValue(e.target.value);
      setFormData(prevFormData => {
        return prevFormData.map(prevFormDataItem => {
          if (prevFormDataItem.project_id === projectSelected?.project_id) {
            return {
              accomplished: prevFormDataItem.accomplished,
              planned: prevFormDataItem.planned,
              impediment: e.target.value,
              project_id: prevFormDataItem.project_id,
              summary_activity_id: prevFormDataItem.summary_activity_id,
            };
          }

          return prevFormDataItem;
        });
      });
    },
    [projectSelected?.project_id, setFormData, setTextAreaValue],
  );

  if (!hasImpediment) {
    return (
      <DailyStatusStyles.Content>
        {children}

        <div>
          <p style={{ maxWidth: '180px', padding: '8px 0 16px 0' }}>
            Você tem algum <b>impeditivo</b> no projeto{' '}
            <b>{projectSelected?.project.name}</b> ?
          </p>

          <DailyStatusStyles.HasImpediment>
            <button
              type="button"
              className="no"
              onClick={() => confirmButtonRef.current?.click()}
            >
              Não
            </button>

            <button
              type="button"
              className="yes"
              onClick={() => setHasImpediment(true)}
            >
              Sim
            </button>
          </DailyStatusStyles.HasImpediment>
        </div>
      </DailyStatusStyles.Content>
    );
  }

  return (
    <DailyStatusStyles.Content>
      {children}

      {title}

      <DailyStatusStyles.Projects>
        {activities.map((activity, index) => {
          const isFilled = !!formData[index].impediment;

          return (
            <DailyStatusStyles.Project
              key={v4()}
              type="button"
              isFilled={isFilled}
              isSelected={projectSelected?.id === activity.id}
              color={colors.activities.danger}
              onClick={() => onSelect(index)}
            >
              <p style={{ maxWidth: isFilled ? '67px' : 'initial' }}>
                {activity.project.name}
              </p>

              {isFilled && (
                <HiOutlineExclamationCircle color={colors.white} size={22} />
              )}
            </DailyStatusStyles.Project>
          );
        })}
      </DailyStatusStyles.Projects>

      <DailyStatusStyles.ContentTextArea
        value={textAreaValue}
        onChange={onChange}
        onKeyDown={e =>
          e.ctrlKey && e.keyCode === 13 && confirmButtonRef.current?.click()
        }
        autoFocus
      />
    </DailyStatusStyles.Content>
  );
};

export { Impediment };
