import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import { Full } from '@interfaces/generic/IGeneric';

import { AsideItemRow } from './AsideItemRow';
import { CalendarRow } from './CalendarRow';
import { TimelineHelper } from './helpers/TimelineHelper';
import { ICalendarItem, IColors, ITimelineRow } from './types';

import { defaultColors, TimelineStyles } from './styles';

interface ITimelineProps {
  colors?: IColors;
  data: ITimelineRow[] | undefined;
  prevMonthsQuantity?: number;
  nextMonthsQuantity?: number;
  dayWidth?: number;
  variation?: 'DEFAULT' | 'REPORT';
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  loadMore: (direction: number) => Promise<void>;
  onPrev?: () => void;
  onNext?: () => void;
}

const Timeline: React.FC<ITimelineProps> = ({
  colors: colorsPassed = defaultColors,
  data = undefined,
  prevMonthsQuantity = 1,
  nextMonthsQuantity = 1,
  dayWidth: dayWidthPassed = 47,
  variation = 'DEFAULT',
  hasPrevPage = false,
  hasNextPage = true,
  loadMore,
  onPrev,
  onNext,
}) => {
  const headerViewportRef = useRef<HTMLDivElement>(null);
  const asideRef = useRef<HTMLDivElement>(null);
  const viewportDataRef = useRef<HTMLDivElement>(null);

  const isDraggingRef = useRef<boolean>(false);
  const draggingPositionRef = useRef<number>(0);

  const [selectedMonth, setSelectedMonth] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth()),
  );
  const [dayWidth] = useState<number>(dayWidthPassed);
  // const [scrollLeft] = useState<number>(0);

  const [calendarItems, setCalendarItems] = useState<ICalendarItem[]>(
    TimelineHelper.getCalendarItems(
      selectedMonth,
      prevMonthsQuantity,
      nextMonthsQuantity,
    ),
  );
  const [timelineData, setTimelineData] = useState<ITimelineRow[]>(
    TimelineHelper.getTimelineData(!hasPrevPage && !data, data),
  );

  const delay = useCallback((time: number) => {
    return new Promise(resolve => setTimeout(() => resolve(time), time));
  }, []);

  useEffect(() => {
    async function set() {
      if (!hasPrevPage) await delay(500);

      setTimelineData(
        TimelineHelper.getTimelineData(!hasPrevPage && !data, data),
      );
    }

    set();
  }, [data, delay, hasPrevPage]);

  useEffect(() => {
    setCalendarItems(() => {
      return TimelineHelper.getCalendarItems(
        selectedMonth,
        prevMonthsQuantity,
        nextMonthsQuantity,
      );
    });
  }, [nextMonthsQuantity, prevMonthsQuantity, selectedMonth]);

  const handlePrevHorizontal = useCallback(() => {
    if (onPrev) onPrev();

    setSelectedMonth(state => subMonths(state, 1));
  }, [onPrev]);

  const handleNextHorizontal = useCallback(() => {
    if (onNext) onNext();

    setSelectedMonth(state => addMonths(state, 1));
  }, [onNext]);

  const handlePrevVertical = useCallback(async () => {
    await loadMore(-1);
  }, [loadMore]);

  const handleNextVertical = useCallback(async () => {
    await loadMore(1);

    await delay(500);

    if (headerViewportRef.current && viewportDataRef.current) {
      const x = viewportDataRef.current.scrollLeft;
      const y = 0;

      headerViewportRef.current.scrollTo(x, y);
      viewportDataRef.current.scrollTo(x, y);
    }
  }, [delay, loadMore]);

  const colors: Full<IColors> = useMemo(() => {
    return {
      ...{
        primary: '#1B1C22',
        weekend: '#1B1C22aa',
        background: '#0f0f10',
        font: { title: '#ffffff', subtitle: '#92abd8' },
        header: { background: '#8991a5', text: '#ffffff' },
        hover: '#176BF8',
        shadow: 'rgba(0, 0, 0, 0.4)',
      },
      ...colorsPassed,
    };
  }, [colorsPassed]);

  const keys = useMemo(() => {
    return {
      aside: `timeline-${v4()}-aside`,
      viewportData: `timeline-${v4()}-viewportData`,
    };
  }, []);

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    isDraggingRef.current = true;
    draggingPositionRef.current = e.clientX;
  }, []);

  // const handleHorizontalChange = useCallback((newDraggingPosition: number) => {
  //   console.log('onHorizontalChange: ', newDraggingPosition);
  // }, []);

  const onMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (isDraggingRef.current) {
      const delta = draggingPositionRef.current - e.clientX;

      if (delta !== 0) {
        draggingPositionRef.current = e.clientX;
        // handleHorizontalChange(scrollLeft + delta);
      }
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  const onMouseLeave = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  return (
    <TimelineStyles.Container>
      <TimelineStyles.Header.Container>
        <TimelineStyles.Header.Items colors={colors}>
          <AsideItemRow
            colors={colors}
            selectedMonth={selectedMonth}
            prevMonthsQuantity={prevMonthsQuantity}
            nextMonthsQuantity={nextMonthsQuantity}
            onPrev={handlePrevHorizontal}
            onNext={handleNextHorizontal}
          />
        </TimelineStyles.Header.Items>

        <TimelineStyles.Header.ViewportData ref={headerViewportRef}>
          {calendarItems.map(({ date }) => {
            return (
              <TimelineStyles.Header.Day
                key={v4()}
                colors={colors}
                label={format(date, 'MMMM yyyy', { locale })}
                days={differenceInDays(endOfMonth(date), startOfMonth(date))}
                isFirstDay={isFirstDayOfMonth(date)}
                isLastDay={isLastDayOfMonth(date)}
              >
                <strong>{format(date, 'dd', { locale })}</strong>
                <strong>
                  {format(date, 'EE', { locale }).substring(0, 3)}
                </strong>
              </TimelineStyles.Header.Day>
            );
          })}
        </TimelineStyles.Header.ViewportData>
      </TimelineStyles.Header.Container>

      <TimelineStyles.Content colors={colors}>
        <TimelineStyles.Aside.Container
          id={keys.aside}
          ref={asideRef}
          colors={colors}
        >
          {hasPrevPage && (
            <AsideItemRow
              key={v4()}
              colors={colors}
              selectedMonth={selectedMonth}
              item={{
                id: 0,
                name: '',
                color: 'red',
                variation: 'LOAD_PREV',
                startDate: new Date(),
                endDate: new Date(),
                bars: [],
                summary_activities: [],
              }}
              isShowContent={variation === 'REPORT'}
              delay={delay}
              onClick={handlePrevVertical}
            />
          )}

          {timelineData.map(item => {
            return (
              <AsideItemRow
                key={v4()}
                colors={colors}
                selectedMonth={selectedMonth}
                item={item}
                isShowContent={variation === 'REPORT'}
              />
            );
          })}

          {hasNextPage &&
            timelineData.filter(item => item.variation === 'EMPTY').length ===
              0 && (
              <AsideItemRow
                key={v4()}
                colors={colors}
                selectedMonth={selectedMonth}
                item={{
                  id: 0,
                  name: '',
                  color: 'red',
                  variation: 'LOAD_NEXT',
                  startDate: new Date(),
                  endDate: new Date(),
                  bars: [],
                  summary_activities: [],
                }}
                isShowContent={variation === 'REPORT'}
                delay={delay}
                onClick={handleNextVertical}
              />
            )}
        </TimelineStyles.Aside.Container>

        <TimelineStyles.Calendar.ViewportData
          id={keys.viewportData}
          ref={viewportDataRef}
          colors={colors}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        >
          {hasPrevPage && (
            <CalendarRow
              key={v4()}
              colors={colors}
              selectedMonth={selectedMonth}
              nextMonthsQuantity={nextMonthsQuantity}
              keys={keys}
              item={{
                id: 0,
                name: '',
                color: 'red',
                variation: 'LOADING',
                startDate: new Date(),
                endDate: new Date(),
                bars: [],
                summary_activities: [],
              }}
              dayWidth={dayWidth}
              calendarItems={calendarItems}
              headerRef={headerViewportRef}
              asideRef={asideRef}
              viewportDataRef={viewportDataRef}
              isShowContent={variation === 'REPORT'}
            />
          )}

          {timelineData.map(item => {
            return (
              <CalendarRow
                key={v4()}
                colors={colors}
                selectedMonth={selectedMonth}
                nextMonthsQuantity={nextMonthsQuantity}
                keys={keys}
                item={item}
                dayWidth={dayWidth}
                calendarItems={calendarItems}
                headerRef={headerViewportRef}
                asideRef={asideRef}
                viewportDataRef={viewportDataRef}
                isShowContent={variation === 'REPORT'}
              />
            );
          })}

          {hasNextPage &&
            timelineData.filter(item => item.variation === 'EMPTY').length ===
              0 && (
              <CalendarRow
                key={v4()}
                colors={colors}
                selectedMonth={selectedMonth}
                nextMonthsQuantity={nextMonthsQuantity}
                keys={keys}
                item={{
                  id: 0,
                  name: '',
                  color: 'red',
                  variation: 'LOADING',
                  startDate: new Date(),
                  endDate: new Date(),
                  bars: [],
                  summary_activities: [],
                }}
                dayWidth={dayWidth}
                calendarItems={calendarItems}
                headerRef={headerViewportRef}
                asideRef={asideRef}
                viewportDataRef={viewportDataRef}
                isShowContent={variation === 'REPORT'}
              />
            )}
        </TimelineStyles.Calendar.ViewportData>
      </TimelineStyles.Content>
    </TimelineStyles.Container>
  );
};

export { Timeline };
