import styled, { css } from 'styled-components';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      padding: 0;

      > div:first-of-type {
        padding: 24px;

        button {
          span {
            display: none;
          }
        }
      }
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'downloads-container',
})`
  height: calc(100% - 106px);

  ${responsive.Container}
`;

export const DownloadsContainer = styled.div.attrs({
  className: 'downloads-container',
})`
  width: 100%;
  height: calc(100% - 68px);

  padding: 0 48px;

  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ul {
    margin: 0;
    padding: 30px;
    list-style: none;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 30px;
    > li {
      display: grid;
      padding: 20px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 10px;
      grid-template-rows: auto 1fr auto;
      text-align: center;
      a {
        color: #fff;
        margin: 0 auto;
        text-decoration: none;
      }
      > svg {
        color: rgba(255, 255, 255, 0.75);
        fill: rgba(255, 255, 255, 0.75);
        margin: 45px auto;
      }
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }
  }
`;
