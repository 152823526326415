import { IQuery } from '@interfaces/generic/IQuery';

function getParsedQuery(query?: IQuery) {
  if (!query) return '?search=false';
  if (query.filters?.length === 0) return '?search=false';

  const { filters, orders } = query;

  const pFilters = filters ? `?filters=${JSON.stringify(filters)}` : '';
  const pOrders = orders ? `&orders=${JSON.stringify(orders)}` : '';
  const parsedQuery = query ? `${pFilters}${pOrders}` : '';

  return `${parsedQuery}&search=true`;
}

export { getParsedQuery };
