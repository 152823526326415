import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'kan_ban-container',
})`
  height: 100%;

  gap: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
`;
