import React from 'react';
import { useTransition } from 'react-spring';

import { Toast } from '@components/atoms';
import { ToastMessage } from '@contexts/ReactToastContext';

import { Container } from './styles';

interface IToastsProps {
  messages: ToastMessage[];
}

const Toasts: React.FC<IToastsProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { right: '-120%' },
      enter: { right: '0%' },
      leave: { right: '-120%' },
    },
  );

  return (
    <Container>
      {messagesWithTransitions.map(({ key, item, props }) => {
        return <Toast key={key} message={item} style={props} />;
      })}
    </Container>
  );
};

export { Toasts };
