import React, { useCallback, useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import { t } from 'i18next';

import * as Icons from '@assets/icons';
import { Button, Loading, Monthly } from '@components/atoms';
import { PageHeader } from '@components/molecules';
import { Organogram } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { OrganogramsDrawer } from '@components/templates';
import {
  NotFound,
  NotFoundContainer,
} from '@components/templates/ScrollList/styles';
import { useDragScroll } from '@hooks/useDragScroll';
import { useOrganogram } from '@hooks/useOrganogram';
import { getOrganograms } from '@services/apis/OrganogramsService';
import { getFirstAndLastDatesFromDate } from '@utils/getFirstAndLastDatesFromDate';

import { Container, Organograms } from './styles';

// interface IFilters {
//   startDate: string;
//   endDate: string;
// }

const Groups: React.FC = () => {
  const { organograms, setOrganograms, filters, setFilters } = useOrganogram();

  const [organogramId, setOrganogramId] = useState<number>();

  const organogramsRef = useRef<HTMLDivElement>(null);
  const organogramsDrawerRef = useRef<IDrawerRef>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleOpenDrawer = useCallback(() => {
    organogramsDrawerRef.current?.open();
  }, []);

  useEffect(() => {
    async function loadOrganograms() {
      const { startDate, endDate } = filters;

      const data = await getOrganograms({ startDate, endDate });

      setOrganograms(data);
      setIsLoading(false);
    }

    loadOrganograms();
  }, [filters, setOrganograms]);

  useDragScroll({ ref: organogramsRef });

  return (
    <Container>
      <PageHeader>
        <Monthly
          onChange={(month, year) => {
            const [first, last] = getFirstAndLastDatesFromDate(
              new Date(year, month, 1),
            );

            setFilters({
              startDate: format(first, 'yyyy-MM-dd'),
              endDate: format(last, 'yyyy-MM-dd'),
            });
          }}
        />

        <Button icon={Icons.Organogram} callToAction onClick={handleOpenDrawer}>
          Adicionar Grupo
        </Button>
      </PageHeader>

      <Organograms ref={organogramsRef}>
        {isLoading ? (
          <Loading />
        ) : organograms.length ? (
          organograms.map(organogram => (
            <Organogram key={organogram.id} organogram={organogram} />
          ))
        ) : (
          <NotFoundContainer
            style={{
              width: '-webkit-fill-available',
              paddingLeft: 0,
              paddingRight: 48,
            }}
          >
            <NotFound>{t('NOT_FOUND')}</NotFound>
          </NotFoundContainer>
        )}
      </Organograms>

      <OrganogramsDrawer
        organogramId={organogramId}
        setOrganogramId={setOrganogramId}
        setOrganograms={setOrganograms}
        organogramDrawerRef={organogramsDrawerRef}
      />
    </Container>
  );
};

export { Groups };
