import React, { useEffect } from 'react';

import { differenceInDays, format, addMonths } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import { useSynchronizedDragScroll } from '@hooks/useSynchronizedDragScroll';
import { useSyncronizedScroll } from '@hooks/useSyncronizedScroll';
import { Full } from '@interfaces/generic/IGeneric';

import { Bar } from './Bar';
import { Day } from './Day';
import { ICalendarItem, IColors, IKeys, ITimelineRow } from './types';

import { CalendarRowStyles } from './styles';

interface ICalendarRowProps {
  colors: Full<IColors>;
  selectedMonth: Date;
  nextMonthsQuantity: number;
  dayWidth: number;
  item: ITimelineRow;
  keys: IKeys;
  calendarItems: ICalendarItem[];
  headerRef: React.RefObject<HTMLDivElement>;
  asideRef: React.RefObject<HTMLDivElement>;
  viewportDataRef: React.RefObject<HTMLDivElement>;
  isShowContent?: boolean;
}

const CalendarRow: React.FC<ICalendarRowProps> = ({
  colors,
  selectedMonth,
  nextMonthsQuantity,
  dayWidth,
  item,
  keys,
  calendarItems,
  headerRef,
  asideRef,
  viewportDataRef,
  isShowContent,
}) => {
  useEffect(() => {
    const todayIndex = calendarItems.findIndex(
      calendarItem =>
        format(calendarItem.date, 'yyyy-MM-dd', { locale }) ===
        format(selectedMonth, 'yyyy-MM-dd', { locale }),
    );

    if (viewportDataRef.current) {
      viewportDataRef.current.scrollTo({
        left: dayWidth * todayIndex,
        behavior: 'auto',
      });
    }

    if (headerRef.current) {
      headerRef.current.scrollTo({
        left: dayWidth * todayIndex,
        behavior: 'auto',
      });
    }
  }, [calendarItems, dayWidth, headerRef, selectedMonth, viewportDataRef]);

  useSynchronizedDragScroll({
    ref: viewportDataRef,
    elementKey: keys.viewportData,
    targetRef: headerRef,
    directions: 'horizontal',
  });

  useSyncronizedScroll({
    refs: [
      { ref: asideRef, id: keys.aside, targetId: keys.viewportData },
      { ref: viewportDataRef, id: keys.viewportData, targetId: keys.aside },
    ],
    scrollType: 'VERTICAL',
  });

  return (
    <CalendarRowStyles.Container>
      {calendarItems.map(calendarItem => {
        return (
          <Day
            key={v4()}
            colors={colors}
            item={item}
            calendarItem={calendarItem}
            isShowContent={isShowContent}
          />
        );
      })}

      {item.bars?.map(bar => {
        const foundedPosition = calendarItems.find(
          cItem =>
            format(cItem.date, 'yyyy-MM-dd', { locale }) === String(bar.start),
        );

        const lastPosition = calendarItems.at(-1);

        if (!lastPosition) return undefined;

        const diff = differenceInDays(new Date(bar.end), new Date(bar.start));

        const nextMonths = addMonths(selectedMonth, nextMonthsQuantity + 1);

        const endDateIsGreater = new Date(bar.end) > nextMonths;

        const calcBar = {
          left: foundedPosition ? dayWidth * foundedPosition.start : 0,
          width: endDateIsGreater
            ? (lastPosition.start - (foundedPosition?.start || 0)) * 47 + 47
            : dayWidth * diff + dayWidth,
        };

        return (
          <Bar
            key={v4()}
            bar={calcBar}
            color={bar.color}
            snap={dayWidth}
            keys={keys}
            viewportDataRef={viewportDataRef}
            isShowContent={isShowContent}
            startDateIsLess={!foundedPosition}
            endDateIsGreater={endDateIsGreater}
          />
        );
      })}
    </CalendarRowStyles.Container>
  );
};

const Component = React.memo(CalendarRow, (prev, next) =>
  Object.is(prev, next),
);

export { Component as CalendarRow };
