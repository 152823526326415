import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';

import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

import { Avatar } from '@components/atoms';
import { ITag } from '@components/atoms/Tags';
import { Can } from '@components/Can';
import { Modal, IModalRef } from '@components/organisms';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { IColors } from '@interfaces/generic/ITheme';
import { IProject } from '@interfaces/IProject';
import {
  associateCollaboradors,
  desassociateCollaboradors,
} from '@services/apis/ProjectsService';
import { UsersService } from '@services/apis/UsersService';

import {
  ModalContent,
  Header,
  ProjectInfo,
  Collaborators,
  CollaboratorsHeader,
  Scroll,
  Collaborator,
  CollaboratorInfo,
  CollaboratorAssociatedAt,
  CollaboratorActions,
  SelectCollaborator,
  SelectCollaboratorWrapper,
} from './styles';

interface IAddCollaboratorsModalProps {
  project: IProject | undefined;
  setProject: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  addCollaboratorsModalRef: React.RefObject<IModalRef>;
}

const AddCollaboratorsModal: React.FC<IAddCollaboratorsModalProps> = ({
  project,
  setProject,
  addCollaboratorsModalRef,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });
  const [collaboratorsSelect, setCollaboratorsSelect] = useState<ITag[]>();
  const { addToast } = useToast();

  // const history = useHistory();

  const placeholder = useMemo(() => {
    if (!project) return '';

    const nameArr = project?.name.split(' ');

    return nameArr[0].substring(0, 1);
  }, [project]);

  // const handleNavigateTimeline = useCallback(() => {
  //   history.push('/timeline');
  // }, [history]);

  const handleDesassociateCollaborator = useCallback(
    async (id: number, name?: string) => {
      if (!project) return;

      const status = await desassociateCollaboradors({
        project_id: project.id,
        user_id: id,
      });

      if (status === 204 && project) {
        const updatedProject = cloneDeep(project);

        if (updatedProject.collaborators) {
          updatedProject.collaborators = updatedProject?.collaborators.filter(
            item => item.user_id !== id,
          );
          setProject(updatedProject);
        }

        addToast({
          title: `O colaborador ${name} foi desassociado com sucesso!`,
          type: 'success',
        });
      }
    },
    [addToast, project, setProject],
  );

  const handleAssociateCollaborator = useCallback(
    async (ids: number[]) => {
      const formattedIds = ids.filter(item => item !== 0);

      if (project && formattedIds.length > 0) {
        const updatedAssociations = await associateCollaboradors({
          project_id: project.id,
          users_ids: ids,
        });

        const updatedProject = cloneDeep(project);

        updatedProject.collaborators = updatedAssociations;

        setProject(updatedProject);
      }
    },
    [project, setProject],
  );

  useEffect(() => {
    async function loadUsers() {
      const filteredUserIds = project?.collaborators?.map(item => item.user.id);

      const response = await UsersService.list({
        query: {
          q: [
            {
              property: 'enabled',
              operator: 'EQUAL',
              value: '1',
              rule: 'AND',
              entity: 'users',
            },
          ],
          page: 1,
          limit: 0,
          sort: [{ order: 'ASC', property: 'name' }],
        },
        relations: [],
      });

      const users = response.data.filter(
        (item: any) => !filteredUserIds?.includes(item.id),
      );

      setCollaboratorsSelect(
        users.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        }),
      );
    }

    loadUsers();
  }, [project?.collaborators]);

  return (
    <Modal
      ref={addCollaboratorsModalRef}
      size="medium"
      hasNavigation={false}
      // onClose={reset}
    >
      <ModalContent>
        <Header>
          <ProjectInfo>
            <Avatar
              name="avatar_url"
              avatar_url={project?.avatar_url}
              avatarSize={{ width: 48, height: 48 }}
              iconSize={24}
              placeholder={placeholder}
              readOnly
            />

            <div>
              <strong>Colaboradores</strong>
              <span>{project?.name}</span>
            </div>
          </ProjectInfo>

          <SelectCollaboratorWrapper>
            <SelectCollaborator
              name="users"
              label="Colaboradores"
              placeholder="Escolha o colaborador"
              tags={collaboratorsSelect}
              onSelect={(items: any) => {
                const ids = items.map((item: any) => {
                  return item.value;
                });

                handleAssociateCollaborator(ids);
              }}
              searchable
              minHeight="36px"
              valueToReturn="value"
            />
          </SelectCollaboratorWrapper>
        </Header>

        <Collaborators>
          <CollaboratorsHeader>
            <strong>Colaborador</strong>
            <strong>Associado em</strong>
            <strong />
          </CollaboratorsHeader>

          <Scroll>
            {project?.collaborators?.map(collaborator => {
              const collaboratorPlaceholder = collaborator.user.name
                .split(' ')[0]
                .substring(0, 1);

              return (
                <Collaborator key={v4()}>
                  <CollaboratorInfo>
                    <Avatar
                      name="avatar_url"
                      avatar_url={collaborator.user.avatar_url}
                      placeholder={collaboratorPlaceholder}
                      avatarSize={{ width: 40, height: 40 }}
                      iconSize={24}
                      readOnly
                    />

                    <div>
                      <strong>{collaborator.user.name}</strong>
                      <p>{collaborator.user.email}</p>
                    </div>
                  </CollaboratorInfo>

                  <CollaboratorAssociatedAt>
                    <p>
                      {format(
                        new Date(collaborator.associated_at),
                        'dd MMM yyyy',
                        { locale },
                      )}
                    </p>
                  </CollaboratorAssociatedAt>

                  <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                    {/* TODO: Quando a timeline estiver funcionando habilitar esse botão */}
                    <CollaboratorActions>
                      {/* <button type="button" onClick={handleNavigateTimeline}>
                        <Icons.Timeline />
                      </button> */}
                      <div />

                      <button
                        type="button"
                        onClick={() =>
                          handleDesassociateCollaborator(
                            collaborator.user_id,
                            collaborator.user.name,
                          )
                        }
                      >
                        <HiOutlineXCircle size={24} color={colors.white} />
                      </button>
                    </CollaboratorActions>
                  </Can>
                </Collaborator>
              );
            })}
          </Scroll>
        </Collaborators>
      </ModalContent>
    </Modal>
  );
};

export { AddCollaboratorsModal };
