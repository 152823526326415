import React from 'react';

import { Heatmap } from '@components/atoms';

import { Container } from './styles';

const Profile: React.FC = () => {
  return (
    <Container>
      <Heatmap />
    </Container>
  );
};

export { Profile };
