import React, { useRef, useState, useCallback, useMemo } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { addMonths } from 'date-fns';
import { CSSProperties } from 'styled-components';

import { useClickAway } from '@hooks/useClickAway';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';

import { DateSelector } from './DateSelector';

import { Container, Arrow, Info, Actions } from './styles';

export type IValue = { original: Date[]; formatted: string; infoLabel: string };
export type IType = 'picker' | 'range';

interface ISmartRangeProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> {
  language?: 'pt-BR';
  minDate?: Date;
  maxDate?: Date;
  canCompare?: boolean;
  hasNavigation?: boolean;
  defaultValue?: Date[];
  type?: IType;
  style?: CSSProperties;
  className?: string;
  onApply: (dates: Date[]) => void;
}

const SmartRange: React.FC<ISmartRangeProps> = ({
  language,
  minDate,
  maxDate,
  canCompare = true,
  hasNavigation = false,
  placeholder,
  defaultValue,
  type = 'range',
  style,
  className,
  onApply,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const parsedDefaultValue: IValue = useMemo(() => {
    if (!defaultValue) {
      return { original: [], formatted: '', infoLabel: '' };
    }

    let formatted = '';

    if (defaultValue.length === 0) formatted = `Todo o período`;

    if (defaultValue.length === 1) {
      formatted = `${defaultValue[0].toLocaleDateString(language)}`;
    }

    if (defaultValue.length === 2) {
      const first = defaultValue[0].toLocaleDateString(language);
      const last = defaultValue[1].toLocaleDateString(language);

      formatted = `${first} - ${last}`;
    }

    if (defaultValue.length === 3) {
      const first = defaultValue[0].toLocaleDateString(language);
      const last = defaultValue[1].toLocaleDateString(language);

      formatted = `${first} - ${last}`;
    }

    if (defaultValue.length === 4) {
      const fromStartComparer = defaultValue[0].toLocaleDateString(language);
      const toEndComparer = defaultValue[3].toLocaleDateString(language);

      formatted = `${fromStartComparer} á ${toEndComparer}`;
      // infoLabel = `${fromStartComparer} á ${toEndComparer}`;
    }

    if (inputRef.current) inputRef.current.setAttribute('value', formatted);

    return { original: defaultValue, formatted, infoLabel: '' };
  }, [defaultValue, language]);

  const [value, setValue] = useState<IValue>(parsedDefaultValue);

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isComparing, setIsComparing] = useState<boolean>(false);

  const onSelect = useCallback(
    (original: Date[], infoLabel: string) => {
      let formatted = '';

      if (original.length === 0) formatted = `Todo o período`;

      if (original.length === 1) {
        formatted = `${original[0].toLocaleDateString(language)}`;
      }

      if (original.length === 2) {
        const first = original[0].toLocaleDateString(language);
        const last = original[1].toLocaleDateString(language);

        formatted = `${first} - ${last}`;
      }

      if (original.length === 3) {
        const first = original[0].toLocaleDateString(language);
        const last = original[1].toLocaleDateString(language);

        formatted = `${first} - ${last}`;
      }

      if (original.length === 4) {
        const fromStartComparer = original[0].toLocaleDateString(language);
        const toEndComparer = original[3].toLocaleDateString(language);

        formatted = `${fromStartComparer} á ${toEndComparer}`;
        // infoLabel = `${fromStartComparer} á ${toEndComparer}`;
      }

      setValue({ original, formatted, infoLabel });

      if (inputRef.current) inputRef.current.setAttribute('value', formatted);
    },
    [language],
  );

  const handleCancel = useCallback(() => setIsFocused(false), []);

  const handleClear = useCallback(() => {
    setValue({ original: [], formatted: '', infoLabel: '' });
    if (inputRef.current) inputRef.current.setAttribute('value', '');
  }, []);

  const handleApply = useCallback(
    (selection: Date[], infoLabel: string) => {
      onApply(selection);
      setIsFocused(false);
      onSelect(selection, infoLabel);
    },
    [onApply, onSelect],
  );

  const onFocus = useCallback(() => setIsFocused(true), []);

  useClickAway(containerRef, () => setIsFocused(false), { enabled: isFocused });

  return (
    <Container
      ref={containerRef}
      isFocused={isFocused}
      hasNavigation={hasNavigation}
      style={style}
      className={className}
    >
      <Info>
        <span>{value.infoLabel}</span>
      </Info>

      <input
        ref={inputRef}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly
      />

      <Arrow isFocused={isFocused} />

      {hasNavigation && (
        <Actions>
          <button
            type="button"
            disabled={value.original.length === 0}
            onClick={() => console.log('onPrev')}
          >
            <FiChevronLeft
              size={32}
              color={colors.input.normal.border}
              style={{ marginRight: '3px' }}
            />
          </button>

          <button
            type="button"
            disabled={value.original.length === 0}
            onClick={() => console.log('onNext')}
          >
            <FiChevronRight
              size={32}
              color={colors.input.normal.border}
              style={{ marginLeft: '3px' }}
            />
          </button>
        </Actions>
      )}

      <DateSelector
        startDate={startDate}
        setStartDate={setStartDate}
        isFocused={isFocused}
        isComparing={isComparing}
        setIsComparing={setIsComparing}
        minDate={minDate}
        maxDate={maxDate}
        canCompare={canCompare}
        type={type}
        onApply={handleApply}
        onCancel={handleCancel}
        onClear={handleClear}
        onSelect={onSelect}
        onPrev={() => setStartDate(state => addMonths(state, -1))}
        onNext={() => setStartDate(state => addMonths(state, +1))}
        value={value}
      />
    </Container>
  );
};

export { SmartRange };
