import React, { useRef, useState } from 'react';
import { HiOutlineFilter } from 'react-icons/hi';

import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { useClickAway } from '@hooks/useClickAway';

import { Container, Input, FilterSelector, List, Item } from './styles';

export type IFilterOption = {
  color: string;
  label: string;
  value: number | string;
  is_checked: boolean;
};

interface IFilterProps {
  options: IFilterOption[];
  onSelect: (option: IFilterOption) => void;
}

const Filter: React.FC<IFilterProps> = ({ options, onSelect }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  async function handleFilter(item: IFilterOption) {
    onSelect(item);
  }

  useClickAway(containerRef, () => setIsOpen(false), { enabled: isOpen });

  return (
    <Container ref={containerRef} isOpen={isOpen}>
      <Input
        ref={inputRef}
        isOpen={isOpen}
        onFocus={() => setIsOpen(true)}
        placeholder="Filtrar"
        readOnly
      />

      <HiOutlineFilter size={24} onClick={() => inputRef.current?.focus()} />

      <FilterSelector isOpen={isOpen}>
        <List>
          {options.map(item => {
            return (
              <Item key={v4()} color={item.color}>
                <input
                  id={`check-item-${item.value}`}
                  type="checkbox"
                  defaultChecked={item.is_checked}
                  onChange={debounce(() => handleFilter(item), 500)}
                />

                <label htmlFor={`check-item-${item.value}`}>
                  <span>{item.label}</span>
                </label>
              </Item>
            );
          })}
        </List>
      </FilterSelector>
    </Container>
  );
};

const Component = React.memo(Filter, (prev, next) => Object.is(prev, next));

export { Component as Filter };
