import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';

import { useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

import { Message } from '@components/atoms/ChatBot/Messages/styles';
import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { useToast } from '@hooks/useToast';
import { ISummaryActivity } from '@interfaces/IProject';
import { ChatBotService } from '@services/apis/ChatBotService';
import { toHMS } from '@utils/toHMS';

import { IComponentsProps } from '../../types';
import { ProjectAssociation } from './ProjectAssociation';
import { ProjectCorrection } from './ProjectCorrection';

import {
  Header,
  Content,
  Actions,
  AddProjectButton,
  ConfirmationDialog,
} from './styles';

export type IFormData = {
  summary_activity_id: number;
  adjusted_worked_hours: number;
  is_adjusted: boolean;
  max: number;
};

const TimeCorrection: React.FC<IComponentsProps> = ({
  response: { total_worked_Hours: total, activities },
  onAnswer,
}) => {
  const { addToast } = useToast();

  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const [projects, setProjects] = useState<ISummaryActivity[]>(activities);
  const [isAssociating, setIsAssociating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [rest, setRest] = useState<number>(total);

  const checkedTotal = useMemo(
    () =>
      activities
        .filter(item => item.checked_worked_hours)
        .reduce((acc, item) => acc + Number(item.checked_worked_hours), 0),
    [activities],
  );

  const onCorrect = useCallback(
    async (summary: ISummaryActivity) => {
      const alreadyCheckedTotal = projects
        .filter(item => item.checked_worked_hours)
        .reduce((acc, item) => acc + Number(item.checked_worked_hours), 0);

      const calculatedRest =
        total - alreadyCheckedTotal - Number(summary.checked_worked_hours);

      setRest(calculatedRest);

      setProjects(state => {
        return state.map(project => {
          if (project.id === summary.id) return summary;
          if (project.checked_worked_hours) return project;

          const original_worked_hours =
            calculatedRest /
            projects.filter(i => !i.checked_worked_hours && i.id !== summary.id)
              .length;

          return { ...project, original_worked_hours };
        });
      });
    },
    [projects, total],
  );

  const onUndo = useCallback(
    (summary: ISummaryActivity) => {
      const alreadyCheckedTotal = projects
        .filter(item => item.checked_worked_hours)
        .reduce((acc, item) => acc + Number(item.checked_worked_hours), 0);

      const calculatedRest =
        total - alreadyCheckedTotal + Number(summary.checked_worked_hours);

      setRest(calculatedRest);

      setProjects(state =>
        state.map(project =>
          project.id === summary.id
            ? { ...summary, checked_worked_hours: undefined }
            : project,
        ),
      );
    },
    [projects, total],
  );

  const onCancel = useCallback(async () => {
    try {
      await ChatBotService.cancelConfirmationOfWorkedHours({
        summary_activities_ids: projects.map(i => i.id),
        worked_date,
      });

      onAnswer({ dailyState: 'INIT' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao cancelar a edição das horas trabalhadas!',
      });
    }
  }, [addToast, onAnswer, projects, worked_date]);

  const onConfirm = useCallback(async () => {
    try {
      await ChatBotService.adjustWorkedHours({
        activities: projects.map(formDataItem => ({
          summary_activity_id: formDataItem.id,
          adjusted_worked_hours: Number(formDataItem.checked_worked_hours),
        })),
        worked_date,
      });

      onAnswer({ dailyState: 'ADJUSTED_WORKED_HOURS' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao confirmar suas horas trabalhadas!',
      });
    }
  }, [addToast, onAnswer, projects, worked_date]);

  useEffect(() => {
    if (checkedTotal > 0) {
      const calculatedRest = total - checkedTotal;

      setRest(calculatedRest);

      setProjects(state => {
        return state.map(project => {
          if (project.checked_worked_hours) return project;

          const original_worked_hours =
            calculatedRest /
            activities.filter(i => !i.checked_worked_hours).length;

          return { ...project, original_worked_hours };
        });
      });
    }
  }, [activities, checkedTotal, total]);

  const projectsCheckeds = projects.filter(
    project => project.checked_worked_hours,
  );

  return (
    <Message.Interaction>
      <div style={{ position: 'relative' }}>
        <Header>
          <strong>
            Horas trabalhadas :{' '}
            {toHMS(
              projects
                .filter(item => item.checked_worked_hours)
                .reduce(
                  (acc, item) => acc + Number(item.checked_worked_hours),
                  0,
                ),
            )}{' '}
            / {toHMS(total)}
          </strong>
          <strong>Correção de horas</strong>
        </Header>

        <Content>
          {projects.map(activity => {
            return (
              <ProjectCorrection
                key={v4()}
                activity={activity}
                max={total}
                rest={rest}
                isSubmitting={isSubmitting}
                onCorrect={onCorrect}
                onUndo={onUndo}
                onAnswer={onAnswer}
              />
            );
          })}

          {isAssociating && (
            <ProjectAssociation
              onAnswer={onAnswer}
              activities={projects}
              setIsAssociating={setIsAssociating}
            />
          )}
        </Content>

        <Actions>
          <AddProjectButton
            type="button"
            onClick={() => {
              setIsAssociating(true);
              onAnswer({ scrollToDown: true });
            }}
          >
            <HiOutlinePlus size={18} />
            Adicionar projeto
          </AddProjectButton>

          <div>
            {isSubmitting && (
              <ConfirmationDialog>
                <strong>
                  Você trabalhou
                  <span>
                    {toHMS(
                      projects
                        .filter(item => item.checked_worked_hours)
                        .reduce(
                          (acc, item) =>
                            acc + Number(item.checked_worked_hours),
                          0,
                        ),
                    )}
                  </span>
                  ?
                </strong>

                <div>
                  <button type="button" className="yes" onClick={onConfirm}>
                    Sim
                  </button>

                  <button
                    type="button"
                    className="no"
                    onClick={() => setIsSubmitting(false)}
                  >
                    Não
                  </button>
                </div>
              </ConfirmationDialog>
            )}

            {sessionStorage.openDailyChat !== 'true' && (
              <button type="button" className="cancel" onClick={onCancel}>
                Cancelar
              </button>
            )}

            <button
              type="button"
              className="save"
              onClick={() => setIsSubmitting(true)}
              disabled={projectsCheckeds.length !== projects.length}
            >
              Salvar
            </button>
          </div>
        </Actions>
      </div>
    </Message.Interaction>
  );
};

export { TimeCorrection };
