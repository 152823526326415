import { parseCookies } from 'nookies';

import { IPayloadToken } from '@interfaces/IAuthentication';

function getJWTPayload(token?: string): IPayloadToken {
  const { 'devstream.token': foundedToken } = parseCookies();

  if (!foundedToken)
    return {
      sub: '',
      exp: 0,
      iat: 0,
      name: '',
      email: '',
      role_id: 0,
      claims: [],
    };

  token = foundedToken;

  if (!token) return {} as IPayloadToken;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export { getJWTPayload };
