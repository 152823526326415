import React from 'react';
import { v4 } from 'uuid';

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import { Person } from '@assets/icons';

import { Figure, Image, FigCaption } from './styles';

const ScreenshotItem: React.FC<any> = ({ event, screenshot, onClick }) => {
  const shotTime = format(
    parseISO(String(screenshot.timestamp.value)),
    'dd/MM/yyyy - HH:mm:ss',
    { locale },
  );
  return (
    <Figure
      key={v4()}
      title={event.titlebar}
      productivity={event.productivity}
      onClick={onClick}
    >
      <Image
        key={v4()}
        src="/1px.gif"
        thumb={screenshot.thumb}
        alt={event.titlebar}
        data-image={screenshot.image}
      />
      <FigCaption key={v4()} shotTime={shotTime}>
        <Person key={v4()} />
        {event.useralias}
      </FigCaption>
    </Figure>
  );
};

export { ScreenshotItem };
