import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  isChecked: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, isChecked }) => css`
    border: 1px solid
      ${theme.title === 'light'
        ? isChecked
          ? theme.colors.activities.success
          : '#E0E0E0'
        : isChecked
        ? theme.colors.activities.success
        : theme.colors.asideMenu};
    border-radius: 8px 8px 8px 0;
    background: ${theme.title === 'light'
      ? isChecked
        ? transparentize(0.4, theme.colors.activities.success)
        : '#F0F4F9'
      : transparentize(
          0.4,
          isChecked ? theme.colors.activities.success : theme.colors.asideMenu,
        )};
    /* background: #f0f4f9; */

    margin: 24px 16px 0 0;
    padding: 8px 16px 8px 8px;

    position: relative;

    gap: 8px;
    display: flex;

    & + div {
      margin: 28px 16px 0 0;
    }

    label {
      font-weight: bold;
      font-size: 0.875rem;

      position: absolute;
      top: -19px;
      left: 8px;
    }

    > div {
    }
  `}
`;

interface IInputGroupProps {
  percentual: string;
  isEditing: boolean;
  isChecked: boolean;
}

export const InputGroup = styled.div<IInputGroupProps>`
  ${({ theme, percentual, isEditing, isChecked }) => css`
    border: 1px solid ${theme.colors.background};
    border-radius: ${theme.borders.radii[100]};
    position: relative;

    display: flex;

    transition: all 400ms;

    &:hover {
      border-color: ${isChecked
        ? theme.colors.background
        : transparentize(0.4, theme.colors.activities.info)};
    }

    &.percentual {
      position: relative;

      flex: 1;

      opacity: ${isChecked ? 0.8 : 1};

      cursor: ${isChecked ? 'not-allowed' : 'pointer'};

      ${!isEditing &&
      css`
        &:before {
          content: '';

          width: ${percentual};
          height: 100%;

          border-radius: ${theme.borders.radii[100]};
          background: ${transparentize(0.4, theme.colors.activities.info)};

          position: absolute;
          top: 0;
          left: 0;
        }

        &:after {
          content: '${percentual}';

          width: 100%;
          height: 100%;

          border-radius: ${theme.borders.radii[100]};
          background: transparent;

          color: ${theme.colors.text.title};

          position: absolute;
          top: 0;
          left: 0;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}

      input {
        max-width: 100%;

        color: ${isEditing ? theme.colors.text.title : 'transparent'};
        font-size: ${isEditing ? '1rem' : '0rem'};
        text-align: center;

        flex: 1;
      }
    }

    input {
      max-width: 110px;
      min-height: 40px;

      border: 1px solid
        ${theme.title === 'light' ? '#B1C5DD' : theme.colors.background};
      border-radius: ${theme.borders.radii[100]};
      background: ${theme.colors.background};

      color: ${theme.colors.text.title};

      padding: 8px;

      cursor: ${isChecked ? 'not-allowed' : 'pointer'};

      &[type='time']::-webkit-calendar-picker-indicator {
        background: none;
      }

      &:disabled {
        background-color: ${theme.colors.background};
      }

      &::placeholder {
        color: ${theme.colors.text.title};
      }
    }

    svg {
      position: absolute;
      top: 50%;
      right: 8px;

      stroke: #666a88;

      transform: translateY(-50%);
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;

    border: 1px solid
      ${theme.title === 'light' ? theme.colors.lines : theme.colors.asideMenu};
    border-radius: 50px;
    background: ${theme.colors.background};

    padding: 2px;

    position: absolute;
    top: 50%;
    right: -11px;

    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(-50%);

    transition: all 200ms;

    &:disabled {
      opacity: 0;
    }

    &:not([disabled]):hover {
      width: 86px;

      &.undo {
        border-color: ${theme.colors.activities.info};
        background: ${theme.colors.activities.info};
      }

      &.remove {
        border-color: ${theme.colors.activities.danger};
        background: ${theme.colors.activities.danger};
      }

      span {
        display: block;
      }

      svg {
        stroke: ${theme.colors.white};

        path {
          fill: ${theme.colors.white};
        }
      }
    }

    span {
      color: ${theme.colors.white};

      display: none;
    }

    svg {
      stroke: ${theme.title === 'light'
        ? theme.colors.lines
        : theme.colors.asideMenu};

      path {
        fill: ${theme.title === 'light'
          ? theme.colors.lines
          : theme.colors.asideMenu};
      }
    }
  `}
`;

export const ConfirmationDialog = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lines};
    border-radius: ${theme.borders.radii[100]};
    background: ${theme.title === 'light'
      ? theme.colors.asideMenu
      : theme.colors.background};

    height: 42px;

    padding: 0 16px;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      gap: 8px;
      display: flex;
    }

    button {
      border: none;
      border-radius: ${theme.borders.radii[100]};

      color: ${theme.title === 'light'
        ? theme.colors.text.invertedTitle
        : theme.colors.text.title};

      padding: 4px 16px;

      &.yes {
        background: ${theme.colors.activities.info};
      }

      &.no {
        background: ${theme.colors.activities.danger};
      }
    }
  `}
`;
