import styled, { css } from 'styled-components';

import { AUTH_ORIGIN } from '@constants/auth';
import { Form } from '@contexts/ReactFormContext';

const origin = localStorage.getItem(AUTH_ORIGIN);

const responsive = css`
  @media (min-width: 280px) and (min-height: 653px) {
    display: flex;

    .send_email_forgot_password .send_email_forgot_password-login svg {
      transform: scale(0.8);
    }

    .send_email_forgot_password-right {
      display: none;
    }
  }

  @media (min-width: 320px) and (min-height: 568px) {
    display: flex;

    .send_email_forgot_password-right {
      display: none;
    }
  }

  @media (min-width: 360px) and (min-height: 640px) {
    display: flex;

    .send_email_forgot_password-right {
      display: none;
    }
  }

  @media (min-width: 768px) and (min-height: 1024px) {
    display: grid;

    .send_email_forgot_password-right {
      gap: 24px;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .send_email_forgot_password-presentations .sign_in-presentation {
        max-width: 300px;

        .send_email_forgot_password-presentation-title p {
          max-width: 280px;
        }

        svg {
          transform: scale(0.6);
        }
      }
    }
  }

  @media (min-width: 1024px) and (min-height: 600px) {
    display: grid;

    .send_email_forgot_password-right {
      gap: 0;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .send_email_forgot_password-presentations .sign_in-presentation {
        gap: 0;

        max-width: 300px;

        .send_email_forgot_password-presentation-title p {
          max-width: 280px;
        }

        svg {
          height: 400px;

          transform: scale(0.6);
        }
      }
    }
  }

  @media (min-width: 1536px) and (min-height: 735.2px) {
    display: grid;

    .send_email_forgot_password-left {
      .send_email_forgot_password-login {
        gap: 80px;

        a {
          margin: 48px 0;
        }
      }
    }

    .send_email_forgot_password-right {
      gap: 48px;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .send_email_forgot_password-presentations .sign_in-presentation {
        gap: 48px;

        max-width: 300px;

        .send_email_forgot_password-presentation-title p {
          max-width: 350px;
        }

        svg {
          height: 450px;

          transform: scale(0.8);
        }
      }
    }
  }

  @media (min-width: 2560px) and (min-height: 951px) {
    display: grid;

    .send_email_forgot_password-left {
      .send_email_forgot_password-login {
        gap: 120px;

        a {
          margin: 48px 0;
        }
      }
    }

    .send_email_forgot_password-right {
      gap: 120px;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .send_email_forgot_password-presentations .sign_in-presentation {
        gap: 120px;

        max-width: 300px;

        .send_email_forgot_password-presentation-title p {
          max-width: 580px;
        }

        svg {
          height: 450px;

          transform: scale(1.2);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .send_email_forgot_password-right {
      display: none;
    }
  }
`;

export const Container = styled.div.attrs({
  className: 'send_email_forgot_password-container',
})`
  width: 100vw;
  height: 100vh;

  border-radius: ${origin === 'electron' ? '16px' : '0'} !important;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;

  ${responsive}
`;

export const Left = styled.div.attrs({
  className: 'send_email_forgot_password-left',
})`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    background: ${theme.colors.activities.tagsWithAction};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Login = styled.div.attrs({
  className: 'send_email_forgot_password-login',
})`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    flex: 1;
    gap: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      color: ${theme.colors.text.links};
      font-size: ${theme.typography.fontSize.small};
      font-weight: ${theme.typography.fontWeight.bold};

      transition: all 400ms;

      &:hover {
        filter: brightness(1.2);
      }
    }
  `}
`;

export const Unform = styled(Form).attrs({
  className: 'send_email_forgot_password-unform',
})`
  ${({ theme }) => css`
    width: 100%;
    max-width: 450px;

    margin-top: 10%;
    padding: 0 48px;

    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.extraLarge};
    }
  `}
`;

export const Right = styled.div.attrs({
  className: 'send_email_forgot_password-right',
})`
  height: 100vh;
`;

export const BoxRight = styled.div.attrs({
  className: 'send_email_forgot_password-presentations',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  display: 'none';
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  max-height: 450px;
`;

export const Title = styled.div.attrs({
  className: 'send_email_forgot_password-title',
})`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: ${theme.typography.fontSize.extraLarge};
      font-weight: bold;
    }

    p {
      width: 480px;

      font-size: ${theme.typography.fontSize.normal};
      font-weight: normal;
      padding-bottom: 15px;
      text-align: justify;
      text-justify: inter-word;
    }
  `}
`;
