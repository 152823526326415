import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borders.radii[500]};
    background: ${theme.colors.cards};
    box-shadow: 0px 0px 24px ${theme.colors.shadow};

    padding: 16px;
  `}
`;
