import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ChatBot } from '@components/atoms';
import { AUTH_ORIGIN } from '@constants/auth';
import { queryClient } from '@services/query';

import { AuthContext, AuthProvider } from './ReactAuthContext';
import { ChatbotProvider } from './ReactChatbotContext';
import { HeaderProvider } from './ReactHeaderContext';
import { I18NextProvider } from './ReactI18NextContext';
import { OrganogramProvider } from './ReactOrganogramContext';
import { ThemeProvider } from './ReactThemeContext';
import { ToastProvider } from './ReactToastContext';

const AppProvider: React.FC = ({ children }) => {
  const origin = localStorage.getItem(AUTH_ORIGIN);

  return (
    <I18NextProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ToastProvider>
            <AuthProvider>
              <AuthContext.Consumer>
                {({ user }) => {
                  if (!user) return <>{children}</>;

                  return (
                    <HeaderProvider>
                      <OrganogramProvider>
                        <ChatbotProvider>
                          {children}
                          {user.users_roles &&
                            user.users_roles[0].role_id !== 1 && (
                              <ChatBot me={user.name} />
                            )}
                        </ChatbotProvider>
                      </OrganogramProvider>
                    </HeaderProvider>
                  );
                }}
              </AuthContext.Consumer>
            </AuthProvider>
          </ToastProvider>
        </ThemeProvider>

        {process.env.NODE_ENV === 'development' && origin !== 'electron' && (
          <ReactQueryDevtools />
        )}
      </QueryClientProvider>
    </I18NextProvider>
  );
};

export { AppProvider };
