import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import { v4 } from 'uuid';

import { Tooltip } from '@components/atoms';
import { projectsShimmerLoading } from '@constants/shimmerLoadings';
import { useDragScroll } from '@hooks/useDragScroll';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { getYAxisValues } from '@utils/getYAxisValues';

import {
  Container,
  Header,
  HeaderLeft,
  Badge,
  ChartContainer,
  ChartLine,
  Bars,
  ChartBar,
  ShimmerChartBar,
  TooltipContent,
  TooltipItem,
} from './styles';

export type IDataItem = {
  project_id: number;
  label: string;
  currentCost: number;
  currentHours: number;
  pastCost: number;
  pastHours: number;
};

export type IData = IDataItem[] | undefined;

export interface IChartData {
  max: number;
  yAxisLabels: string[];
  data: IData;
}

interface IBarChartProps {
  type: 'cost' | 'hours';
  data: IData;
}

const BarChart: React.FC<IBarChartProps> = ({ type, data }) => {
  const barsRef = useRef<HTMLDivElement>(null);
  const colors = useTheme<IColors>({ prop: 'colors' });

  const [chartData, setChartData] = useState<IData>(undefined);

  const totalCurrent = useMemo(() => {
    const total = chartData?.reduce((acc, item) => {
      if (type === 'cost') return acc + item.currentCost;

      return acc + item.currentHours;
    }, 0);

    return total || 0;
  }, [chartData, type]);

  const totalPast = useMemo(() => {
    const total = chartData?.reduce((acc, item) => {
      if (type === 'cost') return acc + item.pastCost;

      return acc + item.pastHours;
    }, 0);

    return total || 0;
  }, [chartData, type]);

  const getMax = useCallback(() => {
    if (type === 'cost') {
      const arr = chartData?.map(i => Number(i.currentCost));

      if (!arr) return 0;

      const max = Math.max.apply(null, arr.length === 0 ? [0] : arr);

      return max;
    }

    const arr = chartData?.map(i => Number(i.currentHours));

    if (!arr) return 0;

    const max = Math.max.apply(null, arr.length === 0 ? [0] : arr);

    return max;
  }, [chartData, type]);

  const shimmerLoading = useMemo(() => {
    return projectsShimmerLoading.map((item: any) => {
      return (
        <ShimmerChartBar
          key={v4()}
          height={(item.value / 100) * 100}
          wordLength={item.label.length}
          isLoading
        >
          <span>{item.label}</span>
        </ShimmerChartBar>
      );
    });
  }, []);

  useEffect(() => {
    async function set() {
      setChartData(data);
    }

    set();
  }, [data]);

  useDragScroll({ ref: barsRef, direction: 'horizontal' });

  const history = useHistory();

  const handleSelectProject = useCallback(
    (project_id: number) => history.push(`/projects/${project_id}`),
    [history],
  );

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <strong>Comparativo de Projetos</strong>

          <div>
            <Badge>
              <span>Período atual:</span>
              <span>
                {type === 'cost'
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalCurrent)
                  : `${totalCurrent.toFixed(2)}h`}
              </span>
            </Badge>

            <Badge>
              <span>Período anterior:</span>
              <span>
                {type === 'cost'
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalPast)
                  : `${totalPast.toFixed(2)}h`}
              </span>
            </Badge>
          </div>
        </HeaderLeft>

        {/* <HeaderRight>
          <Tab
            type="button"
            onClick={() => setGroupBy('weekly')}
          >
            Semanal
          </Tab>

          <Tab
            type="button"
            onClick={() => setGroupBy('monthly')}
          >
            Mensal
          </Tab>
        </HeaderRight> */}
      </Header>

      <ChartContainer>
        {getYAxisValues({ max: getMax(), quantity: 7, type }).map(item => {
          return <ChartLine key={v4()}>{item}</ChartLine>;
        })}

        <Bars ref={barsRef}>
          {!chartData
            ? shimmerLoading
            : chartData?.map(item => {
                const arr = chartData?.map(i =>
                  Number(type === 'cost' ? i.currentCost : i.currentHours),
                );

                const max = arr
                  ? Math.max.apply(null, arr.length === 0 ? [0] : arr)
                  : 0;

                const calculated =
                  (type === 'cost' ? item.currentCost : item.currentHours) /
                  max;

                const height =
                  String(calculated) === 'NaN' ? 0 : calculated * 100;

                return (
                  <ChartBar
                    key={v4()}
                    height={height}
                    wordLength={item.label.length}
                    onClick={() => handleSelectProject(item.project_id)}
                  >
                    <Tooltip direction="LEFT" followCursor>
                      <TooltipContent>
                        <TooltipItem color={colors.hours.productive}>
                          <div>
                            <strong>Período atual</strong>

                            <p>
                              {type === 'cost'
                                ? new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.currentCost)
                                : `${item.currentHours.toFixed(2)}h`}
                            </p>
                          </div>
                        </TooltipItem>

                        <TooltipItem color={colors.hours.unproductive}>
                          <div>
                            <strong>Período anterior</strong>

                            <p>
                              {type === 'cost'
                                ? new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.pastCost)
                                : `${item.pastHours.toFixed(2)}h`}
                            </p>
                          </div>
                        </TooltipItem>
                      </TooltipContent>
                    </Tooltip>

                    <span>{item.label}</span>
                  </ChartBar>
                );
              })}
        </Bars>
      </ChartContainer>
    </Container>
  );
};

type ITransformValue = {
  data: any[];
  getProjectId: (row: any) => number;
  getLabel: (row: any) => string;
  getCurrentCost?: (row: any) => number;
  getCurrentHours?: (row: any) => number;
  getPastCost?: (row: any) => number;
  getPastHours?: (row: any) => number;
};

export const transformValue = ({
  data,
  getProjectId,
  getLabel,
  getCurrentCost,
  getCurrentHours,
  getPastCost,
  getPastHours,
}: ITransformValue): IDataItem[] => {
  return data.map(item => ({
    project_id: getProjectId(item),
    label: getLabel(item),
    currentCost: getCurrentCost ? getCurrentCost(item) : 0,
    currentHours: getCurrentHours ? getCurrentHours(item) / 3600 : 0,
    pastCost: getPastCost ? getPastCost(item) : 0,
    pastHours: getPastHours ? getPastHours(item) / 3600 : 0,
  }));
};

const Component = React.memo(BarChart, (prev, next) => {
  return Object.is(prev, next);
});

export { Component as BarChart };
