/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useState, useRef, useEffect } from 'react';

import { format, isEqual, parseISO, subDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { t } from 'i18next';
import { v4 } from 'uuid';

// import { daily as dailyMock } from '@constants/daily';

// import { useHeader } from '@hooks/useHeader';
import defaultAvatar from '@assets/images/default-avatar.png';
import { Button, Loading, Search, SmartRange } from '@components/atoms';
// import { PageHeader } from '@components/molecules';
import { PageHeader } from '@components/molecules';
import { IModalRef } from '@components/organisms';
// import { DAILY_STATUS_FILTER_CONFIG } from '@constants/filters';
import { DailyModal } from '@components/templates';
import {
  LoadingContainer,
  NotFound,
  NotFoundContainer,
} from '@components/templates/ScrollList/styles';
import { HttpQuery, IParsedQuery } from '@helpers/HttpQueryHelper';
import { useHeader } from '@hooks/useHeader';
import { IDaily } from '@interfaces/IDaily';
import { getDailies } from '@services/apis/DailyService';
import { groupBy } from '@utils/groupBy';
import { toHMS } from '@utils/toHMS';

import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Dailies,
  List,
  LoadMore,
  Tag,
} from './styles';

const DailyStatus: React.FC = () => {
  const { getCurrentInfo } = useHeader();
  const { isChecked } = getCurrentInfo();

  const dailyModalRef = useRef<IModalRef>(null);

  // const [isShowAlert, setIsShowAlert] = useState<boolean>(true);

  const [dailies, setDailies] = useState<any>([]);
  const [dailySelected, setDailySelected] = useState<IDaily | undefined>(
    undefined,
  );
  const [filterDate, setFilterDate] = useState<any>({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  });

  const [filter, setFilter] = useState<IParsedQuery>(
    HttpQuery.getInitialQuery({
      q: [
        {
          property: 'daily_date',
          operator: 'GREATER_THAN_OR_EQUAL',
          value: format(subDays(new Date(), 2), 'yyyy-MM-dd', { locale: ptBR }),
          rule: 'AND',
          entity: 'daily',
        },
        {
          property: 'daily_date',
          operator: 'LESS_THAN_OR_EQUAL',
          value: format(new Date(), 'yyyy-MM-dd', { locale: ptBR }),
          rule: 'AND',
          entity: 'daily',
        },
        {
          property: 'concluded',
          operator: 'EQUAL',
          value: '1',
          rule: 'AND',
          entity: 'daily',
        },
      ],
      sort: [
        { property: 'daily_date', order: 'DESC' },
        { property: 'id', order: 'ASC' },
      ],
    }),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDayReference = useCallback((date: string) => {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    if (isEqual(parseISO(date), today)) return 'Hoje';
    if (isEqual(parseISO(date), subDays(today, 1))) return 'Ontem';
    if (isEqual(parseISO(date), subDays(today, 2))) return 'Anteontem';

    return format(parseISO(date), 'dd/MM/yyyy');
  }, []);

  const getFormattedDate = useCallback((date: string) => {
    const locale = { locale: ptBR };

    const weekDay = format(parseISO(date), 'EEE', locale).substr(0, 3);
    const parsedDate = format(parseISO(date), 'dd MMM yyyy', locale);

    return `${weekDay}, ${parsedDate}`;
  }, []);

  const handleLoadDaily = useCallback(async () => {
    setIsLoading(true);
    const data = await getDailies(filter);

    const formattedResponse = groupBy(data, item => item.daily_date);

    const daily: any = [];
    const impediments: any = [];

    formattedResponse.forEach((response: any) => {
      const formattedData: any = {
        date: null,
        formattedDate: null,
        title: null,
        values: [],
      };
      response.forEach((dailyItem: any) => {
        formattedData.date = dailyItem.daily_date;
        formattedData.formattedDate = getFormattedDate(dailyItem.daily_date);
        formattedData.title = getDayReference(dailyItem.daily_date);
        formattedData.values.push(dailyItem);
      });

      formattedData.values.forEach((dailyItem: any) => {
        const hasImpediment = dailyItem.daily_answers.some(
          (item: any) => item.impediment,
        );

        if (hasImpediment) {
          impediments.push(formattedData);
        }
      });

      daily.push(formattedData);
    });

    if (!isChecked) {
      setDailies(impediments);
    } else {
      setDailies(daily);
    }

    setIsLoading(false);

    const parsedQuery = HttpQuery.getParsedQueryString(filter);

    window.history.pushState('', '', `/daily${parsedQuery}`);
  }, [filter, isChecked, getFormattedDate, getDayReference]);

  const handleLoadMoreDaily = useCallback(async () => {
    setFilterDate({
      startDate: subDays(filterDate.startDate, 7),
      endDate: filterDate.endDate,
    });
  }, [filterDate.startDate, filterDate.endDate]);

  const handleFilterDate = useCallback(
    (firstDate: Date, lastDate: Date) => {
      setFilterDate({ startDate: firstDate, endDate: lastDate });
    },
    [setFilterDate],
  );

  const handleShowDaily = useCallback((daily: any) => {
    setDailySelected(daily);
    dailyModalRef.current?.open();
  }, []);

  useEffect(() => {
    setFilter(state => {
      const updatedState: IParsedQuery = {
        q: state.q.map(item => {
          if (item.operator === 'GREATER_THAN_OR_EQUAL') {
            return {
              property: 'daily_date',
              operator: 'GREATER_THAN_OR_EQUAL',
              value: format(subDays(filterDate.startDate, 7), 'yyyy-MM-dd', {
                locale: ptBR,
              }),
              rule: 'AND',
              entity: 'daily',
            };
          }

          return item;
        }),
        page: state.page,
        limit: state.limit,
        sort: state.sort,
      };

      return updatedState;
    });
  }, [filterDate.endDate, filterDate.startDate]);

  useEffect(() => {
    handleLoadDaily();
  }, [handleLoadDaily]);

  const handleSearch = useCallback(
    async (value: string) => {
      setFilter({
        q: [
          {
            property: 'name',
            operator: 'LIKE',
            value,
            rule: 'AND',
            entity: 'user',
          },
          {
            property: 'daily_date',
            operator: 'GREATER_THAN_OR_EQUAL',
            value: format(filterDate.startDate, 'yyyy-MM-dd', { locale: ptBR }),
            rule: 'AND',
            entity: 'daily',
          },
          {
            property: 'daily_date',
            operator: 'LESS_THAN_OR_EQUAL',
            value: format(filterDate.endDate, 'yyyy-MM-dd', { locale: ptBR }),
            rule: 'AND',
            entity: 'daily',
          },
          {
            property: 'concluded',
            operator: 'EQUAL',
            value: '1',
            rule: 'AND',
            entity: 'daily',
          },
        ],
        page: 1,
        limit: 50,
        sort: [
          { property: 'daily_date', order: 'DESC' },
          { property: 'id', order: 'ASC' },
        ],
      });
    },
    [filterDate.endDate, filterDate.startDate],
  );

  return (
    <Container>
      {/* <InformativeBox isActive={isShowAlert}>
        <FiAlertTriangle size={40} color="#FFC400" />

        <div>
          <p>
            Você possui dailies pendentes de respostas, para responder as
            dailies dos ultimos 7 dias basta clicar no botão
            <strong> 'Responder dailies'</strong> e acessar o chat para
            responder.
          </p>

          <Button callToAction onClick={() => console.log('abri a modal')}>
            Responder dailies
          </Button>
        </div>

        <FiX size={24} onClick={() => setIsShowAlert(false)} />
      </InformativeBox> */}

      <PageHeader>
        <Search
          placeholder="Digite o nome de um usuário ou projeto"
          onSearch={handleSearch}
        />

        <SmartRange
          placeholder="Filtrar"
          minDate={new Date(2019, 1, 1)}
          maxDate={new Date(2022, 12, 1)}
          onApply={dates => {
            if (dates.length === 2) {
              handleFilterDate(dates[0], dates[1]);
            } else if (dates.length === 1) {
              handleFilterDate(dates[0], dates[0]);
            }
          }}
          defaultValue={[filterDate.startDate, filterDate.endDate]}
        />
      </PageHeader>

      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}

      {dailies?.length > 0 && (
        <List>
          {dailies?.map((daily: any) => {
            return (
              <Dailies key={v4()}>
                <strong>{daily.title}</strong>
                <span>{daily.formattedDate}</span>

                <div>
                  {daily?.values.map((item: any) => {
                    let project_name;
                    let original_worked_hours;

                    if (!item.daily_answers[0]?.summary_activity?.project?.name)
                      project_name = 'Não informado';
                    else
                      project_name =
                        item.daily_answers[0].summary_activity.project.name;

                    if (
                      !item.daily_answers[0]?.summary_activity
                        ?.original_worked_hours
                    )
                      original_worked_hours = 0;
                    else
                      original_worked_hours =
                        item.daily_answers[0].summary_activity
                          .original_worked_hours;

                    return (
                      <Card
                        onClick={() => {
                          handleShowDaily(item);
                        }}
                        key={v4()}
                      >
                        <CardHeader>
                          <img src={defaultAvatar} alt="" />

                          <div>
                            <strong>{item.user?.name}</strong>

                            <span>atuando em {project_name}</span>
                          </div>
                        </CardHeader>

                        <CardContent>
                          <Tag>
                            {`${project_name} - ${toHMS(original_worked_hours)
                              .replace(':', 'h ')
                              .replace(':', 'm ')}s`}
                          </Tag>
                          <span>
                            {item.daily_answers[0]?.planned
                              ? item.daily_answers[0]?.planned
                              : item.daily_answers[0]?.accomplished
                              ? item.daily_answers[0]?.accomplished
                              : item.daily_answers[0]?.impediment
                              ? item.daily_answers[0]?.impediment
                              : 'Não informado'}
                          </span>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </Dailies>
            );
          })}
        </List>
      )}

      {dailies?.length === 0 && !isLoading && (
        <NotFoundContainer style={{ padding: 0 }}>
          <NotFound>{t('NOT_FOUND')}</NotFound>
        </NotFoundContainer>
      )}

      <LoadMore>
        <Button onClick={() => handleLoadMoreDaily()}>Carregar mais...</Button>
      </LoadMore>

      <DailyModal
        dailyModalRef={dailyModalRef}
        daily={dailySelected}
        setDaily={setDailySelected}
        closeModal={() => dailyModalRef.current?.close()}
        getDayReference={getDayReference}
      />
    </Container>
  );
};

export { DailyStatus };
