import React from 'react';

import { Tooltip } from '@components/atoms';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { toHMS } from '@utils/toHMS';

import { TooltipStyles } from './styles';

interface IProductivityTooltipProps {
  hours: {
    productive: number;
    unproductive: number;
    passive: number;
    undefined: number;
  };
  direction?: 'TOP' | 'LEFT' | 'RIGHT' | 'BOTTOM';
}

const ProductivityTooltip: React.FC<IProductivityTooltipProps> = ({
  hours,
  direction = 'TOP',
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  return (
    <Tooltip direction={direction}>
      <TooltipStyles.Content>
        <TooltipStyles.Item>
          <TooltipStyles.Square
            style={{
              border: `1px solid ${colors.activities.selection}`,
              background: colors.hours.productive,
            }}
          />

          <TooltipStyles.Info>
            <strong style={{ color: colors.black, fontSize: '14px' }}>
              Produtivo
            </strong>

            <strong style={{ color: colors.black, fontSize: '12px' }}>
              {toHMS(hours.productive)}
            </strong>
          </TooltipStyles.Info>
        </TooltipStyles.Item>

        <TooltipStyles.Item>
          <TooltipStyles.Square
            style={{
              border: `1px solid ${colors.activities.selection}`,
              background: colors.hours.unproductive,
            }}
          />

          <TooltipStyles.Info>
            <strong style={{ color: colors.black, fontSize: '14px' }}>
              Improdutivo
            </strong>

            <strong style={{ color: colors.black, fontSize: '12px' }}>
              {toHMS(hours.unproductive)}
            </strong>
          </TooltipStyles.Info>
        </TooltipStyles.Item>

        <TooltipStyles.Item>
          <TooltipStyles.Square
            style={{
              border: `1px solid ${colors.activities.selection}`,
              background: colors.hours.passive,
            }}
          />

          <TooltipStyles.Info>
            <strong style={{ color: colors.black, fontSize: '14px' }}>
              Passivo
            </strong>

            <strong style={{ color: colors.black, fontSize: '12px' }}>
              {toHMS(hours.passive)}
            </strong>
          </TooltipStyles.Info>
        </TooltipStyles.Item>

        <TooltipStyles.Item>
          <TooltipStyles.Square
            style={{
              border: `1px solid ${colors.activities.selection}`,
              background: colors.hours.undefined,
            }}
          />

          <TooltipStyles.Info>
            <strong style={{ color: colors.black, fontSize: '14px' }}>
              Indefinido
            </strong>

            <strong style={{ color: colors.black, fontSize: '12px' }}>
              {toHMS(hours.undefined)}
            </strong>
          </TooltipStyles.Info>
        </TooltipStyles.Item>
      </TooltipStyles.Content>
    </Tooltip>
  );
};

export { ProductivityTooltip };
