import { differenceInDays, startOfWeek, isBefore } from 'date-fns';
import { addDays } from 'date-fns/esm';

class HeatmapHelper {
  public getDaysOfYear(): any {
    const firstDateOfYear = new Date(new Date().getFullYear(), 0, 1);
    const lastDateOfYear = new Date(new Date().getFullYear(), 11, 31);

    const firstDayOfWeek = startOfWeek(firstDateOfYear);

    const daysOfYear = differenceInDays(lastDateOfYear, firstDayOfWeek) + 1;

    const data = [];

    for (let i = 0; i < daysOfYear; i++) {
      const date = addDays(firstDayOfWeek, i);

      data.push({
        date,
        hours: {
          productive: 0,
          unproductive: 0,
          undefined: 0,
          passive: 0,
        },
        daily: 0,
        isActive: !isBefore(date, firstDateOfYear),
      });
    }

    return data;
  }

  public getMinAndMaxHours(data: any): any {
    const min = Math.min(
      ...data.map((item: any) => item.hours.productive + item.hours.undefined),
    );
    const max = Math.max(
      ...data.map((item: any) => item.hours.productive + item.hours.undefined),
    );

    return { min, max };
  }

  public calculateIntervals(min: number, max: number): number[] {
    const intervals = [];

    const interval = (max - min) / 3;

    for (let i = 0; i < 3; i++) {
      intervals.push(min + i * interval);
    }

    return intervals;
  }
}

const INSTANCE = new HeatmapHelper();

export { INSTANCE as HeatmapHelper };
