import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      height: calc(100% - 68px);

      padding: 0 16px;
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'daily_status-container',
})`
  ${({ theme }) => css`
    height: calc(100% - 92px);

    padding: 0 48px;

    overflow-y: scroll;

    ${responsive.Container}
    ${customScroll(theme.colors.background)}

    .page_header-container {
      padding: 0px;
      width: 100%;
    }

    .input {
      width: auto;
    }
  `}
`;

export const List = styled.div.attrs({
  className: 'daily_status-list',
})`
  overflow-y: auto;
  overflow-x: hidden;

  gap: 32px;
  display: flex;
  flex-direction: column;
`;

interface IInformativeBoxProps {
  isActive?: boolean;
}

export const InformativeBox = styled.div<IInformativeBoxProps>`
  ${({ theme, isActive }) => css`
    display: ${isActive ? 'flex' : 'none'};
    justify-content: space-between;

    background: #ffc4004a;
    border: 2px solid ${theme.colors.activities.warning};
    border-radius: ${theme.borders.radii[500]};

    padding: 16px;
    margin-bottom: 32px;

    > svg:last-child {
      cursor: pointer;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      gap: 24px;
      margin: 0px 24px;

      > button {
        height: 42px;
      }
    }
  `}
`;

export const Dailies = styled.div`
  ${({ theme }) => css`
    padding: 4px 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};
      padding-bottom: 2px;
    }

    > span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 12px;
    }

    > div {
      column-gap: 32px;
      column-count: 6;

      @media (min-width: 1824px) and (max-width: 2124px) {
        column-count: 5;
      }

      @media (min-width: 1524px) and (max-width: 1824px) {
        column-count: 4;
      }

      @media (min-width: 1024px) and (max-width: 1524px) {
        column-count: 3;
      }

      @media (min-width: 660px) and (max-width: 1024px) {
        column-count: 2;
      }

      @media (max-width: 660px) {
        column-count: 1;
      }
    }
  `}
`;

export const Card = styled.div.attrs({
  className: 'daily_status-card',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    break-inside: avoid;
    width: 100%;
    height: fit-content;

    border-radius: ${theme.borders.radii[300]};
    background-color: ${theme.colors.cards};
    padding: 16px;
    gap: 16px;

    margin-bottom: 32px;

    cursor: pointer;
  `}
`;

export const CardHeader = styled.div.attrs({
  className: 'daily_status-card-header',
})`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;

      border-radius: 50%;
    }

    > div {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.small};
        padding-bottom: 2px;
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  `}
`;

export const CardContent = styled.div.attrs({
  className: 'daily_status-card-content',
})`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: 250px;
    max-height: 250px;
    gap: 8px;

    > span {
      /* white-space: nowrap; */
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  `}
`;

export const Tag = styled.div.attrs({
  className: 'daily_status-card-content',
})`
  ${({ theme }) => css`
    display: flex;
    padding: 4px 12px;
    width: fit-content;
    border-radius: ${theme.borders.radii[500]};

    background-color: ${theme.colors.activities.tagsWithAction};
  `}
`;

export const LoadMore = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;

  > button {
    font-size: 14px;
  }
`;
