import React, { useCallback, useEffect, useState } from 'react';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';

import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { Avatar } from '@components/atoms';
import { Modal, IModalRef } from '@components/organisms';
import { useOrganogram } from '@hooks/useOrganogram';
import { IOrganogramGroup } from '@interfaces/IOrganogram';

import Input from '../Input';

import {
  Checkbox,
  Container,
  Content,
  Footer,
  Header,
  Item,
  List,
} from './styles';

interface IItemProps {
  id: number;
  enabled: boolean;
  title: string;
  subtitle: string;
  image_url: string;
}

interface ICreateSubGroupModalProps {
  group: IOrganogramGroup;
  addSubGroupModalRef: React.RefObject<IModalRef>;
}

const CreateSubGroup: React.FC<ICreateSubGroupModalProps> = ({
  group,
  addSubGroupModalRef,
}) => {
  const { onAddSubGroup, users: usersSelect } = useOrganogram();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [internalData, setInternalData] = useState<IItemProps[]>([]);
  const [search, setSearch] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');

  function onSelect(id: number) {
    setSelectedIds(state =>
      state.includes(id) ? state.filter(item => item !== id) : [...state, id],
    );
  }

  const handleCloseModal = useCallback(() => {
    addSubGroupModalRef.current?.close();
  }, [addSubGroupModalRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleAddSubGroup = useCallback(() => {
    const data = {
      name: groupName,
      user_ids: [...selectedIds],
      organogram_id: group.organogram_id,
      parent_group_id: group.id,
    };

    onAddSubGroup(data);

    handleCloseModal();
  }, [
    group.id,
    group.organogram_id,
    groupName,
    handleCloseModal,
    onAddSubGroup,
    selectedIds,
  ]);

  const users = internalData.filter(item => {
    return item.title.toUpperCase().search(search.toUpperCase()) !== -1;
  });

  useEffect(() => {
    async function load() {
      if (usersSelect) {
        setInternalData(
          usersSelect.map((item: any) => ({
            id: item.id,
            enabled: false,
            title: item.name,
            subtitle: item.email,
            image_url: item?.avatar_url,
          })),
        );
      }
    }

    load();
  }, [usersSelect]);

  useEffect(() => {
    setInternalData(prevState => {
      return prevState.map(item => ({
        ...item,
        enabled: selectedIds.includes(item.id),
      }));
    });
  }, [selectedIds]);

  return (
    <Modal
      ref={addSubGroupModalRef}
      size="custom"
      hasNavigation={false}
      // onClose={reset}
    >
      <Container>
        <Header color="#FFC566">
          <div>
            <h4>Adicionar subgrupo</h4>

            <div>
              <div />
              <span>{group.name}</span>
            </div>
          </div>

          <HiOutlineX size={20} onClick={() => handleCloseModal()} />
        </Header>

        <Content>
          <div>
            <Input
              label="Nome do Subgrupo"
              placeholder="Nome do Subgrupo"
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
            />

            <Input
              label="Adicionar colaborador ao subgrupo"
              placeholder="Pesquisar colaborador"
              icon={<HiOutlineSearch size={24} />}
              onChange={debounce(handleSearch, 500)}
            />
          </div>

          <List>
            {users.map((item: IItemProps) => (
              <Item key={v4()}>
                <Checkbox
                  isSelected={item.enabled}
                  onClick={() => onSelect(item.id)}
                />

                <div>
                  <Avatar
                    key={v4()}
                    name="avatar_url"
                    avatar_url={item.image_url}
                    avatarSize={{ width: 40, height: 40 }}
                    iconSize={24}
                    placeholder={item.title.substring(0, 1)}
                    readOnly
                  />

                  <div>
                    <span>{item.title}</span>
                    <span>{item.subtitle}</span>
                  </div>
                </div>
              </Item>
            ))}
          </List>
        </Content>

        <Footer>
          <button type="button" onClick={() => handleCloseModal()}>
            Cancelar
          </button>
          <button type="button" onClick={handleAddSubGroup}>
            Adicionar
          </button>
        </Footer>
      </Container>
    </Modal>
  );
};

export { CreateSubGroup };
