import { useContext } from 'react';

import { HeaderContext, IHeaderContext } from '@contexts/ReactHeaderContext';

function useHeader(): IHeaderContext {
  const context = useContext(HeaderContext);

  return context;
}

export { useHeader };
