import React from 'react';

import { Drawer, IDrawerRef } from '@components/organisms/Drawer';

interface IDeliveryDrawerProps {
  deliveryDrawerRef: React.RefObject<IDrawerRef>;
}

const DeliveryDrawer: React.FC<IDeliveryDrawerProps> = ({
  deliveryDrawerRef,
}) => {
  return (
    <Drawer ref={deliveryDrawerRef} title="Adicionar entrega">
      <h1>DeliveryDrawer</h1>
    </Drawer>
  );
};

export { DeliveryDrawer };
