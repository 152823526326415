import { colors } from '@components/bosons/colors';

export const dark = {
  colors: {
    ...colors,
    chart: colors.chart,
    hours: colors.hours,
    activities: colors.activities,
    text: colors.text,
  },
};
