import { FixedSizeList } from 'react-window';

import { isLastDayOfMonth } from 'date-fns';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { IDate } from '@helpers/DateHelper';

interface IContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 40px;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};

  position: relative;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  transition: all 400ms;

  z-index: 100;

  &:hover {
    cursor: pointer;

    > input {
      border-color: #00b0ff;

      &::placeholder {
        color: #00b0ff;
      }
    }

    > svg {
      stroke: #00b0ff;
    }
  }

  > svg {
    stroke: ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};

    position: absolute;
    right: 0.75rem;

    transition: all 400ms;
  }
`;

interface IInputProps {
  isOpen: boolean;
}

export const Input = styled.input<IInputProps>`
  height: 42px;

  border: 1px solid ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};

  padding: 0 8px;

  cursor: pointer;

  transition: all 400ms;

  &::placeholder {
    color: ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};
    font-size: 0.875rem;

    transition: all 400ms;
  }
`;

interface IExportSelectorProps {
  isOpen: boolean;
  isSelectingDates: boolean;
}

export const ExportSelector = styled.div<IExportSelectorProps>`
  border: 1px solid #00b0ff;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 0px 24px ${({ theme }) => theme.colors.shadow};

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};

  position: absolute;
  top: ${({ isOpen }) => (isOpen ? 'calc(100% + 8px)' : '100%')};
  right: ${({ isSelectingDates }) => (isSelectingDates ? '312px' : '0px')};

  cursor: initial;

  display: flex;
  flex-direction: column;

  transition: all 400ms;
`;

export const Tabs = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cards};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.cards};

  margin: 16px;
  padding: 4px;

  gap: 8px;
  display: flex;
`;

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.button<ITabProps>`
  border: 1px solid ${({ isActive }) => (isActive ? '#00b0ff' : 'transparent')};
  border-radius: 4px;
  background: ${({ isActive }) => (isActive ? '#00b0ff' : 'transparent')};

  color: ${({ isActive }) => (isActive ? '#ffffff' : '#6C87BF')};
  font-weight: bold;
  white-space: nowrap;

  padding: 4px 8px;
`;

interface ISearchProps {
  isFocused: boolean;
}

export const Search = styled.div<ISearchProps>`
  border: 1px solid
    ${({ theme, isFocused }) => (isFocused ? '#00b0ff' : theme.colors.cards)};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.cards};

  margin: 0 16px 16px 16px;
  padding-right: 8px;

  position: relative;

  flex: 1;
  display: flex;
  align-items: center;

  transition: all 200ms;

  input {
    height: 40px;

    border: none;
    background: transparent;

    color: #ffffff;

    padding: 8px;

    flex: 1;

    &::placeholder {
      color: #6c87bf;
      opacity: 50%;
    }
  }

  svg {
    stroke: ${({ isFocused }) => (isFocused ? '#00b0ff' : '#292f4d')};

    cursor: pointer;

    transition: all 200ms;
  }
`;

export const IsSearching = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 50%;

  margin: auto;

  perspective: 800px;

  position: absolute;
  right: 8px;
`;

export const Inner = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    border-radius: 50%;

    position: absolute;

    &.one {
      border-bottom: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      top: 0%;
      left: 0%;

      animation: rotate-one 1s linear infinite;
    }

    &.two {
      border-right: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      right: 0%;
      top: 0%;

      animation: rotate-two 1s linear infinite;
    }

    &.three {
      border-top: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      right: 0%;
      bottom: 0%;

      animation: rotate-three 1s linear infinite;
    }

    @keyframes rotate-one {
      0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
      }
    }

    @keyframes rotate-two {
      0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
      }
    }

    @keyframes rotate-three {
      0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
      }
    }
  `}
`;

export const List = styled.ul`
  height: 180px;

  border-radius: 4px;

  margin: 0 4px 4px 0;
  padding: 0 4px 0 0;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #334b7a;
    border-left: 3.5px solid ${({ theme }) => theme.colors.background};
    border-right: 3.5px solid ${({ theme }) => theme.colors.background};
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00bfff;
    border-radius: 7px;
    border-left: 2px solid ${({ theme }) => theme.colors.background};
    border-right: 2px solid ${({ theme }) => theme.colors.background};
  }
`;

export const Item = styled.li`
  width: 100%;
  height: 36px;

  border-radius: 4px;

  position: relative;

  cursor: pointer;

  &:hover {
    &.hover {
      background: ${({ theme }) =>
        transparentize(
          0.6,
          theme.title === 'dark' ? '#334B7A' : theme.colors.activities.info,
        )};
    }

    cursor: pointer;

    &::before {
      opacity: 1;
    }
  }

  input {
    display: none;

    &:checked {
      & ~ label:before {
        border-color: #00bfff;
        background: #00bfff;
      }

      & ~ label:after {
        content: ' ';

        width: 18px;
        height: 18px;

        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+);
        background-repeat: no-repeat;
        background-size: 8px 8px;
        background-position: center center;
        background-color: transparent;

        font-size: 10px;
        text-align: center;

        margin-left: 0px;

        position: absolute;
        top: 9px;
        left: 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: all 400ms;
      }
    }

    &:disabled {
      & ~ label:before {
        border-color: ${transparentize(0.4, '#ffffff')};
        background: ${transparentize(0.4, '#0a192f')};
      }

      & ~ label {
        cursor: not-allowed;

        span {
          color: ${({ theme }) => transparentize(0.4, theme.colors.text.title)};
        }
      }
    }
  }

  label {
    width: 100%;
    max-width: 220px;
    height: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;

    position: absolute;
    top: 50%;
    left: 16px;

    display: flex;
    align-items: center;

    overflow: hidden;

    cursor: pointer;

    transform: translateY(-50%);

    transition: all 400ms;

    &:before {
      content: ' ';

      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;

      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.text.title};
      border-radius: 4px;
      background-color: transparent;
      box-shadow: none;

      margin-right: 8px;

      display: inline-block;
      vertical-align: middle;

      transition: all 400ms;
    }

    span {
      max-width: 220px;

      color: #ffffff;
      color: ${({ theme }) => theme.colors.text.title};
      text-overflow: ellipsis;
      white-space: nowrap;

      overflow: hidden;
    }
  }
`;

export const ChooseDateCheckbox = styled.div`
  width: 18px;
  height: 18px;

  border: 1px solid #00bfff;
  border-radius: 4px;
  background: #00bfff;

  position: relative;

  &:after {
    content: ' ';

    width: 18px;
    height: 18px;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-position: center center;
    background-color: transparent;

    font-size: 10px;
    text-align: center;

    margin-left: 0px;

    position: absolute;
    top: -1px;
    left: -1px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 400ms;
  }
`;

export const ChooseDate = styled.button`
  border: none;
  background: #152741;
  background: ${({ theme }) =>
    theme.title === 'dark' ? '#152741' : '#ffffff'};

  padding: 12px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: all 400ms;

  &:hover {
    background: ${({ theme }) =>
      transparentize(
        0.4,
        theme.title === 'dark' ? '#152741' : theme.colors.activities.info,
      )};
  }

  strong {
    color: #6c87bf;
  }

  svg {
    stroke: #6c87bf;
  }
`;

interface IDatesSelectorProps {
  isOpen: boolean;
}

export const DatesSelector = styled.div<IDatesSelectorProps>`
  /* width: ${({ isOpen }) => (isOpen ? '312px' : '0')}; */
  width: 312px;
  height: calc(100% + 2px);
  height: fit-content;
  min-height: 352px;

  border: 1px solid #00bfff;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};

  padding: 16px;

  position: absolute;
  /* position: ${({ isOpen }) => (isOpen ? 'absolute' : 'fixed')}; */
  top: -1px;
  left: 100%;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const DatesInputs = styled.div`
  padding-bottom: 16px;

  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    height: 40px;

    border-radius: 4px;
    border: none;
    outline: none;
    border: 1px solid #b1c5dd;
    background: #ffffff;

    color: #666a88;

    padding: 0 8px;

    cursor: pointer;

    &::-webkit-calendar-picker-indicator {
      /* filter: invert(1); */
      filter: initial;

      cursor: pointer;
    }
  }
`;

export const DatesHeader = styled.div`
  border-bottom: 1px solid ${transparentize(0.6, '#92abd8')};

  padding: 8px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #92abd8;
    font-size: 0.875rem;
  }
`;

// opacity: ${isSelecting ? 0 : 1};
// visibility: ${isSelecting ? 'hidden' : 'visible'};
// display: ${isSelecting ? 'none' : 'blobk'};

export const CalendarMonths = styled(FixedSizeList)`
  width: 100%;
  height: 100%;
  max-height: 316px;

  padding: 8px;

  overflow-y: scroll;
  scroll-behavior: smooth;

  > div > div {
    padding: 8px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #334b7a;
    border-left: 3.5px solid ${({ theme }) => theme.colors.background};
    border-right: 3.5px solid ${({ theme }) => theme.colors.background};
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00bfff;
    border-radius: 7px;
    border-left: 2px solid ${({ theme }) => theme.colors.background};
    border-right: 2px solid ${({ theme }) => theme.colors.background};
  }
`;

export const CalendarMonth = styled.div`
  > span {
    color: #b1c5dd;
    font-size: 14px;
    text-transform: capitalize;

    margin-bottom: 6px;

    display: block;
  }
`;

export const CalendarDays = styled.div`
  height: fit-content;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export interface IDayProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  key: string;
  hoverColor: string;
  index: number;
  day: IDate;
  isToday: boolean;
  isSelected: boolean;
  isHovered: boolean;
}

export const CalendarDay = styled.button<IDayProps>`
  ${({ theme, index, day, hoverColor, isToday, isSelected, isHovered }) => css`
    width: 100%;
    height: 100%;
    max-height: 38px;
    min-height: 38px;

    border: 1px solid #e0e0e0;
    background: ${theme.colors.white};

    color: #292f4d;
    font-size: 14px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;

    transition: all 400ms;

    & + button {
      border-top: ${index > 5 ? '0px' : `1px solid #e0e0e0`};
      border-left: ${index === 6
        ? `1px solid #e0e0e0`
        : index === 13
        ? `1px solid #e0e0e0`
        : index === 20
        ? `1px solid #e0e0e0`
        : index === 27
        ? `1px solid #e0e0e0`
        : index === 34
        ? `1px solid #e0e0e0`
        : '0px'};
    }

    &:after {
      content: '';

      width: calc(100% + 2px);
      height: 1px;

      background: ${hoverColor};

      position: absolute;
      top: -1px;
      left: -1px;

      transition: all 400ms;

      opacity: 0;
      visibility: hidden;
    }

    &:before {
      content: '';

      width: 1px;
      height: calc(100% + 2px);

      background: ${hoverColor};

      position: absolute;
      top: -1px;
      left: -1px;

      transition: all 400ms;

      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      border-color: ${hoverColor};
      background: ${hoverColor};

      color: ${theme.colors.white};
      font-weight: bold;

      cursor: pointer;

      &:before,
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    ${!day.isShow && dayVariations(index, hoverColor, day).hide}
    ${isToday && dayVariations(index, hoverColor, day).today}
  ${day.isShow && isHovered && dayVariations(index, hoverColor, day).hovered}
  ${isSelected && dayVariations(index, hoverColor, day).selected}
  `}
`;

export const Actions = styled.div`
  border-radius: 0 0 4px 4px;
  background: ${({ theme }) => theme.colors.cards};

  padding: 16px;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border-radius: 4px;

    color: #ffffff;

    padding: 8px;

    flex: 1;

    transition: all 400ms;

    &.cancel {
      border: 1px solid #ff6c70;
      background: ${transparentize(0.4, '#ff6c70')};

      &:hover {
        background: #ff6c70;
      }
    }

    &.next_step {
      border: 1px solid #334b7a;
      background: ${transparentize(0.4, '#334B7A')};

      &:hover {
        background: #334b7a;
      }
    }

    &.export {
      border: 1px solid #00b0ff;
      background: ${transparentize(0.4, '#00b0ff')};

      &:hover {
        background: #00b0ff;
      }
    }
  }
`;

const dayVariations = (index: number, hoverColor: string, day: IDate) => ({
  hide: css`
    background: transparent !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;

    ${isLastDayOfMonth(day.date) &&
    css`
      border-right: 1px solid #e0e0e0 !important;
    `}

    ${index > 27 &&
    css`
      border-bottom: 0 !important;
    `}

    color: transparent !important;

    &:hover {
      border-color: #e0e0e0;
      background: transparent;

      color: transparent;

      cursor: initial;
    }

    &:after,
    &:before {
      display: none !important;
    }
  `,
  today: css`
    ${({ theme }) => css`
      color: ${theme.colors.activities.info};
      font-weight: bold;
    `}
  `,
  selected: css`
    ${({ theme }) => css`
      border-color: ${hoverColor};
      background: ${hoverColor};

      color: ${theme.colors.white};
      font-weight: bold;

      &:after {
        opacity: 1;
        visibility: visible;
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }
    `}
  `,
  hovered: css`
    ${({ theme }) => css`
      border-color: ${hoverColor} !important;
      background: ${transparentize(0.6, hoverColor)};

      color: ${theme.colors.white};

      &:after {
        opacity: 1;
        visibility: visible;
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }
    `}
  `,
});
