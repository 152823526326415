import React, { useMemo, useCallback, useState } from 'react';
import { HiOutlineCheckCircle, HiOutlineX } from 'react-icons/hi';

import { v4 } from 'uuid';

import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { ISummaryActivity } from '@interfaces/IProject';

import { IDailyFormData } from '..';

import { Search } from '../Search';

import { DailyStatusStyles } from '../styles';
import { Button, Container } from './styles';

interface IPlannedProps {
  activities: ISummaryActivity[];
  formData: IDailyFormData[];
  setFormData: React.Dispatch<React.SetStateAction<IDailyFormData[]>>;
  isResponding: boolean;
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>;
  isAssociating: boolean;
  setIsAssociating: React.Dispatch<React.SetStateAction<boolean>>;
  projectSelected: ISummaryActivity | undefined;
  setProjectSelected: React.Dispatch<
    React.SetStateAction<ISummaryActivity | undefined>
  >;
  textAreaValue: string;
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
  confirmButtonRef: React.RefObject<HTMLButtonElement>;
}

const Planned: React.FC<IPlannedProps> = ({
  activities,
  formData,
  setFormData,
  isResponding,
  setIsResponding,
  isAssociating,
  // setIsAssociating,
  projectSelected,
  setProjectSelected,
  textAreaValue,
  setTextAreaValue,
  confirmButtonRef,
  children,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const title = useMemo(() => {
    if (isResponding && !isAssociating) {
      return (
        <p style={{ maxWidth: '180px' }}>
          O que você pretende fazer <b>hoje</b> no projeto{' '}
          <b>{projectSelected?.project.name}</b> ?
        </p>
      );
    }

    if (!isResponding && isAssociating) {
      return (
        <p style={{ maxWidth: '240px' }}>
          Qual o <b>projeto</b> e o que
          <b> pretende</b> fazer ?
        </p>
      );
    }

    return (
      <p style={{ maxWidth: '180px' }}>
        Em qual <b>projeto</b> você pretende <b>trabalhar hoje</b> ?
      </p>
    );
  }, [isAssociating, isResponding, projectSelected?.project.name]);

  const onSelect = useCallback(
    (index: number) => {
      const foundAnswer = formData[index];
      const project = activities[index];

      setProjectSelected(project);
      setIsResponding(true);
      setTextAreaValue(foundAnswer?.planned || '');
    },
    [
      activities,
      formData,
      setIsResponding,
      setProjectSelected,
      setTextAreaValue,
    ],
  );
  // console.log('formDataformData: ', formData);

  const [isAssociatingTeste, setIsAssociatingTeste] = useState<boolean>(false);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaValue(e.target.value);
      setFormData(prevFormData => {
        return prevFormData.map(prevFormDataItem => {
          if (prevFormDataItem.project_id === projectSelected?.project_id) {
            return {
              accomplished: prevFormDataItem.accomplished,
              planned: e.target.value,
              impediment: prevFormDataItem.impediment,
              project_id: prevFormDataItem.project_id,
              summary_activity_id: prevFormDataItem.summary_activity_id,
            };
          }

          return prevFormDataItem;
        });
      });
    },
    [projectSelected?.project_id, setFormData, setTextAreaValue],
  );

  const onClickAddProject = useMemo((): any => {
    if (isAssociatingTeste)
      return (
        <Container>
          <Search
            activities={activities}
            onSelect={(e: any) => {
              console.log('Teste', e);
            }}
          />

          <Button
            type="button"
            onClick={() => setIsAssociatingTeste(false)}
            className="cancel"
          >
            <HiOutlineX size={16} />

            <span>Cancelar</span>
          </Button>
        </Container>
      );

    return '';
  }, [isAssociatingTeste, activities]);

  // fazer o botão abrir as opções no botão adicionar projeto e depois fazer um get no banco de dados no activities e depois fazer um push
  // const projectTeste: any = {
  //   end_date: '2021-12-31',
  //   id: 5,
  //   name: 'Project J',
  //   planned_cost: 100000.0,
  //   planned_cost_limit: 50.0,
  //   planned_hours: 1000,
  //   planned_hours_limit: 50.0,
  //   start_date: '2021-09-01',
  //   summary_cost: 5.35,
  //   summary_hours: 19254,
  // };
  // const testando: any = {
  //   checked_worked_hours: 19254,
  //   original_cost: 5.35,
  //   original_worked_hours: 19254,
  //   project_id: 5,
  //   user_id: 2,
  //   worked_date: '2022-12-07',
  //   user: undefined,
  //   id: 3,
  //   project: projectTeste,
  // };

  // activities.push(testando);
  return (
    <DailyStatusStyles.Content>
      {children}

      {title}

      <DailyStatusStyles.Projects>
        {activities.map((activity, index) => {
          const isFilled = !!formData[index].planned;
          // console.log('activity: ', activity);

          return (
            <DailyStatusStyles.Project
              key={v4()}
              type="button"
              isFilled={isFilled}
              isSelected={projectSelected?.id === activity.id}
              color={colors.activities.success}
              onClick={() => onSelect(index)}
            >
              <p style={{ maxWidth: isFilled ? '67px' : 'initial' }}>
                {activity.project.name}
              </p>

              {isFilled && (
                <HiOutlineCheckCircle color={colors.white} size={22} />
              )}
            </DailyStatusStyles.Project>
          );
        })}

        {onClickAddProject}

        {/* <AddProjectButton
          type="button"
          onClick={() => {
            setIsAssociatingTeste(true);
          }}
        >
          <HiOutlinePlus size={18} />
          Adicionar projeto
        </AddProjectButton> */}
      </DailyStatusStyles.Projects>
      <DailyStatusStyles.ContentTextArea
        value={textAreaValue}
        onChange={onChange}
        onKeyDown={e =>
          e.ctrlKey && e.keyCode === 13 && confirmButtonRef.current?.click()
        }
        autoFocus
      />
    </DailyStatusStyles.Content>
  );
};

export { Planned };
