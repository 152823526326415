import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;

    > div {
      display: flex;
      width: 100%;
      height: 40px;
      border: 1px solid #666a88;
      background-color: #292f4d;
      align-items: center;

      margin: 10px 0px;
      border-radius: 4px;

      padding: 0px 8px;

      svg {
        color: #666a88;
      }

      input {
        width: 100%;
        height: 100%;
        padding: 8px 0px;

        background-color: transparent;
        border: none;

        color: ${theme.colors.text.title};
      }

      input::placeholder {
        color: #666a88;
      }

      input:invalid:focus {
        border: 1px solid red;
      }

      input:invalid:focus ~ span {
        display: block;
      }
    }

    label {
      font-size: ${theme.typography.fontSize.small};
      color: ${theme.colors.text.title};
    }

    span {
      font-size: 12px;
      padding: 3px;
      color: red;
      display: none;
    }
  `}
`;
