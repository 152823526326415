import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { ScrollList } from '@components/templates';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      height: calc(100% - 68px);

      padding: 0;

      > div:first-of-type {
        padding: 24px;

        button {
          span {
            display: none;
          }
        }
      }
    }
  `,
  List: css`
    @media (max-width: 768px) {
      padding: 24px;

      gap: 24px;
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'collaborators-container',
})`
  height: calc(100% - 92px);

  ${responsive.Container}
`;

export const List = styled(ScrollList).attrs({
  className: 'collaborators-list',
})`
  ${responsive.List}
`;

export const User = styled(Card).attrs({
  className: 'collaborators-user',
})`
  ${({ theme }) => css`
    width: 100%;
    min-width: 240px;
    min-width: 260px;
    min-height: 280px;
    max-height: 280px;

    border: 2px solid ${theme.colors.cards};

    padding: 16px;

    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    user-select: none;

    &:hover {
      border: 2px solid ${theme.colors.cardsBorder};

      cursor: pointer;
    }

    div:nth-of-type(1) {
      position: relative;

      img {
        width: 120px;
        height: 120px;

        border-radius: 50%;
      }
    }

    div:nth-of-type(2) {
      gap: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
        text-align: center;
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
      }
    }

    div:nth-of-type(3) {
      gap: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;

interface ILabelProps {
  color: string;
}

export const Label = styled.span.attrs({
  className: 'collaborators-label',
})<ILabelProps>`
  ${({ theme, color }) => css`
    border-radius: ${theme.borders.radii[500]};
    background: ${color};

    color: ${theme.colors.text.default};
    font-weight: bold;

    padding: 4px 12px;
  `}
`;

export const ModalContent = styled.div`
  width: 480px;

  display: grid;
  grid-template-columns: 282px 1fr;
`;

export const ProfileContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 200px;

    border-top-left-radius: ${theme.borders.radii[300]};
    border-bottom-left-radius: ${theme.borders.radii[300]};
    border-right: 1px solid ${theme.colors.background};
    background: #29385b;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > div:nth-of-type(2) svg {
      border-bottom-left-radius: 8px;
    }
  `}
`;

export const ProfileInfo = styled.div`
  ${({ theme }) => css`
    width: 100%;

    border-top-left-radius: ${theme.borders.radii[300]};

    padding: 32px 0;

    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div:nth-of-type(2) {
      gap: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
      }
    }
  `}
`;

export const Badges = styled.div`
  margin-top: -8px;

  gap: 8px;
  display: flex;
  flex-direction: row;
`;

export const ProjectsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 200px;

    border-top-right-radius: ${theme.borders.radii[300]};
    border-bottom-right-radius: ${theme.borders.radii[300]};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
