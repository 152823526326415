import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { InfiniteScrollList } from '@components/templates';
import { IProjectStatusAlias } from '@interfaces/IProject';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      height: calc(100% - 68px);

      padding: 0;

      > div:first-of-type {
        padding: 24px;

        button {
          span {
            display: none;
          }
        }
      }
    }
  `,
  List: css`
    @media (max-width: 768px) {
      padding: 24px 24px 24px 24px;

      gap: 24px;
    }
  `,
  Project: css`
    @media (max-width: 768px) {
      min-width: 0;
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'projects-container',
})`
  height: calc(100% - 92px);

  ${responsive.Container}

  overflow-x: hidden;
`;

export const List = styled(InfiniteScrollList)`
  ${responsive.List}
`;

interface IProjectProps {
  type: IProjectStatusAlias;
}

const projectVariations = (theme: any) => {
  return {
    CREATED: css``,
    IN_PROGRESS: css`
      strong {
        color: ${theme.colors.text.title};
      }

      span {
        color: ${theme.colors.text.subtitle};
      }
    `,
    DELAYED: css`
      span {
        color: ${theme.colors.activities.danger};
      }

      p {
        color: ${theme.colors.activities.danger};
      }
    `,
    CONCLUDED: css`
      strong {
        color: ${theme.colors.text.title};
      }

      span {
        color: ${theme.colors.activities.success};
      }
    `,
    ARCHIVED: css`
      strong {
        color: ${theme.colors.text.title};
      }

      span {
        color: ${theme.colors.activities.warning};
      }
    `,
  };
};

export const Project = styled(Card).attrs({
  className: 'projects-project',
})<IProjectProps>`
  ${({ theme, type }) => css`
    width: 100%;
    min-width: 344px;
    height: 114px;

    border: 2px solid ${theme.colors.cards};

    padding: 0 16px;

    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: all 400ms;

    user-select: none;

    &:hover {
      border: 2px solid ${theme.colors.cardsBorder};

      cursor: pointer;
    }

    > div:nth-of-type(1) {
      padding: 32px 0;

      position: relative;

      img {
        width: 50px;
        height: 50px;

        border-radius: 50%;
      }
    }

    > div:nth-of-type(2) {
      gap: 6px;
      flex: 1;
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
        font-weight: bold;
        letter-spacing: 0.6px;
      }

      p {
        color: #b1c5dd;
      }

      ${projectVariations(theme)[type]}
    }

    > div:nth-of-type(3),
    > div:nth-of-type(3) svg {
      max-width: 90px;
      max-height: 90px;
    }

    ${responsive.Project}
  `}
`;
