import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';

import { addMonths, format as f, parseISO } from 'date-fns';

import {
  CalendarContainer,
  ErrorContainer,
  IconContainer,
  LabelContainer,
} from '@components/quarks';
import { useClickAway } from '@hooks/useClickAway';
import { useFieldFeedback } from '@hooks/useFieldFeedback';
import { useUnform } from '@hooks/useUnform';
import { IDate } from '@utils/getCalendarDates';

import { Container } from './styles';

type IFormat = { showFormat: string; returnFormat: string };
type IValue = { original: Date[]; formatted: string };

interface IDateRangeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  format?: IFormat;
  defaultValue?: any;
  errorMessage?: string;
  direction?: { vertical: 'UP' | 'DOWN'; horizontal: 'LEFT' | 'RIGHT' };
  theme?: 'light' | 'dark';
  onApply?: (dates: Date[]) => void;
}

const DateRange: React.FC<IDateRangeProps> = ({
  label,
  name,
  format = { showFormat: 'dd/MM/yyyy', returnFormat: 'yyyy-MM-dd' },
  defaultValue,
  errorMessage,
  direction,
  theme = 'light',
  onApply,
  ...rest
}) => {
  const unform = useUnform(name);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<IValue>({ original: [], formatted: '' });

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );

  const {
    calendarIsOpen,
    setCalendarIsOpen,
    isFocused,
    isFilled,
    onFocus,
    onBlur,
  } = useFieldFeedback({
    name,
    unform,
    type: rest.type,
    disabled: rest.disabled,
    dateRange: { inputRef, selectedDates: value.original },
  });

  const onClose = useCallback(() => {
    inputRef.current?.setAttribute('value', value.formatted);

    inputRef.current?.focus();
    inputRef.current?.blur();

    setCalendarIsOpen(false);

    onBlur();
  }, [onBlur, setCalendarIsOpen, value]);

  useEffect(() => {
    if (value.original.length === 2) {
      if (onApply) onApply(value.original);
    }
  }, [onApply, value.original]);

  const onSelect = useCallback(
    (day: IDate) => {
      const { showFormat } = format;

      if (!value.original.length || value.original.length > 1) {
        setValue({ original: [day.date], formatted: f(day.date, showFormat) });

        if (unform) {
          unform.formRef.current?.setFieldValue(name, f(day.date, showFormat));
        }
      } else {
        setValue(({ original, formatted }) => ({
          original: [...original, day.date],
          formatted: `${formatted} - ${f(day.date, showFormat)}`,
        }));

        if (unform) {
          unform.formRef.current?.setFieldValue(
            name,
            `${value.formatted} - ${f(day.date, showFormat)}`,
          );
        }

        onClose();
      }
    },
    [format, name, onClose, unform, value],
  );

  const onCancel = useCallback(() => {
    const { length } = value.original;

    if (length === 1) setValue({ original: [], formatted: '' });

    onClose();
  }, [value, onClose]);

  useEffect(() => {
    inputRef.current?.setAttribute('value', value.formatted);
  }, [value.formatted, onClose, isFocused, name]);

  useClickAway(containerRef, () => onCancel(), { enabled: calendarIsOpen });

  useEffect(() => {
    if (unform) {
      unform.registerField<any>({
        name: unform.fieldName,
        ref: inputRef.current,
        getValue: (ref: HTMLInputElement) => {
          if (ref.value.length === 0) return [];

          const valueArr = ref.value.split(' - ');

          if (valueArr.length === 0) return [];

          const startArr = valueArr[0].split('/');
          const endArr = valueArr[1].split('/');

          const start = `${startArr[2]}-${startArr[1]}-${startArr[0]}`;
          const end = `${endArr[2]}-${endArr[1]}-${endArr[0]}`;

          const sDate = f(parseISO(start), format.returnFormat);
          const eDate = f(parseISO(end), format.returnFormat);

          return [sDate, eDate];
        },
        setValue: (ref, inputValue: string) => {
          if (!inputValue) {
            setValue({ original: [], formatted: '' });
            return;
          }

          if (inputValue.length < 2) {
            setValue({ original: [], formatted: '' });
            return;
          }

          if (inputValue[0].includes('.000Z')) {
            const parsedStart = parseISO(inputValue[0].replace('.000Z', ''));
            const parsedEnd = parseISO(inputValue[1].replace('.000Z', ''));

            const start = f(parsedStart, format.showFormat);
            const end = f(parsedEnd, format.showFormat);

            const formatted = `${start} - ${end}`;

            setValue({ original: [parsedStart, parsedEnd], formatted });

            ref.value = formatted;
          }

          if (inputValue.includes('/')) {
            const valueArr = inputValue.split(' - ');

            if (valueArr.length === 1) {
              const startArr = valueArr[0].split('/');

              const start = `${startArr[2]}-${startArr[1]}-${startArr[0]}`;

              const parsedStart = parseISO(start);

              const formatted = `${f(parsedStart, format.showFormat)}`;

              setValue({ original: [parsedStart], formatted });

              ref.value = formatted;
            }

            if (valueArr.length === 2) {
              const startArr = valueArr[0].split('/');
              const endArr = valueArr[1].split('/');

              const start = `${startArr[2]}-${startArr[1]}-${startArr[0]}`;
              const end = `${endArr[2]}-${endArr[1]}-${endArr[0]}`;

              const parsedStart = parseISO(start);
              const parsedEnd = parseISO(end);

              const formatted = `${f(parsedStart, format.showFormat)} - ${f(
                parsedEnd,
                format.showFormat,
              )}`;

              setValue({ original: [parsedStart, parsedEnd], formatted });

              ref.value = formatted;
            }
          }
        },
        clearValue: ref => {
          setValue({ original: [], formatted: '' });

          ref.value = '';
        },
      });
    }
  }, [format.returnFormat, format.showFormat, name, unform, value]);

  const feedbacks = useMemo(() => {
    return {
      isErrored: (!!errorMessage || !!unform?.error) && !isFilled,
      isFocused,
      isFilled:
        isFilled ||
        (defaultValue && !!unform?.defaultValue) ||
        value.original.length === 2,
      isDisabled: rest.disabled,
    };
  }, [
    errorMessage,
    unform?.error,
    unform?.defaultValue,
    isFilled,
    isFocused,
    defaultValue,
    value.original.length,
    rest.disabled,
  ]);

  const props = useMemo(() => {
    return {
      container: { hasLabel: !!label, ...feedbacks, themeStyle: theme },
      label: { label, ...feedbacks },
      error: { message: errorMessage || unform?.error, ...feedbacks },
    };
  }, [errorMessage, feedbacks, label, theme, unform?.error]);

  const formatDateInput = function formatInput(inputValue: any) {
    const valueToChange = inputValue.target.value;

    if (inputValue.nativeEvent.data) {
      if (
        valueToChange.length === 2 ||
        valueToChange.length === 5 ||
        valueToChange.length === 15 ||
        valueToChange.length === 18
      ) {
        inputValue.target.value = `${inputValue.target.value}/`;
      }

      if (valueToChange.length === 10) {
        inputValue.target.value = `${inputValue.target.value} - `;
      }
    }
  };

  return (
    <Container cRef={containerRef} {...props.container} className="input">
      <LabelContainer {...props.label} />

      <input
        {...rest}
        id="inputDate"
        ref={inputRef}
        name={name}
        disabled={rest.disabled}
        autoComplete="off"
        placeholder={rest.placeholder}
        defaultValue={unform?.defaultValue}
        onFocus={onFocus}
        onBlur={e =>
          rest.onChange && !!inputRef.current?.value && rest.onChange(e)
        }
        onChange={formatDateInput}
        maxLength={23}
      />

      <IconContainer
        icon={HiOutlineCalendar}
        isVisible
        onClick={() => {
          setCalendarIsOpen(true);
        }}
      />

      <ErrorContainer {...props.error} />

      <CalendarContainer
        calendarIsShow={calendarIsOpen}
        startDate={startDate}
        selectedDates={value.original}
        direction={direction}
        theme={theme}
        onPrev={() => setStartDate(state => addMonths(state, -1))}
        onNext={() => setStartDate(state => addMonths(state, 1))}
        onSelect={onSelect}
      />
    </Container>
  );
};

const Component = React.memo(DateRange, (prev, next) => prev !== next);

export { Component as DateRange };
