import { IProject } from '@interfaces/IProject';

type IBudget = { spent: number; planned: number; percentage: number };

interface IGetBudgetResponse {
  cost: IBudget;
  hours: IBudget;
  checklist: IBudget;
}

class ProjectHelper {
  public getBudget(project?: IProject): IGetBudgetResponse {
    const defaultValue = {
      cost: { percentage: 0, spent: 0, planned: project?.planned_cost || 0 },
      hours: { percentage: 0, spent: 0, planned: project?.planned_hours || 0 },
      checklist: { percentage: 0, spent: 0, planned: 0 },
    };

    if (!project) return defaultValue;

    const totalPoints =
      project.checklists?.reduce(
        (acc, item) => acc + Number(item.points || 0),
        0,
      ) || 0;

    const totalPointsCompleted =
      project.checklists
        ?.filter(item => item.end_date)
        .reduce((acc, item) => acc + Number(item.points || 0), 0) || 0;

    return {
      cost: {
        percentage: (+project.summary_cost * 100) / +project.planned_cost,
        spent: +project.summary_cost,
        planned: +project.planned_cost,
      },
      hours: {
        percentage:
          ((+project.summary_hours / 3600) * 100) / +project.planned_hours,
        spent: +project.summary_hours / 3600,
        planned: +project.planned_hours,
      },
      checklist: {
        percentage: (totalPointsCompleted * 100) / totalPoints || 0,
        spent: totalPointsCompleted,
        planned: totalPoints,
      },
    };
  }
}

const INSTANCE = new ProjectHelper();

export { INSTANCE as ProjectHelper };
