import { HTMLInputTypeAttribute } from 'react';

import styled, { css } from 'styled-components';

import { IFeedbackProps } from '@interfaces/components/IInput';
import { ITheme } from '@interfaces/generic/ITheme';

interface IContainerProps extends IFeedbackProps {
  type?: HTMLInputTypeAttribute;
  themeStyle: 'light' | 'dark';
}

const containerVariations = (
  theme: ITheme,
  hasLabel?: boolean,
  themeStyle?: 'light' | 'dark',
) => ({
  errored: css`
    border-color: ${theme.colors.input.errored.border};

    transition: none;
  `,
  focused: css`
    border-color: ${theme.colors.input.focused.border};
    box-shadow: 0px 6px 4px rgba(27, 32, 60, 0.2);

    input {
      height: ${hasLabel ? '82%' : '100%'};

      align-self: flex-end;
    }
  `,
  filled: css`
    border-color: ${themeStyle === 'light'
      ? theme.colors.input.filled.border
      : theme.colors.cards};

    input {
      height: ${hasLabel ? '82%' : '100%'};

      align-self: flex-end;
    }
  `,
  disabled: css`
    border-color: ${theme.colors.input.disabled.border};
    background: transparent;

    color: ${theme.colors.input.disabled.text};

    input {
      height: ${hasLabel ? '82%' : '100%'};

      color: ${theme.colors.input.disabled.text};

      align-self: flex-end;

      user-select: none;

      &::placeholder {
        color: ${theme.colors.input.disabled.text};
      }
    }
  `,
});

export const Container = styled.div<IContainerProps>`
  ${({
    theme,
    isErrored,
    isFocused,
    isFilled,
    disabled,
    hasLabel,
    themeStyle,
    type,
  }) => css`
    /* width: 220px; */
    width: 100%;
    min-width: 220px;
    height: 40px;

    border-radius: ${theme.borders.radii[100]};
    border: 2px solid
      ${themeStyle === 'light'
        ? theme.colors.input.normal.border
        : theme.colors.cards};
    background: ${themeStyle === 'light'
      ? theme.colors.white
      : theme.colors.cards};

    color: ${theme.colors.input.normal.text};
    font-family: Roboto, Poppins, Archivo, sans-serif;

    padding: 0 4px 0 8px;

    position: relative;

    transition: all 400ms;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    box-sizing: border-box;

    input {
      height: 100%;

      border: 0;
      background: transparent;
      outline: 0;

      color: ${themeStyle === 'light'
        ? theme.colors.input.normal.text
        : theme.colors.text.title};
      font-size: 14px;

      flex: 1;

      &::placeholder {
        color: ${themeStyle === 'light' ? '#858585' : theme.colors.text.title};

        opacity: ${themeStyle === 'light' ? '100%' : '50%'};
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
    }

    svg {
      stroke: ${themeStyle === 'light'
        ? theme.colors.black
        : theme.colors.text.input};
    }

    ${isFilled && containerVariations(theme, hasLabel, themeStyle).filled}
    ${isFocused && containerVariations(theme, hasLabel, themeStyle).focused}
    ${disabled && containerVariations(theme, hasLabel, themeStyle).disabled}
    ${isErrored && containerVariations(theme, hasLabel, themeStyle).errored}

    ${type === 'hidden' &&
    css`
      height: 0 !important;

      margin: 0 !important;
      padding: 0 !important;

      opacity: 0 !important;
      visibility: hidden !important;
    `}
  `}
`;
