import InfiniteScroll from 'react-infinite-scroll-component';

import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

export const Container = styled(InfiniteScroll as any)`
  ${({ theme }) => css`
    ${customScroll(theme.colors.background)}
  `}
`;

interface IContainerWrapperProps {
  columns: number;
  columnWidth?: number;
}

export const ContainerWrapper = styled.div<IContainerWrapperProps>`
  ${({ columns, columnWidth }) => css`
    padding: 32px 48px;

    gap: 32px;
    display: grid;
    align-self: center;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: ${`repeat(${columns}, minmax(${columnWidth}px, 1fr))`};

    @media (max-width: 960px) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }

    @media (max-width: 768px) {
      padding: 0 14px 32px 16px;
    }
  `}
`;

export const LoadingContainer = styled.div.attrs({
  className: 'components-templates-scroll_list_loading_container',
})`
  width: 100%;
  height: calc(100% - 42px);
  max-height: calc(100% - 42px);

  display: flex;
`;

export const NotFoundContainer = styled.div`
  margin-top: 16px;
  padding: 0 48px;
`;

export const NotFound = styled.div`
  ${({ theme }) => css`
    width: 100%;

    border: 1px solid ${theme.colors.lines};
    border-radius: 15px;

    color: ${theme.colors.text.title};
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    padding: 15px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      color: ${theme.colors.activities.info};
    }
  `}
`;
