import React, { useEffect } from 'react';

import { IFormRef } from '@contexts/ReactFormContext';

interface IUseResetFormsProps<T> {
  refs: React.RefObject<IFormRef>[];
  trigger: any;
  set: React.Dispatch<React.SetStateAction<T | undefined>>;
}

function useResetForms<T>({
  refs,
  trigger,
  set,
}: IUseResetFormsProps<T>): void {
  useEffect(() => {
    if (!trigger) {
      refs.forEach(ref => ref.current?.reset && ref.current.reset({}));
      set(undefined);
    }
  }, [refs, set, trigger]);
}

export { useResetForms };
