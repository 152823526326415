import React from 'react';

// import { ITimelineRow } from '@components/atoms/Timeline';

import { Container, PageHeader, TimelineContainer } from './styles';

const Associations: React.FC = () => {
  // const [timelineData, setTimelineData] = useState<ITimelineRow[]>();

  // const getTimelineRows = useCallback(
  //   (projects: IProject[]): ITimelineRow[] => {
  //     const parsedTimelineData: ITimelineRow[] = [];

  //     projects.forEach(project => {
  //       const associatedUsers =
  //         project.collaborators?.map(up => up.user_id) || [];

  //       parsedTimelineData.push({
  //         id: project.id,
  //         name: project.name,
  //         variation: 'PROJECT',
  //         color: '',
  //         bars: [],
  //         startDate: new Date(project.start_date),
  //         endDate: new Date(project.end_date),
  //         summary_activities:
  //           project.summary_activities?.filter(sa =>
  //             associatedUsers.includes(sa.user_id),
  //           ) || [],
  //       });

  //       project.collaborators?.forEach(user_project => {
  //         const bars: any[] =
  //           project.allocations
  //             ?.filter(
  //               allocation => allocation.user_id === user_project.user_id,
  //             )
  //             .map(allocation => ({
  //               id: allocation.id,
  //               color: allocation.color,
  //               start: allocation.start,
  //               end: allocation.end,
  //             })) || [];

  //         parsedTimelineData.push({
  //           id: user_project.user_id,
  //           name: user_project.user?.name || '',
  //           variation: 'USER',
  //           color: '',
  //           bars,
  //           startDate: new Date(project.start_date),
  //           endDate: new Date(project.end_date),
  //           summary_activities:
  //             project.summary_activities?.filter(sa =>
  //               associatedUsers.includes(sa.user_id),
  //             ) || [],
  //         });
  //       });
  //     });

  //     return parsedTimelineData;
  //   },
  //   [],
  // );

  // useEffect(() => {
  //   async function load() {
  //     const projects = await getProjects();

  //     setTimelineData(getTimelineRows(projects));
  //   }

  //   load();
  // }, [getTimelineRows]);

  return (
    <Container>
      <PageHeader>{/**/}</PageHeader>

      <TimelineContainer>
        {/* <Timeline data={timelineData} /> */}
      </TimelineContainer>
    </Container>
  );
};

export { Associations };
