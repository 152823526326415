import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { isEqual, parseISO, subDays, format } from 'date-fns';
import { v4 } from 'uuid';

import defaultAvatar from '@assets/images/default-avatar.png';
import { IModalRef } from '@components/organisms';
import { DailyModal } from '@components/templates';
import { IDaily } from '@interfaces/IDaily';
import { delay } from '@utils/delay';

import {
  Container,
  Header,
  HeaderLeft,
  HeaderRight,
  Tab,
  Cards,
  ActivityCard,
  NotFoundData,
  ShimmerLoading,
} from './styles';

export type IActivityItem = {
  avatar_url: string;
  title: string;
  subtitle: string;
  daily?: IDaily;
};

interface IActivitiesProps {
  maxHeight?: string;
  activities: IActivityItem[];
  dailies?: IDaily[];
}

const Activities: React.FC<IActivitiesProps> = ({
  maxHeight,
  activities,
  children,
}) => {
  const dailyModalRef = useRef<IModalRef>(null);

  const [type, setType] = useState<'deliveries' | 'daily'>('daily');
  const [data, setData] = useState<IActivityItem[]>();

  const [dailySelected, setDailySelected] = useState<IDaily | undefined>(
    undefined,
  );

  const getDayReference = useCallback((date: string) => {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    if (isEqual(parseISO(date), today)) return 'Hoje';
    if (isEqual(parseISO(date), subDays(today, 1))) return 'Ontem';
    if (isEqual(parseISO(date), subDays(today, 2))) return 'Anteontem';

    return format(parseISO(date), 'dd/MM/yyyy');
  }, []);

  const handleShowDaily = useCallback((card: any) => {
    setDailySelected(card.daily);
    dailyModalRef.current?.open();
  }, []);

  useLayoutEffect(() => {
    setData(undefined);

    async function set() {
      await delay(2000);

      setData(activities);
    }

    set();
  }, [activities, type]);

  const props = useMemo(() => {
    return {
      deliveries: {
        isActive: type === 'deliveries',
        onClick: () => setType('deliveries'),
      },
      daily: {
        isActive: type === 'daily',
        onClick: () => setType('daily'),
      },
    };
  }, [type]);

  return (
    <Container style={{ maxHeight }}>
      <Header>
        <HeaderLeft>Daily Status</HeaderLeft>

        <HeaderRight>
          {children}

          {false && (
            <Tab type="button" {...props.deliveries}>
              Entregas
            </Tab>
          )}

          {false && (
            <Tab type="button" {...props.daily}>
              Daily Status
            </Tab>
          )}
        </HeaderRight>
      </Header>

      <Cards>
        {!data ? (
          <ShimmerLoading />
        ) : data.length === 0 ? (
          <NotFoundData>Nenhum resultado encontrado</NotFoundData>
        ) : (
          data.map(card => {
            return (
              <ActivityCard
                key={v4()}
                onClick={() => {
                  handleShowDaily(card);
                }}
              >
                <div>
                  <img src={card.avatar_url || defaultAvatar} alt={v4()} />
                </div>

                <div>
                  <strong>{card.title}</strong>
                  <span>{card.subtitle}</span>
                </div>
              </ActivityCard>
            );
          })
        )}
      </Cards>

      <DailyModal
        dailyModalRef={dailyModalRef}
        daily={dailySelected}
        setDaily={setDailySelected}
        closeModal={() => dailyModalRef.current?.close()}
        getDayReference={getDayReference}
      />
    </Container>
  );
};

export { Activities };
