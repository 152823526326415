import React, { useRef, useState, useCallback } from 'react';

import { useResize } from '@hooks/useResize';

import { IBar, IKeys } from './types';

import { TimelineBarStyles } from './styles';

interface IBarProps {
  bar: IBar;
  color: string;
  snap: number;
  keys: IKeys;
  viewportDataRef: React.RefObject<HTMLDivElement>;
  isShowContent?: boolean;
  startDateIsLess?: boolean;
  endDateIsGreater?: boolean;
}

const Bar: React.FC<IBarProps> = ({
  bar,
  color,
  snap,
  keys,
  viewportDataRef,
  isShowContent,
  startDateIsLess,
  endDateIsGreater,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // const dragRef = useRef<HTMLDivElement>(null);
  const leftResizeRef = useRef<HTMLDivElement>(null);
  const rightResizeRef = useRef<HTMLDivElement>(null);

  const [barStyle, setBarStyle] = useState<React.CSSProperties>({
    left: bar.left,
    width: bar.width,
  });

  const leftResize = useCallback(
    (left: number) => {
      setBarStyle(prev => {
        const { width, left: pLeft } = prev;

        if (Number(width) <= 0) return { left, width: snap };
        if (Number(pLeft) > left) return { left, width: Number(width) + snap };
        if (Number(pLeft) === left) return { left, width: Number(width) };
        if (Number(pLeft) < left) return { left, width: Number(width) - snap };

        return { ...prev, left };
      });
    },
    [snap],
  );

  const rightResize = useCallback((width: number) => {
    setBarStyle(prev => ({
      ...prev,
      width: width - Number(prev.left),
    }));
  }, []);

  // const onDragBar = useCallback((left: number) => {
  //   setBarStyle(prev => ({ ...prev, left }));
  // }, []);

  const onDown = () => {
    const aside = document.getElementById(keys.aside);
    const viewportData = document.getElementById(keys.viewportData);

    aside?.classList.add('enabled');
    viewportData?.classList.add('enabled');
  };

  const onUp = () => {
    const aside = document.getElementById(keys.aside);
    const viewportData = document.getElementById(keys.viewportData);

    aside?.classList.remove('enabled');
    viewportData?.classList.remove('enabled');
  };

  // useDrag(dragRef, { onDrag: onDragBar, snap }, calendarRef);
  useResize(
    leftResizeRef,
    { onResize: leftResize, onDown, onUp, snap, elementKey: keys.viewportData },
    viewportDataRef,
  );
  useResize(
    rightResizeRef,
    {
      onResize: rightResize,
      onDown,
      onUp,
      snap,
      elementKey: keys.viewportData,
    },
    viewportDataRef,
  );

  if (isShowContent) return null;

  return (
    <TimelineBarStyles.Container
      ref={containerRef}
      color={color}
      startDateIsLess={startDateIsLess}
      endDateIsGreater={endDateIsGreater}
      style={barStyle}
    >
      {!startDateIsLess && (
        <TimelineBarStyles.LeftInteraction ref={leftResizeRef} />
      )}

      {/* <div ref={dragRef} style={{ height: '100%', display: 'flex', flex: 1 }} /> */}

      {!endDateIsGreater && (
        <TimelineBarStyles.RightInteraction ref={rightResizeRef} />
      )}
    </TimelineBarStyles.Container>
  );
};

const Component = React.memo(Bar, (prev, next) => Object.is(prev, next));

export { Component as Bar };
