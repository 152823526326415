import React, { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

export interface IChatbotContext {
  isOpen: boolean;
  worked_date?: string;
  openChatbot: () => void;
  closeChatbot: () => void;
  chooseWorkedDate: (worked_date?: string) => void;
}

const ChatbotContext = createContext<IChatbotContext>({} as IChatbotContext);

const ChatbotProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [worked_date, setWorkedDate] = useState<string>();

  const openChatbot = useCallback(() => setIsOpen(true), []);

  const closeChatbot = useCallback(() => setIsOpen(false), []);

  const chooseWorkedDate = useCallback((workedDate?: string) => {
    setIsOpen(true);
    setWorkedDate(workedDate);
  }, []);

  const value = useMemo(() => {
    return { isOpen, worked_date, openChatbot, closeChatbot, chooseWorkedDate };
  }, [isOpen, worked_date, openChatbot, closeChatbot, chooseWorkedDate]);

  return (
    <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>
  );
};

export { ChatbotContext, ChatbotProvider };
