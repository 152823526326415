import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { Full } from '@interfaces/generic/IGeneric';
import { IUser } from '@interfaces/IUser';

function getUserLogged(): { user: IUser; profile: number } {
  const user = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

  if (!user) {
    // window.location.href = '/login';
    return { user: {} as IUser, profile: 0 };
  }

  const parsedUser: Full<IUser> = JSON.parse(user);

  return { user: parsedUser, profile: parsedUser.users_roles[0].role_id };
}

export { getUserLogged };
