import { IQuery } from '@interfaces/generic/IQuery';
import { IDaily, IDailyAnswer } from '@interfaces/IDaily';
import { api } from '@services/api';
import { getParsedQuery } from '@utils/getParsedQuery';

export async function getDailies(query?: IQuery): Promise<any[]> {
  const parsedQuery = getParsedQuery(query);

  const { data } = await api.get<IDaily[]>(`/dailies${parsedQuery}`);

  return data;
}

export async function updateDailyAnswer(
  daily_id: number,
  daily_answer_id: number,
  formData: Partial<IDailyAnswer>,
): Promise<IDailyAnswer> {
  const { data } = await api.put<IDailyAnswer>(
    `/dailies/${daily_id}/answer/${daily_answer_id}`,
    formData,
  );

  return data;
}
