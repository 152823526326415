import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: block;
    background-color: ${theme.colors.cards};
    padding: 0;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 10000;
    min-width: 50px;
    min-height: 50px;
    max-width: 340px;
    top: 40px;

    > label {
      margin: 20px 0 5px;
      padding: 0 20px;
    }

    > div[data-label] {
      position: relative;
      margin: 30px 0 0;
      padding: 0;
      display: block;
      width: 340px;
      max-height: 400px;
      border-top: 5px solid ${theme.colors.activities.tagsWithAction};

      :before {
        content: 'Todos os ' attr(data-label);
        font-size: ${theme.typography.fontSize.small};
        font-weight: ${theme.typography.fontWeight.bold};
        color: ${theme.colors.activities.info};
        width: calc(50% - 20px);
        height: 20px;
        line-height: 20px;
        padding-left: 20px;

        border-bottom: 5px solid ${theme.colors.activities.info};
        top: -25px;
        left: 0;
        display: block;
        position: absolute;
        z-index: 3;
      }

      > ul {
        position: relative;
        margin: 0;
        padding: 10px 20px;
        display: block;
        list-style: none;
        font-size: ${theme.typography.fontSize.small};
        font-weight: ${theme.typography.fontWeight.bold};
        width: 340px;
        max-height: 320px;
        overflow: auto;

        > li {
          margin: 3px 0;
          display: block;
          height: 28px;
          line-height: 28px;
          font-size: inherit;
          vertical-align: middle;
        }
      }
    }
  `}
`;

export const FilterSelect = styled.div.attrs({})`
  ${({ theme }) => css`
    display: flex;
    padding: 5px;
    margin: 5px 20px;
    border: 1px SOLID ${theme.colors.text.input};
    border-radius: 5px;
    min-height: 34px;
    flex-wrap: wrap;
    position: relative;

    > span {
      font-size: ${theme.typography.fontSize.extraSmall};
      font-weight: ${theme.typography.fontWeight.bold};
      margin: 2px 5px;
      padding: 0 10px;
      height: 22px;
      line-height: 23px;
      border-radius: 9px;
      display: inline-block;
      background-color: ${theme.colors.activities.tagsWithAction};

      > i {
        display: inline-block;
        padding: 0;
        margin-left: 5px;
        vertical-align: middle;

        :before {
          transform: rotate(45deg);
          content: '+'; /*'\\2573'*/
          display: block;
          width: 15px;
          width: 15px;
          height: 15px;
          line-height: 15px;
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          color: ${theme.colors.cardsBorder};
          vertical-align: top;
          cursor: pointer;
        }
      }
    }

    > input {
      flex: 1;
      min-width: 40%;
      border: none;
      background-color: transparent;
      font-size: ${theme.typography.fontSize.small};
      color: ${theme.colors.text.title};
      padding: 0 10px;
    }

    > input:focus {
      min-width: 100%;
    }

    :after {
      content: '\\2315';
      display: block;
      transform: scaleX(-1);
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      font-size: 30px;
      color: ${theme.colors.text.input};
      position: absolute;
      right: 0;
    }
  `}
`;

export const Close = styled.button.attrs({
  type: 'button',
})`
  ${({ theme }) => css`
    background-color: transparent;
    color: #ffffff;
    position: absolute;
    right: 7px;
    top: 7px;
    border: none;
    font-size: ${theme.typography.fontSize.small};
    font-weight: ${theme.typography.fontWeight.bold};
    cursor: pointer;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    font-size: ${theme.typography.fontSize.small};
    font-weight: ${theme.typography.fontWeight.bold};
  `}
`;

export const Checkbox = styled.span.attrs({
  type: 'checkbox',
})`
  ${({ theme }) => css`
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 5px;

    > input {
      display: block;
      position: fixed;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      border: none;
      overflow: hidden;
      font-size: 0;
      color: transparent;
      vertical-align: middle;
    }

    > label {
      display: inline-block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      line-height: 16px;
      border: 1px SOLID #ffffff;
      border-radius: 3px;
      vertical-align: top;
      cursor: pointer;
    }

    > input:checked + label {
      border-color: transparent;
    }

    > input:checked + label:before {
      content: '\\2713';
      color: ${theme.colors.activities.info};
    }
  `}
`;
