import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';

export const Container = styled.div.attrs({
  className: 'components-molecules-tooltips-project-container',
})`
  ${() => css`
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

interface IItemProps {
  type: 'COST' | 'HOURS' | 'CHECKLIST';
}

const tooltipItemVariations = (theme: ITheme) => ({
  COST: css`
    color: ${theme.colors.hours.productive};
  `,
  HOURS: css`
    color: ${theme.colors.activities.hours};
  `,
  CHECKLIST: css`
    color: ${theme.colors.activities.info};
  `,
});

export const Item = styled.div.attrs({
  className: 'components-molecules-tooltips-project-item',
})<IItemProps>`
  ${({ theme, type }) => css`
    width: 100%;

    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${tooltipItemVariations(theme)[type]}

    > div:nth-of-type(1) {
      strong {
        color: ${theme.colors.black};
        font-size: 12px;
      }

      p {
        font-size: 12px;
        font-weight: bold;
      }
    }
  `}
`;
