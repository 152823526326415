import { RefObject } from 'react';

import { t } from 'i18next';
import { ValidationError } from 'yup';

import { IFormHandles } from '@contexts/ReactFormContext';
import { IToastContext } from '@contexts/ReactToastContext';
import { getValidationErrors } from '@utils/getValidationErrors';

interface IRequest extends Pick<IToastContext, 'addToast'> {
  formRef: RefObject<IFormHandles>;
  messages: { success: string; error: string };
  onSubmit: () => Promise<void>;
  onSuccess?: () => void;
  onError?: () => void;
}

async function useSubmit({
  formRef,
  messages,
  addToast,
  onSubmit,
  onSuccess,
  onError,
}: IRequest) {
  const { success, error } = messages;

  try {
    formRef.current?.setErrors({});

    await onSubmit();

    addToast({ title: t(success), type: 'success' });

    setTimeout(() => onSuccess && onSuccess(), 1500);
  } catch (err) {
    if (onError) onError();

    if (err instanceof ValidationError) {
      const errors = getValidationErrors(err);

      formRef.current?.setErrors(errors);

      return;
    }

    addToast({ title: t(error), type: 'error' });
  }
}

export { useSubmit as trySubmit };
