import React, { useMemo, useState, useCallback } from 'react';

import { useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { IColors } from '@interfaces/generic/ITheme';
import { ChatBotService } from '@services/apis/ChatBotService';
import { toHMS } from '@utils/toHMS';

import { IComponentsProps } from '../../types';

import { ChatButton, Message } from '../../../Messages/styles';

const DailyResume: React.FC<IComponentsProps> = ({
  response: { activities, daily, answers },
  onAnswer,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onYes = useCallback(async () => {
    setIsLoading(true);

    try {
      const question = `Então você trabalhou: ${activities.map(
        ({ original_worked_hours, project: p }) => {
          return `${toHMS(original_worked_hours)} no projeto ${p.name}`;
        },
      )} Essa informação está correta?`;

      await ChatBotService.confirmDailyAnswered({
        question,
        answer: 'Sim',
        worked_date,
      });

      onAnswer({ dailyState: 'CONFIRMED_DAILY_ANSWERED' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao confirmar suas horas trabalhadas!',
      });
    } finally {
      sessionStorage.openDailyChat = 'false';
      setIsLoading(false);
    }
  }, [activities, addToast, onAnswer, worked_date]);

  const onNo = useCallback(async () => {
    setIsLoading(true);

    try {
      const daily_answers_ids = daily.daily_answers.map(
        (item: any) => item.id || 0,
      );

      await ChatBotService.cancelDailyAnswered({
        daily_answers_ids,
        worked_date,
      });

      onAnswer({ dailyState: 'CONFIRMED_ADJUSTED_WORKED_HOURS' });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao corrigir sua daily!',
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, daily.daily_answers, onAnswer, worked_date]);

  const answer = useMemo(() => {
    const adjusted = answers.find(
      ({ flow_stopped }) => flow_stopped === 'CONFIRMED_DAILY_ANSWERED',
    );

    if (adjusted) return adjusted.flow_stopped;

    return null;
  }, [answers]);

  console.log(daily);

  return (
    <Message.Others>
      <div>
        <p>
          <b>Resumo da sua Daily:</b>
        </p>

        <br />

        <p style={{ margin: '4px 0' }}>
          <b style={{ color: colors.activities.selection }}>(Ontem)</b>
        </p>

        {daily?.daily_answers.map((item: any) => {
          const foundedProject = activities.find(
            activity =>
              activity.project_id === item.summary_activity.project_id,
          );

          return (
            <p key={v4()} style={{ marginBottom: '8px' }}>
              <b>Projeto {foundedProject?.project.name}:</b>
              <br />
              <span style={{ color: '#666A88' }}>Sua resposta: </span>{' '}
              <p style={{ whiteSpace: 'break-spaces' }}>{item.accomplished}</p>
            </p>
          );
        })}

        <p style={{ margin: '4px 0' }}>
          <b style={{ color: colors.activities.selection }}>(Hoje)</b>
        </p>

        {daily?.daily_answers
          .filter((item: any) => !!item.planned)
          .map((item: any) => {
            const foundedProject = activities.find(
              activity =>
                activity.project_id === item.summary_activity.project_id,
            );

            return (
              <p key={v4()} style={{ marginBottom: '16px' }}>
                <b>Projeto {foundedProject?.project.name}:</b>
                <br />
                <span style={{ color: '#666A88' }}>Sua resposta: </span>{' '}
                <p style={{ whiteSpace: 'break-spaces' }}>{item.planned}</p>
              </p>
            );
          })}

        <p style={{ margin: '4px 0' }}>
          <b style={{ color: colors.activities.selection }}>(Impeditivos)</b>
        </p>

        {daily?.daily_answers
          .filter((item: any) => !!item.impediment)
          .map((item: any) => {
            const foundedProject = activities.find(
              activity =>
                activity.project_id === item.summary_activity.project_id,
            );

            return (
              <p key={v4()} style={{ marginBottom: '16px' }}>
                <b>Projeto {foundedProject?.project.name}:</b>
                <br />
                <span style={{ color: '#666A88' }}>Sua resposta: </span>{' '}
                <p style={{ whiteSpace: 'break-spaces' }}>{item.impediment}</p>
              </p>
            );
          })}

        <br />

        <p>
          Essas <b>informações</b> estão <b>corretas ?</b>
        </p>
      </div>

      <div style={{ marginTop: '16px', display: 'flex', gap: '16px' }}>
        <ChatButton
          disabled={!!answer || isLoading}
          variation={
            answer === 'CONFIRMED_DAILY_ANSWERED' ? 'SELECTED' : 'NOT_SELECTED'
          }
          style={{ maxWidth: '120px' }}
          onClick={onYes}
        >
          <span>Sim</span>
        </ChatButton>

        <ChatButton
          disabled={!!answer || isLoading}
          variation="NOT_SELECTED"
          style={{ maxWidth: '120px' }}
          onClick={onNo}
        >
          <span>Não</span>
        </ChatButton>
      </div>
    </Message.Others>
  );
};

export { DailyResume };
