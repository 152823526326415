import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { customScroll } from '@styles/global';

const responsive = {
  HoursCategories: css`
    @media (max-width: 768px) {
      display: none;
    }
  `,
  Header: css`
    @media (max-width: 768px) {
      gap: 8px;
      flex: 1;
      flex-direction: column;
    }
  `,
  Bars: css`
    @media (max-width: 768px) {
      padding-top: 64px;
    }
  `,
  Tooltip: {
    Content: css`
      @media (max-width: 768px) {
        display: grid;
        row-gap: 2px;
        column-gap: 8px;
        grid-template-columns: 1fr 1fr;
      }
    `,
    Info: css`
      @media (max-width: 768px) {
        gap: 8px;
        flex-direction: row;
        align-items: center;
      }
    `,
    Square: css`
      @media (max-width: 768px) {
        width: 4px;
        height: 100%;

        border-radius: 2px;

        margin-right: 4px;
      }
    `,
  },
};

export const Container = styled(Card).attrs({
  className: 'productivity-chart_container',
})`
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 200px);

  padding: 24px;

  /* gap: 32px; */
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div.attrs({
  className: 'productivity-chart_header',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${responsive.Header}
`;

export const HeaderLeft = styled.strong.attrs({
  className: 'productivity-chart_header-left',
})`
  ${({ theme }) => css`
    color: ${theme.colors.text.title};
    font-size: ${theme.typography.fontSize.large};
  `}
`;

export const HeaderRight = styled.span.attrs({
  className: 'productivity-chart_header-right',
})`
  ${({ theme }) => css`
    color: ${theme.colors.text.subtitle};
    font-size: ${theme.typography.fontSize.normal};
  `}
`;

export const HoursCategories = styled.div.attrs({
  className: 'productivity-chart_hours-categories',
})`
  padding: 16px 0;

  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${responsive.HoursCategories}
`;

interface IHourCategoryProps {
  color: string;
  isSelected: boolean;
}

export const HourCategory = styled.div.attrs({
  className: 'productivity-chart_hour-category',
})<IHourCategoryProps>`
  ${({ theme, color, isSelected }) => css`
    position: relative;

    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;

    cursor: pointer;

    &:before {
      content: '';

      width: 0%;
      height: 1px;

      background: ${color};

      position: absolute;
      bottom: -4px;

      transition: all 400ms;
    }

    &:after {
      content: '';

      width: 14px;
      height: 14px;

      border-radius: ${theme.borders.radii[100]};
      border: 1px solid ${isSelected ? theme.colors.text.title : color};
      background: ${color};
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }

    strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};
    }
  `}
`;

export const Bars = styled.div.attrs({
  className: 'productivity-chart_bars',
})`
  ${({ theme }) => css`
    height: 100%;

    /* overflow-x: hidden;
    overflow-y: scroll; */

    margin-right: -16px;
    padding-top: 58px;
    padding-right: 24px;

    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${customScroll(theme.colors.cards)}

    ${responsive.Bars}
  `}
`;

interface IBackBarProps {
  left: string;
  right: string | number;
}

export const BackBar = styled.div.attrs({
  className: 'productivity-chart_back-bar',
})<IBackBarProps>`
  ${({ theme, left, right }) => css`
    width: 100%;
    height: 22px;
    min-height: 22px;

    background: ${theme.colors.hours.empty};

    margin-top: 4px;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: all 400ms;

    &:hover {
      cursor: pointer;
      filter: brightness(1.2);

      .components-atoms-tooltip-container {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }

    &:before {
      content: '${left}';

      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};

      position: absolute;
      top: -20px;
      left: 0;
    }

    &:after {
      content: '${right}';

      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};

      position: absolute;
      /* top: -16px; */
      top: -20px;
      right: 0;
    }
  `}
`;

export type IBarType =
  | 'productive'
  | 'unproductive'
  | 'passive'
  | 'undefined'
  | 'empty'
  | 'justify';

export interface IBarProps {
  type: IBarType;
  width: number;
}

export const Bar = styled.div.attrs({
  className: 'productivity-chart_bar',
})<IBarProps>`
  ${({ theme, type, width }) => css`
    width: ${width}%;
    height: 22px;
    min-height: 22px;

    background: ${theme.colors.hours[type]};
  `}
`;

export const NotFoundData = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    border-radius: 8px;

    color: ${theme.colors.activities.selection};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ShimmerLoading = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 400px;

    background-image: ${theme.colors.emptyLoading100DEG},
      linear-gradient(${theme.colors.hours.empty} 15px, transparent 0),
      linear-gradient(${theme.colors.hours.empty} 15px, transparent 0),
      linear-gradient(${theme.colors.hours.empty} 20px, transparent 0);

    background-repeat: repeat-y;

    background-size: 50px 40px, 150px 60px, 80px 60px, 100% 60px; // largura altura

    background-position: 0 0, 0 0, 100% 0, 0 20px; // x y

    animation: shine 1s infinite;

    @keyframes shine {
      to {
        background-position: 100% 0, 0 0, 100% 0, 0 20px;
      }
    }
  `}
`;

export const TooltipStyles = {
  Content: styled.div`
    gap: 16px;
    display: flex;

    ${responsive.Tooltip.Content}
  `,
  Item: styled.div`
    display: flex;
  `,
  Square: styled.div`
    width: 16px;
    height: 16px;

    border-radius: 4px;
    margin-right: 8px;

    ${responsive.Tooltip.Square}
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;

    ${responsive.Tooltip.Info}
  `,
};
