import styled, { css } from 'styled-components';

import { Form } from '@contexts/ReactFormContext';
import { customScroll } from '@styles/global';

export const ModalContent = styled.div`
  width: 480px;

  display: flex;
  flex-direction: column;
`;

export const Action = styled.div`
  ${({ theme }) => css`
    max-height: 200px;

    border-top-left-radius: ${theme.borders.radii[300]};
    border-top-right-radius: ${theme.borders.radii[300]};

    padding: 24px;

    flex: 1;
    gap: 48px;
    display: flex;
    flex-direction: column;
  `}
`;

export const Header = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const ProjectInfo = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
      }
    }
  `}
`;

export const Statistic = styled.div`
  width: 260px;

  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatisticInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      white-space: nowrap;
    }
  `}
`;

export const StatisticInfoBadge = styled.div`
  ${({ theme }) => css`
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > div {
      color: ${theme.colors.background};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
    }
  `}
`;

interface IStatisticBarProps {
  percentage: number;
  color: string;
  goalPercentage?: number;
}

export const StatisticBar = styled.div<IStatisticBarProps>`
  ${({ theme, color, percentage, goalPercentage }) => css`
    width: 100%;
    height: 4px;

    background: ${theme.colors.background};
    border-radius: ${theme.borders.radii[500]};

    position: relative;

    ${!!goalPercentage &&
    css`
      &:before {
        content: '';

        width: 2px;
        height: 18px;

        background: ${color};
        border-radius: ${theme.borders.radii[500]};

        position: absolute;
        left: ${goalPercentage}%;
        top: 50%;

        transform: translateY(-50%);
      }
    `}

    &:after {
      content: '';

      width: ${percentage > 100 ? 100 : percentage}%;
      height: 6px;

      background: ${color};
      border-radius: ${theme.borders.radii[500]};

      position: absolute;
      left: 0;
      top: -1px;
    }
  `}
`;

export const Unform = styled(Form)`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      gap: 24px;
      display: flex;
      align-items: center;
    }
  `}
`;

export const Historics = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.background};
    border-bottom-left-radius: ${theme.borders.radii[300]};
    border-bottom-right-radius: ${theme.borders.radii[300]};
    background: #29385b;

    padding: 24px;

    gap: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`;

export const HistoricScroll = styled.div`
  ${() => css`
    max-height: 200px;

    gap: 16px;
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    ${customScroll()}
  `}
`;

export const Historic = styled.div`
  ${({ theme }) => css`
    gap: 4px;
    display: flex;
    flex-direction: column;

    p {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};
    }

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;

export const HistoricNotFound = styled.div`
  ${({ theme }) => css`
    border: 1px dashed ${theme.colors.text.subtitle};
    border-radius: ${theme.borders.radii[500]};

    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;
