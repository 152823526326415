import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { ChangePassword } from '@pages/Auth/ForgotPassword';
import { ForgotPassword } from '@pages/Auth/SendEmailForgotPassword';
import { SignIn } from '@pages/Auth/SignIn';
import { Associations } from '@pages/Default/Associations';
import { Collaborators } from '@pages/Default/Collaborators';
import { DailyStatus } from '@pages/Default/DailyStatus';
import { Dashboard } from '@pages/Default/Dashboard';
import { Deliveries } from '@pages/Default/Deliveries';
import { Groups } from '@pages/Default/Groups';
import { HourCorrection } from '@pages/Default/HourCorrection';
import { Profile } from '@pages/Default/Profile';
import { Project } from '@pages/Default/Project';
import { Projects } from '@pages/Default/Projects';
import { Reports } from '@pages/Default/Reports';
import { Downloads } from '@pages/Default/Downloads';
import { Screenshots } from '@pages/Default/Screenshots';

import { Route } from './Route';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route
          exact
          path="/forgot_password/:token"
          component={ChangePassword}
        />
        <Route exact path="/" component={Dashboard} isPrivate />
        <Route exact path="/projects" component={Projects} isPrivate />
        <Route exact path="/projects/:id" component={Project} isPrivate />
        <Route exact path="/deliveries" component={Deliveries} isPrivate />
        <Route exact path="/screenshots" component={Screenshots} isPrivate />
        <Route exact path="/downloads" component={Downloads} isPrivate />
        <Route exact path="/daily" component={DailyStatus} isPrivate />
        <Route exact path="/users" component={Collaborators} isPrivate />
        <Route exact path="/hours" component={HourCorrection} isPrivate />
        <Route exact path="/reports" component={Reports} isPrivate />
        <Route exact path="/timeline" component={Associations} isPrivate />
        <Route exact path="/groups" component={Groups} isPrivate />
        <Route exact path="/profile" component={Profile} isPrivate />
        <Route
          exact
          path="/electron-chatbot"
          component={() => <div />}
          isPrivate
        />
      </Switch>
    </BrowserRouter>
  );
};

export { Routes };
