import { IOrganogramGroup } from '@interfaces/IOrganogram';

class OrganogramHelper {
  public transformData(data: any[]): IOrganogramGroup[] {
    const parsed = data.map(item => ({
      ...item,
      has_children: data.filter(i => i.parent_group_id === item.id).length > 0,
    }));

    return parsed;
  }
}

const INSTANCE = new OrganogramHelper();

export { INSTANCE as OrganogramHelper };
