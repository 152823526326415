function hmsToSeconds(hms: string) {
  const splitted = hms.split(':'); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +splitted[0] * 60 * 60 + +splitted[1] * 60 + +splitted[2];

  return seconds;
}

export { hmsToSeconds };
