import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 106px);

  padding: 24px 0;
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 120px;

  padding: 16px 48px;
`;

export const TimelineContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-top: 57px;
`;
