import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled?: boolean;
  mode?: 'light' | 'dark';
}

const containerCss = (theme: ITheme) => ({
  errored: css`
    border-color: ${theme.colors.input.errored.border};

    transition: none;

    svg {
      color: ${theme.colors.input.errored.border};
    }
  `,
  focused: css`
    border-color: ${theme.colors.input.focused.border};

    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:after {
      content: '';

      width: 100%;
      height: 2px;

      background: #ffffff;

      position: absolute;
      bottom: -2px;
      left: 0;

      z-index: 10000;
    }
  `,
  filled: css`
    border-color: ${theme.colors.input.filled.border};
  `,
  disabled: css`
    border-color: ${theme.colors.input.disabled.border};
    background: transparent;

    color: ${theme.colors.input.disabled.text};

    input::placeholder {
      color: ${theme.colors.input.disabled.text};
    }
  `,
  light: css`
    color: #232323;

    div {
      color: #ffffff;
    }

    input {
      color: #232323;

      &::placeholder {
        color: #232323;
      }
    }
  `,
  dark: css`
    color: ${theme.colors.input.normal.text};

    div {
      color: #ffffff;
    }

    input {
      color: ${theme.colors.input.normal.text};

      &:disabled {
        color: #ffffff;

        &::placeholder {
          color: #ffffff;
        }
      }

      &::placeholder {
        color: ${theme.colors.input.normal.text};
      }
    }
  `,
});

export const Container = styled.div<IContainerProps>`
  ${({
    theme,
    isErrored,
    isFocused,
    isFilled,
    disabled,
    mode = 'light',
  }) => css`
    /* width: 220px; */
    width: 100%;
    /* min-width: 220px; */
    height: 40px;

    border-radius: ${theme.borders.radii[100]};
    border: 2px solid ${theme.colors.input.normal.border};
    background: ${theme.colors.white};

    color: ${theme.colors.input.normal.text};
    font-family: Roboto, Poppins, Archivo, sans-serif;

    padding: 0 8px;

    position: relative;

    cursor: pointer;

    transition: all 400ms;

    display: flex;
    align-items: flex-end;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;

    select {
      width: 100%;
      height: 80%;

      border: 0;
      background: transparent;

      color: ${theme.colors.input.normal.text};
      font-size: 14px;

      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      cursor: pointer;

      flex: 1;

      display: none;
    }

    ${isFilled && containerCss(theme).filled}
    ${isFocused && containerCss(theme).focused}
    ${disabled && containerCss(theme).disabled}
    ${isErrored && !isFilled && containerCss(theme).errored}
    ${mode === 'light' ? containerCss(theme).light : containerCss(theme).dark}
  `}
`;

interface ILabelContainer {
  isFocused: boolean;
  isFilled: boolean;
  disabled?: boolean;
}

const labelContainerCss = (theme: ITheme) => ({
  show: css`
    top: -11px;
    left: 8px;

    opacity: 1 !important;
    visibility: visible !important;
  `,
  hide: css`
    top: 0;
    left: 8px;

    opacity: 0 !important;
    visibility: hidden !important;
  `,
  focused: css`
    background: ${theme.colors.input.focused.label};

    color: ${theme.colors.input.focused.text};
  `,
  filled: css`
    border: ${theme.colors.input.filled.border};
    background: ${theme.colors.input.filled.label};

    color: ${theme.colors.input.filled.text};

    ~ span div {
      margin-top: 6px;
    }
  `,
  disabled: css`
    border: ${theme.colors.input.disabled.border};
    background: ${theme.colors.input.disabled.label};

    color: ${theme.colors.input.disabled.text};
  `,
});

export const LabelContainer = styled.div<ILabelContainer>`
  ${({ theme, isFocused, isFilled, disabled }) => css`
    background: ${theme.colors.input.normal.label};

    color: ${theme.colors.text.title};
    font-size: 12px;
    font-weight: normal;
    line-height: initial;

    padding: 2px 8px;

    position: absolute;

    transition: all 400ms;

    display: flex;
    align-items: center;
    justify-content: center;

    ${isFocused ? labelContainerCss(theme).show : labelContainerCss(theme).hide}
    ${isFilled && labelContainerCss(theme).filled}
    ${disabled && labelContainerCss(theme).disabled}
    ${disabled && labelContainerCss(theme).show}
    ${isFilled && labelContainerCss(theme).show}
  `}
`;

interface IIconContainerProps {
  disabled?: boolean;
}

const iconContainerCss = (theme: ITheme) => ({
  disabled: css`
    cursor: pointer;

    &:hover {
      color: ${theme.colors.activities.info};
    }
  `,
});

export const IconContainer = styled.div<IIconContainerProps>`
  width: 20px;
  height: 100%;

  position: absolute;
  right: 8px;

  transition: color 400ms;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, disabled }) => !disabled && iconContainerCss(theme).disabled}
`;

export const ErrorMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.input.errored.border};
    font-size: 14px;
    font-weight: normal;

    position: absolute;
    bottom: -24px;
    left: 0;
  `}
`;

export const SelectOption = styled.span`
  width: 100%;

  color: #858585;
  font-size: 14px;
  line-height: 38px;

  cursor: pointer;

  user-select: none;

  z-index: 8;
`;

interface ISearchableOptionProps {
  mode?: 'light' | 'dark';
}

const searchableOptionVariations = (theme: ITheme) => ({
  light: css`
    color: ${theme.colors.input.normal.text};
  `,
  dark: css`
    color: #ffffff;
  `,
});

export const SearchableOption = styled.input<ISearchableOptionProps>`
  height: 100%;

  border: 0;
  outline: 0;
  background: transparent;

  font-size: 14px;
  line-height: 38px;

  flex: 1;

  ${({ theme, mode = 'light' }) =>
    mode === 'light'
      ? searchableOptionVariations(theme).light
      : searchableOptionVariations(theme).dark}
`;

interface IOptionsContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

const optionsContainerCss = (theme: ITheme) => ({
  show: css`
    top: 37.5px;

    opacity: 1 !important;
    visibility: visible !important;

    transition: all 200ms;
  `,
  hide: css`
    opacity: 0 !important;
    visibility: hidden !important;

    transition: none;

    pointer-events: none;

    * {
      pointer-events: none;
    }

    > div {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  `,
  errored: css`
    border-color: ${theme.colors.input.errored.border} !important;

    transition: none;
  `,
});

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  ${({ theme, isErrored, isFocused }) => css`
    width: 100%;
    max-height: 166px;

    outline: 2px solid ${theme.colors.activities.info};
    border-bottom-right-radius: ${theme.borders.radii[100]};
    border-bottom-left-radius: ${theme.borders.radii[100]};
    background: ${theme.colors.activities.info};
    box-shadow: 0 8px 8px rgb(0 0 0 / 60%);

    position: absolute;
    top: 32px;
    left: 0;

    overflow-y: scroll;

    transition: all 200ms;

    z-index: 10;

    &::-webkit-scrollbar {
      width: 0;
    }

    ${isErrored && optionsContainerCss(theme).errored}
    ${isFocused
      ? optionsContainerCss(theme).show
      : optionsContainerCss(theme).hide}
  `}
`;

interface IOptionProps {
  isSelected: boolean;
  isSearched: boolean;
  isMultiple?: boolean;
  mode?: 'light' | 'dark';
}

const optionCss = (theme: ITheme) => ({
  selected: css`
    background: #373d5d;

    color: #ffffff;

    span {
      color: #ffffff !important;
      font-weight: bold;
    }
  `,
  multipleSelected: css`
    > div {
      background: #373d5d;

      color: #ffffff;
    }
  `,
  searched: css`
    background: ${theme.colors.activities.info};

    span {
      color: #ffffff;
      font-weight: bold;
    }
  `,
  light: css`
    color: ${theme.colors.input.normal.text};

    > span {
      color: ${theme.colors.input.normal.text};
    }
  `,
  dark: css`
    color: #ffffff;
  `,
});

export const Option = styled.div<IOptionProps>`
  ${({ theme, isSearched, isSelected, isMultiple, mode = 'light' }) => css`
    background: #ffffff;

    font-size: 12px;

    padding: 8px;

    position: relative;

    z-index: 10;

    user-select: none;

    &:last-of-type {
      border-bottom-right-radius: ${theme.borders.radii[100]};
      border-bottom-left-radius: ${theme.borders.radii[100]};
    }

    &:hover {
      ${!isSearched && !isMultiple && optionCss(theme).searched}
    }

    ${isSelected && optionCss(theme).selected}
    ${mode === 'light' ? optionCss(theme).light : optionCss(theme).dark}
  `}
`;
