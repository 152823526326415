import React, { useCallback, useRef, useState } from 'react';

import { v4 } from 'uuid';

// import { colors as bosonsColors } from '@components/bosons/colors';
import { useClickAway } from '@hooks/useClickAway';

import { SelectedColor, BoxSelectColor, BoxSelectColorItem } from './styles';

export const colors: string[] = [
  '#FFE066',
  '#71C3FC',
  '#8CE99A',
  '#F0A58F',
  '#FAA2C1',
  '#E599F7',
  '#FAB005',
  '#228AE6',
  '#41C057',
  '#EA7369',
  '#E64980',
  '#BE4ADB',
  '#E67700',
  '#1B6EC2',
  '#2F9E44',
  '#C02323',
  '#A61D4D',
  '#862E9C',
];

interface IColorPickerProps {
  associationsIds?: number[];
  color: string;
  onClose: (color: string) => void;
}

const ColorPicker: React.FC<IColorPickerProps> = ({ color, onClose }) => {
  const boxSelectColorRef = useRef<any>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseOnClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickAway(boxSelectColorRef, handleCloseOnClick, { enabled: isOpen });

  return (
    <SelectedColor color={color} onClick={() => setIsOpen(!isOpen)}>
      {isOpen && (
        <BoxSelectColor ref={boxSelectColorRef}>
          {colors &&
            colors.map((selectedColor: string) => (
              <BoxSelectColorItem
                key={v4()}
                color={selectedColor}
                className={color === selectedColor ? 'selected' : ''}
                onClick={() => onClose(selectedColor)}
              />
            ))}
        </BoxSelectColor>
      )}
    </SelectedColor>
  );
};

export { ColorPicker };
