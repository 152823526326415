import React, { useRef, useMemo, useEffect } from 'react';

import { Container, Chart, Svg, BackLine, Line, ChartInfo } from './styles';

interface IHalfDonutChartProps {
  color: string;
  type: 'cost' | 'hours';
  percent: number;
  text: string;
  value: number;
}

const HalfDonutChart: React.FC<IHalfDonutChartProps> = ({
  color,
  type,
  percent,
  text,
  value,
}) => {
  let count = 0;

  const lineRef = useRef<SVGPathElement>(null);
  const numberRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (lineRef.current) {
      const lineLength = Math.round(lineRef.current.getTotalLength());
      const linePercent = -Math.abs(lineLength * (1 - percent / 100));

      lineRef.current.style.strokeDashoffset = `${linePercent}`;
    }
  }, [percent]);

  useEffect(() => {
    setInterval(() => {
      if (numberRef.current) {
        if (count <= percent) {
          numberRef.current.innerHTML = `${count}%`;
          count++;
        }
      }
    }, 1000 / percent);
  }, [count, percent]);

  const props = useMemo(() => {
    return { width: 340, height: 190, viewBox: '0 0 340 190' };
  }, []);

  return (
    <Container>
      <Chart>
        <Svg {...props}>
          <BackLine d="M320 170c0-82.843-67.157-150-150-150S20 87.157 20 170" />
        </Svg>

        <Svg {...props}>
          <Line
            ref={lineRef}
            color={color}
            d="M320 170c0-82.843-67.157-150-150-150S20 87.157 20 170"
          />
        </Svg>

        <span ref={numberRef}>0%</span>
      </Chart>

      <ChartInfo color={color}>
        <strong>
          {type === 'cost'
            ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(value)
                .slice(0, -3)
            : `${value} h`}
        </strong>
        <span>{text}</span>
      </ChartInfo>
    </Container>
  );
};

const Component = React.memo(HalfDonutChart, (prev, next) => {
  return Object.is(prev, next);
});

export { Component as HalfDonutChart };
