import React, { useEffect, useState } from 'react';

import Slider from '@mui/material-next/Slider';
import { format } from 'date-fns';
import { v4 } from 'uuid';

import { Computer, Groups } from '@assets/icons';
import defaultAvatar from '@assets/images/default-avatar.png';
import { SmartRange } from '@components/atoms/SmartRange';
import { ScreenshotItem } from '@components/molecules';
import { SelectFloater, IFloaterItem } from '@components/quarks/SelectFloater';
import { IProject } from '@interfaces/IProject';
import { IScreenshotsRequest } from '@interfaces/IScreenshots';
import { IUser } from '@interfaces/IUser';
import { getProjects } from '@services/apis/ProjectsService';
import { getScreenshots } from '@services/apis/ScreenshotsService';
import { getUsers } from '@services/apis/UsersService';

import styled, { css } from 'styled-components';
import { customScroll } from '@styles/global';
import { ScreenshotContainer, ScreenshotSearchForm, SFButton } from './styles';

export type IObjectValue = {
  value: String;
};

export type IEventProject = {
  project_id: number;
  name: string;
};

export type IScreenshot = {
  log_id: string;
  image: string;
  thumb: string;
  timestamp: IObjectValue;
};

export type IImageItem = {
  image: string;
  userAlias: string;
  userName: string;
  timestamp: string;
};

export type IEP = {
  project: IEventProject;
  event: IEvent;
};

export type IEvent = {
  id: String;
  local_time: IObjectValue;
  log_url: String;
  titlebar: string;
  useralias: string;
  productivity: string;
  projects: IEventProject[];
  screenshots: IScreenshot[];
};

export type IEventScreenshot = {
  event: IEvent;
  screenshot: IScreenshot;
};

const ModalScreenshots = styled.section`
  ${({ theme }) => css`
    position: fixed;
    display: grid;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 30, 39, 0.75);
    z-index: 9999999;
    backdrop-filter: blur(5px);
    grid-template:
      'tp tp tp' 1fr
      'lf md rt' calc(100vh - 80px)
      'bt bt bt' 1fr
      /1fr calc(100vw - 80px) 1fr;
    ${customScroll(theme.colors.cards)}
  `}
`;

const ModalScreenshotsMeio = styled.div`
  ${({ theme }) => css`
    display: block;
    position: relative;
    grid-area: md;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    text-align: center;

    span.MuiSlider-valueLabelOpen > span.MuiSlider-valueLabelLabel {
      background-color: #6751a4;
      padding: 5px 10px;
      border-radius: 20px;
    }

    > figure {
      position: relative;
      display: block;
      width: 100%;
      height: calc(100% - (40px + 50px));
      text-align: center;
      > img {
        max-height: calc(100% - 20px);
      }
      > img:before {
        content: attr(data-index);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        line-height: 100px;
        text-align: center;
        z-index: 9999;
      }
      > img:not(.selected) {
        display: none;
      }

      ${customScroll(theme.colors.cards)}
    }
    > span.close {
      position: absolute;
      color: #fff;
      border: 3px solid #fff;
      width: 30px;
      height: 30px;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      background-color: #000;
      text-align: center;
      line-height: 15px;
      font-size: 30px;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.25);
    }
  `}
`;

const ModalScreenshotsControllers = styled.div`
  ${({ theme }) => css`
    display: block;
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    display: flex;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 50px;
    > em {
      flex: 1;
    }
    > button {
      display: inline-block;
      border: none;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin: 5px 2px;
      border-radius: 5px;
      background-color: ${theme.colors.cards};
      color: ${theme.colors.text.title};
      cursor: pointer;
    }
    > button:hover {
      background-color: ${theme.colors.cardsBorder};
    }

    &[data-action='reverse'][data-speed='3'] > button.reverse-3x,
    &[data-action='reverse'][data-speed='2'] > button.reverse-2x,
    &[data-action='reverse'][data-speed='1'] > button.reverse,
    &[data-action='pause'] > button.pause,
    &[data-action='play'][data-speed='1'] > button.play,
    &[data-action='play'][data-speed='2'] > button.play-2x,
    &[data-action='play'][data-speed='3'] > button.play-3x {
      background-color: ${theme.colors.asideMenu};
      cursor: not-allowed;
    }

    ${customScroll(theme.colors.cards)}
  `}
`;

const ScreenshotsFull: React.FC<{
  close: Function;
  imageList: IImageItem[];
  index: number;
}> = result => {
  const defaultTime = 2000;
  const { imageList, index, close } = result;
  const [selectedIndex, setSelectedIndex] = useState<number>(index);
  const [velocidade, setVelocidade] = useState<number>(1);
  const [sliderValue, setSliderValue] = React.useState<number>(index);

  const [selectedAction, setSelectedAction] = useState<string>('pause');
  const [direction, setDirection] = useState<number>(0);

  const [intervalTime, setIntervalTime] = useState<number>(
    parseInt((defaultTime / velocidade).toString(), 10),
  );
  const [timeoutHandler, setTimeoutHandler] = useState<any>(null);

  const actionLoop = () => {
    let temp = selectedIndex + direction;
    if (temp < 0) {
      temp = imageList.length - 1;
    } else if (temp >= imageList.length) {
      temp = 0;
    }
    setSelectedIndex(temp);
    setSliderValue(temp);
    handleTimelineValueLabelFormat();
  };

  useEffect(() => {
    clearTimeout(timeoutHandler);
    if (selectedAction !== 'pause') {
      setTimeoutHandler(
        setTimeout(() => {
          actionLoop();
        }, intervalTime),
      );
    }
  }, [selectedAction, intervalTime, direction, selectedIndex]);

  useEffect(() => {
    setIntervalTime(parseInt((defaultTime / velocidade).toString(), 10));
  }, [velocidade]);

  const buttonAction = (action = 'pause', speed = 1) => {
    setSelectedAction(action);
    setVelocidade(speed);
    if (action === 'play') {
      setDirection(1);
    } else if (action === 'reverse') {
      setDirection(-1);
    } else {
      setDirection(0);
    }
  };

  let sliderTextValue: string;
  let timelineTimeout: any;
  useEffect(() => {
    handleTimelineValueLabelFormat();
    // if (!lopping) {
    //   setLooping(true);
    //   actionLoop();
    // }
  }, [sliderValue]);

  const handleTimelineValueLabelFormat = (ind: number = selectedIndex) => {
    const image = imageList.find((val: any, idx: number) => {
      return idx === ind;
    });
    sliderTextValue = image?.timestamp ?? '';
  };

  const handleTimeline = (event: MouseEvent, timelineIndex: number) => {
    clearTimeout(timelineTimeout);
    timelineTimeout = setTimeout(() => {
      setSelectedIndex(timelineIndex);
      setSliderValue(timelineIndex);
      // setSliderValue(timelineIndex);
      // handleTimelineValueLabelFormat(timelineIndex);
    }, 500);
    handleTimelineValueLabelFormat(timelineIndex);
  };
  // const sliderValue: number = index;

  return (
    <ModalScreenshots key={v4()}>
      <ModalScreenshotsMeio>
        <span className="close" onClick={() => close()}>
          &#x2A2F;
        </span>
        <figure>
          {imageList.map((img: IImageItem, imgIndex: number) => {
            const { image } = img;
            const selClassName: string =
              imgIndex === selectedIndex ? 'selected' : '';
            return (
              <img
                key={v4()}
                alt={`${image}`}
                data-index={`${imgIndex}`}
                src={`${image}`}
                className={selClassName}
              />
            );
          })}
        </figure>
        <>
          <Slider
            getAriaLabel={() => sliderTextValue}
            defaultValue={sliderValue}
            onChange={handleTimeline}
            valueLabelDisplay="auto"
            // size="small"
            step={1}
            getAriaValueText={() => sliderTextValue}
            valueLabelFormat={() => sliderTextValue}
            marks
            min={0}
            max={imageList.length}
            style={{ maxWidth: 'calc(100% - 20px)' }}
          />
          <ModalScreenshotsControllers
            data-action={`${selectedAction}`}
            data-speed={`${velocidade}`}
          >
            <em />
            <button
              type="button"
              className="reverse-3x"
              onClick={() => buttonAction('reverse', 3)}
            >
              &#x23F4;&#x23F4;&#x23F4;
            </button>
            <button
              type="button"
              className="reverse-2x"
              onClick={() => buttonAction('reverse', 2)}
            >
              &#x23F4;&#x23F4;
            </button>
            <button
              type="button"
              className="reverse"
              onClick={() => buttonAction('reverse', 1)}
            >
              &#x23F4;
            </button>
            <button
              type="button"
              className="pause"
              onClick={() => buttonAction('pause')}
            >
              &#x23f8;
            </button>
            <button
              type="button"
              className="play"
              onClick={() => buttonAction('play', 1)}
            >
              &#x23F5;
            </button>
            <button
              type="button"
              className="play-2x"
              onClick={() => buttonAction('play', 2)}
            >
              &#x23F5;&#x23F5;
            </button>
            <button
              type="button"
              className="play-3x"
              onClick={() => buttonAction('play', 3)}
            >
              &#x23F5;&#x23F5;&#x23F5;
            </button>
            <em />
          </ModalScreenshotsControllers>
        </>
      </ModalScreenshotsMeio>
    </ModalScreenshots>
  );
};

const ScreenshotsGrid: React.FC = () => {
  const [timestamp, setTimestamp] = useState<number>(0);
  const [pageLoaded, setPageLoaded] = useState<Boolean>(false);
  const [isVisibleDropDown, setIsVisibleDropDown] = useState<Boolean>(false);
  // const [isVisibleDtPicker, setIsVisibleDtPicker] = useState<Boolean>(false);
  const [offsetLeft, setOffsetLeft] = useState<Number>(0);
  const [listaProjetos, setListaProjetos] = useState<IProject[]>([]);
  const [listaUsers, setListaUsers] = useState<any[]>([]);

  const [optionProjetos, setOptProjetos] = useState<IFloaterItem[]>([]);
  const [optionUsers, setOptUsers] = useState<IFloaterItem[]>([]);
  const [optionList, setOptList] = useState<IFloaterItem[]>([]);

  const [dropLabel, setDropLabel] = useState<String>('');
  const [dropLabelPlural, setDropLabelPlural] = useState<String>('');

  const [selectedProject, setSelectedProject] = useState<Number[]>([]);
  const [selectedUser, setSelectedUser] = useState<Number[]>([]);

  const [funcSelected, setFuncSelected] = useState<String>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    const options: IFloaterItem[] = [
      {
        id: 0,
        label: 'Sem projeto definido',
        selectedAt: 0,
        selected: false,
        avatar: listaProjetos[0]?.avatar_url ?? '',
      },
    ];
    for (let key = 0; key < listaProjetos.length; key++) {
      const projeto: IProject = listaProjetos[key];
      const option: IFloaterItem = {
        id: projeto.id,
        label: projeto.name,
        selectedAt: 0,
        selected: false,
        avatar: projeto.avatar_url,
      };
      options.push(option);
    }
    setOptProjetos(options);
  }, [listaProjetos]);

  useEffect(() => {
    const options: IFloaterItem[] = [];
    for (let key = 0; key < listaUsers.length; key++) {
      const user: IUser = listaUsers[key];
      const option: IFloaterItem = {
        id: user.id,
        label: user.name,
        selectedAt: 0,
        selected: false,
        avatar: user.avatar_url,
      };
      options.push(option);
    }
    setOptUsers(options);
  }, [listaUsers]);

  const [data, setData] = useState<IEvent[]>([]);

  useEffect(() => {
    if (pageLoaded) {
      return;
    }
    setPageLoaded(true);
    // setData(dataTest);
    getProjects('limit=999999').then(result => {
      setListaProjetos(result);
    });
    getUsers().then(result => {
      setListaUsers(result);
    });
  }, [pageLoaded]);

  const dateNow: Date = new Date();
  const [currentStartDate, setCurrentStartDate] = useState<Date>(new Date());
  const [currentEndDate, setCurrentEndDate] = useState<Date>(new Date());
  const [projectID, setProjectID] = useState<number>(-1);
  const [userAlias, setUserAlias] = useState<string>('');
  const [projectList, setProjectList] = useState<IEP[]>([]);
  const [userList, setUserList] = useState<string[]>([]);

  const minDistance = 0;
  const [showImages, setShowImages] = useState<boolean>(false);
  const [imageList, setImageList] = useState<IImageItem[]>([]);
  const [hourValue, setHourValue] = React.useState<number[]>([0, 23]);
  const [actualDates, setActualDates] = React.useState<Date[]>([]);
  const [minHour, setMinHour] = React.useState<number>(0);
  const [maxHour, setMaxHour] = React.useState<number>(23);

  const [scrList, setScreenshotsList] = React.useState<IEventScreenshot[]>([]);

  useEffect(() => {
    const screenList: IEventScreenshot[] = [];
    const imgList: IImageItem[] = [];
    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
      const evt = data[dataIndex];
      const hasProg = evt.projects.filter(prog => {
        return prog.project_id === projectID;
      });
      if (hasProg.length !== 0) {
        const findProg: IEventProject | undefined = evt.projects.find(
          prog => prog.project_id === projectID,
        );
        const isProg: boolean = findProg?.project_id === projectID;
        if (isProg) {
          for (let ind = 0; ind < evt.screenshots.length; ind++) {
            const screen: IScreenshot = evt.screenshots[ind] as IScreenshot;
            const hora: string = screen.timestamp.value.replace(
              /^\d+-\d+-\d+[T ](\d+):.+$/,
              '$1',
            );
            const horaNumber: number = parseInt(hora, 10);
            if (horaNumber >= minHour && horaNumber <= maxHour) {
              if (evt.useralias === userAlias) {
                const filterUser = listaUsers.find(
                  usr => usr.email === userAlias,
                );
                const imageItem: IImageItem = {
                  image: screen.image,
                  userAlias: evt.useralias,
                  userName: filterUser.name,
                  timestamp: screen.timestamp.value.toString(),
                };
                imgList.push(imageItem);
                const screenItem: IEventScreenshot = {
                  event: evt,
                  screenshot: screen,
                };
                screenList.push(screenItem);
              }
            }
          }
        }
      }
    }
    setTimestamp(new Date().getTime());
    setImageList(imgList);
    setScreenshotsList(screenList);
  }, [projectID, userAlias, maxHour, minHour]);

  const requestGetScreenshots = () => {
    const query: IScreenshotsRequest = {
      users_ids: selectedUser,
      projects_ids: selectedProject,
      startDate: format(currentStartDate, 'Y-MM-dd HH:mm:ss'),
      endDate: format(currentEndDate, 'Y-MM-dd HH:mm:ss'),
      // endDate?: String;
    };
    getScreenshots(query).then(result => {
      const progList: IEP[] = [];
      const usrList: string[] = [];
      for (let key = 0; key < result.length; key++) {
        const item: IEvent = result[key];
        const itemUsrAlias: string | undefined = usrList.find(
          usr => usr === item.useralias,
        );
        if (typeof itemUsrAlias === 'undefined') {
          usrList.push(item.useralias);
        }
        for (let p = 0; p < item.projects.length; p++) {
          const prog: IEventProject = item.projects[p];
          const proRes: IEP[] = progList.filter(used => {
            return used.project.project_id === prog.project_id;
          });
          if (proRes.length === 0) {
            const iep: IEP = {
              project: prog,
              event: item,
            };
            progList.push(iep);
          }
        }
      }
      setProjectID(-1);
      setUserAlias('');
      setProjectList(progList);
      setUserList(usrList);
      setData(result);
    });
  };

  useEffect(() => {
    if (projectID < 0 || userAlias === '') {
      setImageList([]);
      setShowImages(false);
    }
  }, [projectID, userAlias]);

  useEffect(() => {
    requestGetScreenshots();
  }, [selectedProject, selectedUser, currentStartDate, currentEndDate]);

  const handleToID = (itens: IFloaterItem[]) => {
    const selecteds: Number[] = [];
    itens.forEach(item => {
      selecteds.push(item.id);
    });
    return selecteds;
  };

  const handleSelectedUsers = (itens: IFloaterItem[]) => {
    setSelectedUser(handleToID(itens));
  };

  const handleSelectedProjects = (itens: IFloaterItem[]) => {
    setSelectedProject(handleToID(itens));
  };

  function handleFilterGeneralInfo(dates: Date[] = actualDates) {
    const dt: string = format(dates[0], 'Y-M-dd');
    setCurrentStartDate(new Date(`${dt} 00:00:00`));
    setCurrentEndDate(new Date(`${dt} 23:59:59`));
    requestGetScreenshots();
    setActualDates(dates);
  }

  // let changeHourTimeout: any = null;
  const handleChangeHour = (event: MouseEvent, newValue: number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[0] + minDistance > newValue[1]) {
      return;
    }
    if (newValue[1] - minDistance < newValue[0]) {
      return;
    }
    setMinHour(newValue[0]);
    setMaxHour(newValue[1]);
    setHourValue(newValue);
  };

  return (
    <>
      <ScreenshotSearchForm>
        <SFButton
          onClick={event => {
            setIsVisibleDropDown(false);
            const targ = event.target as HTMLButtonElement;
            setOptList(optionProjetos);
            setDropLabel('projeto');
            setDropLabelPlural('projetos');
            setOffsetLeft(targ.offsetLeft);
            setFuncSelected('projects');
            setTimeout(() => {
              setIsVisibleDropDown(true);
            }, 250);
          }}
        >
          Projetos
          <Computer />
        </SFButton>
        <SFButton
          onClick={event => {
            setIsVisibleDropDown(false);
            const targ = event.target as HTMLButtonElement;
            setOptList(optionUsers);
            setDropLabel('colaborador');
            setDropLabelPlural('colaboradores');
            setOffsetLeft(targ.offsetLeft);
            setFuncSelected('users');
            setTimeout(() => {
              setIsVisibleDropDown(true);
            }, 250);
          }}
        >
          Usuários
          <Groups />
        </SFButton>
        {/* <SFButton
          onClick={event => {
            setIsVisibleDtPicker(true);
          }}
        >
          Escolher data
          <Calendar />
        </SFButton> */}
        <SmartRange
          type="picker"
          placeholder="Escolher data"
          className="datepicker"
          minDate={new Date(2023, 6, 1)}
          maxDate={
            new Date(
              dateNow.getFullYear(),
              dateNow.getMonth() + 1,
              dateNow.getDate(),
            )
          }
          onApply={(dates: Date[]) => handleFilterGeneralInfo(dates)}
          defaultValue={[currentStartDate, currentEndDate]}
        />
        {/* <em /> */}
        {/* <SFButton>
          Exportar
          <Save />
        </SFButton> */}
        {isVisibleDropDown && (
          <SelectFloater
            isVisible={isVisibleDropDown}
            label={dropLabel}
            labelPlural={dropLabelPlural}
            itens={optionList}
            style={{ left: `${offsetLeft}px` }}
            setIsVisible={setIsVisibleDropDown}
            setSelectedItens={(itens: IFloaterItem[]) => {
              switch (funcSelected) {
                case 'users':
                  handleSelectedUsers(itens);
                  break;
                case 'projects':
                  handleSelectedProjects(itens);
                  break;
                default:
                  break;
              }
            }}
          />
        )}
      </ScreenshotSearchForm>

      <ScreenshotContainer>
        {projectID >= 0 && userAlias !== '' && (
          <>
            <span>
              Dê {minHour}h até {maxHour}h
            </span>
            <Slider
              getAriaLabel={() => 'Hour range'}
              value={hourValue}
              defaultValue={hourValue}
              onChange={handleChangeHour}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={23}
              style={{ maxWidth: 'calc(100% - 20px)' }}
            />
            {scrList.map((scr, screenIndex) => {
              const { event, screenshot } = scr;
              return (
                <ScreenshotItem
                  key={v4()}
                  event={event}
                  screenshot={screenshot}
                  onClick={() => {
                    imageList.find((it, ind) => {
                      if (it.image === screenshot.image) {
                        setSelectedIndex(ind);
                        return true;
                      }
                      return false;
                    });
                    setShowImages(true);
                  }}
                />
              );
            })}
          </>
        )}
        {projectID < 1 &&
          userAlias === '' &&
          projectList.map(iep => {
            const filterProg = listaProjetos.find(
              prog => prog.id === iep.project.project_id,
            );
            const avatar: IScreenshot = {
              log_id: '',
              image: filterProg?.avatar_url ?? defaultAvatar,
              thumb: filterProg?.avatar_url ?? defaultAvatar,
              timestamp: {
                value: format(new Date(), 'Y-MM-dd HH:mm:ss'),
              },
            };
            const temp: IEvent = {
              id: '',
              local_time: {
                value: format(new Date(), 'Y-MM-dd HH:mm:ss'),
              },
              log_url: '',
              titlebar: '',
              useralias: filterProg?.name ?? '',
              productivity: '',
              projects: [],
              screenshots: [avatar],
            };
            return (
              <ScreenshotItem
                key={v4()}
                event={temp}
                screenshot={avatar}
                onClick={() => {
                  setProjectID(iep.project.project_id);
                }}
              />
            );
          })}
        {projectID >= 0 &&
          userAlias === '' &&
          userList.map(usrAlias => {
            const filterUser = listaUsers.find(usr => usr.email === usrAlias);
            const avatar: IScreenshot = {
              log_id: '',
              image: defaultAvatar,
              thumb: defaultAvatar,
              timestamp: {
                value: format(new Date(), 'Y-MM-dd HH:mm:ss'),
              },
            };
            const temp: IEvent = {
              id: '',
              local_time: {
                value: format(new Date(), 'Y-MM-dd HH:mm:ss'),
              },
              log_url: '',
              titlebar: '',
              useralias: filterUser?.name ?? usrAlias,
              productivity: '',
              projects: [],
              screenshots: [avatar],
            };
            return (
              <ScreenshotItem
                key={v4()}
                event={temp}
                screenshot={avatar}
                onClick={() => {
                  setUserAlias(usrAlias);
                }}
              />
            );
          })}
      </ScreenshotContainer>
      {showImages && imageList.length > 0 && (
        <ScreenshotsFull
          imageList={imageList}
          index={selectedIndex}
          close={() => setShowImages(false)}
        />
      )}
    </>
  );
};

export { ScreenshotsGrid };
