import { IGroup } from '@interfaces/IGroup';
import { IOrganogram } from '@interfaces/IOrganogram';

import {
  GROUPS_FOUR,
  GROUPS_ONE,
  GROUPS_THREE,
  GROUPS_TWO,
} from './GroupsMock';

export const data1 = [
  { id: 1, name: 'CEO', office: 'CEO', parentId: 0 },
  { id: 2, name: 'Diretor 1', office: 'Diretor 1', parentId: 1 },
  { id: 3, name: 'Diretor 2', office: 'Diretor 2', parentId: 1 },
  { id: 4, name: 'Diretor 3', office: 'Diretor 3', parentId: 1 },
  { id: 5, name: 'Coordenador 1', office: 'Coordenador 1', parentId: 2 },
  { id: 6, name: 'Coordenador 2', office: 'Coordenador 2', parentId: 3 },
  { id: 7, name: 'Coordenador 3', office: 'Coordenador 3', parentId: 4 },
  { id: 8, name: 'Supervisor 1', office: 'Supervisor 1', parentId: 5 },
  { id: 9, name: 'Supervisor 2', office: 'Supervisor 2', parentId: 6 },
  { id: 10, name: 'Operacional 1', office: 'Operacional 1', parentId: 8 },
  { id: 11, name: 'Operacional 2', office: 'Operacional 2', parentId: 9 },
];

export const data2 = [
  {
    id: 1,
    name: 'DESENVOLVIMENTO',
    description: 'X Subgrupos',
    cost: 120000,
    office: 'CEO',
    parentId: 0,
  },
  {
    id: 2,
    name: 'Front End',
    description: 'Colaboradores',
    cost: 40000,
    office: 'Diretor 1',
    parentId: 1,
  },
  {
    id: 3,
    name: 'Back End',
    description: 'Colaboradores',
    cost: 40000,
    office: 'Diretor 2',
    parentId: 1,
  },
  {
    id: 4,
    name: 'Dados',
    description: 'Colaboradores',
    cost: 40000,
    office: 'Diretor 3',
    parentId: 1,
  },
  {
    id: 5,
    name: 'Coordenador 1',
    description: '',
    cost: 0,
    office: 'Coordenador 1',
    parentId: 2,
  },
  {
    id: 6,
    name: 'Coordenador 2',
    description: '',
    cost: 0,
    office: 'Coordenador 2',
    parentId: 3,
  },
  {
    id: 7,
    name: 'Coordenador 3',
    description: '',
    cost: 0,
    office: 'Coordenador 3',
    parentId: 4,
  },
  {
    id: 8,
    name: 'Supervisor 1',
    description: '',
    cost: 0,
    office: 'Supervisor 1',
    parentId: 5,
  },
  {
    id: 9,
    name: 'Supervisor 2',
    description: '',
    cost: 0,
    office: 'Supervisor 2',
    parentId: 6,
  },
  {
    id: 10,
    name: 'Operacional 1',
    description: '',
    cost: 0,
    office: 'Operacional 1',
    parentId: 8,
  },
  {
    id: 11,
    name: 'Operacional 2',
    description: '',
    cost: 0,
    office: 'Operacional 2',
    parentId: 9,
  },
];

export const organogram1 = [
  {
    id: 1,
    name: 'DESENVOLVIMENTO',
    description: 'Subgrupos',
    avatarUrl: '',
    cost: 120000,
    parentId: 0,
  },
  {
    id: 2,
    name: 'Front End',
    avatarUrl: '',
    description: 'Colaboradores',
    cost: 40000,
    parentId: 1,
  },
  {
    id: 3,
    name: 'Back End',
    avatarUrl: '',
    description: 'Colaboradores',
    cost: 40000,
    parentId: 1,
  },
  {
    id: 4,
    name: 'Dados',
    avatarUrl: '',
    description: 'Colaboradores',
    cost: 40000,
    parentId: 1,
  },
  {
    id: 5,
    name: 'Guilherme Ferreira',
    github: 'guiferreira',
    avatarUrl: '',
    description: 'Tech Lead',
    cost: 20000,
    parentId: 2,
  },
  {
    id: 6,
    name: 'Lucas Barbieri',
    github: 'lucasbarbieri',
    avatarUrl: 'https://avatars.githubusercontent.com/u/4584018?v=4',
    description: 'Tech Lead',
    cost: 20000,
    parentId: 2,
  },
  {
    id: 7,
    name: 'Jhonatan Costa',
    github: 'JhonatanSK',
    avatarUrl: 'https://avatars.githubusercontent.com/u/39928763?v=4',
    description: 'Analista de Dados',
    cost: 20000,
    parentId: 4,
  },
  {
    id: 8,
    name: 'Bruno Futema',
    github: 'BrunoFutema',
    avatarUrl: 'https://avatars.githubusercontent.com/u/46576135?v=4',
    description: 'Web Developer',
    cost: 20000,
    parentId: 4,
  },
  {
    id: 11,
    name: 'Augusto Silveira',
    avatarUrl: '',
    description: 'Software Architect',
    cost: 20000,
    parentId: 3,
  },
  {
    id: 12,
    name: 'Shayron Costa',
    avatarUrl: '',
    description: 'Web Developer',
    cost: 20000,
    parentId: 3,
  },
];

export const organogram2 = [
  {
    id: 1,
    name: 'FINANCEIRO',
    avatarUrl: '',
    description: 'Subgrupos',
    cost: 120000,
    parentId: 0,
  },
  {
    id: 2,
    name: 'Viviane Santos',
    avatarUrl: '',
    description: 'Colaboradores',
    cost: 40000,
    parentId: 1,
  },
  // {
  //   id: 3,
  //   name: 'Viviane Santos',
  //   avatarUrl: '',
  //   description: 'Colaboradores',
  //   cost: 40000,
  //   parentId: 1,
  // },
  // {
  //   id: 4,
  //   name: 'Viviane Santos',
  //   avatarUrl: '',
  //   description: 'Colaboradores',
  //   cost: 40000,
  //   parentId: 2,
  // },
];

export const organogram3 = [
  {
    id: 1,
    name: 'MARKETING',
    description: 'Subgrupos',
    avatarUrl: '',
    cost: 120000,
    parentId: 0,
  },
  {
    id: 2,
    name: 'Eventos',
    description: 'Colaboradores',
    avatarUrl: '',
    cost: 40000,
    parentId: 1,
  },
  {
    id: 3,
    name: 'Ads',
    description: 'Colaboradores',
    avatarUrl: '',
    cost: 40000,
    parentId: 1,
  },
  {
    id: 4,
    name: 'Mayara Toledo',
    description: 'Colaboradores',
    avatarUrl: '',
    cost: 40000,
    parentId: 2,
  },
  {
    id: 5,
    name: 'Victor Borges de Toledo',
    description: 'Tech Lead',
    avatarUrl: '',
    cost: 20000,
    parentId: 4,
  },
  {
    id: 6,
    name: 'Caio Cesar Crespo de Araujo',
    description: 'Tech Lead',
    avatarUrl: 'https://avatars.githubusercontent.com/u/4584018?v=4',
    cost: 20000,
    parentId: 4,
  },
  {
    id: 7,
    name: 'Pedro Reis',
    description: 'Analista de Dados',
    avatarUrl: 'https://avatars.githubusercontent.com/u/39928763?v=4',
    cost: 20000,
    parentId: 2,
  },
  {
    id: 8,
    name: 'Bruno Futema',
    description: 'Web Developer',
    avatarUrl: 'https://avatars.githubusercontent.com/u/46576135?v=4',
    cost: 20000,
    parentId: 3,
  },
  {
    id: 9,
    name: 'Augusto Silveira',
    description: 'Software Architect',
    avatarUrl: '',
    cost: 20000,
    parentId: 3,
  },
  {
    id: 10,
    name: 'Shayron Costa',
    description: 'Web Developer',
    avatarUrl: '',
    cost: 20000,
    parentId: 3,
  },
];

interface IGroupResponse extends Omit<IOrganogram, 'childs'> {
  childs: IGroup[];
}

export const ORGANOGRAMS: IGroupResponse[] = [
  { id: 1, color: '#ffe066', childs: GROUPS_ONE },
  { id: 2, color: '#228AE6', childs: GROUPS_TWO },
  { id: 3, color: '#8CE99A', childs: GROUPS_THREE },
  { id: 4, color: '#F0A58F', childs: GROUPS_FOUR },
];
