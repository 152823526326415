import React from 'react';

import { Container, Inner } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <Inner className="one" />

      <Inner className="two" />

      <Inner className="three" />
    </Container>
  );
};

export { Loading };
