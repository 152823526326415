import React, { Dispatch, useCallback, useRef } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { Organogram } from '@assets/icons';
import { IModalRef } from '@components/organisms/Modal';
import { useClickAway } from '@hooks/useClickAway';
import { useOrganogram } from '@hooks/useOrganogram';
import { IOrganogramGroup } from '@interfaces/IOrganogram';

import { Container } from './styles';

interface ISubgroupTooltipProps {
  group: IOrganogramGroup;
  isOpen?: boolean;
  setMoreIsOpen: Dispatch<React.SetStateAction<boolean>>;
  editSubgroupRef: React.RefObject<IModalRef>;
}

const SubgroupTooltip: React.FC<ISubgroupTooltipProps> = ({
  group,
  isOpen = false,
  setMoreIsOpen,
  editSubgroupRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { onDeleteOrganogram, onDeleteSubGroup } = useOrganogram();

  const handleCloseOnClick = useCallback(() => {
    setMoreIsOpen(false);
  }, [setMoreIsOpen]);

  const handleEditSubgroup = useCallback(() => {
    editSubgroupRef.current?.open();

    setMoreIsOpen(false);
  }, [setMoreIsOpen, editSubgroupRef]);

  const handleDelete = useCallback(() => {
    if (group.type === 'group') onDeleteOrganogram(group.organogram_id);

    onDeleteSubGroup(group.organogram_id, group.id);
  }, [group, onDeleteOrganogram, onDeleteSubGroup]);

  useClickAway(containerRef, handleCloseOnClick, { enabled: isOpen });

  return (
    <Container ref={containerRef} isOpen={isOpen}>
      <button type="button" onClick={handleEditSubgroup}>
        <Organogram className="custom-icon" />

        <span>Editar subgrupo</span>
      </button>

      <button type="button" onClick={handleDelete}>
        <FiTrash2 size={18} color="#fbfbfb" />

        <span>Apagar subgrupo</span>
      </button>
    </Container>
  );
};

export { SubgroupTooltip as OrganogramSubgroupTooltip };
