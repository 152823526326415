import { HttpQuery, IParsedQuery } from '@helpers/HttpQueryHelper';
import {
  IChecklist,
  ICreateChecklistRequest,
  ICreateProjectRequest,
  ICreateTagRequest,
  IEditProjectRequest,
  IProject,
  IProjectStatus,
  ITag,
  IUpdateChecklistRequest,
} from '@interfaces/IProject';
import { api } from '@services/api';

// import { getParsedQuery } from '@utils/getParsedQuery';

// eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars, @typescript-eslint/no-unused-vars
export async function getProjects(query?: any): Promise<IProject[]> {
  const q: string = (query == null ? '' : '?') + query;
  // const parsedQuery = getParsedQuery(query);

  // const parsedQuery = HttpQuery.getParsedQueryString(query);

  const { data } = await api.get<any>(`/projects${q}`, {
    headers: { relations: 'project_status' },
  });

  return data.data;
}

export async function searchProject(query?: IParsedQuery): Promise<any[]> {
  const parsedQuery = HttpQuery.getParsedQueryString(query);

  const { data } = await api.get<[IProject[], number]>(
    `/projects/search/${parsedQuery}`,
  );

  const [projects] = data;
  return projects;
}

export async function createProject(
  formData: ICreateProjectRequest,
): Promise<IProject> {
  const { data } = await api.post<IProject>('/projects', formData);

  return data;
}

interface IGetProject {
  id: number;
}

export async function getProject({ id }: IGetProject): Promise<IProject> {
  const { data } = await api.get<IProject>(`/projects/${id}`);

  return data;
}

export async function updateProject(
  formData: Partial<IEditProjectRequest>,
): Promise<IProject> {
  const { data } = await api.put<IProject>(
    `/projects/${formData.id}`,
    formData,
  );

  return data;
}

export async function getProjectStatus(): Promise<IProjectStatus[]> {
  const { data } = await api.get('/projects/status');

  return data;
}

export async function createTag(formData: ICreateTagRequest): Promise<ITag> {
  const { data } = await api.post<ITag>(
    `/projects/${formData.project_id}/tags`,
    formData,
  );

  return data;
}

export async function deleteTag({
  id,
  project_id,
}: Pick<ITag, 'id' | 'project_id'>): Promise<void> {
  await api.delete(`/projects/${project_id}/tags/${id}`);
}

export async function createChecklist(
  formData: ICreateChecklistRequest,
): Promise<IChecklist> {
  const { data } = await api.post<IChecklist>(
    `/projects/${formData.project_id}/checklists`,
    formData,
  );

  return data;
}

export async function updateChecklist(
  formData: Partial<IUpdateChecklistRequest>,
): Promise<IChecklist> {
  const { data } = await api.put<IChecklist>(
    `/projects/${formData.project_id}/checklists/${formData.id}`,
    formData,
  );

  return data;
}

export async function deleteChecklist({
  id,
  project_id,
}: Pick<ITag, 'id' | 'project_id'>): Promise<void> {
  await api.delete(`/projects/${project_id}/checklists/${id}`);
}

export async function associateCollaboradors({
  project_id,
  users_ids,
}: {
  project_id: number;
  users_ids: number[];
}) {
  const { data } = await api.post(`/projects/${project_id}/users`, {
    users_ids,
  });
  return data;
}

export async function desassociateCollaboradors({
  project_id,
  user_id,
}: {
  project_id: number;
  user_id: number;
}) {
  const { status } = await api.delete(
    `/projects/${project_id}/users/${user_id}`,
  );

  return status;
}

interface IListResponse {
  page: number;
  limit: number;
  total: number;
  data: IProject[];
}

interface IListProps {
  query?: IParsedQuery;
  relations?: string[];
}

interface IShowProps {
  id: number;
  relations?: string[];
}

class ProjectsService {
  public async search(query: IParsedQuery): Promise<IProject[]> {
    const parsedQuery = HttpQuery.getParsedQueryString(query);

    const { data } = await api.get<[IProject[], number]>(
      `/projects/search${parsedQuery}`,
    );

    return data[0];
  }

  public async list(params: IListProps): Promise<IListResponse> {
    const parsedQuery = HttpQuery.getParsedQueryString(params.query);

    const options = params.relations
      ? { headers: { relations: params.relations.join(',') } }
      : undefined;

    const { data } = await api.get<IListResponse>(
      `/projects${parsedQuery}`,
      options,
    );

    return data;
  }

  public async show(params: IShowProps): Promise<IProject> {
    const options = params.relations
      ? { headers: { relations: params.relations.join(',') } }
      : undefined;

    const { data } = await api.get<IProject>(`/projects/${params.id}`, options);

    return data;
  }
}

const INSTANCE = new ProjectsService();

export { INSTANCE as ProjectsService };
