import { ORGANOGRAMS } from '@mocks/OrganogramsMock';
import { format } from 'date-fns';

import { OrganogramHelper } from '@helpers/OrganogramHelper';
import { IGeneric } from '@interfaces/generic/IGeneric';
import {
  IEditOrganogramRequest,
  INewOrganogramRequest,
  IOrganogram,
} from '@interfaces/IOrganogram';
import { api } from '@services/api';
import { getFirstAndLastDatesFromDate } from '@utils/getFirstAndLastDatesFromDate';

interface IOptions {
  isFake?: boolean;
}

interface IGetOrganograms {
  startDate?: string;
  endDate?: string;
}

export async function getOrganograms(
  params?: IGetOrganograms,
  options: IOptions = { isFake: false },
): Promise<any> {
  const [first, last] = getFirstAndLastDatesFromDate(new Date());

  const start = params?.startDate || format(first, 'yyyy-MM-dd');
  const end = params?.endDate || format(last, 'yyyy-MM-dd');

  if (options.isFake) {
    return ORGANOGRAMS.map(organogram => {
      return {
        ...organogram,
        childs: OrganogramHelper.transformData(organogram.childs),
      };
    });

    const { data } = await api.get<{ organograms: IOrganogram[] }>(
      `/mirage/organograms?startDate=${start}&endDate=${end}`,
    );

    return data.organograms.map(organogram => {
      return {
        ...organogram,
        childs: OrganogramHelper.transformData(organogram.childs),
      };
    });
  }

  const { data } = await api.get<IOrganogram[]>(
    `/organograms?startDate=${start}&endDate=${end}`,
  );

  return data;
}

export async function createOrganogram(
  formData: INewOrganogramRequest,
): Promise<IOrganogram> {
  const { data } = await api.post<IOrganogram>('/organograms', formData);

  return data;
}

interface IGetOrganogram {
  id: number;
}

export async function getOrganogram({
  id,
}: IGetOrganogram): Promise<IOrganogram> {
  const { data } = await api.get<IOrganogram>(`/organograms/${id}`);

  return data;
}

export async function updateOrganogram(
  formData: IEditOrganogramRequest,
): Promise<IOrganogram> {
  const { data } = await api.put<IOrganogram>(
    `/organograms/${formData.id}`,
    formData,
  );

  return data;
}

export async function deleteOrganogram({ id }: Pick<IGeneric, 'id'>) {
  await api.delete(`/organograms/${id}`);
}
