import React, {
  useRef,
  useMemo,
  useCallback,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import { Button, DateRange, Input } from '@components/atoms';
import { IOption, Select } from '@components/atoms/Select';
import { Can } from '@components/Can';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import {
  IUserFormInitialData,
  IUserValuesFormData,
  IUser,
} from '@interfaces/IUser';
import { createUserCost } from '@services/apis/UsersService';
import { toCurrency } from '@utils/toCurrency';

import {
  Container,
  NewHourValue,
  NewHourValueFields,
  NewHourValueConfirmation,
  Historic,
  HistoricRow,
  HistoricNotFound,
  HistoricCosts,
} from './styles';

interface IUserValuesFormProps {
  user?: IUserFormInitialData;
  setUsers?: React.Dispatch<React.SetStateAction<IUser[]>>;
  closeModal: () => void;
}

const UserValuesForm: React.ForwardRefRenderFunction<
  IFormRef,
  IUserValuesFormProps
> = ({ user, setUsers, closeModal }, ref) => {
  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);
  const [paymentType, setPaymentType] = useState<IOption[]>([]);

  const handleSubmit = useCallback(
    async (formData: IUserValuesFormData) => {
      if (user) {
        const schema = Yup.object().shape({
          cost: Yup.number().required('Informe o valor hora!'),
          is_hour: Yup.number().required('Informe o tipo do pagamento!'),
          dates: Yup.array().required('Informe o período de vigência!'),
        });

        trySubmit({
          formRef,
          addToast,
          messages: {
            success: 'NEW_COLLABORATOR_COST_SUCCESS',
            error: 'NEW_COLLABORATOR_COST_FAILURE',
          },
          onSubmit: async () => {
            await schema.validate(formData, { abortEarly: false });

            const createdUserCost = await createUserCost(
              Number(user.id),
              formData,
            );

            if (setUsers) {
              setUsers(state =>
                state.map(item => {
                  if (item.id === user.id) {
                    return {
                      ...item,
                      costs: item.costs
                        ? [...item.costs, createdUserCost]
                        : [createdUserCost],
                    };
                  }

                  return item;
                }),
              );
            }

            formRef.current?.reset({});

            closeModal();
          },
        });
      }
    },
    [addToast, closeModal, setUsers, user],
  );

  // const currentCost = useMemo(() => {
  //   if (!user) return '----';

  //   if (!user.costs && !user.month_costs) return '----';

  //   console.log('user.month_costs: ', user);

  //   const userCosts: any[] = [{}];

  //   if (user.month_costs && !user.costs) {
  //     user.month_costs.forEach(month_cost => {
  //       userCosts.push(month_cost);
  //     });
  //   }

  //   if (user.costs) {
  //     user.costs.forEach(cost => {
  //       userCosts.push(cost);
  //     });
  //   }

  //   userCosts.sort((a, b) => {
  //     if (a.created_at && b.created_at) {
  //       return a.created_at > b.created_at ? -1 : 1;
  //     }

  //     return a.id > b.id ? -1 : 1;
  //   });

  //   userCosts.splice(0, 1);

  //   const foundedCurrentCost = userCosts?.find(
  //     cost =>
  //       parseISO(cost.to) >=
  //       parseISO(format(new Date(), 'yyyy-MM-dd', { locale })),
  //   );

  //   if (!foundedCurrentCost) return '----';

  //   return toCurrency(foundedCurrentCost.cost);
  // }, [user]);

  const currentCost = useMemo(() => {
    if (!user) return '----';

    if (!user.costs) return '----';

    const userCosts = user.costs;

    userCosts.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return a.created_at > b.created_at ? -1 : 1;
      }

      return a.id > b.id ? -1 : 1;
    });

    const foundedCurrentCost = userCosts.find(
      cost =>
        parseISO(cost.to) >=
        parseISO(format(new Date(), 'yyyy-MM-dd', { locale })),
    );

    if (!foundedCurrentCost) return '----';

    return (
      <>
        <strong>{foundedCurrentCost.is_month ? `Mês` : `Hora`}</strong>
        <span>{toCurrency(foundedCurrentCost.cost)}</span>
      </>
    );
  }, [user]);

  const parsedCosts = useMemo(() => {
    if (!user) return undefined;

    if (!user.costs && !user.month_costs) return undefined;

    if (user.costs) {
      return user.costs?.sort((a, b) => {
        if (a.created_at && b.created_at) {
          return a.created_at > b.created_at ? -1 : 1;
        }

        return a.id > b.id ? -1 : 1;
      });
    }

    return user.month_costs?.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return a.created_at > b.created_at ? -1 : 1;
      }

      return a.id > b.id ? -1 : 1;
    });
  }, [user]);

  useImperativeHandle(ref, () => ({ submit: formRef.current?.submitForm }));

  useEffect(() => {
    setPaymentType([
      { label: 'Hora', value: 1 },
      { label: 'Mensal', value: 2 },
    ]);
  }, []);

  return (
    <Container ref={formRef} onSubmit={handleSubmit}>
      <Can roles={['SUPER_ADMIN']} permissions={[]}>
        <NewHourValue>
          <NewHourValueFields>
            <Select
              name="is_hour"
              options={paymentType}
              placeholder="Tipo de pagamento"
              label="Tipo de pagamento"
              valueToReturn="value"
            />

            <Input
              label="Valor"
              name="cost"
              mask="currency"
              placeholder="Novo valor"
            />

            <DateRange
              label="Vigência"
              name="dates"
              placeholder="Definir vigência"
              autoComplete="off"
            />
          </NewHourValueFields>

          <NewHourValueConfirmation>
            <strong>Valor Atual</strong>

            {currentCost}

            <Button type="submit">Confirmar</Button>
          </NewHourValueConfirmation>
        </NewHourValue>
      </Can>

      <Historic>
        <strong>Histórico</strong>

        <HistoricRow isHeader>
          <strong>Valor</strong>
          <strong>Vigência</strong>
          <strong>Última alteração</strong>
        </HistoricRow>

        <HistoricCosts>
          {parsedCosts ? (
            parsedCosts.map(item => {
              let parsedFrom = '2020-01-01';
              let parsedTo = '2050-01-01';

              if (item.from !== '0000-00-00')
                parsedFrom = format(parseISO(item.from), 'dd MMM yyyy', {
                  locale,
                });

              if (item.to !== '0000-00-00')
                parsedTo = format(parseISO(item.to), 'dd MMM yyyy', {
                  locale,
                });

              let paymentMethod = ` hora`;

              if (item.is_month) paymentMethod = ` mês`;

              return (
                <HistoricRow key={v4()}>
                  <strong>{`${toCurrency(item.cost)}${paymentMethod}`} </strong>

                  <strong>{`${parsedFrom} - ${parsedTo}`}</strong>

                  <strong>{item.user_responsible.name}</strong>
                </HistoricRow>
              );
            })
          ) : (
            <HistoricNotFound>
              <strong>Nenhum valor hora cadastrado</strong>
            </HistoricNotFound>
          )}
        </HistoricCosts>
      </Historic>
    </Container>
  );
};

const Component = React.forwardRef(UserValuesForm);

export { Component as UserValuesForm };
