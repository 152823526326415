import { ApexOptions } from 'apexcharts';

import { colors } from '@components/bosons/colors';
import { toHMS } from '@utils/toHMS';

export const COLORS = [
  colors.chart.blue[500],
  colors.chart.green[500],
  colors.chart.orange[500],
  colors.chart.rose[500],
  colors.chart.purple[500],
  colors.chart.darkBlue[500],
  colors.chart.blue[900],
  colors.chart.green[900],
  colors.chart.orange[900],
  colors.chart.rose[900],
  colors.chart.purple[900],
  colors.chart.darkBlue[900],
  colors.chart.blue[100],
  colors.chart.green[100],
  colors.chart.orange[100],
  colors.chart.rose[100],
  colors.chart.purple[100],
  colors.chart.darkBlue[100],
];

export const COLLABORATOR_PROFILE_CHART_OPTIONS = {
  dataLabels: { enabled: false },
  stroke: { curve: 'smooth' },
  colors: ['#7C4DFF'],
  fill: { gradient: { gradientToColors: ['#7C4DFF44'] } },
  chart: {
    width: 280,
    height: 120,
    type: 'area',
    zoom: { enabled: false },
    toolbar: { show: false },
    sparkline: { enabled: true },
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    theme: 'dark',
    x: { format: 'dd/MM/yyyy' },
  },
  xaxis: {
    labels: { show: false },
    axisBorder: { show: false },
    axisTicks: { show: false },
    type: 'datetime',
    categories: [],
  },
  yaxis: { show: false, min: 0, max: 24 },
  legend: { show: false },
} as ApexOptions;

export const PROJECT_COST_CHART_OPTIONS = {
  chart: { width: 600, type: 'donut' },
  colors: COLORS,
  dataLabels: { enabled: false },
  tooltip: {
    y: {
      formatter: (cost: any) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(cost);
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: { chart: { width: 310 }, legend: { show: false } },
    },
    {
      breakpoint: 800,
      options: { chart: { width: 450 }, legend: { show: false } },
    },
  ],
  plotOptions: { pie: { donut: { size: '50px' } } },
  legend: { position: 'right', fontSize: '14px', offsetY: 0, height: 230 },
} as ApexOptions;

export const PROJECT_HOURS_CHART_OPTIONS = {
  chart: { width: 600, type: 'donut' },
  colors: COLORS,
  dataLabels: { enabled: false },
  tooltip: {
    y: {
      formatter: (hour: any) => {
        return `${toHMS(hour).replace(':', 'h ').replace(':', 'm ')}s`;
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: { chart: { width: 310 }, legend: { show: false } },
    },
    {
      breakpoint: 800,
      options: { chart: { width: 450 }, legend: { show: false } },
    },
  ],
  plotOptions: { pie: { donut: { size: '50px' } } },
  legend: { position: 'right', fontSize: '14px', offsetY: 0, height: 230 },
} as ApexOptions;

export const EMPTY_PROJECT_HOURS_CHART_OPTIONS = {
  chart: { width: 600, type: 'donut' },
  // colors: [colors.background],
  dataLabels: { enabled: false },
  tooltip: { enabled: false },
  responsive: [
    {
      breakpoint: 480,
      options: { chart: { width: 200 }, legend: { show: false } },
    },
    {
      breakpoint: 800,
      options: { chart: { width: 450 }, legend: { show: false } },
    },
  ],
  plotOptions: { pie: { donut: { size: '50px' } } },
  legend: {
    position: 'right',
    fontSize: '14px',
    offsetY: 0,
    height: 230,
    formatter: () => 'Vazio',
  },
} as ApexOptions;
