import React, { useRef, useMemo } from 'react';
import {
  HiOutlineCheck,
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlinePlusCircle,
  HiOutlineTrash,
  HiOutlineX,
} from 'react-icons/hi';

import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import { Badge, Button, Input } from '@components/atoms';
import { Can } from '@components/Can';
import { IFormHandles } from '@contexts/ReactFormContext';
import { useClickAway } from '@hooks/useClickAway';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { IChecklist } from '@interfaces/IProject';

import { IFormData } from '.';

import {
  ChecklistContainer,
  FormFields,
  Chevrons,
  GroupUnform,
  ChecklistBar,
  TaskUnform,
  TaskUnformToggle,
  TaskUnformActions,
  Check,
  FormActions,
} from './styles';

interface IChecklistProps {
  formRef: React.RefObject<IFormHandles>;
  isOpen: boolean;
  isEdittingGroup: boolean;
  isAddingTask: boolean;
  isEdittingTask: number | undefined;
  checklist: IChecklist;
  tasks: IChecklist[];
  onSubmit: (formData: IFormData) => void;
  onChecklistClick: () => void;
  onChecklistClickout: () => void;
  onChecklistBarClick: () => void;
  onChecklistBarClickout: () => void;
  onChecklistGroupDelete: (checklist: IChecklist) => void;
  onChecklistAddTask: () => void;
  onChecklistAddTaskCancel: () => void;
  onChecklistTaskClick: (index?: number) => void;
  onChecklistTaskDelete: (checklist: IChecklist) => void;
  onMarkAsCompleted: (checklist: IChecklist) => void;
}

const Checklist: React.FC<IChecklistProps> = ({
  formRef,
  isOpen,
  isEdittingGroup,
  isAddingTask,
  isEdittingTask,
  checklist,
  tasks,
  onSubmit,
  onChecklistClick,
  onChecklistClickout,
  onChecklistBarClick,
  onChecklistBarClickout,
  onChecklistGroupDelete,
  onChecklistAddTask,
  onChecklistAddTaskCancel,
  onChecklistTaskClick,
  onChecklistTaskDelete,
  onMarkAsCompleted,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const containerRef = useRef<HTMLDivElement>(null);
  const groupUnformRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, onChecklistClickout, { enabled: isOpen });

  useClickAway(groupUnformRef, onChecklistBarClickout, { enabled: isOpen });

  const points = useMemo(() => {
    const total = tasks.reduce(
      (acc, item) => acc + Number(item.points || 0),
      0,
    );

    const completed = tasks
      .filter(item => item.end_date)
      .reduce((acc, item) => acc + Number(item.points || 0), 0);

    return {
      label: `${completed} / ${total} pontos`,
      percent: (completed * 100) / total || 0,
    };
  }, [tasks]);

  function getName(name: string): string {
    const splittedName = name.split(' ');

    const firstName = splittedName[0];
    const lastNameFirstLetter = splittedName[splittedName.length - 1].charAt(0);

    return `${firstName} ${lastNameFirstLetter}.`;
  }

  return (
    <ChecklistContainer
      key={v4()}
      ref={containerRef}
      isOpen={isOpen}
      onClick={onChecklistClick}
    >
      <FormFields>
        {isEdittingGroup ? (
          <div ref={groupUnformRef}>
            <GroupUnform
              ref={formRef}
              initialData={checklist}
              onSubmit={onSubmit}
              style={{ margin: 0 }}
            >
              <Input name="id" type="hidden" />

              <Input
                name="name"
                placeholder="Digite o nome do grupo de tarefas"
              />
            </GroupUnform>
          </div>
        ) : (
          <ChecklistBar onClick={onChecklistBarClick}>
            <strong>{checklist.name}</strong>

            <div>
              <div>
                <div style={{ width: `${points.percent}%` }} />
              </div>

              <Badge color={colors.activities.info}>
                {`${points.percent.toFixed(2)}%`}
              </Badge>
            </div>
          </ChecklistBar>
        )}

        <div>
          <strong>Pontos</strong>
          <p>{points.label}</p>
        </div>

        <div>
          <strong>Concluído em</strong>
          <p>--</p>
        </div>

        <Chevrons>
          {isOpen ? (
            <HiOutlineChevronUp color={colors.white} size={24} />
          ) : (
            <HiOutlineChevronDown color={colors.white} size={24} />
          )}
        </Chevrons>
      </FormFields>

      {isAddingTask && (
        <TaskUnform ref={formRef} onSubmit={onSubmit}>
          <div>
            <Input name="name" placeholder="Digite o nome da tarefa" />
          </div>

          <Input name="points" placeholder="00" />

          <p>--</p>

          <TaskUnformActions>
            <Input
              name="parent_checklist_id"
              type="hidden"
              value={checklist.id}
            />

            <button type="button" onClick={onChecklistAddTaskCancel}>
              <HiOutlineX color={colors.white} size={24} />
            </button>

            <button type="submit">
              <HiOutlineCheck color={colors.white} size={24} />
            </button>
          </TaskUnformActions>
        </TaskUnform>
      )}

      {tasks.map((task, index) => {
        return (
          <TaskUnform
            key={task.id}
            ref={formRef}
            initialData={task}
            onSubmit={onSubmit}
          >
            <div>
              <Check
                type="button"
                isChecked={!!task.end_date}
                onClick={() => onMarkAsCompleted(task)}
              />

              <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                {isEdittingTask === index ? (
                  <Input name="name" placeholder="Digite o nome da tarefa" />
                ) : (
                  <TaskUnformToggle
                    type="button"
                    onClick={() => onChecklistTaskClick(index)}
                  >
                    <span>{task.name}</span>
                  </TaskUnformToggle>
                )}
              </Can>

              <Can roles={['COLLABORATOR']} permissions={[]}>
                <span>{task.name}</span>
              </Can>
            </div>

            <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
              {isEdittingTask === index ? (
                <Input name="points" placeholder="00" />
              ) : (
                <TaskUnformToggle
                  type="button"
                  onClick={() => onChecklistTaskClick(index)}
                >
                  <span>{task.points} pontos</span>
                </TaskUnformToggle>
              )}
            </Can>

            <Can roles={['COLLABORATOR']} permissions={[]}>
              <span>{task.points} pontos</span>
            </Can>
            <p>
              {task.end_date ? (
                <>
                  {format(new Date(task.end_date), 'dd MMM yyyy', { locale })}
                  <br />
                  {task?.user && <span>por {getName(task.user.name)}</span>}
                </>
              ) : (
                '--'
              )}
            </p>

            <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
              <TaskUnformActions>
                <Input name="id" type="hidden" />
                <Input name="parent_checklist_id" type="hidden" />
                <Input name="end_date" type="hidden" />

                {isEdittingTask === index && (
                  <button
                    type="button"
                    onClick={() => onChecklistTaskClick(undefined)}
                  >
                    <HiOutlineX color={colors.white} size={24} />
                  </button>
                )}

                <button
                  type="button"
                  onClick={() => onChecklistTaskDelete(task)}
                >
                  <HiOutlineTrash color={colors.white} size={24} />
                </button>

                {isEdittingTask === index && (
                  <button type="submit">
                    <HiOutlineCheck color={colors.white} size={24} />
                  </button>
                )}
              </TaskUnformActions>
            </Can>
          </TaskUnform>
        );
      })}

      <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
        <FormActions>
          <Button
            type="button"
            icon={HiOutlinePlusCircle}
            onClick={onChecklistAddTask}
          >
            Adicionar tarefa
          </Button>

          <Button
            type="button"
            actionType="remove"
            onClick={() => onChecklistGroupDelete(checklist)}
          >
            Excluir
          </Button>
        </FormActions>
      </Can>
    </ChecklistContainer>
  );
};

export { Checklist };
