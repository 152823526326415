import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { HiOutlineChevronRight, HiOutlineCog } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useInfiniteQuery } from '@tanstack/react-query';
import { differenceInDays, format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import {
  Document,
  Packer,
  AlignmentType,
  Paragraph,
  HeadingLevel,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from 'docx';
import { saveAs } from 'file-saver';
import { useTheme } from 'styled-components';
import { v4 } from 'uuid';

import defaultAvatar from '@assets/images/default-avatar.png';
import { Button, CircleChart, Exportations, Filter } from '@components/atoms';
import { IFilterOption } from '@components/atoms/Filter';
import { Search } from '@components/atoms/Search';
import { Can } from '@components/Can';
import { PageHeader, ProjectTooltip } from '@components/molecules';
import { IDrawerRef } from '@components/organisms/Drawer';
import { InfiniteScrollList, ProjectDrawer } from '@components/templates';
import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { HttpQuery, IFilter, IParsedQuery } from '@helpers/HttpQueryHelper';
import { ProjectHelper } from '@helpers/ProjectHelper';
import { IProject } from '@interfaces/IProject';
import { IProjectStatusAlias, IProjectStatus } from '@interfaces/IProject';
import { api } from '@services/api';
import { ProjectsService } from '@services/apis/ProjectsService';
import { getUserLogged } from '@utils/getUserLogged';
import { toHMS } from '@utils/toHMS';

import { Container, Project } from './styles';

interface IGetProjectStatus {
  alias: IProjectStatusAlias;
  status: string;
  rest: string;
}

interface IOptionFilter extends IFilter {
  type?: 'CREATED' | 'IN_PROGRESS' | 'DELAYED' | 'CONCLUDED' | 'ARCHIVED';
}

const Projects: React.FC = () => {
  const history = useHistory();

  const { colors } = useTheme();

  const projectDrawerRef = useRef<IDrawerRef>(null);

  const [, setProjects] = useState<IProject[]>([]);
  const [exportProjects, setExportProjects] = useState<
    { id: number; name: string }[]
  >([]);
  const [isFirstQuery, setIsFirstQuery] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<number>();
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<IParsedQuery>(
    HttpQuery.getInitialQuery({
      limit: 100,
      sort: [{ order: 'ASC', property: 'name' }],
    }),
  );
  const [filterOptions, setFilterOptions] = useState<IFilterOption[]>([
    {
      color: colors.activities.info,
      label: 'Novo',
      value: 'CREATED',
      is_checked: false,
    },
    {
      color: colors.activities.selection,
      label: 'Em andamento',
      value: 'IN_PROGRESS',
      is_checked: false,
    },
    {
      color: colors.activities.danger,
      label: 'Em atraso',
      value: 'DELAYED',
      is_checked: false,
    },
    {
      color: colors.activities.success,
      label: 'Concluído',
      value: 'CONCLUDED',
      is_checked: false,
    },
    {
      color: colors.activities.warning,
      label: 'Arquivado',
      value: 'ARCHIVED',
      is_checked: false,
    },
  ]);

  const handleGetFilterStatus = useCallback(
    (
      status: 'CREATED' | 'IN_PROGRESS' | 'DELAYED' | 'CONCLUDED' | 'ARCHIVED',
    ): IOptionFilter[] => {
      switch (status) {
        case 'CREATED':
          return [
            {
              entity: 'project_status',
              value: status,
              property: 'alias',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'CREATED',
            },
            {
              entity: 'projects',
              value: format(new Date(), 'yyyy-MM-dd'),
              property: 'end_date',
              operator: 'GREATER_THAN_OR_EQUAL',
              rule: 'OR',
              type: 'CREATED',
            },
          ];
        case 'IN_PROGRESS':
          return [
            {
              entity: 'project_status',
              value: status,
              property: 'alias',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'IN_PROGRESS',
            },
            {
              entity: 'projects',
              value: format(new Date(), 'yyyy-MM-dd'),
              property: 'end_date',
              operator: 'GREATER_THAN',
              rule: 'OR',
              type: 'IN_PROGRESS',
            },
          ];
        case 'CONCLUDED':
          return [
            {
              entity: 'project_status',
              value: status,
              property: 'alias',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'CONCLUDED',
            },
          ];
        case 'DELAYED':
          return [
            {
              entity: 'project_status',
              value: status,
              property: 'alias',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'DELAYED',
            },
            {
              entity: 'projects',
              value: format(new Date(), 'yyyy-MM-dd'),
              property: 'end_date',
              operator: 'LESS_THAN_OR_EQUAL',
              rule: 'OR',
              type: 'DELAYED',
            },
          ];
        case 'ARCHIVED':
          return [
            {
              entity: 'project_status',
              value: status,
              property: 'alias',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'ARCHIVED',
            },
            {
              entity: 'projects',
              value: '4',
              property: 'project_status_id',
              operator: 'EQUAL',
              rule: 'OR',
              type: 'ARCHIVED',
            },
          ];
        default:
          return [];
      }
    },
    [],
  );

  const getProjects = useCallback(
    async (params?: any) => {
      let newQuery: IParsedQuery;

      const updatedQuery: IParsedQuery = {
        ...query,
        page: params?.pageParam || 1,
      };

      setQuery(updatedQuery);

      if (isFirstQuery) {
        newQuery = {
          ...query,
          q: [
            {
              entity: 'projects',
              value: '4',
              property: 'project_status_id',
              operator: 'NOT_EQUAL',
              rule: 'OR',
            },
          ],
          page: params?.pageParam || 1,
        };

        setIsFirstQuery(false);
      } else {
        newQuery = {
          ...query,
          page: params?.pageParam || 1,
        };
      }

      const projects = await ProjectsService.list({
        query: newQuery,
        relations: [
          'project_status',
          'project_has_checklists',
          'users',
          'users.user',
        ],
      });

      setTotal(projects.total);

      const parsedQuery = HttpQuery.getParsedQueryString(updatedQuery);

      window.history.pushState('', '', `/projects${parsedQuery}`);

      return projects;
    },
    [isFirstQuery, query],
  );

  const { data, isLoading, error, fetchNextPage, refetch } = useInfiniteQuery(
    ['projects-list', 1],
    getProjects,
    { getNextPageParam: lastPage => lastPage.page + 1 },
  );

  const results = useMemo(() => {
    const projects: any[] = [];

    data?.pages.forEach(page => {
      page.data.forEach((user: any) => {
        projects.push(user);
      });
    });

    return projects;
  }, [data?.pages]);

  // const [isFiltered, setIsFiltered] = useState<boolean>(false);
  // const [query, setQuery] = useState<IQueryState>({
  //   searched: undefined,
  //   filtered: undefined,
  // });
  // const [filteredResults, setFilteredResults] = useState<IFilteredResult[]>();

  const handleOpenDrawer = useCallback(
    () => projectDrawerRef.current?.open(),
    [],
  );

  const handleSelectProject = useCallback(
    (project_id: number) => history.push(`/projects/${project_id}`),
    [history],
  );

  const handleSearch = useCallback(
    async (value: string) => {
      setQuery({
        q: [
          {
            entity: 'projects',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'AND',
          },
          {
            entity: 'users',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'OR',
          },
        ],
        page: 1,
        limit: 100,
        sort: [{ order: 'ASC', property: 'name' }],
      });

      await refetch();
    },
    [refetch],
  );

  const handleFilter = useCallback(
    async (item: any) => {
      setQuery(prevQuery => {
        if (item.value === 'ARCHIVED' && item.is_checked) setIsFirstQuery(true);

        let q: any[] = [...prevQuery.q];

        const itemFilters = handleGetFilterStatus(item.value);

        if (item.is_checked) {
          q = prevQuery.q.filter(
            (query_item: any) => query_item?.type !== item.value,
          );
        } else {
          itemFilters.forEach(item_filter => {
            q.push(item_filter);
          });
        }

        return {
          q,
          page: 1,
          limit: 100,
          sort: [{ order: 'ASC', property: 'name' }],
        };
      });

      setFilterOptions(state => {
        return state.map(stateItem => {
          if (stateItem.value === item.value) {
            return { ...item, is_checked: !item.is_checked };
          }

          return stateItem;
        });
      });

      await refetch();
    },
    [handleGetFilterStatus, refetch],
  );

  const getProjectStatus = (
    status: IProjectStatus,
    days: number,
  ): IGetProjectStatus => {
    // | 'CREATED'
    // | 'IN_PROGRESS'
    // | 'DELAYED'
    // | 'CONCLUDED'
    // | 'ARCHIVED'

    if (status.alias === 'ARCHIVED') {
      return {
        alias: 'ARCHIVED',
        status: 'Arquivado',
        rest: `Projeto arquivado`,
      };
    }

    if (status.alias === 'CONCLUDED') {
      return {
        alias: 'CONCLUDED',
        status: 'Concluído',
        rest: `Projeto entregue`,
      };
    }

    if (days < 0) {
      return {
        alias: 'DELAYED',
        status: 'Em atraso',
        rest: `${Math.abs(days)} dias de atraso`,
      };
    }

    return {
      alias: 'IN_PROGRESS',
      status: 'Em andamento',
      rest: `${days} dias restantes`,
    };
  };

  const userLogged = useMemo(() => {
    const foundedUser = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

    if (foundedUser) {
      return JSON.parse(foundedUser);
    }

    return {};
  }, []);

  const hasMore = useMemo(() => {
    if (userLogged.users_roles[0].role.alias === 'COLLABORATOR') return false;

    if (results.length === total) return false;

    return true;
  }, [results.length, total, userLogged.users_roles]);

  useEffect(() => {
    async function loadExportProjects() {
      const { data: allProjects } = await ProjectsService.list({
        query: {
          q: [],
          page: 1,
          limit: 0,
          sort: [{ order: 'ASC', property: 'name' }],
        },
      });

      setExportProjects(allProjects.map(({ id, name }) => ({ id, name })));
    }

    loadExportProjects();

    if (sessionStorage.openAddProject === 'true') handleOpenDrawer();
  }, [handleOpenDrawer]);

  const user = getUserLogged();

  const usersPermitted = [1, 10, 32, 40, 41, 49, 51];

  return (
    <Container>
      <PageHeader>
        <Search
          placeholder="Digite o nome de um usuário ou projeto"
          onSearch={handleSearch}
        />

        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {usersPermitted.includes(user.user.id) && (
            <Exportations
              data={exportProjects}
              onSearch={async value => {
                const { data: founds } = await ProjectsService.list({
                  query: {
                    q: [
                      {
                        entity: 'projects',
                        value,
                        property: 'name',
                        operator: 'LIKE',
                        rule: 'AND',
                      },
                    ],
                    page: 1,
                    limit: 0,
                    sort: [{ order: 'ASC', property: 'name' }],
                  },
                });

                setExportProjects(founds.map(({ id, name }) => ({ id, name })));
              }}
              onExport={async params => {
                const projects_ids = params.ids.join(',');
                const start = format(params.startDate, 'yyyy-MM-dd', {
                  locale,
                });
                const end = format(params.endDate, 'yyyy-MM-dd', { locale });

                const response = await api.get(
                  `/projects/reports?projects_ids=${projects_ids}&start=${start}&end=${end}`,
                );

                const dataWithRules = response.data.map((project: any) => {
                  const dailies = project.dailies.map((daily: any) => {
                    const totalPlannedHours = project.planned_hours;
                    const totalRealHours =
                      project.dailies.reduce((acc: any, item: any) => {
                        acc += item.hours;
                        return acc;
                      }, 0) / 3600;

                    const percent = totalPlannedHours / totalRealHours;

                    return { ...daily, new_hours: daily.hours * percent };
                  });

                  return { ...project, dailies };
                });

                const documents = dataWithRules.map((project: any) => {
                  const dailiesRows = project.dailies.map((daily: any) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          margins: {
                            top: 120,
                            right: 120,
                            bottom: 120,
                            left: 120,
                          },
                          children: [
                            new Paragraph(
                              format(
                                parseISO(daily.worked_date.substring(0, 10)),
                                'dd/MM/yyyy',
                                { locale },
                              ),
                            ),
                          ],
                        }),
                        new TableCell({
                          margins: {
                            top: 120,
                            right: 120,
                            bottom: 120,
                            left: 120,
                          },
                          children: [new Paragraph(daily.accomplished)],
                        }),
                        new TableCell({
                          margins: {
                            top: 120,
                            right: 120,
                            bottom: 120,
                            left: 120,
                          },
                          children: [new Paragraph(daily.collaborator_name)],
                        }),
                        new TableCell({
                          margins: {
                            top: 120,
                            right: 120,
                            bottom: 120,
                            left: 120,
                          },
                          children: [new Paragraph(toHMS(daily.new_hours))],
                        }),
                      ],
                    });
                  });

                  const totalHoursByProject = project.dailies.reduce(
                    (acc: any, item: any) => {
                      acc += item.new_hours;
                      return acc;
                    },
                    0,
                  );

                  const doc = new Document({
                    sections: [
                      {
                        children: [
                          new Paragraph({
                            text: `Relatório de horas projeto ${project.name}`,
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                            style: 'single',
                            spacing: { before: 100, after: 400 },
                          }),
                          new Table({
                            width: { size: 100, type: WidthType.PERCENTAGE },
                            rows: [
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Empresa')],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('CNPJ')],
                                  }),
                                ],
                              }),
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('')],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('')],
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new Paragraph({
                            text: '',
                            spacing: { before: 200, after: 200 },
                          }),
                          new Table({
                            width: { size: 100, type: WidthType.PERCENTAGE },
                            rows: [
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph('Nome do projeto'),
                                    ],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Data de início')],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Prazo final')],
                                  }),
                                ],
                              }),
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph(project.name)],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph(
                                        format(
                                          parseISO(
                                            project.start_date.substring(0, 10),
                                          ),
                                          'dd/MM/yyyy',
                                          { locale },
                                        ),
                                      ),
                                    ],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph(
                                        format(
                                          parseISO(
                                            project.end_date.substring(0, 10),
                                          ),
                                          'dd/MM/yyyy',
                                          { locale },
                                        ),
                                      ),
                                    ],
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new Paragraph({
                            text: '',
                            spacing: { before: 200, after: 200 },
                          }),
                          new Table({
                            width: { size: 100, type: WidthType.PERCENTAGE },
                            rows: [
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph('Horas contratadas'),
                                    ],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Horas gastas')],
                                  }),
                                ],
                              }),
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph(
                                        toHMS(project.planned_hours * 3600),
                                      ),
                                    ],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph(toHMS(totalHoursByProject)),
                                    ],
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new Paragraph({
                            text: '',
                            spacing: { before: 200, after: 200 },
                          }),
                          new Table({
                            width: { size: 100, type: WidthType.PERCENTAGE },
                            rows: [
                              new TableRow({
                                children: [
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Data')],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph('O que foi realizado'),
                                    ],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [new Paragraph('Profissional')],
                                  }),
                                  new TableCell({
                                    margins: {
                                      top: 120,
                                      right: 120,
                                      bottom: 120,
                                      left: 120,
                                    },
                                    children: [
                                      new Paragraph('Horas utilizadas'),
                                    ],
                                  }),
                                ],
                              }),
                              ...dailiesRows,
                            ],
                          }),
                        ],
                      },
                    ],
                  });

                  return doc;
                });

                documents.forEach((doc: any, index: number) =>
                  Packer.toBlob(doc).then(blob =>
                    saveAs(
                      blob,
                      `Relatório Projeto ${dataWithRules[index].name}.docx`,
                    ),
                  ),
                );
              }}
            />
          )}

          <Filter onSelect={handleFilter} options={filterOptions} />

          <Button icon={HiOutlineCog} callToAction onClick={handleOpenDrawer}>
            Adicionar Projeto
          </Button>
        </div>
      </PageHeader>

      <InfiniteScrollList
        dataLength={results.length}
        hasMore={hasMore}
        next={fetchNextPage}
        length={results.length}
        columnWidth={[344, 366]}
        isLoading={isLoading}
      >
        {isLoading ? (
          <strong>Carregando...</strong>
        ) : error ? (
          <strong>Erro!</strong>
        ) : (
          <>
            {data?.pages.map(page => {
              return page.data.map(project => {
                const { alias, status, rest } = getProjectStatus(
                  project.status,
                  differenceInDays(new Date(project.end_date), new Date()),
                );

                const budget = ProjectHelper.getBudget(project);

                return (
                  <Project
                    key={v4()}
                    type={alias}
                    onClick={() => handleSelectProject(project.id)}
                  >
                    <div>
                      <img
                        src={project.avatar_url || defaultAvatar}
                        alt={String(project.id)}
                      />
                    </div>

                    <div>
                      <strong>{project.name}</strong>
                      <span>{status}</span>
                      <p>{rest}</p>
                    </div>

                    <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                      <CircleChart
                        tooltip={<ProjectTooltip project={project} />}
                        percentage={[
                          budget.cost.percentage,
                          budget.hours.percentage,
                          budget.checklist.percentage,
                        ]}
                      />
                    </Can>

                    <Can roles={['COLLABORATOR']} permissions={[]}>
                      <HiOutlineChevronRight size={32} />
                    </Can>
                  </Project>
                );
              });
            })}
          </>
        )}
      </InfiniteScrollList>

      <ProjectDrawer
        projectId={projectId}
        setProjectId={setProjectId}
        setProjects={setProjects}
        projectDrawerRef={projectDrawerRef}
      />
    </Container>
  );
};

export { Projects };
