/* eslint-disable no-useless-constructor */
/* eslint-disable prettier/prettier */
import { ISummaryActivity } from '@interfaces/IProject';
import { api } from '@services/api';

export type IChatBotLastAnswer =
  | 'INIT'
  | 'CONFIRMED_WORKED_HOURS'
  | 'NOT_CONFIRMED_WORKED_HOURS'
  | 'ADJUSTED_WORKED_HOURS'
  | 'CONFIRMED_ADJUSTED_WORKED_HOURS'
  | 'DAILY_ANSWERED'
  | 'CONFIRMED_DAILY_ANSWERED';

export type IAnswer = {
  id: number;
  question: string;
  answer: string;
  flow_stopped: IChatBotLastAnswer;
  daily_date: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
};

type IChatbotRequest = { worked_date?: string };

export interface IChatResponse {
  total_worked_Hours: number;
  worked_date: string;
  lastAnswer: IChatBotLastAnswer;
  answers: IAnswer[];
  activities: ISummaryActivity[];
  daily: any;
}

class ChatBotService {
  public async identifyWorkedHours({ worked_date }: IChatbotRequest): Promise<IChatResponse> {
    // console.log("remIdentifyWorkedHours: ", worked_date);
    
    const { data } = await api.post<IChatResponse>('/chatbot', {
      service: 'INIT',
      payload: {
        worked_date,
      },
    });

    // console.log("remData: ", data);
    

    return data;
  }

  public async confirmWorkedHours({
    summary_activities_ids,
    question,
    answer,
    worked_date,
  }: {
    summary_activities_ids: number[];
    question: string;
    answer: string;
    worked_date?: string,
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CONFIRMED_WORKED_HOURS',
      payload: {
        summary_activities_ids,
        question,
        answer,
        worked_date,
      },
    });
  }

  public async notConfirmWorkedHours({
    question,
    answer,
    worked_date,
  }: {
    question: string;
    answer: string;
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'NOT_CONFIRMED_WORKED_HOURS',
      payload: {
        question,
        answer,
        worked_date,
      },
    });
  }

  public async cancelConfirmationOfWorkedHours({
    summary_activities_ids,
    worked_date,
  }: {
    summary_activities_ids: number[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CANCEL_TIME_CORRECTION',
      payload: {
        summary_activities_ids,
        worked_date,
      },
    });
  }

  public async associateAnotherProject({
    project_id,
    worked_date,
  }: {
    project_id: number;
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'ASSOCIATE_ANOTHER_PROJECT',
      payload: { project_id, worked_date },
    });
  }

  public async adjustWorkedHours({
    activities,
    worked_date,
  }: {
    activities: {
      summary_activity_id: number;
      adjusted_worked_hours: number;
    }[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'ADJUSTED_WORKED_HOURS',
      payload: { activities, worked_date },
    });
  }

  public async adjustUniqueWorkedHours({
    activities,
  }: {
    activities: {
      summary_activity_id: number;
      adjusted_worked_hours: number;
    }[];
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'ADJUST_UNIQUE_WORKED_HOUR',
      payload: { activities },
    });
  }

  public async undoAdjustUniqueWorkedHours({
    activities,
  }: {
    activities: { summary_activity_id: number }[];
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'UNDO_ADJUST_UNIQUE_WORKED_HOUR',
      payload: { activities },
    });
  }

  public async cancelAdjustedWorkedHours({
    summary_activities_ids,
    worked_date,
  }: {
    summary_activities_ids: number[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CANCEL_ADJUSTED_WORKED_HOURS',
      payload: {
        summary_activities_ids,
        worked_date,
      },
    });
  }

  public async removeSummaryActivity({
    summary_activity_id,
  }: {
    summary_activity_id: number;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'REMOVE_SUMMARY_ACTIVITY',
      payload: { summary_activity_id },
    });
  }

  public async confirmAdjustedWorkedHours({
    summary_activities_ids,
    question,
    answer,
    worked_date,
  }: {
    summary_activities_ids: number[];
    question: string;
    answer: string;
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CONFIRMED_ADJUSTED_WORKED_HOURS',
      payload: {
        summary_activities_ids,
        question,
        answer,
        worked_date,
      },
    });
  }

  public async cancelConfirmationAdjustedOfWorkedHours({
    summary_activities_ids,
    worked_date,
  }: {
    summary_activities_ids: number[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CANCEL_DAILY_STATUS', // VERIFICAR
      payload: {
        summary_activities_ids,
        worked_date,
      },
    });
  }

  public async answerDaily({
    daily_answers,
    worked_date,
  }: {
    daily_answers: {
      accomplished: string;
      planned: string;
      impediment: string;
      project_id: number;
      summary_activity_id: number;
    }[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'DAILY_ANSWERED',
      payload: { daily_answers, worked_date },
    });
  }

  public async confirmDailyAnswered({
    question,
    answer,
    worked_date,
  }: {
    question: string;
    answer: string;
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CONFIRMED_DAILY_ANSWERED',
      payload: {
        question,
        answer,
        worked_date,
      },
    });
  }

  public async cancelDailyAnswered({
    daily_answers_ids,
    worked_date,
  }: {
    daily_answers_ids: number[];
    worked_date?: string;
  }): Promise<void> {
    await api.post('/chatbot', {
      service: 'CANCEL_DAILY_ANSWERED',
      payload: { daily_answers_ids, worked_date },
    });
  }
}

const INSTANCE = new ChatBotService();

export { INSTANCE as ChatBotService };
