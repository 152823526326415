import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'dropdown-container',
})`
  position: relative;
`;

interface IMoreButtonProps {
  isActive: boolean;
}

export const MoreButton = styled.button.attrs({
  className: 'dropdown-more_button',
})<IMoreButtonProps>`
  ${({ theme, isActive }) => css`
    border: 0;
    border-radius: 50%;
    background: ${isActive ? theme.colors.asideMenu : 0};

    padding: 4px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    &:hover {
      background: ${darken(0.05, theme.colors.asideMenu)};
      box-shadow: 0 0 6px ${theme.colors.shadow};

      cursor: pointer;
    }

    svg {
      stroke: ${isActive
        ? theme.colors.activities.info
        : theme.colors.text.title};
    }
  `}
`;

interface IItemsProps {
  isActive: boolean;
}

export const Items = styled.div.attrs({
  className: 'dropdown-items',
})<IItemsProps>`
  ${({ theme, isActive }) => css`
    border-radius: ${theme.borders.radii[100]};
    background: ${theme.colors.asideMenu};
    box-shadow: 0px 0px 24px ${theme.colors.shadow};

    position: absolute;
    top: 40px;
    right: 0;

    transition: opacity 400ms, visibility 400ms;

    opacity: ${isActive ? 1 : 0};
    visibility: ${isActive ? 'visible' : 'hidden'};

    z-index: 10000;
  `}
`;

export const Item = styled.div.attrs({
  className: 'dropdown-item',
})`
  ${({ theme }) => css`
    width: 230px;

    white-space: nowrap;

    padding: 8px 16px;

    position: relative;

    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: background 400ms;

    z-index: 10000;

    &:first-of-type {
      border-top-left-radius: ${theme.borders.radii[100]};
      border-top-right-radius: ${theme.borders.radii[100]};
    }

    &:last-of-type {
      border-bottom-left-radius: ${theme.borders.radii[100]};
      border-bottom-right-radius: ${theme.borders.radii[100]};
    }

    &:hover {
      background: ${darken(0.05, theme.colors.asideMenu)};

      cursor: pointer;
    }

    & + div {
      border-top: 1px solid ${theme.colors.lines};
    }

    span {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};
    }

    svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
      min-height: 18px;

      /* transition: all 100ms; */
    }
  `}
`;
