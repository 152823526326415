import React, { useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { IOrganogram, IOrganogramGroup } from '@interfaces/IOrganogram';

import { OrganogramContent } from '../Content';

import { Childs, Container, Content, OpenButton } from './styles';

interface IOrganogramCardProps {
  organogram: IOrganogram;
  group: IOrganogramGroup;
  level: number;
}

const OrganogramCard: React.FC<IOrganogramCardProps> = ({
  organogram,
  group,
  level,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const belowQuantity = useMemo(() => {
    return organogram.childs.filter(
      treeItem => group.id === treeItem.parent_group_id,
    );
  }, [organogram.childs, group.id]);

  const belowCosts = useMemo(() => {
    if (group.type === 'group') {
      return {
        current: organogram?.childs.reduce(
          (accumulator, curr) =>
            accumulator +
            (curr.values?.current?.cost ? curr.values?.current.cost : 0),
          0,
        ),
        past: organogram?.childs.reduce(
          (accumulator, curr) =>
            accumulator +
            (curr.values?.past?.cost ? curr.values?.past.cost : 0),
          0,
        ),
      };
    }

    return {
      current: organogram?.childs
        .filter(treeItem => group.id === treeItem.parent_group_id)
        .reduce(
          (accumulator, curr) =>
            accumulator +
            (curr.values?.current?.cost ? curr.values.current.cost : 0),
          0,
        ),
      past: organogram?.childs
        .filter(treeItem => group.id === treeItem.parent_group_id)
        .reduce(
          (accumulator, curr) =>
            accumulator + (curr.values?.past?.cost ? curr.values.past.cost : 0),
          0,
        ),
    };
  }, [group, organogram.childs]);

  const belowHours = useMemo(() => {
    if (group.type === 'group') {
      const usersIds: number[] = [];

      organogram.childs.forEach(
        item => item.type === 'collaborator' && usersIds.push(item.id),
      );

      return {
        current: organogram.childs.reduce((accumulator, curr) => {
          if (usersIds.includes(curr.id)) {
            return (
              accumulator +
              (curr.values.current.hours ? curr.values.current.hours : 0)
            );
          }

          return accumulator;
        }, 0),
        past: organogram.childs.reduce((accumulator, curr) => {
          if (usersIds.includes(curr.id)) {
            return (
              accumulator +
              (curr.values.past.hours ? curr.values.past.hours : 0)
            );
          }

          return accumulator;
        }, 0),
      };
    }

    return {
      current: organogram?.childs
        .filter(treeItem => group.id === treeItem.parent_group_id)
        .reduce(
          (accumulator, curr) =>
            accumulator +
            (curr.values?.current?.hours ? curr.values.current.hours : 0),
          0,
        ),
      past: organogram.childs
        .filter(treeItem => group.id === treeItem.parent_group_id)
        .reduce(
          (accumulator, curr) =>
            accumulator +
            (curr.values?.past?.hours ? curr.values.past.hours : 0),
          0,
        ),
    };
  }, [group, organogram.childs]);

  const parentBelowQuantity = useMemo(() => {
    const aboveParent = organogram.childs.find(
      item => item.id === group.parent_group_id,
    );

    if (!aboveParent) return 0;

    return organogram.childs.filter(
      item => item.parent_group_id === aboveParent.id,
    ).length;
  }, [organogram.childs, group.parent_group_id]);

  const props = useMemo(() => {
    return {
      container: {
        color: organogram.color,
        type: group.type,
        level,
        belowQuantity: belowQuantity.length,
        hasChildren: group.has_children,
        lastHasChildren: belowQuantity[belowQuantity.length - 1]?.has_children,
        parentBelowQuantity,
      },
      content: {
        color: organogram.color,
        type: group.type,
        level,
        isOpen,
        parentBelowQuantity,
      },
      button: {
        isOpen,
        color: organogram.color,
        onClick: () => setIsOpen(!isOpen),
      },
      childs: {
        isOpen,
        color: organogram.color,
        level,
        hasChildren: group.has_children,
      },
    };
  }, [
    organogram.color,
    group,
    level,
    belowQuantity,
    parentBelowQuantity,
    isOpen,
  ]);

  return (
    <Container {...props.container}>
      <Content {...props.content}>
        <OrganogramContent
          organogram={organogram}
          group={group}
          color={organogram.color}
          level={level}
          belowQuantity={belowQuantity.length}
          belowCosts={belowCosts}
          belowHours={belowHours}
        />

        {group.has_children && (
          <OpenButton {...props.button} type="button">
            {isOpen ? (
              <FiChevronUp size={48} color="#232323" />
            ) : (
              <FiChevronDown size={48} color="#232323" />
            )}
          </OpenButton>
        )}
      </Content>

      {React.Children.count(children) > 0 && (
        <Childs {...props.childs}>{children}</Childs>
      )}
    </Container>
  );
};

export { OrganogramCard };
