import React, { useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import { Container, IsSearching, Inner, Icon } from './styles';

interface ISearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSearch: (value: string) => Promise<void>;
}

const Search: React.FC<ISearchProps> = ({ onSearch, ...rest }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    await onSearch(value);

    setIsSearching(false);
  }

  return (
    <Container ref={containerRef}>
      <input
        {...rest}
        ref={inputRef}
        onKeyDown={() => setIsSearching(true)}
        onChange={debounce(handleSearch, 500)}
      />

      {isSearching ? (
        <IsSearching>
          <Inner className="one" />

          <Inner className="two" />

          <Inner className="three" />
        </IsSearching>
      ) : (
        <Icon size={24} color="#666A88" />
      )}
    </Container>
  );
};

export { Search };
