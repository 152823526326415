import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { Avatar, DateRange, Input, Select, Tags } from '@components/atoms';
import { IOption } from '@components/atoms/Select';
import { ITag } from '@components/atoms/Tags';
import {
  DATES_OPTIONAL,
  DEFAULT_MSG,
  NUMBER_OPTIONAL,
  NUMBER_REQUIRED,
  STRING_OPTIONAL,
  STRING_REQUIRED,
} from '@constants/yup';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { IUserFormInitialData, IUserFormData, IUser } from '@interfaces/IUser';
import { getGroups } from '@services/apis/GroupsService';
import { ProjectsService } from '@services/apis/ProjectsService';
import { getRoles } from '@services/apis/RolesService';
import { createUser, updateUser } from '@services/apis/UsersService';

import { Container, FormRow } from './styles';

interface IUserFormProps {
  user?: IUserFormInitialData;
  setUsers?: React.Dispatch<React.SetStateAction<IUser[]>>;
  closeModal: () => void;
}

const UserForm: React.ForwardRefRenderFunction<IFormRef, IUserFormProps> = (
  { user, setUsers, closeModal },
  ref,
) => {
  const [t] = useTranslation();

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const [roles, setRoles] = useState<IOption[]>([]);
  const [paymentType, setPaymentType] = useState<IOption[]>([]);
  // const [saveData, setSaveData] = useState({});
  const [groups, setGroups] = useState<ITag[]>([]);
  const [projects, setProjects] = useState<ITag[]>([]);
  const [userStatus, setUserStatus] = useState<IOption[]>([]);

  const handleSubmit = useCallback(
    async (formData: IUserFormData) => {
      const schema = Yup.object().shape({
        name: STRING_REQUIRED(DEFAULT_MSG('nome')),
        email: STRING_REQUIRED(DEFAULT_MSG('e-mail')),
        user_role_id: NUMBER_REQUIRED(DEFAULT_MSG('perfil'), 1, 3),
        password: STRING_OPTIONAL(t('PASS'), !user?.id),
        password_confirmation: STRING_OPTIONAL(t('PASS_CONFIRM'), !user?.id),
        cost: NUMBER_OPTIONAL(DEFAULT_MSG('valor hora'), !user?.id),
        dates: DATES_OPTIONAL(DEFAULT_MSG('vigência'), !user?.id),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: !user?.id
            ? 'NEW_COLLABORATOR_SUCCESS'
            : 'EDIT_COLLABORATOR_SUCCESS',
          error: !user?.id
            ? 'NEW_COLLABORATOR_FAILURE'
            : 'EDIT_COLLABORATOR_FAILURE',
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          if (!user?.id) {
            const created = await createUser(formData);

            if (setUsers) {
              setUsers(s => [...s, created]);
            }
          } else {
            if (formData.enabled === 1) formData.enabled = true;
            else formData.enabled = false;

            const updated = await updateUser(user.id, formData);

            if (setUsers) {
              setUsers(s => s.map(i => (i.id === user.id ? updated : i)));
            }
          }

          closeModal();
        },
      });
    },
    [addToast, closeModal, setUsers, t, user],
  );

  useImperativeHandle(ref, () => ({
    submit: formRef.current?.submitForm,
    reset: formRef.current?.reset,
  }));

  useEffect(() => {
    async function loadRoles() {
      const response = await getRoles();

      setRoles(response.map(i => ({ label: i.name, value: i.id })));
    }

    loadRoles();
  }, []);

  useEffect(() => {
    async function loadGroups() {
      const response = await getGroups({
        filters: [
          {
            entity: 'group',
            property: 'type',
            value: 'subgroup',
            rule: 'AND',
            operator: '=',
          },
        ],
      });

      setGroups(response.map(i => ({ label: i.name, value: i.id })));
    }

    loadGroups();
  }, []);

  useEffect(() => {
    async function loadProjects() {
      const response: any = await ProjectsService.list({
        query: {
          q: [
            {
              entity: 'projects',
              value: '4',
              property: 'project_status_id',
              operator: 'NOT_EQUAL',
              rule: 'OR',
            },
          ],
          page: 1,
          limit: 0,
          sort: [{ order: 'ASC', property: 'name' }],
        },
        relations: [],
      });

      setProjects(
        response.data?.map((i: any) => ({ label: i.name, value: i.id })),
      );
    }

    loadProjects();
  }, []);

  useEffect(() => {
    function loadPaymentType() {
      setPaymentType([
        { label: 'Hora', value: 1 },
        { label: 'Mensal', value: 2 },
      ]);
    }

    loadPaymentType();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setData({
        ...user,
        enabled: user?.enabled ? [1] : [2],
      });
    }
  }, [user]);

  useLayoutEffect(() => {
    if (!user) formRef.current?.setData({});
  }, [user]);

  useEffect(() => {
    setUserStatus([
      { label: 'Ativo', value: 1 },
      { label: 'Inativo', value: 2 },
    ]);
  }, [user]);

  return (
    <Container
      ref={formRef}
      initialData={user}
      onSubmit={handleSubmit}
      // onChange={(onChange: any) => {
      //   setSaveData({
      //     ...saveData,
      //     [onChange.target?.name]: onChange.target?.value,
      //   });
      // }}
      autoComplete="off"
    >
      <Avatar
        name="avatar_url"
        onChange={e => console.log(e)}
        avatarSize={{ width: 120, height: 120 }}
      />

      <FormRow>
        <Input label="Nome" name="name" placeholder="Nome" />

        <Input
          label="E-mail"
          name="email"
          placeholder="E-mail"
          autoComplete="nope"
        />
      </FormRow>

      <FormRow>
        <Input
          label="Telefone"
          name="phone"
          mask="cellphone"
          placeholder="Telefone"
        />

        <Select
          name="user_role_id"
          options={roles}
          placeholder="Perfil"
          label="Perfil"
          valueToReturn="value"
        />
      </FormRow>

      {!user?.id && (
        <FormRow>
          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder="Senha"
            autoComplete="new-password"
          />

          <Input
            label="Confirmação da senha"
            name="password_confirmation"
            type="password"
            placeholder="Confirme a senha"
            autoComplete="nope"
          />
        </FormRow>
      )}

      {!user?.id && (
        <>
          <FormRow>
            <Input
              label="Valor"
              name="cost"
              mask="currency"
              placeholder="Valor"
            />

            <Select
              name="is_hour"
              options={paymentType}
              placeholder="Tipo de pagamento"
              label="Tipo de pagamento"
              valueToReturn="value"
            />
          </FormRow>
          <FormRow>
            <DateRange
              label="Vigência"
              name="dates"
              placeholder="Definir vigência"
              direction={{ vertical: 'UP', horizontal: 'RIGHT' }}
              autoComplete="off"
            />
          </FormRow>
        </>
      )}

      <Tags
        name="groups_ids"
        label="Grupos"
        placeholder="Digite o grupo + Enter"
        tags={groups}
        searchable
        minHeight="40px"
        valueToReturn="value"
      />

      <Tags
        name="projects_ids"
        label="Projetos"
        placeholder="Digite o projeto + Enter"
        tags={projects}
        searchable
        minHeight="40px"
        valueToReturn="value"
      />

      {user?.id && (
        <FormRow>
          <Select
            name="enabled"
            options={userStatus}
            placeholder="Status"
            label="Status"
            valueToReturn="value"
          />
        </FormRow>
      )}
    </Container>
  );
};

const Component = React.forwardRef(UserForm);

export { Component as UserForm };
