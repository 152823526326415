import React, { useRef, useState, useEffect, useCallback } from 'react';

import { ProjectForm } from '@components/molecules/Forms/Project';
import { UpdateProjectForm } from '@components/molecules/Forms/Project/Update';
import { Drawer } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { IFormRef } from '@contexts/ReactFormContext';
import { useResetForms } from '@hooks/useResetForms';
import { IEditProjectForm, IProject } from '@interfaces/IProject';

interface IProjectDrawerProps {
  projectId?: number;
  project?: IProject | undefined;
  setProjectId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setProjects?: React.Dispatch<React.SetStateAction<IProject[]>>;
  setProject?: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  projectDrawerRef: React.RefObject<IDrawerRef>;
  type?: 'edit' | 'new';
}

const ProjectDrawer: React.FC<IProjectDrawerProps> = ({
  projectId,
  setProjectId,
  setProjects,
  setProject,
  project,
  projectDrawerRef,
  type = 'new',
}) => {
  const projectFormRef = useRef<IFormRef>(null);
  const updateProjectFormRef = useRef<IFormRef>(null);

  const [selectedProject, setSelectedProject] = useState<IEditProjectForm>();

  // useEffect(() => {
  //   async function loadProject(id: number) {
  //     const data = await ProjectsService.show({
  //       id,
  //       relations: [
  //         'project_status',
  //         'tags',
  //         'users',
  //         'users.user',
  //         'project_has_checklists',
  //       ],
  //     });

  //     setSelectedProject({
  //       id: data.id,
  //       name: data.name,
  //       planned_cost: data.planned_cost,
  //       planned_hours: data.planned_hours,
  //       dates: [data.start_date, data.end_date],
  //       tags: data.tags,
  //     });
  //   }

  //   if (projectId) loadProject(projectId);
  // }, [projectId]);

  useEffect(() => {
    async function loadProject() {
      if (project) {
        setSelectedProject({
          id: project.id,
          name: project.name,
          planned_cost: project.planned_cost,
          planned_hours: project.planned_hours,
          dates: [project.start_date, project.end_date],
          tags: project?.tags,
        });
      }
    }

    if (project) loadProject();
  }, [project]);

  const onClose = useCallback(() => {
    sessionStorage.openAddProject = 'false';
    setProjectId(state => (state === 0 ? undefined : 0));
  }, [setProjectId]);

  useResetForms<IEditProjectForm>({
    refs: [projectFormRef],
    trigger: projectId,
    set: setSelectedProject,
  });

  return (
    <Drawer
      ref={projectDrawerRef}
      title={selectedProject ? selectedProject.name : 'Adicionar projeto'}
      hasData={!!selectedProject}
      onClose={onClose}
    >
      {type === 'new' ? (
        <ProjectForm
          ref={projectFormRef}
          project={selectedProject}
          setProjects={setProjects}
          setProject={setProject}
          closeModal={() => projectDrawerRef.current?.close()}
        />
      ) : (
        <UpdateProjectForm
          ref={updateProjectFormRef}
          project={project}
          setProject={setProject}
          closeModal={() => projectDrawerRef.current?.close()}
        />
      )}
    </Drawer>
  );
};

export { ProjectDrawer };
