import React, { useState, useEffect, useCallback } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

import { addMonths, format, subMonths } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import { Container } from './styles';

interface IMonthlyProps {
  onChange: (month: number, year: number) => void;
}

const Monthly: React.FC<IMonthlyProps> = ({ onChange }) => {
  const [current, setCurrent] = useState<Date>(new Date());

  const onPrev = useCallback(() => {
    setCurrent(state => subMonths(state, 1));
  }, []);

  const onNext = useCallback(() => {
    setCurrent(state => addMonths(state, 1));
  }, []);

  useEffect(
    () => onChange(current.getMonth(), current.getFullYear()),
    [current, onChange],
  );

  return (
    <Container>
      <button type="button" onClick={onPrev}>
        <HiOutlineChevronLeft size={20} />
      </button>

      <span>{format(current, 'MMM yyyy', { locale })}</span>

      <button type="button" onClick={onNext}>
        <HiOutlineChevronRight size={20} />
      </button>
    </Container>
  );
};

const Component = React.memo(Monthly, (prev, next) => prev !== next);

export { Component as Monthly };
