import styled, { css } from 'styled-components';

const responsive = css`
  @media (max-width: 768px) {
    height: 40px;

    margin-bottom: 16px;
    padding: 0 16px !important;

    gap: 24px;
  }
`;

export const Container = styled.div.attrs({
  className: 'page_header-container',
})`
  height: 80px;

  padding: 0 48px;

  gap: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${responsive}
`;
