import React from 'react';

import { Drawer, IDrawerRef } from '@components/organisms/Drawer';

interface IScreenshotDrawerProps {
  screenshotDrawerRef: React.RefObject<IDrawerRef>;
}

const ScreenshotDrawer: React.FC<IScreenshotDrawerProps> = ({
  screenshotDrawerRef,
}) => {
  return (
    <Drawer ref={screenshotDrawerRef} title="Adicionar entrega">
      <h1>ScreenshotDrawer</h1>
    </Drawer>
  );
};

export { ScreenshotDrawer };
