import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { HeatmapHelper } from '@helpers/HeatmapHelper';
import { IWorkedHoursHeatmap } from '@interfaces/IDashboard';
import { getWorkedWours } from '@services/apis/DashboardService';

import {
  Container,
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderTitle,
  HeatmapAxisX,
  HeatmapAxisY,
  HeatmapChart,
  HeatmapContent,
  HeatmapLabel,
  HeatmapLabelSquare,
  HeatmapSquare,
  Tab,
} from './styles';

const Heatmap: React.FC = () => {
  const [data, setData] = useState<IWorkedHoursHeatmap[]>([]);

  const rangeRule = useMemo(() => {
    const getMinAndMax = HeatmapHelper.getMinAndMaxHours(data);

    return HeatmapHelper.calculateIntervals(getMinAndMax.min, getMinAndMax.max);
  }, [data]);

  const quantityColumns = useMemo(() => {
    return Math.round(data.length / 7);
  }, [data.length]);

  const calculateSquareColor = useCallback(
    (value: number) => {
      if (value === 0) {
        return 'none';
      }
      if (value > 0 && value <= rangeRule[1]) {
        return 'low';
      }
      if (value > rangeRule[1] && value <= rangeRule[2]) {
        return 'medium';
      }
      if (value > rangeRule[2]) {
        return 'high';
      }

      return 'none';
    },
    [rangeRule],
  );

  useEffect(() => {
    async function loadWorkedHours() {
      const response = await getWorkedWours();

      setData(response);
    }

    loadWorkedHours();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <HeaderTitle>Histórico de Atividade</HeaderTitle>

          <HeatmapLabel>
            <span>Mín</span>

            <div>
              <HeatmapLabelSquare />
              <HeatmapLabelSquare />
              <HeatmapLabelSquare />
              <HeatmapLabelSquare />
            </div>

            <span>Máx.</span>
          </HeatmapLabel>
        </HeaderLeft>

        <HeaderRight>
          <Tab type="button" isActive>
            Horas
          </Tab>

          <Tab type="button" isActive={false} style={{ cursor: 'not-allowed' }}>
            Daily
          </Tab>
        </HeaderRight>
      </Header>

      <HeatmapChart>
        <HeatmapAxisY>
          <span>Seg</span>
          <span>Qua</span>
          <span>Sex</span>
        </HeatmapAxisY>

        <div>
          <HeatmapContent columns={quantityColumns}>
            {data.map((item: any) => (
              <HeatmapSquare
                type={calculateSquareColor(
                  Number(item.hours.productive + item.hours.undefined),
                )}
                isActive={item.isActive}
                key={v4()}
              />
            ))}
          </HeatmapContent>

          <HeatmapAxisX>
            <span>Jan</span>
            <span>Fev</span>
            <span>Mar</span>
            <span>Abr</span>
            <span>Mai</span>
            <span>Jun</span>
            <span>Jul</span>
            <span>Ago</span>
            <span>Set</span>
            <span>Out</span>
            <span>Nov</span>
            <span>Dez</span>
            <span />
          </HeatmapAxisX>
        </div>
      </HeatmapChart>
    </Container>
  );
};

export { Heatmap };
