/* eslint-disable react/no-unescaped-entities */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import ApexChart from 'react-apexcharts';
import { FiAlertTriangle, FiEdit, FiX } from 'react-icons/fi';
import {
  HiOutlineCurrencyDollar,
  HiOutlineClock,
  HiOutlineClipboardList,
  HiOutlineUserAdd,
  // HiOutlineMailOpen,
  HiOutlineX,
  HiOutlineChevronLeft,
  HiOutlineUsers,
  HiOutlineTag,
} from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';

import { differenceInDays, format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  SmartRange,
  Switcher,
} from '@components/atoms';
import { Can } from '@components/Can';
import { Activities } from '@components/molecules';
import { IActivityItem } from '@components/molecules/Activities';
import { UsersTooltip, UserTooltip } from '@components/molecules/Tooltips/User';
import { IModalRef } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { Row } from '@components/quarks';
import {
  AddCollaboratorsModal,
  ProjectChecklistModal,
  ProjectCostModal,
  ProjectDrawer,
  ProjectHoursModal,
} from '@components/templates';
import {
  EMPTY_PROJECT_HOURS_CHART_OPTIONS,
  PROJECT_COST_CHART_OPTIONS,
  PROJECT_HOURS_CHART_OPTIONS,
} from '@constants/charts';
import { ProjectHelper } from '@helpers/ProjectHelper';
import { useHeader } from '@hooks/useHeader';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { IProject, IProjectStatusAlias } from '@interfaces/IProject';
import { IUser } from '@interfaces/IUser';
import { getDailies } from '@services/apis/DailyService';
import {
  deleteTag,
  createTag,
  ProjectsService,
} from '@services/apis/ProjectsService';
import { toCurrency } from '@utils/toCurrency';

import {
  Container,
  CustomHeader,
  ProjectInfo,
  SwitcherContainer,
  Dashboard,
  Header,
  HeaderLeft,
  HeaderRight,
  Statistics,
  Statistic,
  StatisticInfo,
  StatisticInfoBadge,
  StatisticBar,
  Collaborators,
  CollaboratorsTitle,
  CollaboratorsAvatars,
  CollaboratorsQuantity,
  TagsContainer,
  Tags,
  Tag,
  Graphic,
  ChartContainer,
  InformativeBox,
} from './styles';

interface IGetProjectStatus {
  alias: IProjectStatusAlias;
  status: string;
  rest: string;
}

const Project: React.FC = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const projectDrawerRef = useRef<IDrawerRef>(null);

  const { setCustomHeader, setCurrentMenu } = useHeader();

  const colors = useTheme<IColors>({ prop: 'colors' });
  const theme = useTheme<'light' | 'dark'>({ prop: 'theme' });

  const projectCostModalRef = useRef<IModalRef>(null);
  const projectHoursModalRef = useRef<IModalRef>(null);
  const projectChecklistModalRef = useRef<IModalRef>(null);
  const addCollaboratorModalRef = useRef<IModalRef>(null);
  // const inviteCollaboratorModalRef = useRef<IModalRef>(null);

  // const [type, setType] = useState<'project' | 'collaborators'>('project');
  const [project, setProject] = useState<IProject>();
  const [tags, setTags] = useState<any>([]);
  const [tag, setTag] = useState('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [collaborators, setCollaborators] = useState<IUser[]>([]);
  const [dailyData, setDailyData] = useState<IActivityItem[]>([]);
  const [dailyDate, setDailyDate] = useState<Date>(new Date());

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);

  const handleOpenDrawer = useCallback(
    () => projectDrawerRef.current?.open(),
    [],
  );

  const handleAddTag = useCallback(
    async (name: string) => {
      const response = await createTag({ name, project_id: id });

      setTag('');

      setTags((prevState: any) => [...prevState, response]);
    },
    [id],
  );

  const handleRemoveTag = useCallback(
    async (tag_id: number) => {
      const response = await deleteTag({ id: tag_id, project_id: id });

      const updatedTags = tags.filter((item: any) => item.id !== tag_id);

      setTags(updatedTags);

      return response;
    },
    [id, tags],
  );

  const graphicData = useMemo(() => {
    if (!project?.summary) return [];

    return project.summary.map(item => {
      return {
        name: item.user_name,
        cost: +item.total_original_cost,
        hours: +item.total_original_worked_hours,
      };
    });
  }, [project]);

  const cost = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: budget.cost.percentage.toFixed(2),
      spent: toCurrency(budget.cost.spent),
      planned: toCurrency(budget.cost.planned),
    };
  }, [project]);

  const hours = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: budget.hours.percentage.toFixed(2),
      spent: `${budget.hours.spent.toFixed()} h`,
      planned: `${budget.hours.planned} h`,
    };
  }, [project]);

  const checklist = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: budget.checklist.percentage.toFixed(2),
      spent: `${budget.checklist.spent.toFixed()}`,
      planned: `${budget.checklist.planned} pontos`,
    };
  }, [project]);

  const handleFilterDaily = useCallback((dates: Date[]) => {
    setDailyData([]);

    setDailyDate(dates[0]);
  }, []);

  useEffect(() => {
    async function loadProject() {
      const foundedProject = await ProjectsService.show({
        id,
        relations: [
          'project_status',
          'tags',
          'users',
          'users.user',
          'project_has_checklists',
          'project_has_checklists.user',
        ],
      });

      const parsedUsers = foundedProject?.collaborators?.map(collaborator => {
        return collaborator.user;
      });

      setProject(foundedProject);
      setTags(foundedProject.tags);
      setCollaborators(parsedUsers || []);
    }

    loadProject();
  }, [id]);

  useEffect(() => {
    async function loadDailyStatus() {
      const data = await getDailies({
        q: [
          {
            property: 'daily_date',
            operator: 'EQUAL',
            value: format(dailyDate, 'yyyy-MM-dd', { locale }),
            rule: 'AND',
            entity: 'daily',
          },
          {
            property: 'concluded',
            operator: 'EQUAL',
            value: '1',
            rule: 'AND',
            entity: 'daily',
          },
        ],
        page: 1,
        limit: 0,
        sort: [
          { property: 'daily_date', order: 'DESC' },
          { property: 'id', order: 'ASC' },
        ],
      });

      const collaboratorsIds = collaborators?.map(item => item.id) || [];

      let filteredData = data.filter(item => {
        return collaboratorsIds.includes(item.user_id);
      });

      filteredData = filteredData.map(item => {
        const filteredDailies = item.daily_answers.filter((daily: any) => {
          return daily.summary_activity.project_id === Number(id);
        });

        const updatedItem = { ...item };

        updatedItem.daily_answers = filteredDailies;

        return updatedItem;
      });

      filteredData = filteredData.filter(item => {
        return item.daily_answers.length > 0;
      });

      setDailyData(
        filteredData.map((item: any) => {
          const pattern = "dd/MM/yyyy HH'h'mm";
          const parsedDate = format(parseISO(item.created_at), pattern, {
            locale,
          });
          const parsedProjects = item.daily_answers.map((answer: any) => {
            return answer.summary_activity.project.name;
          });

          return {
            avatar_url: '',
            title: item.user.name,
            subtitle: `${parsedDate} - ${parsedProjects.join(' - ')}`,
            daily: item,
          };
        }),
      );
    }

    loadDailyStatus();
  }, [id, collaborators, dailyDate]);

  useEffect(() => {
    if (!project) return;

    const isDelayed =
      differenceInDays(new Date(project?.end_date), new Date()) < 0;

    if (isDelayed) {
      setIsShowAlert(true);
    }
  }, [project]);

  const costChartOptions = cloneDeep(PROJECT_COST_CHART_OPTIONS);
  const hoursChartOptions = cloneDeep(PROJECT_HOURS_CHART_OPTIONS);

  costChartOptions.labels = graphicData.map(item => item.name);
  hoursChartOptions.labels = graphicData.map(item => item.name);

  const getProjectStatus = useCallback((): IGetProjectStatus => {
    if (!project) return { alias: 'CREATED', status: 'Criado', rest: '' };

    const { alias } = project.status;
    const days = differenceInDays(new Date(project.end_date), new Date()) + 1;

    if (alias === 'ARCHIVED') {
      return {
        alias: 'ARCHIVED',
        status: 'Arquivado',
        rest: `Projeto arquivado`,
      };
    }

    if (alias === 'CONCLUDED') {
      return {
        alias: 'CONCLUDED',
        status: 'Concluído',
        rest: `Projeto entregue`,
      };
    }

    if (days < 0) {
      return {
        alias: 'DELAYED',
        status: 'Em atraso',
        rest: `${Math.abs(days)} dias de atraso`,
      };
    }

    return {
      alias: 'IN_PROGRESS',
      status: 'Em andamento',
      rest: `${days} dias restantes`,
    };
  }, [project]);

  useEffect(() => {
    const nameArr = project?.name.split(' ') || [''];

    const { rest } = getProjectStatus();

    const customHeaderComponent = (
      <>
        <CustomHeader>
          <ProjectInfo>
            <button type="button" onClick={() => history.push('/projects')}>
              <HiOutlineChevronLeft size={24} color={colors.white} />
            </button>

            <Avatar
              name="avatar_url"
              avatar_url={project?.avatar_url}
              avatarSize={{ width: 48, height: 48 }}
              iconSize={24}
              placeholder={nameArr[0].substring(0, 1)}
              readOnly
            />

            <div>
              <strong>{project?.name}</strong>
              <span>{rest}</span>
            </div>

            <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
              <FiEdit size={24} onClick={handleOpenDrawer} />
            </Can>
          </ProjectInfo>
        </CustomHeader>

        <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
          <SwitcherContainer>
            <Switcher
              name="enabled"
              labels={{ active: 'Horas', inactive: 'Custo' }}
              checked={false}
              onSwitch={() => setIsChecked(state => !state)}
              staticColor
            />
          </SwitcherContainer>
        </Can>
      </>
    );

    setCustomHeader(customHeaderComponent);
    setCurrentMenu(1);

    return () => setCustomHeader(undefined);
  }, [
    colors,
    getProjectStatus,
    handleOpenDrawer,
    history,
    project,
    setCurrentMenu,
    setCustomHeader,
  ]);

  return (
    <Container>
      <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
        <Row>
          <InformativeBox isActive={isShowAlert}>
            <FiAlertTriangle size={40} color="#FFC400" />

            <div>
              <p>
                Este projeto está com <strong>{getProjectStatus().rest}</strong>
                , para alterar o prazo dele basta clicar no botão{' '}
                <strong>'Alterar projeto'</strong> e alterar a data de fim dele,
                com isso a associação dos colaboradores também serão atualizadas
                mudando o fim para a data final do projeto atualizada.
              </p>

              <Button callToAction onClick={handleOpenDrawer}>
                Alterar projeto
              </Button>
            </div>

            <FiX size={24} onClick={() => setIsShowAlert(false)} />
          </InformativeBox>
        </Row>
      </Can>

      <Row columns={2}>
        <Dashboard>
          <Header>
            <HeaderLeft>Orçamento</HeaderLeft>

            <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
              <Dropdown
                items={[
                  {
                    text: 'Editar custo',
                    icon: HiOutlineCurrencyDollar,
                    onClick: () => projectCostModalRef.current?.open(),
                  },
                  {
                    text: 'Editar horas',
                    icon: HiOutlineClock,
                    onClick: () => projectHoursModalRef.current?.open(),
                  },
                  {
                    text: 'Editar checklist',
                    icon: HiOutlineClipboardList,
                    onClick: () => projectChecklistModalRef.current?.open(),
                  },
                ]}
              />
            </Can>

            <Can roles={['COLLABORATOR']} permissions={[]}>
              <Dropdown
                items={[
                  {
                    text: 'Visualizar checklist',
                    icon: HiOutlineClipboardList,
                    onClick: () => projectChecklistModalRef.current?.open(),
                  },
                ]}
              />
            </Can>
          </Header>

          <Statistics>
            <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
              <Statistic>
                <StatisticInfo>
                  <StatisticInfoBadge>
                    <span>Custo Planejado</span>

                    <Badge color={colors.activities.success}>
                      {cost.percentage} %
                    </Badge>
                  </StatisticInfoBadge>

                  <span>
                    {cost.spent} / {cost.planned}
                  </span>
                </StatisticInfo>

                <StatisticBar
                  goalPercentage={Number(project?.planned_cost_limit)}
                  color={colors.activities.success}
                  percentage={+cost.percentage}
                />
              </Statistic>
            </Can>

            <Statistic>
              <StatisticInfo>
                <StatisticInfoBadge>
                  <span>Horas Planejadas</span>

                  <Badge color={colors.activities.hours}>
                    {hours.percentage} %
                  </Badge>
                </StatisticInfoBadge>

                <span>
                  {hours.spent} / {hours.planned}
                </span>
              </StatisticInfo>

              <StatisticBar
                goalPercentage={Number(project?.planned_hours_limit)}
                color={colors.activities.hours}
                percentage={+hours.percentage}
              />
            </Statistic>

            <Statistic>
              <StatisticInfo>
                <StatisticInfoBadge>
                  <span>Checklist</span>

                  <Badge color={colors.activities.info}>
                    {checklist.percentage} %
                  </Badge>
                </StatisticInfoBadge>

                <span>
                  {checklist.spent} / {checklist.planned}
                </span>
              </StatisticInfo>

              <StatisticBar
                color={colors.activities.info}
                percentage={+checklist.percentage}
              />
            </Statistic>
          </Statistics>
        </Dashboard>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <Collaborators>
            <CollaboratorsTitle>
              <strong>Colaboradores</strong>

              <span>
                {project?.collaborators?.length
                  ? project?.collaborators?.length
                  : 0}{' '}
                associados
              </span>
            </CollaboratorsTitle>

            <CollaboratorsAvatars>
              {collaborators.map((user, index) => {
                const nameArr = user.name.split(' ');
                const placeholder = nameArr[0].substring(0, 1);

                const avatar = (
                  <UserTooltip
                    user={user}
                    key={v4()}
                    tooltip={
                      <Avatar
                        key={v4()}
                        name="avatar_url"
                        avatar_url={user.avatar_url}
                        avatarSize={{ width: 40, height: 40 }}
                        iconSize={24}
                        placeholder={placeholder}
                        readOnly
                      />
                    }
                  />
                );
                const collaboratorsRemaining = collaborators.slice(4);

                const quantity = (
                  <UsersTooltip
                    users={collaboratorsRemaining}
                    key={v4()}
                    tooltip={
                      <CollaboratorsQuantity
                        key={v4()}
                        avatarSize={{ width: 40, height: 40 }}
                      >
                        +{collaborators.length - 4}
                      </CollaboratorsQuantity>
                    }
                  />
                );

                if (index > 4) return undefined;

                return index <= 3 ? avatar : quantity;
              })}

              <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                <Dropdown
                  items={[
                    {
                      text: 'Adicionar colaboradores',
                      icon: HiOutlineUserAdd,
                      onClick: () => addCollaboratorModalRef.current?.open(),
                    },
                    // {
                    //   text: 'Convidar colaboradores',
                    //   icon: HiOutlineMailOpen,
                    //   onClick: () => console.log('Convidar colaboradores'),
                    // },
                  ]}
                />
              </Can>

              <Can roles={['COLLABORATOR']} permissions={[]}>
                <Dropdown
                  items={[
                    {
                      text: 'Visualizar colaboradores',
                      icon: HiOutlineUsers,
                      onClick: () => addCollaboratorModalRef.current?.open(),
                    },
                  ]}
                />
              </Can>
            </CollaboratorsAvatars>
          </Collaborators>

          <TagsContainer>
            <HeaderLeft>Tags do Projeto</HeaderLeft>

            <Tags>
              {tags?.map((item: any) => (
                <Tag key={v4()}>
                  <strong>{item.name}</strong>

                  <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                    <HiOutlineX
                      size={20}
                      onClick={() => handleRemoveTag(item.id)}
                    />
                  </Can>

                  <Can roles={['COLLABORATOR']} permissions={[]}>
                    <HiOutlineTag size={20} />
                  </Can>
                </Tag>
              ))}

              <input
                type="text"
                placeholder="Digite aqui a tag + Enter"
                value={tag}
                onChange={e => setTag(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    if (e.target.value.trim().length > 0) {
                      handleAddTag(e.target?.value);
                    }
                  }
                }}
              />
            </Tags>
          </TagsContainer>
        </div>
      </Row>

      <Row columns={2}>
        <Graphic>
          <Header>
            <HeaderLeft>
              <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                {isChecked ? 'Horas' : 'Custo'} por colaboradores
              </Can>

              <Can roles={['COLLABORATOR']} permissions={[]}>
                Horas por colaboradores
              </Can>
            </HeaderLeft>

            <HeaderRight />
          </Header>

          <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
            <ChartContainer>
              {isChecked ? (
                <>
                  {graphicData.map(item => item.hours).length > 0 ? (
                    <ApexChart
                      options={hoursChartOptions}
                      series={graphicData.map(item => item.hours)}
                      type="donut"
                      width={530}
                    />
                  ) : (
                    <>
                      {theme === 'light' ? (
                        <ApexChart
                          options={{
                            ...EMPTY_PROJECT_HOURS_CHART_OPTIONS,
                            colors: ['#F0F4F9'],
                          }}
                          series={[1]}
                          type="donut"
                          width={530}
                        />
                      ) : (
                        <ApexChart
                          options={{
                            ...EMPTY_PROJECT_HOURS_CHART_OPTIONS,
                            colors: ['#0A192F'],
                          }}
                          series={[1]}
                          type="donut"
                          width={530}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {graphicData.map(item => item.cost).length > 0 ? (
                    <ApexChart
                      options={costChartOptions}
                      series={graphicData.map(item => item.cost)}
                      type="donut"
                      width={529}
                    />
                  ) : (
                    <>
                      {theme === 'light' ? (
                        <ApexChart
                          options={{
                            ...EMPTY_PROJECT_HOURS_CHART_OPTIONS,
                            colors: ['#F0F4F9'],
                          }}
                          series={[1]}
                          type="donut"
                          width={530}
                        />
                      ) : (
                        <ApexChart
                          options={{
                            ...EMPTY_PROJECT_HOURS_CHART_OPTIONS,
                            colors: ['#0A192F'],
                          }}
                          series={[1]}
                          type="donut"
                          width={530}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </ChartContainer>
          </Can>

          <Can roles={['COLLABORATOR']} permissions={[]}>
            <ChartContainer>
              <ApexChart
                options={hoursChartOptions}
                series={graphicData.map(item => item.hours)}
                type="donut"
                width={530}
              />
            </ChartContainer>
          </Can>
        </Graphic>

        <Activities activities={dailyData} maxHeight="600px">
          <SmartRange
            type="picker"
            placeholder="Filtrar"
            minDate={new Date(2010, 1, 1)}
            maxDate={new Date(2040, 12, 1)}
            onApply={handleFilterDaily}
            defaultValue={[dailyDate]}
          />
        </Activities>
      </Row>

      {project && (
        <>
          <ProjectCostModal
            project={project}
            setProject={setProject}
            projectCostModalRef={projectCostModalRef}
            closeModal={() => projectCostModalRef.current?.close()}
          />

          <ProjectHoursModal
            project={project}
            setProject={setProject}
            projectHoursModalRef={projectHoursModalRef}
            closeModal={() => projectHoursModalRef.current?.close()}
          />

          <ProjectChecklistModal
            project={project}
            setProject={setProject}
            projectChecklistModalRef={projectChecklistModalRef}
          />

          <AddCollaboratorsModal
            project={project}
            setProject={setProject}
            addCollaboratorsModalRef={addCollaboratorModalRef}
          />

          <ProjectDrawer
            projectId={project.id}
            project={project}
            setProject={setProject}
            setProjectId={() => undefined}
            projectDrawerRef={projectDrawerRef}
            type="edit"
          />
        </>
      )}
    </Container>
  );
};

export { Project };
