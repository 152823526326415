import React from 'react';
import { HiOutlineX } from 'react-icons/hi';

import { useContextSelector } from 'use-context-selector';

import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { ISummaryActivity } from '@interfaces/IProject';
import { ChatBotService } from '@services/apis/ChatBotService';

import { IComponentsProps } from '../../../types';
import { Search } from '../Search';

import { Container, Button } from './styles';

interface IProjectAssociationProps extends Pick<IComponentsProps, 'onAnswer'> {
  activities: ISummaryActivity[];
  setIsAssociating: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectAssociation: React.FC<IProjectAssociationProps> = ({
  activities,
  setIsAssociating,
  onAnswer,
}) => {
  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  return (
    <Container>
      <Search
        activities={activities}
        onSelect={async ({ id: project_id }) => {
          await ChatBotService.associateAnotherProject({
            project_id,
            worked_date,
          });

          onAnswer({ dailyState: 'INIT' });
          onAnswer({ dailyState: 'NOT_CONFIRMED_WORKED_HOURS' });
          // setLastStateAnswer('INIT');
          // setLastStateAnswer('NOT_CONFIRMED_WORKED_HOURS');
        }}
      />

      <Button
        type="button"
        onClick={() => setIsAssociating(false)}
        className="cancel"
      >
        <HiOutlineX size={16} />

        <span>Cancelar</span>
      </Button>
    </Container>
  );
};

export { ProjectAssociation };
