import React, { useCallback, useEffect, useState } from 'react';

import { endOfMonth, format, parseISO, startOfMonth, subDays } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import {
  // DeliveryGantt,
  HalfDonutChart,
  ProductivityChart,
  SmartRange,
} from '@components/atoms';
import { IProductivityItem } from '@components/atoms/Charts/Productivity';
import { Activities } from '@components/molecules';
import { IActivityItem } from '@components/molecules/Activities';
import { DashboardHelper as DH } from '@helpers/DashboardHelper';
import { useHeader } from '@hooks/useHeader';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { getDailies } from '@services/apis/DailyService';
import {
  getCollasboratorProductivityByPeriod,
  getSummaryWorkedHoursByCollaborator,
} from '@services/apis/DashboardService';

import { Container, TitleArea, RowCharts } from './styles';

type IHalfDonut = {
  color: string;
  percent: number;
  text: string;
  value: number;
};

const Dashboard: React.FC = () => {
  const { getCurrentInfo } = useHeader();

  const colors = useTheme<IColors>({ prop: 'colors' });

  const { isChecked } = getCurrentInfo();

  const [period, setPeriod] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const [halfDonutsData, setHalfDonutsData] = useState<{
    cost: IHalfDonut[];
    hours: IHalfDonut[];
  }>(() => {
    const { hours: h } = colors;

    return {
      cost: [
        { color: h.productive, percent: 0, text: 'produtivo', value: 0 },
        { color: h.unproductive, percent: 0, text: 'improdutivo', value: 0 },
        { color: h.passive, percent: 0, text: 'passivo', value: 0 },
        { color: h.undefined, percent: 0, text: 'indefinido', value: 0 },
      ],
      hours: [
        { color: h.productive, percent: 0, text: 'produtivo', value: 0 },
        { color: h.unproductive, percent: 0, text: 'improdutivo', value: 0 },
        { color: h.passive, percent: 0, text: 'passivo', value: 0 },
        { color: h.undefined, percent: 0, text: 'indefinido', value: 0 },
      ],
    };
  });
  const [productivityData, setProductivityData] = useState<IProductivityItem[]>(
    [],
  );
  const [dailyData, setDailyData] = useState<IActivityItem[]>([]);

  useEffect(() => {
    async function loadSummaryWorkedHours() {
      const data = await getSummaryWorkedHoursByCollaborator({
        start_date: format(period.start, 'yyyy-MM-dd'),
        end_date: format(period.end, 'yyyy-MM-dd'),
      });

      const { hours: h } = colors;

      setHalfDonutsData({
        cost: [
          {
            color: h.productive,
            percent: Math.round((data.productive.cost / data.total.cost) * 100),
            text: 'produtivo',
            value: data.productive.cost,
          },
          {
            color: h.unproductive,
            percent: Math.round(
              (data.unproductive.cost / data.total.cost) * 100,
            ),
            text: 'improdutivo',
            value: data.unproductive.cost,
          },
          {
            color: h.passive,
            percent: Math.round((data.passive.cost / data.total.cost) * 100),
            text: 'passivo',
            value: data.passive.cost,
          },
          {
            color: h.undefined,
            percent: Math.round((data.undefined.cost / data.total.cost) * 100),
            text: 'indefinido',
            value: data.undefined.cost,
          },
        ],
        hours: [
          {
            color: h.productive,
            percent: Math.round(
              (data.productive.hours / data.total.hours) * 100,
            ),
            text: 'produtivo',
            value: Number((data.productive.hours / 3600).toFixed(0)),
          },
          {
            color: h.unproductive,
            percent: Math.round(
              (data.unproductive.hours / data.total.hours) * 100,
            ),
            text: 'improdutivo',
            value: Number((data.unproductive.hours / 3600).toFixed(0)),
          },
          {
            color: h.passive,
            percent: Math.round((data.passive.hours / data.total.hours) * 100),
            text: 'passivo',
            value: Number((data.passive.hours / 3600).toFixed(0)),
          },
          {
            color: h.undefined,
            percent: Math.round(
              (data.undefined.hours / data.total.hours) * 100,
            ),
            text: 'indefinido',
            value: Number((data.undefined.hours / 3600).toFixed(0)),
          },
        ],
      });
    }

    loadSummaryWorkedHours();
  }, [colors, period.end, period.start]);

  useEffect(() => {
    async function loadCollaboratorsProductivity() {
      const data = await getCollasboratorProductivityByPeriod({
        start_date: format(period.start, 'yyyy-MM-dd'),
        end_date: format(period.end, 'yyyy-MM-dd'),
      });

      // TODO: Refazer esse metodo para colocar o title como a data ao passar as propriedades
      const rowsAgrouped = DH.collaboratorProductivityByDate(data);

      setProductivityData([]);
      setProductivityData(rowsAgrouped);
    }

    loadCollaboratorsProductivity();
  }, [period.end, period.start]);

  useEffect(() => {
    async function loadDailyStatus() {
      const data = await getDailies({
        q: [
          {
            property: 'daily_date',
            operator: 'EQUAL',
            value: format(subDays(new Date(), 1), 'yyyy-MM-dd', { locale }),
            rule: 'AND',
            entity: 'daily',
          },
          {
            property: 'daily_date',
            operator: 'LESS_THAN_OR_EQUAL',
            value: format(new Date(), 'yyyy-MM-dd', { locale }),
            rule: 'AND',
            entity: 'daily',
          },
          {
            property: 'concluded',
            operator: 'EQUAL',
            value: '1',
            rule: 'AND',
            entity: 'daily',
          },
        ],
        page: 1,
        limit: 0,
        sort: [
          { property: 'daily_date', order: 'DESC' },
          { property: 'id', order: 'ASC' },
        ],
      });

      setDailyData(
        data.map(item => {
          const pattern = "dd/MM/yyyy HH'h'mm";
          const parsedDate = format(parseISO(item.created_at), pattern, {
            locale,
          });
          const parsedProjects = item.daily_answers.map((answer: any) => {
            return answer.summary_activity?.project?.name;
          });

          return {
            avatar_url: '',
            title: item.user.name,
            subtitle: `${parsedDate} - ${parsedProjects.join(' - ')}`,
            daily: item,
          };
        }),
      );
    }

    loadDailyStatus();
  }, []);

  const handleFilterGeneralInfo = useCallback((dates: Date[]) => {
    if (dates.length === 1) {
      setPeriod({ start: dates[0], end: dates[0] });
    } else if (dates.length >= 2) {
      setPeriod({ start: dates[0], end: dates[1] });
    } else {
      setPeriod({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      });
    }
  }, []);

  return (
    <Container>
      {/* {process.env.NODE_ENV === 'development' && <DeliveryGantt />} */}
      <TitleArea>
        <strong
          style={{ color: colors.activities.selection, fontSize: '24px' }}
        >
          Informações Gerais
        </strong>

        <div
          style={{
            width: '280px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <SmartRange
            placeholder="Filtrar"
            minDate={new Date(2010, 1, 1)}
            maxDate={new Date(2040, 12, 1)}
            onApply={handleFilterGeneralInfo}
            defaultValue={[period.start, period.end]}
          />
        </div>
      </TitleArea>

      <RowCharts className="half_donuts">
        {isChecked
          ? halfDonutsData.cost.map(halfDonut => {
              return (
                <HalfDonutChart
                  type="cost"
                  key={halfDonut.color}
                  {...halfDonut}
                />
              );
            })
          : halfDonutsData.hours.map(halfDonut => {
              return (
                <HalfDonutChart
                  type="hours"
                  key={halfDonut.color}
                  {...halfDonut}
                />
              );
            })}
      </RowCharts>

      <RowCharts className="productivity_activities">
        <ProductivityChart
          type={isChecked ? 'cost' : 'hours'}
          onLoad={by => DH.order(productivityData, 'date', by)}
        />

        <Activities activities={dailyData} />
      </RowCharts>
    </Container>
  );
};

export { Dashboard };
