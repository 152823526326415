import React, { useMemo } from 'react';

import defaultAvatar from '@assets/images/default-avatar.png';
import { IDeliveryStatus } from '@interfaces/IDelivery';

import { Container, CardInfo } from './styles';

interface IKanBanCardProps {
  id: number;
  title: string;
  description: string;
  avatar_url?: string;
  status: IDeliveryStatus;
}

const KanBanCard: React.FC<IKanBanCardProps> = ({
  title,
  description,
  avatar_url,
  status,
}) => {
  const parsedStatus = useMemo(() => {
    switch (status) {
      case 'DELAYED':
        return 'Em atraso desde 10 ago 2021';
      case 'WAITING_FOR_APPROVAL':
        return 'Entregue em 14 ago 2021';
      case 'FINISHED':
        return 'Aprovado em 14 ago 2021';
      default:
        return 'Entrega prevista para 30 ago 2021';
    }
  }, [status]);

  return (
    <Container type={status}>
      <img src={avatar_url || defaultAvatar} alt={title} />

      <CardInfo type={status}>
        <strong>{title}</strong>
        <span>{description}</span>
        <p>{parsedStatus}</p>
      </CardInfo>
    </Container>
  );
};

export { KanBanCard };
