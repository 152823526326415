import React from 'react';

import * as Icons from '@assets/icons';
import { useTheme } from '@hooks/useTheme';

import { Container } from './styles';

interface ILogoProps {
  isFolded?: boolean;
}

const Logo: React.FC<ILogoProps> = ({ isFolded = false }) => {
  const theme = useTheme<'dark' | 'light'>({ prop: 'theme' });

  return (
    <Container isFolded={isFolded}>
      {theme === 'dark' ? (
        isFolded ? (
          <Icons.DevstreamLogoMinLight />
        ) : (
          <Icons.DevstreamLogoLight />
        )
      ) : isFolded ? (
        <Icons.DevstreamLogoMinDark />
      ) : (
        <Icons.DevstreamLogoDark />
      )}
    </Container>
  );
};

export { Logo };
