import React, { useRef, useMemo, useState, useCallback } from 'react';
import { HiOutlineDocumentAdd, HiOutlinePlusCircle } from 'react-icons/hi';

import { t } from 'i18next';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import { Avatar, Badge, Button, Input } from '@components/atoms';
import { Can } from '@components/Can';
import { Modal, IModalRef } from '@components/organisms/Modal';
import { DEFAULT_MSG, STRING_REQUIRED } from '@constants/yup';
import { IFormHandles } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { IColors } from '@interfaces/generic/ITheme';
import { IChecklist, IProject } from '@interfaces/IProject';
import {
  createChecklist,
  updateChecklist,
  deleteChecklist,
} from '@services/apis/ProjectsService';
import { getValidationErrors } from '@utils/getValidationErrors';

import { Checklist } from './Checklist';

import {
  ModalContent,
  Header,
  ProjectInfo,
  Statistic,
  StatisticInfo,
  StatisticInfoBadge,
  StatisticBar,
  Checklists,
  GroupUnform,
  FormFields,
  FormActions,
  ChecklistsNotFound,
  AddChecklistButton,
} from './styles';

export type IFormData = {
  id?: number;
  name: string;
  points: number;
  parent_checklist_id: number;
};

interface IProjectChecklistModalProps {
  project: IProject | undefined;
  projectChecklistModalRef: React.RefObject<IModalRef>;
  setProject: React.Dispatch<React.SetStateAction<IProject | undefined>>;
}

const ProjectChecklistModal: React.FC<IProjectChecklistModalProps> = ({
  project,
  projectChecklistModalRef,
  setProject,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false);
  const [isEdittingGroup, setIsEdittingGroup] = useState<number>();
  const [isOpenGroup, setIsOpenGroup] = useState<number>();
  const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
  const [isEdittingTask, setIsEdittingTask] = useState<number>();

  const placeholder = useMemo(() => {
    if (!project) return '';

    const nameArr = project?.name.split(' ');

    return nameArr[0].substring(0, 1);
  }, [project]);

  const checklists = useMemo(() => {
    const ordered =
      project?.checklists?.sort((a, b) => (a.id > b.id ? -1 : 1)) || [];

    return ordered;
  }, [project?.checklists]);

  const checklist = useMemo(() => {
    const total = checklists.reduce(
      (acc, item) => acc + Number(item.points || 0),
      0,
    );

    const completed = checklists
      .filter(item => item.end_date)
      .reduce((acc, item) => acc + Number(item.points || 0), 0);

    return {
      percentage: (completed * 100) / total || 0,
      spent: completed,
      planned: total,
    };
  }, [checklists]);

  const reset = useCallback(() => {
    setIsAddingGroup(false);
    setIsEdittingGroup(undefined);
    setIsOpenGroup(undefined);
    setIsAddingTask(false);
    setIsEdittingTask(undefined);
  }, []);

  const onMarkAsCompleted = useCallback(
    async (task: IChecklist) => {
      try {
        const updated = await updateChecklist({
          ...task,
          end_date: task.end_date ? null : new Date(),
        });

        setProject(state => {
          if (!state) return state;

          return {
            ...state,
            checklists: [
              ...(state.checklists || []).map(item => {
                if (item.id === updated.id) return updated;

                return item;
              }),
            ],
          };
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: t('DELETE_PROJECT_CHECKLIST_FAILURE'),
          type: 'error',
        });
      }
    },
    [addToast, setProject],
  );

  const handleDelete = useCallback(
    async (checklistToDelete: IChecklist) => {
      try {
        await deleteChecklist({
          ...checklistToDelete,
          id: checklistToDelete.id,
          project_id: checklistToDelete.project_id,
        });

        setProject(state => {
          if (!state) return state;

          return {
            ...state,
            checklists: [
              ...(state.checklists || []).filter(
                item => item.id !== checklistToDelete.id,
              ),
            ],
          };
        });

        reset();

        addToast({
          title: t('DELETE_PROJECT_CHECKLIST_SUCCESS'),
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: t('DELETE_PROJECT_CHECKLIST_FAILURE'),
          type: 'error',
        });
      }
    },
    [addToast, reset, setProject],
  );

  const handleSubmit = useCallback(
    async (formData: IFormData) => {
      const schema = Yup.object().shape({
        name: STRING_REQUIRED(DEFAULT_MSG('checklist')),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: formData.id
            ? 'UPDATE_PROJECT_CHECKLIST_SUCCESS'
            : 'CREATE_PROJECT_CHECKLIST_SUCCESS',
          error: formData.id
            ? 'UPDATE_PROJECT_CHECKLIST_FAILURE'
            : 'CREATE_PROJECT_CHECKLIST_FAILURE',
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          if (project) {
            if (!formData.id) {
              const created = await createChecklist({
                ...formData,
                project_id: project.id,
                name: formData.name,
                points: formData.points,
                parent_checklist_id: formData.parent_checklist_id,
              });

              setProject(state => {
                if (!state) return state;

                return {
                  ...state,
                  checklists: [created, ...(state.checklists || [])],
                };
              });
            } else {
              const updated = await updateChecklist({
                ...formData,
                id: formData.id,
                project_id: project.id,
                name: formData.name,
                points: formData.points,
                parent_checklist_id: formData.parent_checklist_id,
              });

              setProject(state => {
                if (!state) return state;

                return {
                  ...state,
                  checklists: [
                    ...(state.checklists || []).map(item => {
                      if (item.id === updated.id) return updated;

                      return item;
                    }),
                  ],
                };
              });
            }

            formRef.current?.reset({});

            if (!formData.parent_checklist_id) reset();
            else setIsEdittingTask(undefined);
          }
        },
      });
    },
    [addToast, project, reset, setProject],
  );

  return (
    <Modal
      ref={projectChecklistModalRef}
      size="medium"
      hasNavigation={false}
      onClose={reset}
    >
      <ModalContent>
        <Header>
          <ProjectInfo>
            <Avatar
              name="avatar_url"
              avatar_url={project?.avatar_url}
              avatarSize={{ width: 48, height: 48 }}
              iconSize={24}
              placeholder={placeholder}
              readOnly
            />

            <div>
              <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
                <strong>Editar Checklist</strong>
              </Can>

              <Can roles={['COLLABORATOR']} permissions={[]}>
                <strong>Visualização do Checklist</strong>
              </Can>
              <span>{project?.name}</span>
            </div>
          </ProjectInfo>

          <Statistic>
            <StatisticInfo>
              <StatisticInfoBadge>
                <Badge color={colors.activities.info}>
                  {checklist.percentage.toFixed(2)} %
                </Badge>
              </StatisticInfoBadge>

              <span>
                {checklist.spent} / {checklist.planned} pontos
              </span>
            </StatisticInfo>

            <StatisticBar
              color={colors.activities.info}
              percentage={+checklist.percentage}
            />
          </Statistic>
        </Header>

        <Checklists>
          {isAddingGroup && (
            <GroupUnform ref={formRef} onSubmit={handleSubmit}>
              <FormFields>
                <Input
                  name="name"
                  placeholder="Digite o nome do grupo de tarefas"
                />

                <div>
                  <strong>Pontos</strong>
                  <p>--</p>
                </div>

                <div>
                  <strong>Concluído em</strong>
                  <p>--</p>
                </div>
              </FormFields>

              <FormActions>
                <Button type="submit" icon={HiOutlinePlusCircle}>
                  Adicionar tarefa
                </Button>

                <Button
                  type="button"
                  actionType="cancel"
                  onClick={() => setIsAddingGroup(false)}
                >
                  Cancelar
                </Button>
              </FormActions>
            </GroupUnform>
          )}

          {checklists.length === 0 ? (
            <ChecklistsNotFound>
              <span>Nenhum grupo de tarefas foi adicionado</span>
            </ChecklistsNotFound>
          ) : (
            checklists
              .filter(item => !item.parent_checklist_id)
              .map((item: any, index: number) => {
                return (
                  <Checklist
                    key={v4()}
                    formRef={formRef}
                    isOpen={isOpenGroup === index}
                    isEdittingGroup={isEdittingGroup === index}
                    isAddingTask={isAddingTask}
                    isEdittingTask={isEdittingTask}
                    checklist={item}
                    tasks={checklists.filter(
                      task => Number(task.parent_checklist_id) === item.id,
                    )}
                    onSubmit={handleSubmit}
                    onChecklistClick={() => setIsOpenGroup(index)}
                    onChecklistClickout={() => setIsEdittingGroup(undefined)}
                    onChecklistBarClick={() => setIsEdittingGroup(index)}
                    onChecklistBarClickout={() => setIsEdittingGroup(undefined)}
                    onChecklistGroupDelete={handleDelete}
                    onChecklistAddTask={() => setIsAddingTask(true)}
                    onChecklistAddTaskCancel={() => setIsAddingTask(false)}
                    onChecklistTaskClick={i => setIsEdittingTask(i)}
                    onChecklistTaskDelete={handleDelete}
                    onMarkAsCompleted={onMarkAsCompleted}
                  />
                );
              })
          )}
        </Checklists>

        <Can roles={['SUPER_ADMIN', 'MANAGER']} permissions={[]}>
          <AddChecklistButton
            type="button"
            onClick={() => {
              setIsAddingGroup(true);
              setIsOpenGroup(undefined);
            }}
          >
            <HiOutlineDocumentAdd color={colors.white} size={24} />
          </AddChecklistButton>
        </Can>
      </ModalContent>
    </Modal>
  );
};

export { ProjectChecklistModal };
