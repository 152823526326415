import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 410px;
  height: 520px;
  max-width: 410px;

  cursor: default;
`;

interface IHeaderProps {
  color?: string;
}

export const Header = styled.header<IHeaderProps>`
  ${({ theme, color = 'transparent' }) => css`
    display: flex;
    justify-content: space-between;

    padding: 16px 16px 8px;

    border-bottom: 1px solid ${theme.colors.background};

    > svg {
      cursor: pointer;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: start;

      gap: 6px;

      h4 {
        font-size: 18px;
      }

      > div {
        display: flex;
        gap: 4px;
        color: ${theme.colors.text.subtitle};
        align-items: center;

        div {
          width: 12px;
          height: 12px;
          border: 1px solid ${theme.colors.background};
          background-color: ${color};
        }
      }
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;

  padding: 16px 16px 8px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const List = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 195px;

    ${customScroll(theme.colors.background)}
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 16px;

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 8px;

      > div {
        display: flex;
        flex-direction: column;
        text-align: start;
        font-size: 0.9rem;

        span:last-child {
          color: ${theme.colors.text.subtitle};
          font-size: ${theme.typography.fontSize.small};
        }
      }
    }
  `}
`;

interface ICheckboxProps {
  isSelected: boolean;
}

export const Checkbox = styled.div<ICheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 16px;
    height: 16px;

    border: 1px solid ${isSelected ? theme.colors.activities.info : '#F0F4F9'};
    border-radius: 4px;
    background: ${isSelected ? theme.colors.activities.info : 'transparent'};

    cursor: pointer;

    position: relative;

    &:after {
      content: '';

      width: 4px;
      height: 7px;

      border: solid #ffffff;
      border-width: 0 3px 3px 0;

      transition: all 400ms;
    }

    ${isSelected
      ? css`
          &:after {
            position: absolute;
            top: 50%;
            left: 50%;

            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: translateY(-64%) translateX(-50%) rotate(45deg);
          }
        `
      : css`
          &:after {
            top: 0;

            opacity: 0 !important;

            transition: none;
          }
        `}
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    padding: 16px 16px 8px;

    border-top: 1px solid ${theme.colors.background};

    button {
      height: 40px;
      padding: 8px 16px;
      font-weight: 700;
      color: ${theme.colors.white};
      background: #00b1ffff;
      border-radius: ${theme.borders.radii[100]};
      border: 1px solid #00b1ff;
    }

    button:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }
  `}
`;
