import { isAfter, isBefore, parseISO } from 'date-fns';

import { ITimelineRow } from '@components/atoms/Timeline/types';
import { IParsedQuery } from '@helpers/HttpQueryHelper';
import { IProject } from '@interfaces/IProject';

import { ProjectsService } from './ProjectsService';

interface IListProps {
  query?: IParsedQuery;
}

class ReportsService {
  public async list({ query }: IListProps) {
    const projects = await ProjectsService.list({
      query,
      relations: ['users', 'users.user', 'summary_activities', 'allocations'],
    });

    const startDate = query?.q?.find(
      item => item.operator === 'GREATER_THAN_OR_EQUAL',
    );
    const endDate = query?.q?.find(
      item => item.operator === 'LESS_THAN_OR_EQUAL',
    );

    return {
      page: projects.page,
      limit: projects.limit,
      total: projects.total,
      data: this.getTimelineRows(
        projects.data,
        startDate?.value as string,
        endDate?.value as string,
      ),
    };
  }

  private getTimelineRows(
    projects: IProject[],
    startDate: string,
    endDate: string,
  ): ITimelineRow[] {
    const parsedTimelineData: ITimelineRow[] = [];

    projects.forEach(project => {
      const associatedUsers =
        project.collaborators?.map(up => up.user_id) || [];

      parsedTimelineData.push({
        id: project.id,
        name: project.name,
        variation: 'PROJECT',
        color: '',
        // className: `project_$s{project.id}`,
        bars: [],
        startDate: new Date(project.start_date),
        endDate: new Date(project.end_date),
        summary_activities:
          project.summary_activities?.filter(
            sa =>
              associatedUsers.includes(sa.user_id) &&
              isAfter(parseISO(sa.worked_date as any), parseISO(startDate)) &&
              isBefore(parseISO(sa.worked_date as any), parseISO(endDate)),
          ) || [],
      });

      project.collaborators?.forEach(user_project => {
        const bars: any[] =
          project.allocations
            ?.filter(allocation => allocation.user_id === user_project.user_id)
            .map(allocation => ({
              id: allocation.id,
              color: allocation.color,
              start: allocation.start,
              end: allocation.end,
            })) || [];

        parsedTimelineData.push({
          id: user_project.user_id,
          name: user_project.user?.name || '',
          variation: 'USER',
          color: '',
          bars,
          startDate: new Date(project.start_date),
          endDate: new Date(project.end_date),
          summary_activities:
            project.summary_activities?.filter(
              sa =>
                associatedUsers.includes(sa.user_id) &&
                isAfter(parseISO(sa.worked_date as any), parseISO(startDate)) &&
                isBefore(parseISO(sa.worked_date as any), parseISO(endDate)),
            ) || [],
        });
      });
    });

    return parsedTimelineData;
  }
}

const INSTANCE = new ReportsService();

export { INSTANCE as ReportsService };
