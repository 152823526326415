interface IGetYAxisValuesProps {
  max: number;
  quantity: number;
  type?: 'cost' | 'hours';
}

function getYAxisValues({
  max,
  quantity,
  type = 'cost',
}: IGetYAxisValuesProps) {
  const values: string[] = [];
  const iterationValue = max / quantity;

  for (let i = 0; i < quantity; i++) {
    const calculatedValue = (i + 1) * iterationValue;

    if (type === 'cost') {
      values.push(
        `${
          String(max) === '-Infinity'
            ? '-'
            : calculatedValue >= 1000 && calculatedValue < 10000
            ? `${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(calculatedValue)
                .replace('R$', '')
                .trim()
                .substring(0, 3)}k`
            : calculatedValue >= 10000 && calculatedValue < 100000
            ? `${calculatedValue.toFixed(0).substring(0, 2)}k`
            : calculatedValue >= 100000 && calculatedValue < 1000000
            ? `${calculatedValue.toFixed(0).substring(0, 3)}k`
            : calculatedValue >= 1000000 && calculatedValue < 2000000
            ? `${calculatedValue.toFixed(0).substring(0, 3)}Mi`
            : calculatedValue >= 2000000 && calculatedValue < 3000000
            ? `${calculatedValue.toFixed(0).substring(0, 3)}Bi`
            : calculatedValue.toFixed(0)
        }`,
      );
    } else {
      values.push(`${calculatedValue.toFixed(0)}h`);
    }
  }

  return values.reverse();
}

export { getYAxisValues };
