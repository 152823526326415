import React, { useRef, useMemo, useCallback } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { HiArrowCircleDown, HiArrowCircleUp } from 'react-icons/hi';

import { addMonths, format, subMonths } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import { Full } from '@interfaces/generic/IGeneric';
import { toHMS } from '@utils/toHMS';

import { IColors, ITimelineRow } from './types';

import { AsideItemRowStyles, IsSearching, Inner } from './styles';

interface IAsideItemRowProps {
  colors: Full<IColors>;
  selectedMonth: Date;
  item?: ITimelineRow;
  isShowContent?: boolean;
  prevMonthsQuantity?: number;
  nextMonthsQuantity?: number;
  delay?: (time: number) => Promise<unknown>;
  onClick?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}

const AsideItemRow: React.FC<IAsideItemRowProps> = ({
  colors,
  selectedMonth,
  item,
  isShowContent = false,
  prevMonthsQuantity = 1,
  nextMonthsQuantity = 1,
  delay,
  onClick,
  onPrev,
  onNext,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const transformHours = useCallback((hours: number) => {
    if (hours === 0) return '00h';

    const parsedHours = toHMS(hours);

    const parsedArray = parsedHours.split(':');

    const H = parsedArray[0];
    const M = parsedArray[1];
    // const S = parsedArray[2];

    return `${H}h ${M}m`;
  }, []);

  const handleClick = useCallback(async () => {
    if (!item) return;

    if (
      item.variation === 'PROJECT' ||
      item.variation === 'USER' ||
      item.variation === 'EMPTY'
    )
      return;

    if (buttonRef.current) {
      buttonRef.current.disabled = true;

      buttonRef.current.classList.remove('loading');
      buttonRef.current.classList.add('loading');
    }

    if (delay) await delay(500);

    if (onClick) onClick();
  }, [delay, item, onClick]);

  const variations = useMemo(() => {
    const parsedProject = { start: '', end: '', hours: '' };
    const parsedUser = { hours: '' };

    if (item) {
      parsedProject.start = format(item.startDate, 'dd MMM yyyy', { locale });
      parsedProject.end = format(item.endDate, 'dd MMM yyyy', { locale });

      if (item.variation === 'PROJECT') {
        const projectHours = item.summary_activities
          .filter(activity => activity.project_id === item.id)
          .reduce((acc, activity) => {
            const summed = acc + activity.original_worked_hours;
            return summed;
          }, 0);

        parsedProject.hours = transformHours(projectHours);
      }

      if (item.variation === 'USER') {
        const userHours = item.summary_activities
          .filter(activity => activity.user_id === item.id)
          .reduce((acc, activity) => {
            const summed = acc + activity.original_worked_hours;
            return summed;
          }, 0);

        parsedUser.hours = transformHours(userHours);
      }
    }

    return {
      HEADER: (
        <AsideItemRowStyles.Content variation="HEADER" colors={colors}>
          <strong>Projetos</strong>

          <AsideItemRowStyles.Selector>
            {onPrev && (
              <AsideItemRowStyles.SelectorArrow
                colors={colors}
                className="left"
              >
                <FiChevronLeft size={24} onClick={onPrev} />
              </AsideItemRowStyles.SelectorArrow>
            )}

            <AsideItemRowStyles.SelectorMonths colors={colors}>
              <div>
                <strong>
                  {format(subMonths(selectedMonth, prevMonthsQuantity), 'MMM', {
                    locale,
                  })}
                </strong>
                <strong>
                  {format(
                    subMonths(selectedMonth, prevMonthsQuantity),
                    'yyyy',
                    { locale },
                  )}
                </strong>
              </div>

              <strong> - </strong>

              <div>
                <strong>
                  {format(addMonths(selectedMonth, nextMonthsQuantity), 'MMM', {
                    locale,
                  })}
                </strong>
                <strong>
                  {format(
                    addMonths(selectedMonth, nextMonthsQuantity),
                    'yyyy',
                    { locale },
                  )}
                </strong>
              </div>
            </AsideItemRowStyles.SelectorMonths>

            {onNext && (
              <AsideItemRowStyles.SelectorArrow
                colors={colors}
                className="right"
              >
                <FiChevronRight size={24} onClick={onNext} />
              </AsideItemRowStyles.SelectorArrow>
            )}
          </AsideItemRowStyles.Selector>
        </AsideItemRowStyles.Content>
      ),
      PROJECT: (
        <AsideItemRowStyles.Content variation="PROJECT" colors={colors}>
          <div>
            <strong>{item?.name}</strong>

            <span>
              {parsedProject.start} - {parsedProject.end}
            </span>
          </div>

          {isShowContent && <span>{parsedProject.hours}</span>}
        </AsideItemRowStyles.Content>
      ),
      USER: (
        <AsideItemRowStyles.Content variation="USER" colors={colors}>
          <div>
            <strong>{item?.name}</strong>

            {!isShowContent && (
              <AsideItemRowStyles.ColorPicker
                colors={colors}
                color={item?.color}
              />
            )}
          </div>

          {isShowContent && <span>{parsedUser.hours}</span>}
        </AsideItemRowStyles.Content>
      ),
      EMPTY: (
        <AsideItemRowStyles.Content variation="EMPTY" colors={colors}>
          <div />
        </AsideItemRowStyles.Content>
      ),
      LOADING: (
        <AsideItemRowStyles.Content variation="LOADING" colors={colors}>
          <div />
        </AsideItemRowStyles.Content>
      ),
      LOAD_PREV: (
        <AsideItemRowStyles.Content variation="LOAD_PREV" colors={colors}>
          <span>
            Carregar anteriores
            <div className="loading-component">
              <IsSearching>
                <Inner className="one" />

                <Inner className="two" />

                <Inner className="three" />
              </IsSearching>
            </div>
          </span>

          <div>
            <HiArrowCircleUp size={24} />
          </div>
        </AsideItemRowStyles.Content>
      ),
      LOAD_NEXT: (
        <AsideItemRowStyles.Content variation="LOAD_NEXT" colors={colors}>
          <span>
            Carregar mais
            <div className="loading-component">
              <IsSearching>
                <Inner className="one" />

                <Inner className="two" />

                <Inner className="three" />
              </IsSearching>
            </div>
          </span>

          <div>
            <HiArrowCircleDown size={24} />
          </div>
        </AsideItemRowStyles.Content>
      ),
    };
  }, [
    item,
    colors,
    onPrev,
    selectedMonth,
    prevMonthsQuantity,
    nextMonthsQuantity,
    onNext,
    isShowContent,
    transformHours,
  ]);

  return (
    <AsideItemRowStyles.Container
      ref={buttonRef}
      type="button"
      colors={colors}
      onClick={handleClick}
      className={String(item?.variation)}
    >
      {variations[item?.variation || 'HEADER']}
    </AsideItemRowStyles.Container>
  );
};

const Component = React.memo(AsideItemRow, (prev, next) =>
  Object.is(prev, next),
);

export { Component as AsideItemRow };
