import styled, { css } from 'styled-components';

import { Form } from '@contexts/ReactFormContext';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      padding: 0;

      > div:first-of-type {
        padding: 24px;

        button {
          span {
            display: none;
          }
        }
      }
    }
  `,
  Organograms: css`
    @media (max-width: 768px) {
      padding: 24px;
    }
  `,
};

export const Container = styled.div`
  height: 100%;
  width: 100%;

  > div:first-of-type {
    padding: 24px 48px;
  }

  ${responsive.Container}
`;

export const Organograms = styled.div`
  /* height: calc(100% - 195px); */
  height: calc(100% - 154px);

  overflow: scroll;

  padding: 32px 0 32px 48px;

  display: flex;
  gap: 32px;
  flex: 1;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${responsive.Organograms}
`;

export const Unform = styled(Form)`
  width: 50%;

  display: grid;
  gap: 24px;
  grid-template-columns: 260px 460px;
`;
