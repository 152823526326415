import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    position: relative;

    .components-atoms-tooltip-container::before,
    .components-atoms-tooltip-container::after {
      border-bottom-color: ${theme.colors.activities.info};
      bottom: calc(100% - 0px);
    }

    .components-atoms-tooltip-container::before {
      border-bottom-color: ${theme.colors.activities.info};
      bottom: calc(100% + 1px);
    }

    .components-atoms-tooltip-container::after {
      border-bottom-color: ${theme.colors.background};
      bottom: calc(100% - 1px);
    }

    .components-atoms-tooltip-container {
      background-color: ${theme.colors.background};

      border: 1px solid #00b0ff;
      border-radius: 4px;
    }
  `}
`;

export const Content = styled.div`
  ${() => css`
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    > div:last-of-type {
      strong {
        white-space: nowrap;
      }
    }

    & {
      margin-top: 8px;
    }

    &:first-child {
      margin-top: 0px;
    }
  `}
`;
