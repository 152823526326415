export const colors = {
  background: '#0A192F',
  asideMenu: '#334B7A',
  cards: '#292F4D',
  cardsBorder: '#00B1FF',
  asideMenuButton: '#292F4D',
  lines: '#292F4D',
  white: '#FFFFFF',
  black: '#232323',
  timeline: {
    normalDay: '#232b47',
    lines: '#0a192f',
    weekendDay: '#29385c',
  },
  input: {
    normal: { text: '#373D5D', border: '#b1c5dd', label: '#6C87BC' },
    focused: { text: '#373D5D', border: '#00B1FF', label: '#292f4d' },
    filled: { text: '#FFFFFF', border: '#FFFFFF', label: '#292f4d' },
    errored: { text: '#FFFFFF', border: '#FF6C70', label: '#FF6C70' },
    disabled: { text: '#FFFFFF', border: '#0A192F', label: '#0A192F' },
  },
  chart: {
    blue: {
      100: '#5CE9E2',
      500: '#00BCD4',
      900: '#006E98',
    },
    green: {
      100: '#82EB90',
      500: '#31BF60',
      900: '#188954',
    },
    orange: {
      100: '#FFC566',
      500: '#FF8800',
      900: '#B75000',
    },
    rose: {
      100: '#FF959E',
      500: '#FF4081',
      900: '#B7276C',
    },
    purple: {
      100: '#BA68C8',
      500: '#9C27B0',
      900: '#7B1FA2',
    },
    darkBlue: {
      100: '#8D9EE9',
      500: '#3F51B8',
      900: '#1F2B84',
    },
  },
  hours: {
    productive: '#15C8AC',
    passive: '#FFC400',
    unproductive: '#FF6C70',

    undefined: '#607D8B',
    justify: '#00957C',
    empty: '#0A192F',
  },
  activities: {
    success: '#15C8AC',
    warning: '#FFC400',
    danger: '#FF6C70',

    info: '#00B0FF',
    hours: '#7C4DFF',
    selection: '#6C87BF',

    tagsWithoutAction: '#0A192F',
    tagsWithAction: '#334B7A',
    areas: '#292F4D',
  },
  text: {
    default: '#292F4D',
    title: '#FFFFFF',
    invertedTitle: '#292F4D',
    subtitle: '#92ABD8',
    links: '#00B0FF',

    input: '#666A88',
    completed: '#15C8AC',
    delayed: '#FF6C70',
  },
  shadow: 'rgba(0, 0, 0, 0.4)',
  emptyLoading15DEG:
    'linear-gradient(15deg, rgba(41, 47, 77, 0), rgba(41, 47, 77, 0.5) 50%, rgba(41, 47, 77, 0) 80%)',
  emptyLoading100DEG:
    'linear-gradient(100deg, rgba(41, 47, 77, 0), rgba(41, 47, 77, 0.5) 50%, rgba(41, 47, 77, 0) 80%)',
};
