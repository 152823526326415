import { AxiosResponse } from 'axios';

import {
  IAuthRequest,
  IAuthResponse,
  IChangePasswordRequest,
  IForgotPasswordRequest,
} from '@interfaces/IAuthentication';
import { api } from '@services/api';

export async function login({
  email,
  password,
}: IAuthRequest): Promise<AxiosResponse<IAuthResponse>> {
  const response = await api.post<IAuthResponse>('/auth', { email, password });

  api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

  return response;
}

export async function forgotPassword({
  token,
  new_password,
  repeat_password,
}: IForgotPasswordRequest): Promise<void> {
  await api.put('/auth/forgot_password', {
    token,
    new_password,
    repeat_password,
  });
}

export async function changePassword({
  old_password,
  new_password,
  new_password_confirmation,
}: IChangePasswordRequest): Promise<void> {
  await api.put<IAuthResponse>('/auth/change_password', {
    old_password,
    new_password,
    new_password_confirmation,
  });
}
