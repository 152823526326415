import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { Form } from '@contexts/ReactFormContext';
import { ITheme } from '@interfaces/generic/ITheme';
import { customScroll } from '@styles/global';

export const ModalContent = styled.div`
  width: 480px;

  position: relative;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${() => css`
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const ProjectInfo = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
      }
    }
  `}
`;

export const Statistic = styled.div`
  width: 260px;

  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatisticInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      white-space: nowrap;
    }
  `}
`;

export const StatisticInfoBadge = styled.div`
  ${({ theme }) => css`
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > div {
      color: ${theme.colors.background};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
    }
  `}
`;

interface IStatisticBarProps {
  percentage: number;
  color: string;
}

export const StatisticBar = styled.div<IStatisticBarProps>`
  ${({ theme, color, percentage }) => css`
    width: 100%;
    height: 4px;

    background: ${theme.colors.background};
    border-radius: ${theme.borders.radii[500]};

    position: relative;

    &:after {
      content: '';

      width: ${percentage > 100 ? 100 : percentage}%;
      height: 6px;

      background: ${color};
      border-radius: ${theme.borders.radii[500]};

      position: absolute;
      left: 0;
      top: -1px;
    }
  `}
`;

export const Checklists = styled.div`
  ${() =>
    css`
      max-height: 500px;

      padding: 24px 24px 80px 24px;
      margin-right: 8px;

      overflow-y: scroll;

      ${customScroll()}
    `}
`;

export const GroupUnform = styled(Form)`
  ${({ theme }) => css`
    border-radius: ${theme.borders.radii[500]};
    background: #29385b;
    box-shadow: 0 0 24px ${theme.colors.shadow};

    margin-bottom: 16px;

    transition: all 400ms;
  `}
`;

export const FormFields = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borders.radii[500]};

    padding: 24px 16px;

    gap: 48px;
    display: grid;
    grid-template-columns: 36% 1fr 1fr 10%;

    transition: all 400ms;

    > div {
      strong {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
      }

      p {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.small};
      }
    }
  `}
`;

export const Chevrons = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-self: flex-end;
  `}
`;

export const FormActions = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.background};
    border-bottom-left-radius: ${theme.borders.radii[500]};
    border-bottom-right-radius: ${theme.borders.radii[500]};

    padding: 16px;

    gap: 16px;
    display: flex;
    align-items: center;
  `}
`;

interface IChecklistProps {
  isOpen?: boolean;
}

const checklistVariations = (theme: ITheme) => ({
  opened: css`
    box-shadow: 0 0 24px ${theme.colors.shadow};

    > form {
      height: initial;

      opacity: 1;
      visibility: visible;
    }

    > div:nth-of-type(2) {
      height: initial;

      opacity: 1;
      visibility: visible;
    }
  `,
  closed: css`
    > form {
      display: none;
    }

    > div:nth-of-type(2) {
      display: none;
    }
  `,
});

export const ChecklistContainer = styled.div<IChecklistProps>`
  ${({ theme, isOpen }) => css`
    border-radius: ${theme.borders.radii[500]};
    background: #29385b;

    margin-bottom: 16px;

    cursor: pointer;

    &:hover {
      box-shadow: 0 0 24px ${theme.colors.shadow};
    }

    ${isOpen
      ? checklistVariations(theme).opened
      : checklistVariations(theme).closed}
  `}
`;

export const ChecklistBar = styled.div`
  ${({ theme }) => css`
    border: 1px dashed transparent;
    border-radius: ${theme.borders.radii[100]};

    cursor: pointer;

    &:hover {
      border: 1px dashed ${theme.colors.text.subtitle};
    }

    strong {
      color: ${theme.colors.white} !important;
    }

    > div {
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div:nth-of-type(1) {
        height: 4px;

        border-radius: ${theme.borders.radii[500]};
        background: ${theme.colors.background};

        position: relative;

        flex: 1;
        display: flex;

        div {
          height: 6px;

          border-radius: ${theme.borders.radii[500]};
          background: ${theme.colors.activities.info};

          position: absolute;
          top: -1px;
          left: 0;
        }
      }
    }
  `}
`;

export const TaskUnform = styled(Form)`
  ${({ theme }) => css`
    max-width: 755px;
    min-height: 65px;

    border-top: 1px solid ${theme.colors.background};

    padding: 16px;

    gap: 48px;
    display: grid;
    align-items: center;
    grid-template-columns: 36% 17% 1fr 10%;

    div:nth-of-type(1) {
      gap: 16px;
      display: flex;
      align-items: center;
    }

    > p {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};

        margin-top: 2px;

        display: block;
      }
    }
  `}
`;

export const TaskUnformToggle = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 36px;

    border: 1px dashed transparent;
    background: transparent;
    border-radius: ${theme.borders.radii[100]};

    color: ${theme.colors.white};

    padding: 0;

    &:hover {
      border: 1px dashed ${theme.colors.text.subtitle};
    }

    span {
      height: 100%;

      display: flex;
      align-items: center;
    }
  `}
`;

export const TaskUnformActions = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    * {
      transition: 400ms;
    }

    button {
      border: none;
      background: none;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale(1.2);
      }

      &:first-child {
        &:hover {
          path {
            stroke: ${theme.colors.activities.danger};
          }
        }
      }

      &:last-child {
        &:hover {
          path {
            stroke: ${theme.colors.activities.success};
          }
        }
      }
    }
  `}
`;

interface ICheckProps {
  isChecked: boolean;
}

export const Check = styled.button<ICheckProps>`
  ${({ theme, isChecked }) => css`
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;

    border: 1px solid ${isChecked ? theme.colors.activities.info : '#6c87bf'};
    border-radius: 4px;
    background: ${isChecked ? theme.colors.activities.info : 'none'};

    position: relative;

    &:hover {
      border-color: ${theme.colors.activities.info};
    }

    &:active {
      transform: scale(0.9);
    }

    ${isChecked &&
    css`
      &:after {
        content: '';

        width: 7px;
        height: 12px;

        border: solid white;
        border-width: 0 3px 3px 0;

        position: absolute;
        top: 1px;
        left: 6px;

        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    `}
  `}
`;

export const ChecklistsNotFound = styled.div`
  ${({ theme }) => css`
    border: 1px dashed ${theme.colors.text.subtitle};
    border-radius: ${theme.borders.radii[500]};

    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;

export const AddChecklistButton = styled.button`
  ${({ theme }) => css`
    width: 48px;
    height: 48px;

    border: none;
    border-radius: 50%;
    background: ${theme.colors.activities.info};
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);

    position: absolute;
    right: 24px;
    bottom: 24px;

    cursor: pointer;

    &:hover {
      background: ${darken(0.1, theme.colors.activities.info)};
    }
  `}
`;
