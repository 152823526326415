import React, { Dispatch, useCallback, useRef } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { Organogram } from '@assets/icons';
import { IModalRef } from '@components/organisms/Modal';
import { useClickAway } from '@hooks/useClickAway';
import { useOrganogram } from '@hooks/useOrganogram';
import { IOrganogram, IOrganogramGroup } from '@interfaces/IOrganogram';

import { Container } from './styles';

interface IGroupTooltipProps {
  organogram: IOrganogram;
  group: IOrganogramGroup;
  isOpen?: boolean;
  setMoreIsOpen: Dispatch<React.SetStateAction<boolean>>;
  subGroupRef: React.RefObject<IModalRef>;
}

const GroupTooltip: React.FC<IGroupTooltipProps> = ({
  organogram,
  group,
  isOpen = false,
  setMoreIsOpen,
  subGroupRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { onDeleteOrganogram, onDeleteSubGroup } = useOrganogram();

  const handleCloseOnClick = useCallback(() => {
    setMoreIsOpen(false);
  }, [setMoreIsOpen]);

  const handleAddSubGroup = useCallback(() => {
    subGroupRef.current?.open();

    setMoreIsOpen(false);
  }, [setMoreIsOpen, subGroupRef]);

  const handleDelete = useCallback(() => {
    if (group.type === 'group') onDeleteOrganogram(organogram.id);

    onDeleteSubGroup(organogram.id, group.id);
  }, [group, onDeleteOrganogram, onDeleteSubGroup, organogram.id]);

  useClickAway(containerRef, handleCloseOnClick, { enabled: isOpen });

  return (
    <Container ref={containerRef} isOpen={isOpen}>
      <button type="button" onClick={handleAddSubGroup}>
        <Organogram className="custom-icon" />

        <span>Adicionar Subgrupo</span>
      </button>

      <button type="button" onClick={handleDelete}>
        <FiTrash2 size={18} color="#fbfbfb" />

        <span>Excluir Grupo</span>
      </button>
    </Container>
  );
};

export { GroupTooltip as OrganogramGroupTooltip };
