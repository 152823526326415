import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { IDeliveryStatus } from '@interfaces/IDelivery';

interface IContainerProps {
  type: IDeliveryStatus;
}

const containerVariations = (theme: any) => {
  return {
    IN_PROGRESS: css`
      &:before {
        background: ${theme.colors.activities.tagsWithAction};
      }
    `,
    DELAYED: css`
      &:before {
        background: ${theme.colors.hours.unproductive};
      }
    `,
    WAITING_FOR_APPROVAL: css`
      &:before {
        background: ${theme.colors.hours.passive};
      }
    `,
    FINISHED: css`
      &:before {
        background: ${theme.colors.hours.productive};
      }
    `,
  };
};

export const Container = styled(Card).attrs({
  className: 'kan_ban_card-container',
})<IContainerProps>`
  ${({ theme, type = 'IN_PROGRESS' }) => css`
    width: 100%;
    height: 110px;
    min-height: 110px;

    position: relative;

    gap: 16px;
    display: flex;
    align-items: center;

    transition: all 400ms;

    &:hover {
      background: ${darken(0.05, theme.colors.cards)};

      cursor: pointer;
    }

    &:before {
      content: '';

      width: 36px;
      height: 100%;

      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 40px;
      height: 40px;

      border-radius: 50%;

      z-index: 1;
    }

    ${containerVariations(theme)[type]}
  `}
`;

type ICardInfoProps = IContainerProps;

const cardInfoVariations = (theme: any) => {
  return {
    IN_PROGRESS: css`
      p {
        color: #b1c5dd;
      }
    `,
    DELAYED: css`
      p {
        color: ${theme.colors.hours.unproductive};
      }
    `,
    WAITING_FOR_APPROVAL: css`
      p {
        color: ${theme.colors.hours.passive};
      }
    `,
    FINISHED: css`
      p {
        color: ${theme.colors.hours.productive};
      }
    `,
  };
};

export const CardInfo = styled.div.attrs({
  className: 'kan_ban_card-card_info',
})<ICardInfoProps>`
  ${({ theme, type }) => css`
    height: 100%;

    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
    }

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
    }

    p {
      color: #b1c5dd;
      font-size: ${theme.typography.fontSize.small};
    }

    ${cardInfoVariations(theme)[type]}
  `}
`;
