import React, { createContext, useMemo, useState, useCallback } from 'react';

import { initialMenu } from '@constants/menu';
import { IRoleAlias } from '@interfaces/IRole';

export type IMenuItem = {
  icon: any;
  text: string;
  isChecked: boolean;
  labels?: { active: string; inactive: string };
  url: string;
  roles: IRoleAlias[];
  isCommingSoon: boolean;
  className?: string;
};

export interface IHeaderContext {
  dispatch: boolean;
  currentMenu: number;
  setCurrentMenu: React.Dispatch<React.SetStateAction<number>>;
  menu: IMenuItem[];
  setMenu: React.Dispatch<React.SetStateAction<IMenuItem[]>>;
  customHeader: JSX.Element | undefined;
  setCustomHeader: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
  getCurrentInfo: () => IMenuItem;
  onSwitch: (menuText: string) => void;
  onDispatch: () => void;
}

const HeaderContext = createContext<IHeaderContext>({} as IHeaderContext);

const HeaderProvider: React.FC = ({ children }) => {
  const [menu, setMenu] = useState<IMenuItem[]>(initialMenu);
  const [dispatch, setDispatch] = useState<boolean>(false);
  const [customHeader, setCustomHeader] = useState<JSX.Element>();

  const [currentMenu, setCurrentMenu] = useState<number>(() => {
    const index = menu.findIndex(item => item.url === window.location.pathname);

    return index < 0 ? 0 : index;
  });

  const getCurrentInfo = useCallback(
    () => menu[currentMenu],
    [currentMenu, menu],
  );

  const onDispatch = useCallback(() => setDispatch(state => !state), []);

  const onSwitch = useCallback((menuText: string) => {
    setMenu(state => {
      return state.map(item =>
        item.text === menuText ? { ...item, isChecked: !item.isChecked } : item,
      );
    });
  }, []);

  const value: IHeaderContext = useMemo(
    () => ({
      dispatch,
      currentMenu,
      setCurrentMenu,
      menu,
      setMenu,
      customHeader,
      setCustomHeader,
      getCurrentInfo,
      onDispatch,
      onSwitch,
    }),
    [
      dispatch,
      currentMenu,
      menu,
      customHeader,
      getCurrentInfo,
      onDispatch,
      onSwitch,
    ],
  );

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
