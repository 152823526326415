import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'toasts-container',
})`
  width: 100%;

  padding: 30px;

  position: absolute;
  top: 0;

  gap: 16px;

  overflow: hidden;

  pointer-events: none;

  z-index: 10000;
`;
