import styled, { css } from 'styled-components';

export const Figure = styled.figure<{
  productivity?: string;
}>`
  ${({ theme, productivity }) => css`
    width: 254px;
    height: 190px;
    padding: 5px;
    border-radius: 10px;

    position: relative;

    gap: 32px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.cards};
    border: 1px SOLID ${theme.colors.cardsBorder}60;

    transition-duration: 150ms;
    box-shadow: 0 0 0 ${theme.colors.cardsBorder}00;

    // :hover {
    //   border: 1px SOLID #ffffff80;
    //   box-shadow: 0 0 10px ${theme.colors.cardsBorder}ff;
    // }

    :before {
      overflow: hidden;
      content: attr(title);
      text-overflow: ellipsis;
      width: 234px;
      height: ${theme.typography.lineHeight.extraSmall};
      line-height: ${theme.typography.lineHeight.extraSmall};
      padding: 0 5px;

      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      border-radius: 5px 5px 0 0;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      background-color: ${productivity === 'PRODUCTIVE'
        ? theme.colors.activities.success
        : productivity === 'UNPRODUCTIVE'
        ? theme.colors.activities.danger
        : theme.colors.activities.warning};

      font-family: ${theme.typography.fontFamily};
      color: ${theme.colors.text.default};
      font-size: ${theme.typography.fontSize.extraSmall};
      font-weight: bold;
      letter-spacing: 0.6px;
    }
  `}
`;

export const FigCaption = styled.figcaption<{
  shotTime?: string;
}>`
  ${({ theme, shotTime }) => css`
    width: 244px;
    height: calc(180px - (${theme.typography.lineHeight.extraSmall} + 146px));
    line-height: ${theme.typography.lineHeight.extraSmall};
    display: block;

    position: absolute;
    top: calc(151px + ${theme.typography.lineHeight.extraSmall});
    left: 5px;
    z-index: 2;
    border-radius: 0 0 5px 5px;

    align-items: center;
    justify-content: flex-start;

    font-family: ${theme.typography.fontFamily};
    color: ${theme.colors.text.title};
    font-size: 0.5rem;
    font-weight: bold;
    letter-spacing: 0.6px;

    :after {
      content: '${shotTime}';
      display: block;
      width: 244px;
      height: ${theme.typography.lineHeight.extraSmall};
      line-height: ${theme.typography.lineHeight.extraSmall};
    }

    > svg {
      fill: ${theme.colors.text.title};
      width: ${theme.typography.lineHeight.extraSmall};
      height: ${theme.typography.lineHeight.extraSmall};
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  `}
`;

export const Image = styled.img<{
  thumb?: string;
}>`
  ${({ thumb }) => css`
    position: absolute;
    width: 244px;
    height: 146px;
    border-radius: 0 0 5px 5px;
    top: 18px;
    left: 5px;
    z-index: 1;
    background: transparent url(${thumb}) left center no-repeat;
    background-size: cover;
    transition-duration: 600ms;
    transition-timing-function: ease;
    :hover {
      background-position-x: right;
      cursor: pointer;
    }
  `}
`;
