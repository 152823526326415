import React, { useMemo } from 'react';

import { Badge } from '@components/atoms';
import { ProjectHelper } from '@helpers/ProjectHelper';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { IProject } from '@interfaces/IProject';
import { toCurrency } from '@utils/toCurrency';

import { Container, Item } from './styles';

interface IProjectTooltipProps {
  project: IProject;
}

const ProjectTooltip: React.FC<IProjectTooltipProps> = ({ project }) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const cost = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: Number(budget.cost.percentage).toFixed(2),
      spent: toCurrency(budget.cost.spent),
      planned: toCurrency(budget.cost.planned),
    };
  }, [project]);

  const hours = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: Number(budget.hours.percentage).toFixed(2),
      spent: `${budget.hours.spent.toFixed()} h`,
      planned: `${budget.hours.planned} h`,
    };
  }, [project]);

  const checklist = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: Number(budget.checklist.percentage).toFixed(2),
      spent: `${budget.checklist.spent.toFixed()}`,
      planned: `${budget.checklist.planned} pontos`,
    };
  }, [project]);

  return (
    <Container>
      <Item type="COST">
        <div>
          <strong>Custo</strong>

          <p>{cost.spent}</p>
        </div>

        <Badge color={colors.hours.productive}>{cost.percentage}%</Badge>
      </Item>

      <Item type="HOURS">
        <div>
          <strong>Horas</strong>

          <p>{hours.spent}</p>
        </div>

        <Badge color={colors.activities.hours}>{hours.percentage}%</Badge>
      </Item>

      <Item type="CHECKLIST">
        <div>
          <strong>Checklist</strong>

          <p>{checklist.spent} pts</p>
        </div>

        <Badge color={colors.activities.info}>{checklist.percentage}%</Badge>
      </Item>
    </Container>
  );
};

export { ProjectTooltip };
