import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';

interface IContainerProps {
  isSelected: boolean;
}

const containerCss = (theme: ITheme) => ({
  select: css`
    background: ${theme.colors.input.focused.label};
  `,
  selected: css`
    max-width: 150px;

    background: ${theme.colors.input.filled.label};

    span {
      text-overflow: ellipsis;
      white-space: nowrap;

      overflow: hidden;
    }
  `,
});

export const Container = styled.div<IContainerProps>`
  ${({ theme, isSelected }) => css`
    width: fit-content;
    height: fit-content;

    border-radius: 4px;

    font-family: Roboto, Poppins, Archivo, sans-serif;

    padding: 2px 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${isSelected ? containerCss(theme).selected : containerCss(theme).select}

    &:hover {
      background: #373d5d;
    }

    span {
      color: ${theme.colors.input.filled.text};
      font-size: 12px;
      line-height: initial;

      margin-right: 6px;
      padding: 2px 0;

      user-select: none;
    }

    button {
      border: 0;
      background: transparent;

      margin: 0;
      padding: 0;

      transition: all 200ms;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        filter: brightness(1.4);
        transform: scale(1.2);
      }

      svg {
        cursor: pointer;
      }
    }
  `}
`;
