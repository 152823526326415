import React, { useRef, useState, useCallback } from 'react';

import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { useClickAway } from '@hooks/useClickAway';
import { IProject, ISummaryActivity } from '@interfaces/IProject';
import { ProjectsService } from '@services/apis/ProjectsService';

import { Container, Results, ResultItem } from './styles';

interface ISearchProps {
  activities: ISummaryActivity[];
  onSelect: (project: IProject) => void;
}

const Search: React.FC<ISearchProps> = ({ activities, onSelect }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<IProject[] | undefined>(undefined);

  const handleSearch = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setResults([]);

      const response = await ProjectsService.search({
        q: [
          {
            property: 'name',
            operator: 'LIKE',
            value: e.target.value,
            rule: 'AND',
            entity: 'projects',
          },
        ],
        page: 1,
        limit: 0,
        sort: [{ property: 'name', order: 'ASC' }],
      });

      const alreadyAssociatedIds = activities.map(i => i.project_id);

      const filteredResults = response.filter(
        i => !alreadyAssociatedIds.includes(i.id),
      );

      setResults(filteredResults);
      setIsLoading(false);
    },
    [activities],
  );

  useClickAway(
    containerRef,
    () => {
      setIsLoading(false);
      setResults(undefined);
    },
    { enabled: !isLoading },
  );

  return (
    <Container ref={containerRef}>
      <input onChange={debounce(handleSearch, 500)} autoFocus />
      {results && (
        <Results>
          {results.length === 0 && !isLoading ? (
            <ResultItem type="button">Nenhum resultado encontrado!</ResultItem>
          ) : results.length === 0 && isLoading ? (
            <ResultItem type="button">Carregando</ResultItem>
          ) : (
            results.map(project => {
              return (
                <ResultItem
                  key={v4()}
                  type="button"
                  onClick={() => onSelect(project)}
                >
                  {project.name}
                </ResultItem>
              );
            })
          )}
        </Results>
      )}
      {/* {results &&
        (results.length === 0 ? (
          <Results>
            {isLoading ? (
              <ResultItem type="button">Carregando</ResultItem>
            ) : (
              results.map(project => {
                return (
                  <ResultItem
                    key={v4()}
                    type="button"
                    onClick={() => onSelect(project)}
                  >
                    {project.name}
                  </ResultItem>
                );
              })
            )}
          </Results>
        ) : (
          <Results>
            <ResultItem type="button">Nenhum resultado encontrado!</ResultItem>
          </Results>
        ))} */}
    </Container>
  );
};

export { Search };
