import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';
import { customScroll } from '@styles/global';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled?: boolean;
  mode?: 'light' | 'dark';
  minHeight?: string;
  maxHeight?: string;
}

const containerVariations = (theme: ITheme) => ({
  errored: css`
    border-color: ${theme.colors.input.errored.border};

    transition: none;

    svg {
      color: ${theme.colors.input.errored.border};
    }
  `,
  focused: css`
    height: 36px !important;
    min-height: 36px !important;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
    border-color: ${theme.colors.input.focused.border};

    padding: 0 8px;

    &:after {
      content: '';

      width: 100.6%;
      height: 2px;

      background: ${theme.colors.input.focused.border};

      position: absolute;
      bottom: -2px;
      left: -2px;

      z-index: 10000;
    }

    input {
      height: 80%;

      position: absolute;
      bottom: 0;
    }

    > div:nth-of-type(2) {
      position: relative;

      > span {
        display: none;
      }
    }
  `,
  filled: css`
    min-height: fit-content;

    border-color: ${theme.colors.input.filled.border};

    align-content: flex-start;

    transition: none;
  `,
  disabled: css`
    border-color: ${theme.colors.input.disabled.border};
    background: transparent;

    color: ${theme.colors.input.disabled.text};

    input::placeholder {
      color: ${theme.colors.input.disabled.text};
    }
  `,
  light: css`
    color: #232323;
  `,
  dark: css`
    color: ${theme.colors.input.disabled.text};

    select:disabled ~ div {
      color: #ffffff;
    }
  `,
});

export const Container = styled.div<IContainerProps>`
  ${({
    theme,
    isErrored,
    isFocused,
    isFilled,
    disabled,
    mode,
    minHeight,
    maxHeight,
  }) => css`
    width: 100%;
    min-height: ${minHeight || '184px'};
    max-height: ${maxHeight || '220px'};

    border-radius: ${theme.borders.radii[100]};
    border: 2px solid ${theme.colors.input.normal.border};
    background: ${theme.colors.white};

    color: ${theme.colors.input.normal.text};
    font-family: Roboto, Poppins, Archivo, sans-serif;

    padding: 0 4px 0 8px;

    position: relative;

    cursor: text;

    transition: all 400ms;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    box-sizing: border-box;

    select {
      width: 100%;
      height: 80%;

      border: 0;
      outline: 0;
      background: transparent;

      color: ${theme.colors.input.normal.text};
      font-size: 14px;

      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      cursor: pointer;

      flex: 1;

      display: none;
    }

    ${isFilled && containerVariations(theme).filled}
    ${isFocused && containerVariations(theme).focused}
    ${disabled && containerVariations(theme).disabled}
    ${isErrored && !isFilled && containerVariations(theme).errored}
    ${mode === 'light'
      ? containerVariations(theme).light
      : containerVariations(theme).dark}
  `}
`;

interface ILabelContainer {
  isFocused: boolean;
  isFilled: boolean;
  disabled?: boolean;
}

const labelContainerVariations = (theme: ITheme) => ({
  show: css`
    top: -11px;
    left: 8px;

    opacity: 1 !important;
    visibility: visible !important;
  `,
  hide: css`
    top: 0;
    left: 8px;

    opacity: 0 !important;
    visibility: hidden !important;
  `,
  focused: css`
    background: ${theme.colors.input.focused.label};

    color: ${theme.colors.input.focused.text};
  `,
  filled: css`
    border: ${theme.colors.input.filled.border};
    background: ${theme.colors.input.filled.label};

    color: ${theme.colors.input.filled.text};
  `,
  disabled: css`
    border: ${theme.colors.input.disabled.border};
    background: ${theme.colors.input.disabled.label};

    color: ${theme.colors.input.disabled.text};
  `,
});

export const LabelContainer = styled.div<ILabelContainer>`
  ${({ theme, isFocused, isFilled, disabled }) => css`
    background: ${theme.colors.input.normal.label};

    color: ${theme.colors.text.title};
    font-size: 12px;
    font-weight: normal;
    line-height: initial;

    padding: 2px 8px;

    position: absolute;

    transition: all 400ms;

    display: flex;
    align-items: center;
    justify-content: center;

    ${isFocused
      ? labelContainerVariations(theme).show
      : labelContainerVariations(theme).hide}
    ${isFilled && labelContainerVariations(theme).filled}
    ${disabled && labelContainerVariations(theme).disabled}
    ${disabled && labelContainerVariations(theme).show}
    ${isFilled && labelContainerVariations(theme).show}
  `}
`;

export const ErrorMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.input.errored.border};
    font-size: 14px;
    font-weight: normal;

    position: absolute;
    bottom: -24px;
    left: 0;
  `}
`;

interface ITagsListProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const TagsList = styled.div<ITagsListProps>`
  ${({ isFocused, isFilled }) => css`
    height: 100%;
    max-height: 190px;
    width: 100%;

    margin-right: 8px;

    padding-top: ${isFocused || isFilled ? '10px' : '0px'};

    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    align-self: flex-end;
    gap: 8px;

    ${customScroll()}
  `}
`;

export const SelectOption = styled.span`
  color: #858585;
  font-size: 14px;
  line-height: 26px;

  cursor: pointer;

  user-select: none;

  z-index: 8;
`;

export const Input = styled.input`
  height: 26px;

  border: 0;
  outline: 0;
  background: transparent;

  font-size: 14px;
  line-height: 38px;

  flex: 1;
`;

interface IOptionsContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isFilled: boolean;
}

const optionsContainerVariations = (theme: ITheme) => ({
  show: css`
    top: 38px;

    opacity: 1 !important;
    visibility: visible !important;

    transition: all 200ms;
  `,
  hide: css`
    opacity: 0 !important;
    visibility: hidden !important;

    transition: none;

    * {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    /* display: none; */
  `,
  errored: css`
    border-color: ${theme.colors.input.errored.border} !important;

    transition: none;
  `,
});

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  ${({ theme, isErrored, isFocused, isFilled }) => css`
    width: 100%;
    height: fit-content;
    max-height: 166px;

    outline: 2px solid ${theme.colors.activities.info};
    border-bottom-right-radius: ${theme.borders.radii[100]};
    border-bottom-left-radius: ${theme.borders.radii[100]};
    background: ${theme.colors.activities.info};
    box-shadow: 0 8px 8px rgb(0 0 0 / 60%);

    position: absolute;
    top: 32px;
    left: 0;

    overflow-y: scroll;

    transition: all 200ms;

    z-index: 10;

    &::-webkit-scrollbar {
      width: 0;
    }

    ${isErrored && !isFilled && optionsContainerVariations(theme).errored}
    ${isFocused
      ? optionsContainerVariations(theme).show
      : optionsContainerVariations(theme).hide}
  `}
`;

interface IOptionProps {
  isSelected: boolean;
  isSearched: boolean;
  isMultiple?: boolean;
  mode?: 'light' | 'dark';
}

const optionCss = (theme: ITheme) => ({
  selected: css`
    color: ${theme.colors.activities.info};

    span {
      color: ${theme.colors.activities.info} !important;
      font-weight: bold;
    }
  `,
  selectedHover: css`
    color: ${theme.colors.input.disabled.text};

    span {
      color: ${theme.colors.input.disabled.text} !important;
      font-weight: bold;
    }
  `,
  multipleSelected: css`
    > div {
      background: #373d5d;

      color: ${theme.colors.white};
    }
  `,
  searched: css`
    span {
      color: ${theme.colors.activities.info} !important;
    }
  `,
  light: css`
    color: ${theme.colors.input.normal.text};

    > span {
      color: ${theme.colors.input.normal.text} !important;
    }
  `,
  dark: css`
    color: #ffffff;
  `,
});

export const Option = styled.div<IOptionProps>`
  ${({ theme, isSearched, isSelected, isMultiple, mode = 'light' }) => css`
    background: #ffffff;

    font-size: 14px;

    padding: 8px;

    position: relative;

    z-index: 10;

    user-select: none;

    cursor: pointer;

    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;

    &:last-of-type {
      border-bottom-right-radius: ${theme.borders.radii[100]};
      border-bottom-left-radius: ${theme.borders.radii[100]};
    }

    &:hover {
      ${!isSearched && isMultiple && optionCss(theme).searched}

      ${isSelected &&
      css`
        > div {
          color: #6c87bc;
          border-color: #6c87bc !important;
          background-color: #6c87bc;
        }

        > span {
          color: #6c87bc !important;
        }
      `}

      > div {
        border-color: ${theme.colors.activities.info};
      }
    }

    ${isSelected && optionCss(theme).selected}
    ${mode === 'light' ? optionCss(theme).light : optionCss(theme).dark}
  `}
`;

interface ICheckboxProps {
  isSelected: boolean;
}

export const Checkbox = styled.div<ICheckboxProps>`
  ${({ theme, isSelected }) => css`
    width: 16px;
    height: 16px;

    border: 1px solid ${isSelected ? theme.colors.activities.info : '#6C87BC'};
    border-radius: 4px;
    background: ${isSelected
      ? theme.colors.activities.info
      : theme.colors.white};

    position: relative;

    &:after {
      content: '';

      width: 4px;
      height: 7px;

      border: solid #ffffff;
      border-width: 0 3px 3px 0;

      transition: all 400ms;
    }

    ${isSelected
      ? css`
          &:after {
            position: absolute;
            top: 50%;
            left: 50%;

            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: translateY(-64%) translateX(-50%) rotate(45deg);
          }
        `
      : css`
          &:after {
            top: 0;

            opacity: 0 !important;

            transition: none;
          }
        `}
  `}
`;

export const NotFound = styled.div`
  background: #ffffff;

  padding: 8px;

  span {
    color: #232323;
    font-size: 14px;
  }
`;
