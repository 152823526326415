import { useEffect, useState } from 'react';

import { IRoleAlias } from '@interfaces/IRole';
import { IUser } from '@interfaces/IUser';
import { getUserLogged } from '@utils/getUserLogged';

import { useAuth } from './useAuth';

interface IUserCanProps {
  permissions: string[];
  roles: IRoleAlias[];
}

function useCan({ roles }: IUserCanProps) {
  const { signed } = useAuth();

  const [user, setUser] = useState<IUser>(getUserLogged().user);

  useEffect(() => setUser(getUserLogged().user), []);

  const userRoleAlias = user?.users_roles?.map(ur => ur.role.alias) || [];

  if (!signed) return false;

  if (roles.length > 0) {
    const hasAllRoles = roles.some(role => userRoleAlias.includes(role));

    if (!hasAllRoles) return false;
  }

  return true;
}

export { useCan };
