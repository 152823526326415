import { useContextSelector } from 'use-context-selector';

import { ThemeContext } from '@contexts/ReactThemeContext';

interface IUseThemeProps {
  prop: 'theme' | 'colors' | 'toggleTheme';
}

function useTheme<T>({ prop }: IUseThemeProps): T {
  const context = useContextSelector(ThemeContext, data => data[prop]);

  return context as unknown as T;
}

export { useTheme };
