import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';
import { customScroll } from '@styles/global';

import { IProps } from '../types';

import { alertVariations } from '../styles';

const responsive = {
  Container: css<IContainerProps>`
    ${({ isOpen }) => css`
      @media screen and (max-width: 768px) {
        width: ${isOpen ? '100vw' : '0px'};
      }

      @media screen and (max-width: 280px) {
        width: ${isOpen ? '100vw' : '0px'};
      }
    `}
  `,
  Overlay: css`
    @media screen and (max-width: 768px) {
      display: none;
    }
  `,
  Header: css`
    ${() => css`
      @media screen and (max-width: 280px) {
        padding: 16px;
      }
    `}
  `,
  MessagesList: css`
    ${() => css`
      @media screen and (max-width: 280px) {
        padding: 16px;
      }
    `}
  `,
};

interface IContainerProps {
  isOpen: boolean;
  alwaysOpen: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, isOpen, alwaysOpen }) => css`
    width: ${alwaysOpen ? '100vw' : isOpen ? '480px' : '0px'};
    height: 100%;

    background: ${theme.colors.background};
    box-shadow: 0px 6px 16px ${theme.colors.shadow};

    position: relative;

    display: flex;
    flex-direction: column;

    z-index: 10;

    transition: all 200ms;

    ${responsive.Container}
  `}
`;

interface IOverlayProps {
  isOpen: boolean;
  alwaysOpen: boolean;
}

export const Overlay = styled.div<IOverlayProps>`
  ${({ theme, isOpen, alwaysOpen }) => css`
    background: ${theme.colors.black};

    opacity: ${alwaysOpen ? 0.4 : isOpen ? 0.4 : 0};
    visibility: ${alwaysOpen ? 'visible' : isOpen ? 'visible' : 'hidden'};

    position: fixed;
    top: 0;
    right: ${alwaysOpen ? '100vw' : isOpen ? '480px' : '0px'};
    bottom: 0;
    left: 0;

    z-index: 1;

    transition: all 200ms;

    ${responsive.Overlay}
  `}
`;

type IHeaderProps = Pick<IProps, 'alert'>;

export const Header = styled.header<IHeaderProps>`
  ${({ theme, alert }) =>
    css`
      border: 1px solid red;
      background: ;

      padding: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        flex: 1;
        gap: 16px;
        display: flex;
        align-items: center;

        strong {
          font-size: ${theme.typography.fontSize.large};
        }
      }

      button {
        border: none;
        background: none;
      }

      ${alertVariations[alert]}
      ${responsive.Header}
    `}
`;

export const MessagesList = styled.main`
  ${({ theme }) => css`
    margin: 0 4px 0 0;
    padding: 24px;

    scroll-behavior: smooth;

    flex: 1;
    gap: 16px;
    display: flex;
    flex-direction: column;

    ${responsive.MessagesList}
    ${customScroll(theme.colors.background)}
  `}
`;

/**
 * MESSAGES
 */
const Others = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > div:first-child {
      max-width: 326px;

      border: 1px solid ${theme.colors.lines};
      border-radius: 16px 16px 16px 0;
      background: ${theme.colors.cards};

      padding: 16px;

      align-self: flex-start;
    }
  `}
`;

const Me = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > div:first-child {
      max-width: 326px;

      border: 1px solid ${theme.colors.lines};
      border-radius: 16px 16px 0 16px;
      background: ${theme.colors.cards};

      padding: 16px;

      align-self: flex-end;
    }
  `}
`;

const Interaction = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > div:first-child {
      width: 100%;
      max-width: 400px;

      border: 1px solid ${theme.colors.lines};
      border-radius: 16px 16px 0 16px;
      background: ${theme.colors.cards};

      align-self: flex-end;
    }
  `}
`;

export const Message = { Others, Me, Interaction };

interface IChatButtonProps {
  variation: 'SELECTED' | 'NOT_SELECTED' | 'NOT_SELECTED_BLUE';
}

const chatButtonVariations = (theme: ITheme, disabled?: boolean) => ({
  SELECTED: css`
    background: ${theme.colors.activities.info};
    border: 1px solid ${theme.colors.activities.info};

    span {
      color: ${theme.colors.white};
    }
  `,
  NOT_SELECTED: css`
    background: ${theme.colors.white};
    background: transparent;
    border: 1px solid ${theme.colors.activities.selection};

    ${!disabled &&
    css`
      &:hover {
        background: ${theme.colors.activities.selection};

        span {
          color: ${theme.colors.white};
        }
      }
    `}

    span {
      color: ${theme.colors.activities.selection};
    }
  `,
  NOT_SELECTED_BLUE: css`
    background: ${theme.colors.white};
    background: ${theme.colors.activities.info};
    border: 1px solid ${theme.colors.activities.selection};

    ${!disabled &&
    css`
      &:hover {
        background: ${theme.colors.activities.selection};

        span {
          color: ${theme.colors.white};
        }
      }
    `}

    span {
      color: ${theme.colors.white};
    }
  `,
});

export const ChatButton = styled.button<IChatButtonProps>`
  ${({ theme, variation, disabled }) => css`
    width: 100%;
    height: 40px;

    border-radius: ${theme.borders.radii[100]};
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    transition: all 400ms;

    user-select: none;

    &:active {
      transform: ${disabled ? 'scale(1)' : 'scale(0.9)'};
    }

    ${chatButtonVariations(theme, disabled)[variation]};
  `}
`;
