import {
  HiOutlineChartSquareBar,
  HiOutlineCog,
  HiOutlineCube,
  HiOutlineAnnotation,
  HiOutlineUserGroup,
  HiOutlineClock,
  HiOutlineDocumentText,
  HiLightBulb,
} from 'react-icons/hi';

import * as Icons from '@assets/icons';
import { IMenuItem } from '@contexts/ReactHeaderContext';

export const initialMenu: IMenuItem[] = [
  {
    icon: HiOutlineChartSquareBar,
    text: 'Painel Geral',
    labels: { active: 'Custo', inactive: 'Horas' },
    isChecked: false,
    url: '/',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
  },
  {
    icon: HiOutlineCog,
    text: 'Projetos',
    isChecked: false,
    url: '/projects',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
  },
  {
    icon: HiOutlineCube,
    text: 'Entregas',
    isChecked: false,
    url: '/deliveries',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: true,
  },
  {
    icon: HiLightBulb,
    text: 'Screenshots',
    isChecked: false,
    url: '/screenshots',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
  },
  {
    icon: HiOutlineAnnotation,
    text: 'Daily Status',
    labels: { active: 'Daily', inactive: 'Impeditivos' },
    isChecked: true,
    url: '/daily',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
  },
  {
    icon: HiOutlineUserGroup,
    text: 'Colaboradores',
    isChecked: false,
    url: '/users',
    roles: ['SUPER_ADMIN', 'MANAGER'],
    isCommingSoon: false,
  },
  {
    icon: HiOutlineClock,
    text: 'Correção de Horas',
    labels: { active: 'Total', inactive: 'Média' },
    isChecked: false,
    url: '/hours',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: true,
  },
  {
    icon: HiOutlineDocumentText,
    text: 'Relatórios',
    labels: { active: 'Projetos', inactive: 'Colaboradores' },
    isChecked: false,
    url: '/reports',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
  },
  {
    icon: Icons.Timeline,
    text: 'Timeline',
    labels: { active: 'Projetos', inactive: 'Colaboradores' },
    isChecked: false,
    url: '/timeline',
    roles: ['SUPER_ADMIN', 'MANAGER'],
    isCommingSoon: true,
    className: 'timeline',
  },
  {
    icon: Icons.Organogram,
    text: 'Grupos',
    isChecked: false,
    url: '/groups',
    roles: ['SUPER_ADMIN', 'MANAGER'],
    isCommingSoon: false,
    className: 'organogram',
  },
  {
    icon: Icons.Download,
    text: 'Downloads',
    isChecked: false,
    url: '/downloads',
    roles: ['SUPER_ADMIN', 'MANAGER', 'COLLABORATOR'],
    isCommingSoon: false,
    className: 'organogram',
  },
];
