import React, {
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import * as Yup from 'yup';

import { DatePicker, Input, Select } from '@components/atoms';
import { IOption } from '@components/atoms/Select';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { IProject } from '@interfaces/IProject';
import {
  getProjectStatus,
  updateProject,
} from '@services/apis/ProjectsService';

import { Container, FormRow } from './styles';

export interface IUpdateProjectRequest {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  project_status_id?: number;
  tags?: any[];
}

interface IUpdateProjectFormProps {
  project?: IUpdateProjectRequest;
  setProject?: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  closeModal: () => void;
}

const UpdateProjectForm: React.ForwardRefRenderFunction<
  IFormRef,
  IUpdateProjectFormProps
> = ({ project, closeModal, setProject }, ref) => {
  const [t] = useTranslation();

  const { addToast } = useToast();

  const [projectStatus, setProjectStatus] = useState<IOption[]>([]);
  const formRef = useRef<IFormHandles>(null);

  const handleSubmit = useCallback(
    async (formData: Omit<IUpdateProjectRequest, 'id'>) => {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!'),
        start_date: Yup.string().required(
          'O campo data de início é obrigatório!',
        ),
        end_date: Yup.string().required('O campo data de fim é obrigatório!'),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: t('EDIT_PROJECT_SUCCESS'),
          error: t('EDIT_PROJECT_FAILURE'),
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          if (project) {
            if (project.tags) delete project.tags;

            const updatedProject = await updateProject({
              ...project,
              id: project.id,
              name: formData.name,
              project_status_id: formData.project_status_id,
              start_date: formData.start_date,
              end_date: formData.end_date,
            });

            if (setProject) {
              setProject(updatedProject);
            }
          }

          closeModal();
        },
      });
    },
    [addToast, t, project, closeModal, setProject],
  );

  useImperativeHandle(ref, () => ({
    submit: formRef.current?.submitForm,
    reset: formRef.current?.reset,
  }));

  useEffect(() => {
    async function loadProjectStatus() {
      const response = await getProjectStatus();
      setProjectStatus(response.map(i => ({ label: i.name, value: i.id })));
    }

    loadProjectStatus();
  }, []);

  useEffect(() => {
    if (formRef.current && project)
      formRef.current.setData({
        name: project?.name,
        project_status_id: project.project_status_id,
        start_date: format(parseISO(String(project.start_date)), 'dd/MM/yyyy', {
          locale,
        }),
        end_date: format(parseISO(String(project.end_date)), 'dd/MM/yyyy', {
          locale,
        }),
      });
  }, [project]);

  return (
    <Container
      ref={formRef}
      initialData={project}
      onSubmit={handleSubmit}
      autoComplete="off"
      style={{ height: '100%' }}
    >
      <FormRow>
        <Input label="Nome" name="name" placeholder="Nome" />
        <Select
          name="project_status_id"
          options={projectStatus}
          placeholder="Status"
          label="Status"
          valueToReturn="value"
        />
      </FormRow>

      <FormRow>
        <DatePicker name="start_date" placeholder="Início" label="Início" />

        <DatePicker name="end_date" label="Fim" placeholder="Fim" />
      </FormRow>
    </Container>
  );
};

const Component = React.forwardRef(UpdateProjectForm);

export { Component as UpdateProjectForm };
