import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { format, endOfMonth, startOfMonth, subDays, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import {
  BarChart,
  HalfDonutChart,
  ProductivityChart,
  SmartRange,
} from '@components/atoms';
import { IData, IDataItem } from '@components/atoms/Charts/Bar';
import { IProductivityItem } from '@components/atoms/Charts/Productivity';
import { Activities } from '@components/molecules';
import { IActivityItem } from '@components/molecules/Activities';
import { DashboardHelper as DH } from '@helpers/DashboardHelper';
import { useHeader } from '@hooks/useHeader';
import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { getDailies } from '@services/apis/DailyService';
import {
  getCollaboratorsProductivity,
  getSummaryActivities,
  getSummaryWorkedHours,
} from '@services/apis/DashboardService';
import { delay } from '@utils/delay';

import { Container, TitleArea, RowCharts } from './styles';

type IHalfDonut = {
  color: string;
  percent: number;
  text: string;
  value: number;
};

interface IHalfDonutData {
  cost: IHalfDonut[];
  hours: IHalfDonut[];
}

const Dashboard: React.FC = () => {
  const { getCurrentInfo } = useHeader();

  const colors = useTheme<IColors>({ prop: 'colors' });

  const { isChecked } = getCurrentInfo();

  const [searched, setSearched] = useState<boolean>(false);

  const [currentStartDate, setCurrentStartDate] = useState<Date>(
    startOfMonth(new Date()),
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date>(
    endOfMonth(new Date()),
  );
  const [pastStartDate, setPastStartDate] = useState<Date>();
  const [pastEndDate, setPastEndDate] = useState<Date>();
  const [productivityDate] = useState<Date>(subDays(new Date(), 1));
  const [dailyDate] = useState<Date>(new Date());

  const defaultHalfDonutsData = useMemo(
    () => ({
      cost: [
        {
          color: colors.hours.productive,
          percent: 0,
          text: 'produtivo',
          value: 0,
        },
        {
          color: colors.hours.unproductive,
          percent: 0,
          text: 'improdutivo',
          value: 0,
        },
        { color: colors.hours.passive, percent: 0, text: 'passivo', value: 0 },
        {
          color: colors.hours.undefined,
          percent: 0,
          text: 'indefinido',
          value: 0,
        },
      ],
      hours: [
        {
          color: colors.hours.productive,
          percent: 0,
          text: 'produtivo',
          value: 0,
        },
        {
          color: colors.hours.unproductive,
          percent: 0,
          text: 'improdutivo',
          value: 0,
        },
        { color: colors.hours.passive, percent: 0, text: 'passivo', value: 0 },
        {
          color: colors.hours.undefined,
          percent: 0,
          text: 'indefinido',
          value: 0,
        },
      ],
    }),
    [colors],
  );

  const [halfDonutsData, setHalfDonutsData] = useState<IHalfDonutData>(
    defaultHalfDonutsData,
  );
  const [productivityData, setProductivityData] = useState<IProductivityItem[]>(
    [],
  );
  const [chartData, setChartData] = useState<IData>(undefined);
  const [dailyData, setDailyData] = useState<IActivityItem[]>([]);

  useEffect(() => {
    async function loadSummaryWorkedHours() {
      const data = await getSummaryWorkedHours({
        start_date: format(currentStartDate, 'yyyy-MM-dd'),
        end_date: format(currentEndDate, 'yyyy-MM-dd'),
      });

      const { hours: h } = colors;

      await delay(1500);

      setHalfDonutsData({
        cost: [
          {
            color: h.productive,
            percent:
              data.productive.cost === 0
                ? 0
                : Math.round((data.productive.cost / data.total.cost) * 100),
            text: 'produtivo',
            value: data.productive.cost,
          },
          {
            color: h.unproductive,
            percent:
              data.unproductive.cost === 0
                ? 0
                : Math.round((data.unproductive.cost / data.total.cost) * 100),
            text: 'improdutivo',
            value: data.unproductive.cost,
          },
          {
            color: h.passive,
            percent:
              data.passive.cost === 0
                ? 0
                : Math.round((data.passive.cost / data.total.cost) * 100),
            text: 'passivo',
            value: data.passive.cost,
          },
          {
            color: h.undefined,
            percent:
              data.undefined.cost === 0
                ? 0
                : Math.round((data.undefined.cost / data.total.cost) * 100),
            text: 'indefinido',
            value: data.undefined.cost,
          },
        ],
        hours: [
          {
            color: h.productive,
            percent:
              data.productive.hours === 0
                ? 0
                : Math.round((data.productive.hours / data.total.hours) * 100),
            text: 'produtivo',
            value: Number((data.productive.hours / 3600).toFixed(0)),
          },
          {
            color: h.unproductive,
            percent:
              data.unproductive.hours === 0
                ? 0
                : Math.round(
                    (data.unproductive.hours / data.total.hours) * 100,
                  ),
            text: 'improdutivo',
            value: Number((data.unproductive.hours / 3600).toFixed(0)),
          },
          {
            color: h.passive,
            percent:
              data.passive.hours === 0
                ? 0
                : Math.round((data.passive.hours / data.total.hours) * 100),
            text: 'passivo',
            value: Number((data.passive.hours / 3600).toFixed(0)),
          },
          {
            color: h.undefined,
            percent:
              data.undefined.hours === 0
                ? 0
                : Math.round((data.undefined.hours / data.total.hours) * 100),
            text: 'indefinido',
            value: Number((data.undefined.hours / 3600).toFixed(0)),
          },
        ],
      });
    }

    loadSummaryWorkedHours();
  }, [colors, currentEndDate, currentStartDate]);

  useEffect(() => {
    async function loadSummaryActivities() {
      const data = await getSummaryActivities({
        current_start_date: format(currentStartDate, 'yyyy-MM-dd'),
        current_end_date: format(currentEndDate, 'yyyy-MM-dd'),
        past_start_date: pastStartDate
          ? format(pastStartDate, 'yyyy-MM-dd')
          : undefined,
        past_end_date: pastEndDate
          ? format(pastEndDate, 'yyyy-MM-dd')
          : undefined,
      });

      const currentData = data.current.map(currentItem => {
        const past = data.past.find(
          pastItem => pastItem.label === currentItem.label,
        );

        return {
          project_id: currentItem.project_id,
          label: currentItem.label,
          currentCost: currentItem.cost,
          currentHours: currentItem.hours / 3600,
          pastCost: past?.cost,
          pastHours: (past?.hours || 0) / 3600,
        } as IDataItem;
      });

      await delay(1500);

      setChartData(currentData);
    }

    loadSummaryActivities();
  }, [currentEndDate, currentStartDate, pastEndDate, pastStartDate]);

  useEffect(() => {
    async function loadCollaboratorsProductivity() {
      const data = await getCollaboratorsProductivity({
        searched,
        worked_date: format(productivityDate, 'yyyy-MM-dd'),
        current_start_date: format(currentStartDate, 'yyyy-MM-dd'),
        current_end_date: format(currentEndDate, 'yyyy-MM-dd'),
      });

      const rowsAgrouped = DH.collaboratorsProductivity(data);

      setProductivityData(rowsAgrouped);
    }

    loadCollaboratorsProductivity();
  }, [productivityDate, currentEndDate, currentStartDate, searched]);

  useEffect(() => {
    async function loadDailyStatus() {
      let queryToSearch: any;

      if (searched) {
        queryToSearch = {
          q: [
            {
              property: 'daily_date',
              operator: 'GREATER_THAN_OR_EQUAL',
              value: format(currentStartDate, 'yyyy-MM-dd', { locale }),
              rule: 'AND',
              entity: 'daily',
            },
            {
              property: 'daily_date',
              operator: 'LESS_THAN_OR_EQUAL',
              value: format(currentEndDate, 'yyyy-MM-dd', { locale }),
              rule: 'AND',
              entity: 'daily',
            },
            {
              property: 'concluded',
              operator: 'EQUAL',
              value: '1',
              rule: 'AND',
              entity: 'daily',
            },
          ],
          page: 1,
          limit: 0,
          sort: [
            { property: 'daily_date', order: 'DESC' },
            { property: 'id', order: 'ASC' },
          ],
        };
      } else {
        queryToSearch = {
          q: [
            {
              property: 'daily_date',
              operator: 'EQUAL',
              value: format(dailyDate, 'yyyy-MM-dd', { locale }),
              rule: 'AND',
              entity: 'daily',
            },
            {
              property: 'concluded',
              operator: 'EQUAL',
              value: '1',
              rule: 'AND',
              entity: 'daily',
            },
          ],
          page: 1,
          limit: 0,
          sort: [
            { property: 'daily_date', order: 'DESC' },
            { property: 'id', order: 'ASC' },
          ],
        };
      }

      const data: any = await getDailies(queryToSearch);

      setDailyData(
        data.map(
          (item: {
            daily_date: string;
            daily_answers: any[];
            user: { name: any };
          }) => {
            const pattern = "dd/MM/yyyy HH'h'mm";
            const parsedDate = format(parseISO(item.daily_date), pattern, {
              locale,
            });
            const parsedProjects = item.daily_answers.map((answer: any) => {
              if (!answer.summary_activity?.project) return 'Não informado';

              return answer.summary_activity.project.name;
            });

            return {
              avatar_url: '',
              title: item.user.name,
              subtitle: `${parsedDate} - ${parsedProjects.join(' - ')}`,
              daily: item,
            };
          },
        ),
      );
    }

    loadDailyStatus();
  }, [dailyDate, currentEndDate, currentStartDate, searched]);

  const handleFilterGeneralInfo = useCallback(
    (dates: Date[]) => {
      setHalfDonutsData(defaultHalfDonutsData);
      setChartData(undefined);

      let changeValueDateOne;

      if (dates[0] > dates[1]) {
        // eslint-disable-next-line prefer-destructuring
        changeValueDateOne = dates[0];

        // eslint-disable-next-line prefer-destructuring
        dates[0] = dates[1];
        dates[1] = changeValueDateOne;
      }

      if (dates.length === 1) {
        setCurrentStartDate(dates[0]);
        setCurrentEndDate(dates[0]);
        setSearched(true);
      } else if (dates.length === 2) {
        setCurrentStartDate(dates[0]);
        setCurrentEndDate(dates[1]);
        setSearched(true);
      } else if (dates.length === 3) {
        setCurrentStartDate(dates[0]);
        setCurrentEndDate(dates[1]);
        setPastStartDate(dates[2]);
        setSearched(true);
      } else if (dates.length === 4) {
        setCurrentStartDate(dates[0]);
        setCurrentEndDate(dates[1]);
        setPastStartDate(dates[2]);
        setPastEndDate(dates[3]);
        setSearched(true);
      } else {
        setCurrentStartDate(startOfMonth(new Date()));
        setCurrentEndDate(endOfMonth(new Date()));
      }
    },
    [defaultHalfDonutsData],
  );

  const sortedData = chartData?.sort((a, b) => {
    if (isChecked) {
      return b.currentCost - a.currentCost;
    }

    return b.currentHours - a.currentHours;
  });

  return (
    <Container>
      <TitleArea>
        <strong
          style={{ color: colors.activities.selection, fontSize: '24px' }}
        >
          Informações Gerais
        </strong>

        <div
          style={{
            width: '280px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <SmartRange
            placeholder="Filtrar"
            minDate={new Date(2010, 1, 1)}
            maxDate={new Date(2040, 12, 1)}
            onApply={handleFilterGeneralInfo}
            defaultValue={[currentStartDate, currentEndDate]}
          />
        </div>
      </TitleArea>

      <RowCharts className="half_donuts">
        {isChecked
          ? halfDonutsData.cost.map(halfDonut => {
              return <HalfDonutChart type="cost" key={v4()} {...halfDonut} />;
            })
          : halfDonutsData.hours.map(halfDonut => {
              return <HalfDonutChart type="hours" key={v4()} {...halfDonut} />;
            })}
      </RowCharts>

      <BarChart type={isChecked ? 'cost' : 'hours'} data={sortedData} />

      <RowCharts className="productivity_activities">
        <ProductivityChart
          type={isChecked ? 'cost' : 'hours'}
          onLoad={by =>
            DH.order(productivityData, isChecked ? 'cost' : 'hours', by)
          }
        />
        <Activities activities={dailyData} />
      </RowCharts>
    </Container>
  );
};

export { Dashboard };
