export const projectsShimmerLoading = [
  { label: 'Carregando', value: 100 },
  { label: 'Carregando', value: 100 },
  { label: 'Carregando', value: 100 },
  { label: 'Carregando', value: 86 },
  { label: 'Carregando', value: 86 },
  { label: 'Carregando', value: 86 },
  { label: 'Carregando', value: 60 },
  { label: 'Carregando', value: 60 },
  { label: 'Carregando', value: 60 },
  { label: 'Carregando', value: 46 },
  { label: 'Carregando', value: 46 },
  { label: 'Carregando', value: 46 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
  { label: 'Carregando', value: 33 },
];
