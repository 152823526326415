import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      padding: 8px 16px;

      flex-direction: column;
    }
  `,
  ChartInfo: css`
    @media (max-width: 768px) {
      margin-top: 8px;

      gap: 0;
      align-items: center;
      justify-content: space-between;
    }
  `,
};

export const Container = styled(Card).attrs({
  className: 'half_donut_chart-container',
})`
  width: 100%;

  display: flex;
  justify-content: space-between;

  ${responsive.Container}
`;

export const Chart = styled.div.attrs({
  className: 'half_donut_chart-chart',
})`
  ${({ theme }) => css`
    width: 100%;
    max-width: 150px;
    height: 100%;
    min-height: 64px;

    margin: 0;

    position: relative;

    display: flex;
    align-self: center;
    justify-content: center;

    span {
      font-size: ${theme.typography.fontSize.extraLarge};
      font-weight: ${theme.typography.fontWeight.bold};

      padding-bottom: 6px;

      align-self: flex-end;
    }
  `}
`;

export const Svg = styled.svg.attrs({
  className: 'half_donut_chart-svg',
  xmlns: 'http://www.w3.org/2000/svg',
})`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;

export const BackLine = styled.path.attrs({
  className: 'half_donut_chart-back_line',
})`
  ${({ theme }) => css`
    fill: none;
    fill-rule: evenodd;

    stroke: ${theme.colors.background};
    stroke-width: 16px;
    stroke-linecap: round;

    transition: all 1s;
  `}
`;

interface ILineProps {
  color: string;
}

export const Line = styled.path.attrs({
  className: 'half_donut_chart-line',
})<ILineProps>`
  ${({ color }) => css`
    fill: none;
    fill-rule: evenodd;

    stroke: ${color};
    stroke-width: 32px;
    stroke-dashoffset: -471;
    stroke-dasharray: 471;
    stroke-linecap: round;

    transition: all 1s;
  `}
`;

type IChartInfo = ILineProps;

export const ChartInfo = styled.div.attrs({
  className: 'half_donut_chart-chart_info',
})<IChartInfo>`
  ${({ theme, color }) => css`
    width: 100%;

    padding: 2px 0;

    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    strong {
      color: ${color};
      font-size: ${theme.typography.fontSize.large};
    }

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      letter-spacing: 0.6px;
    }

    ${responsive.ChartInfo}
  `}
`;
