import React, {
  useRef,
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { DateRange, Input, Tags } from '@components/atoms';
import { ITag } from '@components/atoms/Tags';
import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import {
  IEditProjectForm,
  INewProjectForm,
  IProject,
} from '@interfaces/IProject';
import {
  associateCollaboradors,
  createProject,
} from '@services/apis/ProjectsService';

import { Container, FormRow } from './styles';

interface IProjectFormProps {
  project?: IEditProjectForm;
  setProjects?: React.Dispatch<React.SetStateAction<IProject[]>>;
  setProject?: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  closeModal: () => void;
}

const ProjectForm: React.ForwardRefRenderFunction<
  IFormRef,
  IProjectFormProps
> = ({ project, setProjects, closeModal }, ref) => {
  const [t] = useTranslation();

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const handleSubmit = useCallback(
    async (formData: INewProjectForm) => {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!'),
        dates: Yup.array().nullable().length(2, 'O campo data é obrigatório!'),
        planned_cost: Yup.number().required(
          'O campo custo planejado é obrigatório!',
        ),
        planned_hours: Yup.number().required(
          'O campo horas planejadas é obrigatório!',
        ),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: !project?.id
            ? t('NEW_PROJECT_SUCCESS')
            : t('EDIT_PROJECT_SUCCESS'),
          error: !project?.id
            ? t('NEW_PROJECT_FAILURE')
            : t('EDIT_PROJECT_FAILURE'),
        },
        onSubmit: async () => {
          const userStorage: any = localStorage.getItem(
            AUTH_LOCAL_STORAGE_USER,
          );

          await schema.validate(formData, { abortEarly: false });

          if (!project?.id) {
            const createdProject = await createProject({
              ...formData,
              start_date: formData.dates[0],
              end_date: formData.dates[1],
            });

            if (sessionStorage.openAddProject === 'true') {
              sessionStorage.openDailyChat = 'true';
              sessionStorage.createdProjectName = createdProject.name;

              await associateCollaboradors({
                project_id: createdProject.id,
                users_ids: [JSON.parse(userStorage).id],
              });
            }

            if (setProjects) setProjects(state => [...state, createdProject]);
          }

          closeModal();
        },
      });
    },
    [addToast, t, project, closeModal, setProjects],
  );

  useImperativeHandle(ref, () => ({
    submit: formRef.current?.submitForm,
    reset: formRef.current?.reset,
  }));

  useEffect(() => {
    if (formRef.current) formRef.current.setData({ ...project });
  }, [project]);

  useLayoutEffect(() => {
    if (!project) formRef.current?.setData({});
  }, [project]);

  const parsedTags: ITag[] = useMemo(() => {
    if (!project) return [];

    if (!project.tags) return [];

    return project.tags.map(item => ({
      label: item.name,
      value: item.id,
      isSelected: true,
    })) as ITag[];
  }, [project]);

  return (
    <Container
      ref={formRef}
      initialData={project}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <FormRow>
        <Input label="Nome" name="name" placeholder="Nome" />

        <DateRange
          label="Início - Fim"
          name="dates"
          placeholder="Definir Data"
          autoComplete="off"
        />
      </FormRow>

      <FormRow>
        <Input
          label="Custo planejado"
          name="planned_cost"
          mask="currency"
          placeholder="Custo planejado"
        />

        <Input
          label="Horas planejadas"
          name="planned_hours"
          mask="number"
          placeholder="Horas planejadas"
        />
      </FormRow>

      <Tags
        name="tags"
        label="Tags"
        tags={parsedTags}
        placeholder="Digite a tag + Enter"
        minHeight="36px"
        valueToReturn="label"
      />
    </Container>
  );
};

const Component = React.forwardRef(ProjectForm);

export { Component as ProjectForm };
