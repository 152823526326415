import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useUnform } from '@hooks/useUnform';

import { Ball, Container } from './styles';

interface ISwitcherProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  labels?: { active: string; inactive: string };
  staticColor?: boolean;
  onSwitch?: (checked: boolean) => void;
}

const Switcher: React.FC<ISwitcherProps> = ({
  name,
  label,
  labels,
  checked = true,
  staticColor = false,
  onSwitch,
}) => {
  const unform = useUnform(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isActive, setIsActive] = useState<boolean>(checked);

  const toggle = useCallback(() => {
    if (onSwitch) onSwitch(!isActive);

    setIsActive(!isActive);
  }, [isActive, onSwitch]);

  useLayoutEffect(() => {
    setIsActive(checked || false);
  }, [checked]);

  useEffect(() => {
    if (unform) {
      unform.registerField({
        name: unform.fieldName,
        ref: inputRef.current,
        path: 'checked',
      });
    }
  }, [unform]);

  return (
    <Container isActive={isActive} staticColor={staticColor} onClick={toggle}>
      <Ball isActive={isActive} staticColor={staticColor} />

      <span>
        {label}
        {isActive ? labels?.active || 'Ativo' : labels?.inactive || 'Inativo'}
      </span>

      <input ref={inputRef} type="radio" checked={isActive} onChange={toggle} />
    </Container>
  );
};

export { Switcher };
