import { darken, lighten, transparentize } from 'polished';
import styled, { css } from 'styled-components';

const responsive = (isFolded: boolean) => {
  return css`
    @media (max-width: 960px) {
      .default-layout-aside-menu {
        width: ${isFolded ? 0 : '248px'};
        min-width: ${isFolded ? 0 : '248px'};

        position: fixed;
        top: 0;
        left: 0;

        ${isFolded &&
        css`
          svg {
            width: 0 !important;
            min-width: 0 !important;

            transition: all 400ms !important;
          }
        `}
      }

      .default-layout-fold-toggle {
        display: ${isFolded ? 'none' : 'flex'};
      }

      .default-layout-main {
        width: 100%;
      }
    }
  `;
};

interface IContainerProps {
  isFolded: boolean;
}

export const Container = styled.div.attrs({
  className: 'default-layout-container',
})<IContainerProps>`
  ${({ isFolded }) => css`
    width: 100%;
    height: 100%;

    display: flex;

    ${responsive(isFolded)}
  `}
`;

interface IAsideMenuProps {
  isFolded: boolean;
}

export const AsideMenu = styled.div.attrs({
  className: 'default-layout-aside-menu',
})<IAsideMenuProps>`
  ${({ theme, isFolded }) => css`
    width: 100%;
    min-width: ${isFolded ? '64px' : '248px'};
    max-width: ${isFolded ? '64px' : '248px'};
    height: 100vh;

    background: ${theme.colors.asideMenu};
    box-shadow: 0 0px 8px ${theme.colors.shadow};

    color: ${theme.colors.text.title};

    position: relative;

    transition: all 400ms;

    z-index: 1000;
  `}
`;

export const LogoContainer = styled.div.attrs({
  className: 'default-layout-logo-container',
})`
  width: 100%;

  padding: 48px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type IFoldToggleProps = IAsideMenuProps;

export const FoldToggle = styled.button.attrs({
  className: 'default-layout-fold-toggle',
})<IFoldToggleProps>`
  ${({ theme, isFolded }) => css`
    width: 24px;
    height: 24px;

    border: 0;
    border-radius: 50%;
    background: ${isFolded
      ? theme.colors.asideMenuButton
      : theme.colors.activities.info};

    position: absolute;
    top: 100px;
    right: -12px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    &:hover {
      background: ${darken(
        0.1,
        isFolded ? theme.colors.asideMenuButton : theme.colors.activities.info,
      )};
    }

    svg {
      margin-left: ${isFolded ? '2px' : '0'};
    }
  `}
`;

export const Navigation = styled.div.attrs({
  className: 'default-layout-navigation',
})`
  width: 100%;
  height: calc(100% - 160px);

  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

interface INavigationItemProps extends IAsideMenuProps {
  isActive: boolean;
}

const navigationItemVariations = (theme: any) => {
  return {
    active: css`
      background: ${transparentize(0.9, theme.colors.text.links)};

      svg {
        stroke: ${theme.colors.text.links};
      }

      span {
        color: ${theme.colors.text.links};
      }
    `,
  };
};

export const NavigationItem = styled.div.attrs({
  className: 'default-layout-navigation-item',
})<INavigationItemProps>`
  ${({ theme, isActive, isFolded }) => css`
    width: 100%;

    background: transparent;

    padding: ${isFolded ? '1rem 1rem' : '1rem 1.5rem'};

    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: ${isFolded ? 'center' : 'flex-start'};

    transition: background 400ms, color 400ms;

    span {
      font-size: ${theme.typography.fontSize.normal};
      font-weight: ${isActive ? '500' : '400'};
      white-space: nowrap;

      display: ${isFolded ? 'none' : 'block'};
    }

    svg {
      width: 24px;
      height: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      &.timeline {
        width: 22px;
        height: 22px;

        margin-left: 4px;

        path {
          stroke: ${isActive
            ? theme.colors.text.links
            : theme.colors.text.title};

          transition: all 400ms;
        }
      }

      &.organogram {
        width: 22px;
        height: 22px;

        margin-left: 2px;

        path {
          stroke: none;
          fill: ${isActive ? theme.colors.text.links : theme.colors.text.title};

          transition: all 400ms;
        }
      }
    }

    ${isActive && navigationItemVariations(theme).active}

    &:hover {
      background: ${transparentize(0.9, theme.colors.text.links)};

      svg {
        path {
          stroke: ${lighten(0.1, theme.colors.text.links)};

          transition: all 400ms;
        }

        &.organogram {
          path {
            stroke: none;
            fill: ${lighten(0.1, theme.colors.text.links)};
          }
        }

        stroke: ${lighten(0.1, theme.colors.text.links)};
      }

      span {
        color: ${theme.colors.text.links};

        transition: color 400ms;
      }

      cursor: pointer;
    }
  `}
`;

type IMainProps = IAsideMenuProps;

export const Main = styled.main.attrs({
  className: 'default-layout-main',
})<IMainProps>`
  ${({ isFolded }) => css`
    width: ${isFolded ? 'calc(100% - 64px)' : 'calc(100% - 248px)'};

    transition: all 400ms;
  `}
`;
