import styled, { css } from 'styled-components';

import { Form } from '@contexts/ReactFormContext';
import { customScroll } from '@styles/global';

export const Container = styled(Form)`
  width: 100%;

  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const NewHourValue = styled.div.attrs({
  className: 'components-molecules-forms-user_values_new_hour_value',
})`
  ${({ theme }) => css`
    border-radius: ${theme.borders.radii[300]};
    background: ${theme.colors.activities.tagsWithAction};
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.4);

    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const NewHourValueFields = styled.div.attrs({
  className: 'components-molecules-forms-user_values_new_hour_value_fields',
})`
  flex: 1;
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

export const NewHourValueConfirmation = styled.div`
  ${({ theme }) => css`
    height: 100%;

    padding-left: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > strong {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
    }

    > span {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.extraLarge};

      margin-bottom: 8px;
    }

    button {
      width: 140px;
      min-width: 140px;
    }
  `}
`;

export const Historic = styled.div.attrs({
  className: 'historic',
})`
  ${() => css`
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    > strong {
      margin-bottom: 5px;
    }
  `}
`;

export const HistoricCosts = styled.div.attrs({
  className: 'historic_costs',
})`
  ${({ theme }) => css`
    margin-top: 16px;
    height: 87px;
    height: calc(100vh - 635px);

    display: flex;
    flex-direction: column;

    > strong {
      margin-bottom: 16px;
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 635px);
    }

    @media (min-width: 1540px) {
      height: calc(100vh - 705px);
    }

    ${customScroll(theme.colors.cards)}
  `}
`;

interface IHistoricRowProps {
  isHeader?: boolean;
}

export const HistoricRow = styled.div<IHistoricRowProps>`
  ${({ theme, isHeader = false }) => css`
    border-bottom: 1px solid ${theme.colors.activities.tagsWithoutAction}66;

    padding: 16px 4px;

    display: grid;
    grid-template-columns: 140px 240px 1fr;

    strong {
      color: ${isHeader ? theme.colors.text.subtitle : theme.colors.white};
      font-size: ${theme.typography.fontSize.normal};
      font-weight: ${isHeader ? 'bold' : 'normal'};
      white-space: nowrap;
    }
  `}
`;

export const HistoricNotFound = styled(HistoricRow)`
  display: flex;
  justify-content: center;
`;
