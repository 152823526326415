import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import * as Icons from '@assets/icons';
import { Button, Input } from '@components/atoms';
import { IFormHandles } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { forgotPassword } from '@services/apis/AuthService';

import {
  Container,
  Left,
  Login,
  Unform,
  Right,
  BoxRight,
  Title,
} from './styles';

const ChangePassword: React.FC = () => {
  const [t] = useTranslation();
  const urlParams: any = useParams();

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const history = useHistory();

  const handleSubmit = useCallback(
    async (formData: any) => {
      if (
        formData.new_password.match(/[0-9]/) &&
        formData.repeat_password.match(/[0-9]/)
      ) {
        const newPassword = formData.new_password.match(/[0-9]/);
        const newPasswordConfirmation = formData.repeat_password.match(/[0-9]/);

        if (
          !newPassword.input.slice(newPassword.index + 1) ||
          !newPasswordConfirmation.input.slice(
            newPasswordConfirmation.index + 1,
          )
        ) {
          formData.new_password = '1';
          formData.repeat_password = '2';
        }
      } else {
        formData.new_password = '1';
        formData.repeat_password = '2';
      }

      const schema = Yup.object().shape({
        new_password: Yup.string()
          .required('Informe sua nova senha!')
          .min(10, 'A nova senha precisa ter 10 carecteres e 2 números!'),
        repeat_password: Yup.string()
          .required('Repita sua senha!')
          .min(10, 'As senhas precisam ser iguais!'),
      });

      let valid = await schema.isValid({
        new_password: formData.new_password,
        repeat_password: formData.repeat_password,
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: t('CHANGE_PASSWORD_SUCCESS'),
          error: t('CHANGE_PASSWORD_FAILURE'),
        },
        onSubmit: async () => {
          valid = await schema.isValid({
            new_password: formData.new_password,
            repeat_password: formData.repeat_password,
          });

          if (!valid || formData.new_password !== formData.repeat_password)
            throw new Error();

          await forgotPassword({
            token: urlParams.token,
            new_password: formData.new_password,
            repeat_password: formData.repeat_password,
          });

          history.push(`/`);
        },
      });
    },
    [addToast, t, urlParams, history],
  );

  return (
    <Container>
      <Left>
        <Login>
          <Icons.DevstreamLogoLight />

          <Unform ref={formRef} onSubmit={handleSubmit}>
            <strong>Alteração de senha</strong>

            <Input
              label="Nova senha"
              name="new_password"
              type="password"
              placeholder="Nova senha"
            />

            <Input
              label="Repetir senha"
              name="repeat_password"
              type="password"
              placeholder="Repetir senha"
            />

            <Button type="submit" fullWidth>
              Confirmar nova senha
            </Button>
          </Unform>
        </Login>
      </Left>

      <Right>
        <BoxRight>
          <Title>
            <strong>+ Segurança para sua conta</strong>

            <p>
              Informe o email da sua conta e lhe enviaremos um link com as
              instruções para alterar sua senha
            </p>
          </Title>
          <Icons.DevstreamForgotPassword />
        </BoxRight>
      </Right>
    </Container>
  );
};

export { ChangePassword };
