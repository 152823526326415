import {
  ICollaboratorProductivity,
  IWorkedHoursHeatmap,
  ISummaryWorkedHourResponse,
  ISummaryActivityResponse,
} from '@interfaces/IDashboard';
import { api } from '@services/api';

interface IFilter {
  start_date: string;
  end_date: string;
}

export async function getSummaryWorkedHours({
  start_date,
  end_date,
}: IFilter): Promise<ISummaryWorkedHourResponse> {
  const { data } = await api.get<ISummaryWorkedHourResponse>(
    `/dashboard/summary_worked_hours?start_date=${start_date}&end_date=${end_date}`,
  );

  return data;
}

export async function getCollaboratorsProductivity({
  searched,
  worked_date,
  current_start_date,
  current_end_date,
}: {
  searched?: boolean;
  worked_date?: string;
  current_start_date?: string;
  current_end_date?: string;
}): Promise<ICollaboratorProductivity[]> {
  let queryParams;

  if (searched)
    queryParams = `current_start_date=${current_start_date}&current_end_date=${current_end_date}`;
  else queryParams = `worked_date=${worked_date}`;

  const { data } = await api.get<ICollaboratorProductivity[]>(
    `/dashboard/collaborators_productivity?${queryParams}`,
  );

  return data;
}

export async function getWorkedWours(): Promise<IWorkedHoursHeatmap[]> {
  const { data } = await api.get<IWorkedHoursHeatmap[]>(
    '/dashboard/worked_hours',
  );

  return data;
}

export async function getSummaryActivities({
  current_start_date,
  current_end_date,
  past_start_date = '',
  past_end_date = '',
}: {
  current_start_date: string;
  current_end_date: string;
  past_start_date?: string;
  past_end_date?: string;
}): Promise<ISummaryActivityResponse> {
  const currentParams = `current_start_date=${current_start_date}&current_end_date=${current_end_date}`;
  const pastParams = `past_start_date=${past_start_date}&past_end_date=${past_end_date}`;
  const queryParams = `${currentParams}&${pastParams}`;

  const { data } = await api.get<ISummaryActivityResponse>(
    `/dashboard/summary_activities?${queryParams}`,
  );

  return data;
}

// DASHBOARD COLLABORATOR

export async function getSummaryWorkedHoursByCollaborator({
  start_date,
  end_date,
}: IFilter): Promise<ISummaryWorkedHourResponse> {
  const { data } = await api.get<ISummaryWorkedHourResponse>(
    `/dashboard/collaborator/summary_worked_hours?start_date=${start_date}&end_date=${end_date}`,
  );

  return data;
}

export async function getCollasboratorProductivityByPeriod({
  start_date,
  end_date,
}: IFilter): Promise<ICollaboratorProductivity[]> {
  const { data } = await api.get<ICollaboratorProductivity[]>(
    `/dashboard/collaborator/collaborators_productivity?start_date=${start_date}&end_date=${end_date}`,
    {
      headers: { relations: 'user,user.costs' },
    },
  );

  return data;
}
