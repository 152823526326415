import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'components-atoms-monthly_container',
})`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 40px;

      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.small};
      text-align: center;
    }

    button {
      border: none;
      background: none;

      &:hover {
        svg {
          stroke: ${theme.colors.text.links};

          transform: scale(1.2);
        }
      }

      svg {
        stroke: ${theme.colors.text.title};

        transition: all 400ms;
      }
    }
  `}
`;
