import styled, { css } from 'styled-components';

interface IContainerProps {
  isFolded?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ isFolded = false }) => css`
    ${!isFolded &&
    css`
      > svg {
        width: 130px;
        height: 40px;
      }
    `}
  `}
`;
