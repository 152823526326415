import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  hasNavigation: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, isFocused, hasNavigation }) => css`
    width: 200px;
    height: 32px;

    border-bottom: 1px solid
      ${isFocused
        ? theme.colors.input.focused.border
        : theme.colors.text.subtitle};

    margin-right: ${hasNavigation ? '86px' : '0'};

    position: relative;

    display: flex;
    align-items: center;

    transition: all 400ms;

    > input {
      width: 100%;
      height: 100%;

      border-radius: 8px;
      border: none;
      background: ${theme.colors.background};
      background: transparent;
      outline: none;

      color: ${theme.colors.text.subtitle};
      font-size: 14px;
      font-weight: normal;

      padding: 0;

      cursor: pointer;

      transition: all 400ms;

      &::placeholder {
        color: ${theme.colors.text.subtitle};
      }
    }
  `}
`;

interface IArrowProps {
  isFocused: boolean;
}

export const Arrow = styled.div<IArrowProps>`
  ${({ theme, isFocused }) => css`
    width: 0;
    height: 0;

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 9px solid
      ${isFocused
        ? theme.colors.input.focused.border
        : theme.colors.text.subtitle};

    position: absolute;
    right: 8px;

    pointer-events: none;

    cursor: pointer;

    transform: ${isFocused ? 'rotate(0deg)' : 'rotate(180deg)'};

    transition: all 400ms;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 220px;

    span {
      color: ${theme.colors.input.normal.border};
      font-size: 14px;
      white-space: nowrap;
    }
  `}
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: -86px;

    gap: 6px;
    display: flex;
    align-items: center;

    button {
      width: 34px;
      height: 34px;

      border-radius: 50%;
      border: 1px solid transparent;
      background: none;

      color: ${theme.colors.input.normal.border};
      text-transform: capitalize;

      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 400ms;

      svg polyline {
        transition: all 400ms;
      }

      &:not([disabled]) {
        &:hover {
          border: 1px solid ${theme.colors.activities.info};

          svg polyline {
            stroke: ${theme.colors.activities.info};
          }
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  `}
`;
