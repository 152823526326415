import React, { useRef, useState, useEffect, useCallback } from 'react';

import { addMonths, format, subMonths } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';

import { DateRange, Search, Timeline } from '@components/atoms';
import { PageHeader } from '@components/molecules';
import { HttpQuery, IParsedQuery } from '@helpers/HttpQueryHelper';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { ITimelineRow } from '@interfaces/ITimeline';
import { ReportsService } from '@services/apis/ReportsService';
import { getFirstAndLastDatesFromDate } from '@utils/getFirstAndLastDatesFromDate';

import { Container } from './styles';

const Reports: React.FC = () => {
  const theme = useTheme<'light' | 'dark'>({ prop: 'theme' });
  const { addToast } = useToast();

  const getInitialDates = () => {
    return [
      getFirstAndLastDatesFromDate(subMonths(new Date(), 1))[0],
      getFirstAndLastDatesFromDate(addMonths(new Date(), 1))[1],
    ];
  };

  const currentPage = useRef<number>(0);
  const currentSearch = useRef<string>('');
  const currentDates = useRef<Date[]>(getInitialDates());

  const [data, setData] = useState<ITimelineRow[]>();
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const loadMore = useCallback(
    async (direction: number, queryParams?: IParsedQuery) => {
      if (direction !== 0) {
        if (direction === 1) currentPage.current += 1;
        else if (direction === -1) currentPage.current -= 1;
        else currentPage.current = 1;
      } else {
        currentPage.current = 1;
      }

      const query = HttpQuery.getInitialQuery(
        queryParams || {
          page: currentPage.current,
          limit: 4,
          sort: [{ order: 'ASC', property: 'name' }],
        },
      );

      const defaultStartDate = getInitialDates()[0];
      const defaultEndDate = getInitialDates()[1];

      const defaultStartDateFormatted = format(defaultStartDate, 'yyyy-MM-dd', {
        locale,
      });
      const defaultEndDateFormatted = format(defaultEndDate, 'yyyy-MM-dd', {
        locale,
      });

      try {
        const {
          data: newData,
          page,
          total,
        } = await ReportsService.list({
          query: {
            q: queryParams
              ? queryParams.q
              : [
                  {
                    entity: 'summary_activities',
                    property: 'worked_date',
                    rule: 'AND',
                    operator: 'GREATER_THAN_OR_EQUAL',
                    value: defaultStartDateFormatted,
                  },
                  {
                    entity: 'summary_activities',
                    property: 'worked_date',
                    rule: 'AND',
                    operator: 'LESS_THAN_OR_EQUAL',
                    value: defaultEndDateFormatted,
                  },
                ],
            page: query.page,
            limit: query.limit,
            sort: query.sort,
          },
        });

        const newHasPrevPage = page > 1;
        const newHasNextPage = page < total;

        setData(state => (newData.length === 0 ? state : newData));
        setHasPrevPage(newHasPrevPage);
        setHasNextPage(newHasNextPage);

        const parsedQuery = HttpQuery.getParsedQueryString(query);

        window.history.pushState('', '', `/reports${parsedQuery}`);
      } catch (err) {
        addToast({ title: 'Erro ao buscar mais projetos!', type: 'error' });
      }
    },
    [addToast],
  );

  useEffect(() => {
    async function load() {
      await loadMore(1);
    }

    load();
  }, [loadMore]);

  const handleSearch = useCallback(
    async (value: string) => {
      const newQuery: IParsedQuery = {
        q: [
          {
            entity: 'projects',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'AND',
          },
          {
            entity: 'summary_activities',
            property: 'worked_date',
            rule: 'AND',
            operator: 'GREATER_THAN_OR_EQUAL',
            value: format(currentDates.current[0], 'yyyy-MM-dd', { locale }),
          },
          {
            entity: 'summary_activities',
            property: 'worked_date',
            rule: 'AND',
            operator: 'LESS_THAN_OR_EQUAL',
            value: format(currentDates.current[1], 'yyyy-MM-dd', { locale }),
          },
          {
            entity: 'users',
            value,
            property: 'name',
            operator: 'LIKE',
            rule: 'OR',
          },
        ],
        page: 1,
        limit: 4,
        sort: [{ order: 'ASC', property: 'name' }],
      };

      currentSearch.current = value;

      await loadMore(0, newQuery);
    },
    [loadMore],
  );

  const handleFilter = useCallback(
    async (dates: Date[]) => {
      const filteredStartDate = dates[0];
      const filteredEndDate = dates[1];

      const filteredStartDateFormatted = format(
        filteredStartDate,
        'yyyy-MM-dd',
        { locale },
      );
      const filteredEndDateFormatted = format(filteredEndDate, 'yyyy-MM-dd', {
        locale,
      });

      const newQuery: IParsedQuery = {
        q: [
          {
            entity: 'projects',
            value: currentSearch.current,
            property: 'name',
            operator: 'LIKE',
            rule: 'AND',
          },
          {
            entity: 'summary_activities',
            property: 'worked_date',
            rule: 'AND',
            operator: 'GREATER_THAN_OR_EQUAL',
            value: filteredStartDateFormatted,
          },
          {
            entity: 'summary_activities',
            property: 'worked_date',
            rule: 'AND',
            operator: 'LESS_THAN_OR_EQUAL',
            value: filteredEndDateFormatted,
          },
          {
            entity: 'users',
            value: currentSearch.current,
            property: 'name',
            operator: 'LIKE',
            rule: 'OR',
          },
        ],
        page: 1,
        limit: 4,
        sort: [{ order: 'ASC', property: 'name' }],
      };

      currentDates.current = [dates[0], dates[1]];

      await loadMore(0, newQuery);
    },
    [loadMore],
  );

  const handlePrev = useCallback(async () => {
    const filteredStartDate = subMonths(currentDates.current[0], 1);
    const filteredEndDate = subMonths(currentDates.current[1], 1);

    const filteredStartDateFormatted = format(filteredStartDate, 'yyyy-MM-dd', {
      locale,
    });
    const filteredEndDateFormatted = format(filteredEndDate, 'yyyy-MM-dd', {
      locale,
    });

    const newQuery: IParsedQuery = {
      q:
        currentSearch.current.length > 0
          ? [
              {
                entity: 'projects',
                value: currentSearch.current,
                property: 'name',
                operator: 'LIKE',
                rule: 'AND',
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'GREATER_THAN_OR_EQUAL',
                value: filteredStartDateFormatted,
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'LESS_THAN_OR_EQUAL',
                value: filteredEndDateFormatted,
              },
              {
                entity: 'users',
                value: currentSearch.current,
                property: 'name',
                operator: 'LIKE',
                rule: 'OR',
              },
            ]
          : [
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'GREATER_THAN_OR_EQUAL',
                value: filteredStartDateFormatted,
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'LESS_THAN_OR_EQUAL',
                value: filteredEndDateFormatted,
              },
            ],
      page: 1,
      limit: 4,
      sort: [{ order: 'ASC', property: 'name' }],
    };

    currentDates.current = [filteredStartDate, filteredEndDate];

    await loadMore(0, newQuery);
  }, [loadMore]);

  const handleNext = useCallback(async () => {
    const filteredStartDate = addMonths(currentDates.current[0], 1);
    const filteredEndDate = addMonths(currentDates.current[1], 1);

    const filteredStartDateFormatted = format(filteredStartDate, 'yyyy-MM-dd', {
      locale,
    });
    const filteredEndDateFormatted = format(filteredEndDate, 'yyyy-MM-dd', {
      locale,
    });

    const newQuery: IParsedQuery = {
      q:
        currentSearch.current.length > 0
          ? [
              {
                entity: 'projects',
                value: currentSearch.current,
                property: 'name',
                operator: 'LIKE',
                rule: 'AND',
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'GREATER_THAN_OR_EQUAL',
                value: filteredStartDateFormatted,
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'LESS_THAN_OR_EQUAL',
                value: filteredEndDateFormatted,
              },
              {
                entity: 'users',
                value: currentSearch.current,
                property: 'name',
                operator: 'LIKE',
                rule: 'OR',
              },
            ]
          : [
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'GREATER_THAN_OR_EQUAL',
                value: filteredStartDateFormatted,
              },
              {
                entity: 'summary_activities',
                property: 'worked_date',
                rule: 'AND',
                operator: 'LESS_THAN_OR_EQUAL',
                value: filteredEndDateFormatted,
              },
            ],
      page: 1,
      limit: 4,
      sort: [{ order: 'ASC', property: 'name' }],
    };

    currentDates.current = [filteredStartDate, filteredEndDate];

    await loadMore(0, newQuery);
  }, [loadMore]);

  const timelineThemes = {
    light: {
      primary: '#FFFFFF',
      weekend: '#F0F4F9',
      background: '#D8E2EE',
      font: { title: '#292F4D', subtitle: '#92abd8' },
      header: { background: '#6c87bf', text: '#FFFFFF' },
      hover: '#00B0FF',
      shadow: 'rgba(177, 197, 221, 0.4)',
    },
    dark: {
      primary: '#232b47',
      weekend: '#29385c',
      background: '#0A192F',
      font: { title: '#E3E9EC', subtitle: '#92abd8' },
      header: { background: '#6c87bf', text: '#E3E9EC' },
      hover: '#00B0FF',
      shadow: 'rgba(0, 0, 0, 0.4)',
    },
  };

  return (
    <Container>
      <PageHeader>
        <Search
          placeholder="Digite o nome de um usuário ou projeto"
          onSearch={handleSearch}
        />

        <div>
          <DateRange
            name="filters"
            placeholder="Definir Data"
            autoComplete="off"
            theme="dark"
            onApply={handleFilter}
          />
        </div>
      </PageHeader>

      <Timeline
        data={data}
        variation="REPORT"
        prevMonthsQuantity={1}
        nextMonthsQuantity={1}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
        onPrev={handlePrev}
        onNext={handleNext}
        colors={timelineThemes[theme]}
      />
    </Container>
  );
};

export { Reports };
