import React, { ComponentType, useRef, useState } from 'react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';

import { v4 } from 'uuid';

import { useClickAway } from '@hooks/useClickAway';

import { Container, MoreButton, Items, Item } from './styles';

type IDropItem = {
  text: string;
  icon: ComponentType<any>;
  onClick: () => void;
};

interface IDropdownProps {
  items: IDropItem[];
}

const Dropdown: React.FC<IDropdownProps> = ({ items }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState<boolean>(false);

  function onClick(fn: () => void) {
    setIsActive(false);
    fn();
  }

  useClickAway(containerRef, () => setIsActive(false), {
    enabled: isActive,
  });

  return (
    <Container ref={containerRef}>
      <MoreButton
        type="button"
        isActive={isActive}
        onClick={() => setIsActive(state => !state)}
      >
        <HiOutlineDotsHorizontal size={24} />
      </MoreButton>

      <Items isActive={isActive}>
        {items.map(({ text, icon: Icon, onClick: fn }) => {
          return (
            <Item key={v4()} onClick={() => onClick(fn)}>
              <span>{text}</span>

              <Icon />
            </Item>
          );
        })}
      </Items>
    </Container>
  );
};

export { Dropdown };
