import React, { useMemo } from 'react';

import { IAlert } from '../types';

import {
  Container,
  Header,
  Footer,
  ReplyNowButton,
  ReplyLaterButton,
} from './styles';

interface IAlertProps {
  name: string;
  isAlert: boolean;
  isAnswered: boolean;
  alert: IAlert;
  onReply: () => void;
  onCancel: () => void;
}

const Alert: React.FC<IAlertProps> = ({
  name,
  isAlert,
  isAnswered,
  alert,
  onReply,
  onCancel,
}) => {
  const alerts = useMemo(() => {
    return {
      success: {
        title: `Oi ${name}, tudo joia?`,
        message: 'Que tal começar suas atividades respondendo o seu status?',
      },
      warning: {
        title: `Hey ${name},`,
        message:
          'Parte do seu período já se foi. Não acha que esse é o momento de responder o seu status?',
      },
      danger: {
        title: `Poxa ${name}, esqueceu do status?`,
        message:
          'Suas respostas influenciam o trabalho de outros profissionais. Não encerre seu ciclo sem responde-lo!',
      },
    };
  }, [name]);

  return (
    <Container isAlert={isAlert} alert={alert}>
      <Header alert={alert}>
        {isAnswered ? (
          <strong>
            Valeu <b>{name.split(' ')[0]}</b>! Sua daily está <b>concluída</b>.
          </strong>
        ) : (
          <>
            <strong>{alerts[alert].title}</strong>
            <strong>{alerts[alert].message}</strong>
          </>
        )}
      </Header>

      <Footer>
        <ReplyNowButton type="button" onClick={onReply}>
          <span className="web">
            {isAnswered ? 'Visualizar daily' : 'Responder agora'}
          </span>
          <span className="mobile">
            {isAnswered ? 'Visualizar' : 'Responder'}
          </span>
        </ReplyNowButton>

        {alert !== 'danger' && (
          <ReplyLaterButton type="button" onClick={onCancel}>
            <span className="web">
              {isAnswered ? 'Minimizar chat' : 'Responder depois'}
            </span>
            <span className="mobile">
              {isAnswered ? 'Minimizar' : 'Agora não'}
            </span>
          </ReplyLaterButton>
        )}
      </Footer>
    </Container>
  );
};

export { Alert };
