import { IParsedQuery, HttpQuery } from '@helpers/HttpQueryHelper';
import { IDaily } from '@interfaces/IDaily';
import { api } from '@services/api';

export async function getDailies(query?: IParsedQuery): Promise<any[]> {
  const parsedQuery = HttpQuery.getParsedQueryString(query);

  const { data } = await api.get<IDaily[]>(`/dailies${parsedQuery}`);

  return data;
}
