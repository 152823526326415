import styled, { css } from 'styled-components';

import { IProps } from './types';

const responsive = {
  ChatButton: css<IChatButtonProps>`
    ${({ isAlert }) => css`
      @media screen and (max-width: 768px) {
        width: ${isAlert ? '42px' : '40px'};
        height: ${isAlert ? '42px' : '40px'};

        right: ${isAlert ? '16px' : '0px'};
        bottom: ${isAlert ? '16px' : '0px'};

        svg {
          width: ${isAlert ? '24px' : '24px'};
          height: ${isAlert ? '24px' : '24px'};

          transition: all 400ms;
        }
      }

      @media screen and (max-width: 280px) {
        width: ${isAlert ? '42px' : '40px'};
        height: ${isAlert ? '42px' : '40px'};

        right: ${isAlert ? '16px' : '0px'};
        bottom: ${isAlert ? '16px' : '0px'};

        svg {
          width: ${isAlert ? '24px' : '24px'};
          height: ${isAlert ? '24px' : '24px'};

          transition: all 400ms;
        }
      }
    `}
  `,
};

export const alertVariations = {
  success: css`
    ${({ theme }) => css`
      border: 1px solid ${theme.colors.activities.success};
      background: ${theme.colors.activities.success};

      color: ${theme.colors.background};

      svg {
        stroke: ${theme.colors.background};
      }
    `}
  `,
  warning: css`
    ${({ theme }) => css`
      border: 1px solid ${theme.colors.activities.warning};
      background: ${theme.colors.activities.warning};

      color: ${theme.colors.text.default};
    `}
  `,
  danger: css`
    ${({ theme }) => css`
      border: 1px solid ${theme.colors.activities.danger};
      background: ${theme.colors.activities.danger};

      color: ${theme.colors.background};

      svg {
        stroke: ${theme.colors.background};
      }
    `}
  `,
};

export const Container = styled.div`
  ${() => css`
    height: 100vh;

    position: fixed;
    right: 0;
    bottom: 0;

    z-index: 1000;
  `}
`;

type IChatButtonProps = IProps;

export const ChatButton = styled.button<IChatButtonProps>`
  ${({ theme, isAlert, alert }) => css`
    width: ${isAlert ? '72px' : '40px'};
    height: ${isAlert ? '72px' : '40px'};

    border-radius: 50% 50% 0 50%;
    box-shadow: 0px 6px 16px ${theme.colors.shadow};

    position: absolute;
    right: ${isAlert ? '36px' : '0px'};
    bottom: ${isAlert ? '36px' : '0px'};

    transition: all 400ms;

    svg {
      width: ${isAlert ? '32px' : '24px'};
      height: ${isAlert ? '32px' : '24px'};

      transition: all 400ms;
    }

    ${alertVariations[alert]}
    ${responsive.ChatButton}
  `}
`;
