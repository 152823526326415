import styled, { css } from 'styled-components';

import { colors } from '@components/atoms/ColorPicker';

interface IContainerProps {
  color: string;
  level?: number;
}

export const Container = styled.div<IContainerProps>`
  cursor: pointer;

  position: relative;

  ${({ color, level = 0 }) =>
    level === 2 &&
    css`
      &:before {
        content: '';

        width: 30px;

        border: 1px solid ${color};
        background: ${color};

        position: absolute;
        left: -48px;
      }

      &:after {
        content: '';

        width: 16px;
        height: 16px;

        border-radius: 50%;
        background: ${color};

        position: absolute;
        left: -56px;
      }
    `}
`;

export const DefaultAvatar = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;

    border-radius: 50%;
    background: ${colors[13]};

    font-size: small;

    margin-right: 10px;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    strong {
      /* color: #fbfbfb !important; */
      color: ${theme.colors.text.title} !important;

      display: block;
    }
  `}
`;

export const Avatar = styled.img`
  height: 40px;
  width: 40px;

  border-radius: 50%;

  font-size: small;

  margin-right: 10px;
`;

export const Cost = styled.div`
  ${({ theme }) => css`
    height: 24px;

    font-size: 14px;

    display: flex;
    gap: 8px;

    > strong {
      align-self: flex-end;

      > span {
        color: ${theme.colors.text.title};
        font-weight: normal;
        letter-spacing: -0.8px;

        margin-left: 4px;

        display: inline-block;
      }
    }

    > div {
      align-self: flex-start;

      border-radius: 8px;
      background: ${theme.colors.background};

      padding: 2px 6px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;

        path {
          stroke: ${theme.colors.text.subtitle};
        }
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  `}
`;

export const Hours = styled.div`
  ${({ theme }) => css`
    height: 24px;

    font-size: 14px;

    display: flex;
    gap: 8px;

    > strong {
      align-self: flex-end;

      > span {
        font-weight: normal;
        letter-spacing: -0.8px;

        margin-left: 4px;

        position: relative;

        display: inline-block;
      }
    }

    > div {
      align-self: flex-start;

      border-radius: 8px;
      background: ${theme.colors.background};

      padding: 2px 6px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;

        path {
          stroke: ${theme.colors.text.subtitle};
        }
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  `}
`;

export const Left = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div:nth-of-type(1) {
      height: 100%;

      margin-bottom: 6px;

      display: flex;
      align-items: flex-start;

      > div {
        display: flex;
        gap: 4px;
        flex-direction: column;
        align-items: flex-start;

        > span {
          font-size: 14px;
          font-weight: bold;
        }

        > strong {
          max-width: 130px;

          color: ${theme.colors.text.subtitle};
          font-size: 14px;
          letter-spacing: -0.6px;
          text-overflow: ellipsis;

          overflow: hidden;
        }
      }
    }
  `}
`;

export const Right = styled.div`
  height: 100%;
  min-height: 70px;

  display: none; // flex;
  flex-direction: column;
  justify-content: center;
`;
