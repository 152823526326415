import styled, { css, DefaultTheme } from 'styled-components';

import { Button as ButtonComponent } from '@components/atoms';
import { customScroll } from '@styles/global';

export const ModalContent = styled.div`
  width: 480px;
  max-height: 980px;

  display: flex;
  flex-direction: column;

  padding: 24px;
`;

export const DailyHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 40px;
      height: 40px;

      border-radius: 50%;
    }

    > div {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.small};
        padding-bottom: 2px;
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  `}
`;

interface ITagProps {
  status?: 'default' | 'alert' | 'resolved';
}

const TagVariation = {
  default: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.activities.tagsWithAction};
    background-color: #334b7a99;
  `,
  alert: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.activities.danger};
    background-color: #ff6c7066;
  `,
  resolved: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.activities.success};
    background-color: #15c8ac66;
  `,
};

export const Tag = styled.div<ITagProps>`
  ${({ theme, status = 'default' }) => css`
    display: flex;
    padding: 4px 12px;
    width: fit-content;
    border-radius: ${theme.borders.radii[500]};

    ${TagVariation[status](theme)}
  `}
`;

export const List = styled.div`
  overflow-y: scroll;
  max-height: 520px;
  max-width: 980px;

  padding-right: 12px;

  ${customScroll()}
`;

export const Daily = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;

  margin-bottom: 24px;
`;

export const Question = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    gap: 2px;

    > span:nth-child(1) {
      color: ${theme.colors.text.subtitle};
      font-size: 1.1em;
      font-weight: 500;
    }

    > span:nth-child(2) {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};
      text-align: justify;
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 24px;

      span {
        color: ${theme.colors.text.subtitle};
        font-size: 1.1em;
        font-weight: 500;
      }

      > div {
        display: flex;
        gap: 12px;
      }

      /* button:nth-child(1) {
        background: ${`#6C87BF`};
      }

      button:nth-child(2) {
        background: ${`#00B1FF66`};
      } */
    }
  `}
`;

export const Button = styled(ButtonComponent)`
  width: 130px;
  min-width: 130px;
  max-width: 130px;
`;
