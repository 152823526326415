import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { v4 } from 'uuid';

import { Logo } from '@components/atoms';
import { Can } from '@components/Can';
import { Header } from '@components/organisms';
import { IMenuItem } from '@contexts/ReactHeaderContext';
import { useAuth } from '@hooks/useAuth';
import { useHeader } from '@hooks/useHeader';

import {
  Container,
  AsideMenu,
  LogoContainer,
  FoldToggle,
  Navigation,
  NavigationItem,
  Main,
} from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();

  const { currentMenu, setCurrentMenu, menu, onDispatch } = useHeader();

  if (!menu) signOut();

  const history = useHistory();

  const [isFolded, setIsFolded] = useState<boolean>(() => {
    if (window.innerWidth <= 960) return true;

    return false;
  });

  const getTitle = useCallback(() => {
    return menu[currentMenu]?.text
      ? menu[currentMenu]?.text
      : window.location.pathname.includes('/profile')
      ? 'Meu Perfíl'
      : 'Painel Geral';
  }, [currentMenu, menu]);

  const toggleFold = useCallback(() => {
    setIsFolded(state => !state);
    onDispatch();
  }, [onDispatch]);

  const handleNavigate = useCallback(
    ({ text, url }: IMenuItem) => {
      if (window.innerWidth <= 960) setIsFolded(true);

      const foundedIndex = menu.findIndex(item => item.text === text);

      setCurrentMenu(foundedIndex);

      history.push(url);
    },
    [history, menu, setCurrentMenu],
  );

  const icons = useMemo(() => {
    const props = { size: 22, color: '#FFFFFF' };

    return {
      left: <HiChevronLeft {...props} />,
      right: <HiChevronRight {...props} />,
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1200) setIsFolded(true);
      else setIsFolded(false);
    });
  }, []);

  return (
    <Container isFolded={isFolded}>
      <AsideMenu isFolded={isFolded}>
        <LogoContainer>
          <Logo isFolded={isFolded} />
        </LogoContainer>

        <FoldToggle type="button" isFolded={isFolded} onClick={toggleFold}>
          {isFolded ? icons.right : icons.left}
        </FoldToggle>

        <Navigation>
          {menu.map(({ icon: Icon, ...rest }, index) => {
            const isDev = process.env.NODE_ENV === 'development';

            if (!isDev && rest.isCommingSoon) return null;

            return (
              <Can key={v4()} permissions={[]} roles={rest.roles}>
                <NavigationItem
                  isFolded={isFolded}
                  isActive={currentMenu === index}
                  onClick={() => handleNavigate({ icon: Icon, ...rest })}
                >
                  <Icon className={rest.className} />

                  <span>{rest.text}</span>
                </NavigationItem>
              </Can>
            );
          })}
        </Navigation>
      </AsideMenu>

      <Main isFolded={isFolded}>
        <Header title={getTitle()} onFold={toggleFold} />

        {children}
      </Main>
    </Container>
  );
};

export { DefaultLayout };
