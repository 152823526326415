import React, { useEffect, useRef, useState, useCallback } from 'react';

import { OrganogramForm } from '@components/molecules';
import { Drawer, IDrawerRef } from '@components/organisms/Drawer';
import { IFormRef } from '@contexts/ReactFormContext';
import { useResetForms } from '@hooks/useResetForms';
import { IEditOrganogramForm, IOrganogram } from '@interfaces/IOrganogram';
import { getOrganogram } from '@services/apis/OrganogramsService';

interface IOrganogramsDrawerProps {
  organogramId?: number;
  setOrganogramId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOrganograms: React.Dispatch<React.SetStateAction<IOrganogram[]>>;
  organogramDrawerRef: React.RefObject<IDrawerRef>;
}

const OrganogramsDrawer: React.FC<IOrganogramsDrawerProps> = ({
  organogramId,
  setOrganogramId,
  setOrganograms,
  organogramDrawerRef,
}) => {
  const organogramFormRef = useRef<IFormRef>(null);

  const [selectedOrganogram, setSelectedOrganogram] =
    useState<IEditOrganogramForm>();

  useEffect(() => {
    async function loadOrganogram(id: number) {
      const organogram = await getOrganogram({ id });

      const subgroups = organogram.childs?.map((i: any) => i.group.id) || [];

      setSelectedOrganogram({
        id: organogramId,
        color: organogram.color,
        name:
          organogram.childs.find(item => item.parent_group_id === 0)?.name ||
          '',
        subgroups,
      });
    }

    if (organogramId) loadOrganogram(organogramId);
  }, [organogramId]);

  const onClose = useCallback(
    () => setOrganogramId(state => (state === 0 ? undefined : 0)),
    [setOrganogramId],
  );

  useResetForms<IEditOrganogramForm>({
    refs: [organogramFormRef],
    trigger: organogramId,
    set: setSelectedOrganogram,
  });

  return (
    <Drawer
      ref={organogramDrawerRef}
      title={selectedOrganogram ? selectedOrganogram.name : 'Adicionar grupo'}
      hasData={!!selectedOrganogram}
      onClose={onClose}
    >
      <OrganogramForm
        ref={organogramFormRef}
        organogram={selectedOrganogram}
        setOrganograms={setOrganograms}
        closeModal={() => organogramDrawerRef.current?.close()}
      />
    </Drawer>
  );
};

export { OrganogramsDrawer };
