import { HiOutlineSearch } from 'react-icons/hi';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 370px;
    height: 40px;

    border-radius: 4px;
    border: 2px solid
      ${theme.title === 'light'
        ? theme.colors.input.normal.border
        : theme.colors.cards};
    background: ${theme.colors.cards};

    position: relative;

    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 100%;

      border: none;
      border-radius: 4px;
      background: inherit;

      color: ${theme.colors.text.title};

      padding: 0 calc(8px + 24px + 8px) 0 8px;

      &::placeholder {
        color: ${theme.colors.text.title};

        opacity: 50%;
      }
    }
  `}
`;

export const IsSearching = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 50%;

  margin: auto;

  perspective: 800px;

  position: absolute;
  right: 8px;
`;

export const Inner = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    border-radius: 50%;

    position: absolute;

    &.one {
      border-bottom: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      top: 0%;
      left: 0%;

      animation: rotate-one 1s linear infinite;
    }

    &.two {
      border-right: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      right: 0%;
      top: 0%;

      animation: rotate-two 1s linear infinite;
    }

    &.three {
      border-top: 3px solid
        ${theme.title === 'dark' ? '#efeffa' : theme.colors.text.title};

      right: 0%;
      bottom: 0%;

      animation: rotate-three 1s linear infinite;
    }

    @keyframes rotate-one {
      0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
      }
    }

    @keyframes rotate-two {
      0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
      }
    }

    @keyframes rotate-three {
      0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
      }
      100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
      }
    }
  `}
`;

export const Icon = styled(HiOutlineSearch)`
  position: absolute;
  right: 8px;
`;
