import React, { useRef, useCallback, useImperativeHandle } from 'react';

import * as Yup from 'yup';

import { Input } from '@components/atoms';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import { changePassword } from '@services/apis/AuthService';

import { Container } from './styles';

interface IUserChangePasswordFormProps {
  closeModal: () => void;
}

const UserChangePasswordForm: React.ForwardRefRenderFunction<
  IFormRef,
  IUserChangePasswordFormProps
> = ({ closeModal }, ref) => {
  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const handleSubmit = useCallback(
    async (formData: any) => {
      if (
        formData.new_password.match(/[0-9]/) &&
        formData.new_password_confirmation.match(/[0-9]/)
      ) {
        const newPassword = formData.new_password.match(/[0-9]/);
        const newPasswordConfirmation =
          formData.new_password_confirmation.match(/[0-9]/);

        if (
          !newPassword.input.slice(newPassword.index + 1) ||
          !newPasswordConfirmation.input.slice(
            newPasswordConfirmation.index + 1,
          )
        ) {
          formData.new_password = '1';
          formData.new_password_confirmation = '2';
        }
      } else {
        formData.new_password = '1';
        formData.new_password_confirmation = '2';
      }

      const schema = Yup.object().shape({
        old_password: Yup.string().required('Informe sua senha atual!'),
        new_password: Yup.string()
          .required('Informe uma nova senha!')
          .min(10, 'A nova senha precisa ter 10 carecteres e 2 números!')
          .max(12, 'A nova senha pode ter no máximo 12 carecteres!'),
        new_password_confirmation: Yup.string()
          .required('Confirme sua nova senha!')
          .min(
            10,
            'A confirmação da senha precisa ter 10 carecteres 2 números!',
          )
          .max(12, 'A confirmação da senha pode ter no máximo 12 carecteres!'),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: 'Senha alterada com sucesso!',
          error: 'Ocorreu um erro ao alterar a senha, tente novamente!',
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          await changePassword(formData);

          formRef.current?.reset({});

          closeModal();
        },
      });
    },
    [addToast, closeModal],
  );

  useImperativeHandle(ref, () => ({ submit: formRef.current?.submitForm }));

  return (
    <Container ref={formRef} onSubmit={handleSubmit}>
      <strong>Alteração de senha</strong>

      <Input
        label="Senha atual"
        name="old_password"
        type="password"
        placeholder="Senha atual"
      />

      <Input
        label="Nova senha"
        name="new_password"
        type="password"
        placeholder="Nova senha"
      />

      <Input
        label="Confirmação da nova senha"
        name="new_password_confirmation"
        type="password"
        placeholder="Confirmação da nova senha"
      />
    </Container>
  );
};

const Component = React.forwardRef(UserChangePasswordForm);

export { Component as UserChangePasswordForm };
