import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { format, isYesterday, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

import { PlusCircle } from '@assets/icons';
import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { useToast } from '@hooks/useToast';
import { ChatBotService } from '@services/apis/ChatBotService';
import { toHM } from '@utils/toHM';
import { toHMS } from '@utils/toHMS';

import { IComponentsProps } from '../../types';

import { ChatButton, Message } from '../../../Messages/styles';

const IdentifiedWorkedHours: React.FC<IComponentsProps> = ({
  response: { activities, answers },
  onAnswer,
}) => {
  const { addToast } = useToast();

  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const workedDate = useMemo(() => {
    if (sessionStorage.worked_date) {
      const parsedDate = parseISO(String(sessionStorage.worked_date));

      if (isYesterday(parsedDate)) return 'ontem';

      if (!isYesterday(parsedDate)) {
        const formattedDate = format(parsedDate, 'dd/MM', { locale });

        return `no dia ${formattedDate}`;
      }
    }

    return 'ontem';
  }, []);

  const answer = useMemo(() => {
    const confirmed = answers.find(
      ({ flow_stopped }) => flow_stopped === 'CONFIRMED_WORKED_HOURS',
    );

    const notConfirmed = answers.find(
      ({ flow_stopped }) => flow_stopped === 'NOT_CONFIRMED_WORKED_HOURS',
    );

    if (sessionStorage.openDailyChat === 'true') return true;

    if (confirmed) return confirmed.flow_stopped;

    if (notConfirmed) return notConfirmed.flow_stopped;

    return null;
  }, [answers]);

  const workedHours = useMemo(() => {
    return activities
      .filter(item => item.original_worked_hours > 0)
      .map(activity => {
        return (
          <span style={{ display: 'block' }} key={v4()}>
            <b>
              {toHM(activity.original_worked_hours)
                .replace(':', 'h ')
                .replace(':', 'm ')}
              min
            </b>{' '}
            no projeto
            <b> {activity.project.name}</b>
          </span>
        );
      });
  }, [activities]);

  const workedHoursNoProject = useMemo(() => {
    return (
      <span style={{ display: 'block' }} key={v4()}>
        <b>
          {toHM(sessionStorage.total_worked_Hours)
            .replace(':', 'h ')
            .replace(':', 'm ')}
          min
        </b>{' '}
        em um projeto não cadastrado
      </span>
    );
  }, []);

  const onYes = useCallback(async () => {
    setIsLoading(true);

    try {
      const projectsHours = activities.map(
        ({ original_worked_hours: h, project: p }) =>
          `${toHMS(h)} horas no projeto ${p.name}`,
      );

      await ChatBotService.confirmWorkedHours({
        summary_activities_ids: activities.map(item => item.id),
        question: `Identificamos que ${workedDate} você trabalhou: ${projectsHours} Essa informação está correta?`,
        answer: 'Sim',
        worked_date,
      });

      onAnswer({ dailyState: 'CONFIRMED_WORKED_HOURS', scrollToDown: true });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao confirmar suas horas trabalhadas!',
      });
    } finally {
      setIsLoading(false);
    }
  }, [activities, addToast, onAnswer, workedDate, worked_date]);

  const onNo = useCallback(async () => {
    setIsLoading(true);

    try {
      const projectsHours = activities.map(
        ({ original_worked_hours: h, project: p }) =>
          `${toHMS(h)} horas no projeto ${p.name}`,
      );

      await ChatBotService.notConfirmWorkedHours({
        question: `Identificamos que ${workedDate} você trabalhou: ${projectsHours} Essa informação está correta?`,
        answer: 'Não',
        worked_date,
      });

      onAnswer({
        dailyState: 'NOT_CONFIRMED_WORKED_HOURS',
        scrollToDown: true,
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao tentar corrigir suas horas trabalhadas!',
      });
    } finally {
      setIsLoading(false);
    }
  }, [activities, addToast, onAnswer, workedDate, worked_date]);

  const onYesCreateProject = () => {
    sessionStorage.openAddProject = 'true';
    window.history.pushState('', '', `/projects`);
    window.location.reload();
  };
  useEffect(() => {
    if (
      sessionStorage.openDailyChat === 'true' &&
      sessionStorage.state === 'INIT'
    )
      onNo();
  }, [onNo]);

  return (
    <>
      <Message.Others>
        <div>
          {workedHours.length > 0 ? (
            <>
              <p>
                Identificamos que <b>{workedDate}</b> você trabalhou:
                {workedHours}
              </p>
              <br />
              <p>Essa informação está correta?</p>
            </>
          ) : (
            <>
              <p>
                Identificamos que <b>{workedDate}</b> você trabalhou:
                {workedHoursNoProject}
              </p>
              <br />
              <p>Deseja cadastrar um novo projeto?</p>
            </>
          )}
        </div>
        <div style={{ marginTop: '16px', display: 'flex', gap: '16px' }}>
          {activities.length > 0 ? (
            <>
              <ChatButton
                disabled={!!answer || isLoading}
                variation={
                  answer === 'CONFIRMED_WORKED_HOURS'
                    ? 'SELECTED'
                    : 'NOT_SELECTED'
                }
                style={{ maxWidth: '120px' }}
                onClick={onYes}
              >
                <span>Sim</span>
              </ChatButton>
              <ChatButton
                disabled={!!answer || isLoading}
                variation={
                  answer === 'NOT_CONFIRMED_WORKED_HOURS'
                    ? 'SELECTED'
                    : 'NOT_SELECTED'
                }
                style={{ maxWidth: '120px' }}
                onClick={onNo}
              >
                <span>Não</span>
              </ChatButton>
            </>
          ) : (
            <>
              <ChatButton
                disabled={!!answer || isLoading}
                variation={
                  answer === 'CONFIRMED_WORKED_HOURS'
                    ? 'SELECTED'
                    : 'NOT_SELECTED_BLUE'
                }
                style={{ maxWidth: '120px' }}
                onClick={onYesCreateProject}
              >
                <span>Add Projeto</span>
                <PlusCircle style={{ marginLeft: '7px' }} />
              </ChatButton>
              <ChatButton
                disabled={!!answer || isLoading}
                variation={
                  answer === 'NOT_CONFIRMED_WORKED_HOURS'
                    ? 'SELECTED'
                    : 'NOT_SELECTED'
                }
                style={{ maxWidth: '120px' }}
                onClick={onNo}
              >
                <span>Não</span>
              </ChatButton>
            </>
          )}
        </div>
        <br />
        {sessionStorage.openDailyChat === 'true' && (
          <Message.Others>
            <div>
              <p>
                Agora que encontramos o projeto{' '}
                <b>{sessionStorage.createdProjectName}</b>, preencha as horas em
                que atuou.
              </p>
            </div>
          </Message.Others>
        )}
      </Message.Others>
    </>
  );
};

export { IdentifiedWorkedHours };
