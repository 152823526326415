import React, {
  useRef,
  useCallback,
  useLayoutEffect,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { Input, Tags } from '@components/atoms';
import { IFormHandles, IFormRef } from '@contexts/ReactFormContext';
import { trySubmit } from '@hooks/useSubmit';
import { useToast } from '@hooks/useToast';
import {
  IEditOrganogramForm,
  INewOrganogramForm,
  IOrganogram,
} from '@interfaces/IOrganogram';
import {
  createOrganogram,
  updateOrganogram,
} from '@services/apis/OrganogramsService';

import { Container, FormRow } from './styles';

interface IOrganogramFormProps {
  organogram?: IEditOrganogramForm;
  setOrganograms?: React.Dispatch<React.SetStateAction<IOrganogram[]>>;
  closeModal: () => void;
}

const OrganogramForm: React.ForwardRefRenderFunction<
  IFormRef,
  IOrganogramFormProps
> = ({ organogram, setOrganograms, closeModal }, ref) => {
  const [t] = useTranslation();

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const handleSubmit = useCallback(
    async (formData: INewOrganogramForm) => {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!'),
        color: Yup.string().required('O campo e-mail é obrigatório!'),
      });

      const success = !organogram?.id
        ? t('NEW_GROUP_SUCCESS')
        : t('EDIT_COLLABORATOR_SUCCESS');
      const error = !organogram?.id
        ? t('NEW_GROUP_FAILURE')
        : t('EDIT_COLLABORATOR_FAILURE');

      trySubmit({
        formRef,
        addToast,
        messages: { success, error },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          if (!organogram?.id) {
            const createdOrganogram = await createOrganogram({
              color: formData.color,
              group: { name: formData.name, subgroups: formData.subgroups },
            });

            if (setOrganograms) {
              setOrganograms(state => [...state, createdOrganogram]);
            }
          } else {
            const updatedOrganogram = await updateOrganogram({
              id: organogram.id,
              color: formData.color,
            });

            if (setOrganograms) {
              setOrganograms(state => {
                return state.map(item =>
                  item.id === organogram.id
                    ? { ...item, ...updatedOrganogram }
                    : item,
                );
              });
            }
          }

          closeModal();
        },
      });
    },
    [addToast, closeModal, organogram, setOrganograms, t],
  );

  useImperativeHandle(ref, () => ({
    submit: formRef.current?.submitForm,
    reset: formRef.current?.reset,
  }));

  useLayoutEffect(() => {
    if (!organogram) formRef.current?.setData({});
  }, [organogram]);

  return (
    <Container
      ref={formRef}
      initialData={organogram}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <FormRow>
        <Input label="Nome" name="name" placeholder="Nome do grupo" />

        <Input
          label="Cor"
          name="color"
          type="color"
          placeholder="Escolher cor"
        />
      </FormRow>

      <Tags
        name="subgroups"
        label="Grupos"
        placeholder="Digite o grupo + Enter"
        minHeight="36px"
        valueToReturn="label"
      />
    </Container>
  );
};

const Component = React.forwardRef(OrganogramForm);

export { Component as OrganogramForm };
