import { useContext } from 'react';

import {
  IOrganogramContext,
  OrganogramContext,
} from '@contexts/ReactOrganogramContext';

function useOrganogram(): IOrganogramContext {
  const context = useContext(OrganogramContext);

  return context;
}

export { useOrganogram };
