import React, { useRef, useMemo, useCallback } from 'react';

import { format } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import { Avatar, Badge, Button, Input } from '@components/atoms';
import { Modal, IModalRef } from '@components/organisms/Modal';
import { STRING_REQUIRED } from '@constants/yup';
import { IFormHandles } from '@contexts/ReactFormContext';
import { ProjectHelper } from '@helpers/ProjectHelper';
import { trySubmit } from '@hooks/useSubmit';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { IColors } from '@interfaces/generic/ITheme';
import { IProject } from '@interfaces/IProject';
import { updateProject } from '@services/apis/ProjectsService';
import { toCurrency } from '@utils/toCurrency';

import {
  ModalContent,
  Action,
  Header,
  ProjectInfo,
  Statistic,
  StatisticInfo,
  StatisticInfoBadge,
  StatisticBar,
  Unform,
  Historics,
  HistoricScroll,
  Historic,
  HistoricNotFound,
} from './styles';

interface IFormData extends Pick<IProject, 'planned_hours'> {
  new_planned_hours: number;
  new_planned_hours_limit: number;
}

interface IProjectHoursModalProps {
  project: IProject | undefined;
  projectHoursModalRef: React.RefObject<IModalRef>;
  setProject: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  closeModal: () => void;
}

const ProjectHoursModal: React.FC<IProjectHoursModalProps> = ({
  project,
  projectHoursModalRef,
  setProject,
  closeModal,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const { addToast } = useToast();

  const formRef = useRef<IFormHandles>(null);

  const initialData = useMemo(() => {
    return { planned_hours: project?.planned_hours };
  }, [project]);

  const placeholder = useMemo(() => {
    if (!project) return '';

    const nameArr = project?.name.split(' ');

    return nameArr[0].substring(0, 1);
  }, [project]);

  const hours = useMemo(() => {
    const budget = ProjectHelper.getBudget(project);

    return {
      percentage: budget.hours.percentage.toFixed(2),
      spent: toCurrency(budget.hours.spent),
      planned: toCurrency(budget.hours.planned),
    };
  }, [project]);

  const historics = useMemo(() => {
    const ordered =
      project?.project_hours_historic?.sort((a, b) => (a.id > b.id ? -1 : 1)) ||
      [];

    return ordered;
  }, [project]);

  const handleSubmit = useCallback(
    async (formData: IFormData) => {
      const schema = Yup.object().shape({
        new_planned_hours: STRING_REQUIRED('Informe as novas horas'),
        new_planned_hours_limit: STRING_REQUIRED('Informe a nova meta (em %)'),
      });

      trySubmit({
        formRef,
        addToast,
        messages: {
          success: 'UPDATE_PROJECT_HOUR_SUCCESS',
          error: 'UPDATE_PROJECT_HOUR_FAILURE',
        },
        onSubmit: async () => {
          await schema.validate(formData, { abortEarly: false });

          if (project) {
            const updated = await updateProject({
              id: project.id,
              planned_cost: project.planned_cost,
              planned_cost_limit: project.planned_cost_limit,
              planned_hours: formData.new_planned_hours,
              planned_hours_limit: formData.new_planned_hours_limit,
            });

            formRef.current?.reset({});
            formRef.current?.setFieldValue(
              'planned_hours',
              formData.new_planned_hours,
            );

            setProject(updated);
          }

          closeModal();
        },
      });
    },
    [addToast, setProject, project, closeModal],
  );

  return (
    <Modal
      ref={projectHoursModalRef}
      size="medium"
      hasNavigation={false}
      onClose={() => formRef.current?.reset({})}
    >
      <ModalContent>
        <Action>
          <Header>
            <ProjectInfo>
              <Avatar
                name="avatar_url"
                avatar_url={project?.avatar_url}
                avatarSize={{ width: 48, height: 48 }}
                iconSize={24}
                placeholder={placeholder}
                readOnly
              />

              <div>
                <strong>Planejar Horas</strong>
                <span>{project?.name}</span>
              </div>
            </ProjectInfo>

            <Statistic>
              <StatisticInfo>
                <StatisticInfoBadge>
                  <Badge color={colors.activities.hours}>
                    {hours.percentage} %
                  </Badge>
                </StatisticInfoBadge>

                <span>
                  {hours.spent} / {hours.planned}
                </span>
              </StatisticInfo>

              <StatisticBar
                goalPercentage={Number(project?.planned_hours_limit)}
                color={colors.activities.hours}
                percentage={+hours.percentage}
              />
            </Statistic>
          </Header>

          <Unform
            ref={formRef}
            initialData={initialData}
            onSubmit={handleSubmit}
          >
            <div>
              <Input
                label="Horas Planejadas"
                name="planned_hours"
                placeholder="Horas planejadas"
                mask="number"
                disabled
              />

              <Input
                label="Novas Horas"
                name="new_planned_hours"
                placeholder="Novas horas"
                mask="number"
              />

              <Input
                label="Nova Meta"
                name="new_planned_hours_limit"
                placeholder="Nova meta"
                mask="number"
              />
            </div>

            <Button type="submit">Atualizar</Button>
          </Unform>
        </Action>

        <Historics>
          <strong>Histórico</strong>

          <HistoricScroll>
            {historics.length === 0 ? (
              <HistoricNotFound>
                <span>
                  Nenhuma alteração de horas plenejadas foi encontrada
                </span>
              </HistoricNotFound>
            ) : (
              historics.map(historic => {
                return (
                  <Historic key={v4()}>
                    <p>{historic.description}</p>

                    <span>
                      {historic.created_at &&
                        format(
                          new Date(historic.created_at),
                          "'Alterado em 'dd MMM yyyy - HH'h'mm",
                          { locale },
                        )}
                    </span>
                  </Historic>
                );
              })
            )}
          </HistoricScroll>
        </Historics>
      </ModalContent>
    </Modal>
  );
};

export { ProjectHoursModal };
