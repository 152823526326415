import { t } from 'i18next';
import * as Yup from 'yup';

export const DEFAULT_MSG = (field: string) => {
  return t('FIELD_REQUIRED').replace('[FIELD]', field);
};

export const STRING_OPTIONAL = (message: string, condition: boolean) => {
  return condition
    ? Yup.string().required(t(message))
    : Yup.string().optional();
};

export const NUMBER_OPTIONAL = (message: string, condition: boolean) => {
  return condition
    ? Yup.number().required(t(message))
    : Yup.number().optional();
};

export const DATES_OPTIONAL = (message: string, condition: boolean) => {
  return condition ? Yup.array().length(2, t(message)) : Yup.array().optional();
};

export const STRING_REQUIRED = (message: string) => {
  return Yup.string().required(message);
};

export const NUMBER_REQUIRED = (message: string, min: number, max: number) => {
  return Yup.number().min(min, message).max(max, message);
};
