import styled, { css } from 'styled-components';

import { AvatarSize } from './index';

interface IContainerProps {
  avatarSize?: AvatarSize;
  hasFile: boolean;
}

const containerVariations = {
  withFile: css`
    border-radius: 100%;
    background: transparent;

    span {
      display: none;
    }

    label {
      width: 100%;
      height: 100%;

      border-radius: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;

        border-radius: 100%;

        object-fit: cover;

        display: block;
      }
    }
  `,
  withoutFile: css`
    border: 1px dashed #8b9dc3;
    border-radius: 100%;
    background: transparent;

    label {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: none;
      }
    }
  `,
};

export const Container = styled.div<IContainerProps>`
  ${({ avatarSize, hasFile, theme }) => css`
    width: ${avatarSize ? `${avatarSize.width}px` : '64px'};
    min-width: ${avatarSize ? `${avatarSize.width}px` : '64px'};
    height: ${avatarSize ? `${avatarSize.height}px` : '64px'};
    min-height: ${avatarSize ? `${avatarSize.height}px` : '64px'};

    background: #bdbdbd;
    border-radius: 50%;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: ${theme.colors.text.title};
      font-size: 24px;
      font-weight: bold;
      line-height: 26px;
      /* letter-spacing: 2.6px; */
      text-transform: uppercase;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translateY(-50%) translateX(-50%);
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    input {
      display: none;

      cursor: pointer;
    }

    button {
      /* width: 40px; */
      /* height: 40px; */
      width: 42%;
      height: 42%;

      border-radius: 100%;
      border: 0;
      background: #00bfff;

      position: absolute;
      right: -4px;
      bottom: -4px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      transition: all 400ms;

      &:hover {
        background: #00bfffcc;
      }
    }

    ${hasFile ? containerVariations.withFile : containerVariations.withoutFile}
  `}
`;
