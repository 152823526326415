import { colors } from '@components/bosons/colors';

export const light = {
  title: 'light',
  colors: {
    ...colors,
    timeline: {
      normalDay: '#FCFDFE',
      lines: '#D8E2EE',
      weekendDay: '#F0F4F9',
    },
    input: {
      ...colors.input,
      // filled: { text: '#FFFFFF', border: '#b1c5dd', label: '#292f4d' },
    },
    lines: '#B1C5DD',
    background: '#F0F4F9',
    asideMenu: '#FFFFFF',
    cards: '#FFFFFF',
    chart: colors.chart,
    hours: { ...colors.hours, empty: '#F0F4F9' },
    activities: { ...colors.activities, areas: '#FFFFFF' },
    text: { ...colors.text, title: '#292F4D', invertedTitle: '#FFFFFF' },
    shadow: 'rgba(177, 197, 221, 0.4)',
    // emptyLoading15DEG:
    //   'linear-gradient(15deg, rgba(41, 47, 77, 0), rgba(41, 47, 77, 0.5) 50%, rgba(41, 47, 77, 0) 80%)',
    // emptyLoading100DEG:
    //   'linear-gradient(100deg, rgba(41, 47, 77, 0), rgba(41, 47, 77, 0.5) 50%, rgba(41, 47, 77, 0) 80%)',
    emptyLoading15DEG:
      'linear-gradient(15deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%)',
    emptyLoading100DEG:
      'linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%)',
  },
};
