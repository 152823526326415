import React, { useRef, useCallback } from 'react';
import { HiOutlineCube } from 'react-icons/hi';

import { Button, DateRange } from '@components/atoms';
import { PageHeader } from '@components/molecules';
import { KanBan } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { DeliveryDrawer } from '@components/templates';

import { Container, KanBanContainer } from './styles';

const Deliveries: React.FC = () => {
  const deliveryDrawerRef = useRef<IDrawerRef>(null);

  const handleOpenDrawer = useCallback(() => {
    deliveryDrawerRef.current?.open();
  }, []);

  return (
    <Container>
      <PageHeader>
        {/* <Search
          name="search"
          config={DELIVERIES_FILTER_CONFIG}
          setQuery={setQuery}
          setIsFiltered={setIsFiltered}
          filteredResults={filteredResults}
          placeholder="Digite o nome de um usuário, grupo ou projeto"
        /> */}

        <div />

        <div style={{ display: 'flex', gap: '16px' }}>
          <DateRange
            label="Filtrar data"
            name="dates"
            placeholder="Definir Data"
            autoComplete="off"
          />

          <Button icon={HiOutlineCube} callToAction onClick={handleOpenDrawer}>
            Adicionar Entregas
          </Button>
        </div>
      </PageHeader>

      <KanBanContainer>
        <KanBan />
      </KanBanContainer>

      <DeliveryDrawer
        // projectId={projectId}
        // setProjectId={setProjectId}
        // setProjects={setProjects}
        deliveryDrawerRef={deliveryDrawerRef}
      />
    </Container>
  );
};

export { Deliveries };
