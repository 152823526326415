import styled, { css } from 'styled-components';

interface IContainerProps {
  isOpen?: boolean;
}

const containerCss = {
  visible: css`
    opacity: 1;
    visibility: visible;

    transition: opacity 400ms;
  `,
  hidden: css`
    opacity: 0;
    visibility: hidden;
  `,
};

export const Container = styled.div<IContainerProps>`
  ${({ theme, isOpen }) => css`
    /* border: 1px solid #1b203c; */
    border: 1px solid ${theme.colors.lines};
    border-radius: 6px;
    /* background: #334b7a; */
    background: ${theme.colors.asideMenu};

    position: absolute;
    bottom: -52px;

    z-index: 10;

    ${isOpen ? containerCss.visible : containerCss.hidden}

    button {
      width: 100%;

      color: #fbfbfb;

      padding: 8px 16px;

      gap: 8px;

      & + button {
        border-top: 1px solid ${theme.colors.lines};
      }

      svg {
        margin-right: 4px;
      }

      svg {
        stroke: ${theme.colors.text.title};

        &.custom-icon {
          width: 18px;

          stroke: none;

          path {
            fill: ${theme.colors.text.title};
          }
        }
      }

      span {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.small};
      }

      &:hover {
        color: #00bfff;

        svg {
          stroke: #00bfff;
        }

        svg.custom-icon {
          stroke: initial;

          path {
            fill: #00bfff;
          }
        }

        span {
          color: ${theme.colors.text.links};
        }
      }
    }
  `}
`;
