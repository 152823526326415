import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { customScroll } from '@styles/global';

export const Container = styled(Card).attrs({
  className: 'activities_container',
})`
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 200px);

  padding: 24px;

  gap: 32px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div.attrs({
  className: 'activities_header',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.strong.attrs({
  className: 'activities_header-left',
})`
  ${({ theme }) => css`
    color: ${theme.colors.text.title};
    font-size: ${theme.typography.fontSize.large};
  `}
`;

export const HeaderRight = styled.div.attrs({
  className: 'activities_header-right',
})`
  gap: 8px;
  display: flex;
`;

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.button.attrs({
  className: 'activities_tab',
})<ITabProps>`
  ${({ theme, isActive }) => css`
    border: 1px solid
      ${isActive
        ? theme.colors.activities.info
        : theme.colors.activities.selection};
    border-radius: ${theme.borders.radii[100]};
    background: ${isActive ? theme.colors.activities.info : 'transparent'};

    color: ${isActive ? '#FFFFFF' : theme.colors.activities.selection};
    font-size: ${theme.typography.fontSize.small};
    font-weight: ${theme.typography.fontWeight.normal};

    padding: 8px 16px;

    transition: all 400ms;

    &:hover {
      border-color: ${theme.colors.activities.info};
      background: ${theme.colors.activities.info};

      color: #ffffff;
    }
  `}
`;

export const Cards = styled.div.attrs({
  className: 'activities_cards',
})`
  ${({ theme }) => css`
    height: 100%;

    overflow-x: hidden;
    overflow-y: scroll;

    margin-right: -16px;
    padding-top: 24px;
    padding-right: 24px;

    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${customScroll(theme.colors.cards)}
  `}
`;

interface IActivityCardProps {
  isOnline?: boolean;
}

const activityCardVariations = (theme: any) => {
  return {
    online: css`
      &:after {
        content: '';

        width: 10px;
        height: 10px;

        border-radius: 50%;
        border: 3px solid ${theme.colors.cards};
        background: ${theme.colors.hours.productive};

        position: absolute;
        right: 0;
        bottom: 0;
      }
    `,
  };
};

export const ActivityCard = styled.div.attrs({
  className: 'activities_activity-card',
})<IActivityCardProps>`
  ${({ theme, isOnline }) => css`
    width: 100%;

    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    div:first-of-type {
      position: relative;

      img {
        width: 50px;
        height: 50px;

        border-radius: 50%;
      }

      ${isOnline && activityCardVariations(theme).online}
    }

    div:last-of-type {
      flex: 1;
      gap: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
      }
    }
  `}
`;

export const NotFoundData = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    border-radius: 8px;

    color: ${theme.colors.activities.selection};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ShimmerLoading = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 400px;

    background-image: ${theme.colors.emptyLoading100DEG},
      linear-gradient(${theme.colors.hours.empty} 15px, transparent 0),
      linear-gradient(${theme.colors.hours.empty} 15px, transparent 0),
      linear-gradient(${theme.colors.hours.empty} 20px, transparent 0);

    background-repeat: repeat-y;

    background-size: 50px 40px, 150px 60px, 80px 60px, 100% 60px; // largura altura

    background-position: 0 0, 0 0, 100% 0, 0 20px; // x y

    animation: shine 1s infinite;

    @keyframes shine {
      to {
        background-position: 100% 0, 0 0, 100% 0, 0 20px;
      }
    }
  `}
`;
