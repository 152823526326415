import { IGroup } from '@interfaces/IGroup';

export const GROUPS: IGroup[] = [];

export const GROUPS_ONE: IGroup[] = [
  {
    id: 1,
    name: 'DESENVOLVIMENTO',
    type: 'group',
    info: '',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 0,
  },
  {
    id: 4,
    name: 'Front End',
    type: 'subgroup',
    info: '',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 1,
  },
  {
    id: 5,
    name: 'Back End',
    type: 'subgroup',
    info: '',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 1,
  },
  {
    id: 6,
    name: 'Dados',
    type: 'subgroup',
    info: '',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 1,
  },
  {
    id: 7,
    name: 'Guilherme Ferreira',
    type: 'collaborator',
    info: 'Tech Lead',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 4,
  },
  {
    id: 8,
    name: 'Lucas Barbieri',
    type: 'collaborator',
    info: 'Tech Lead',
    organogram_id: 1,
    avatar_url: 'https://avatars.githubusercontent.com/u/4584018?v=4',
    parent_group_id: 4,
  },
  {
    id: 9,
    name: 'Augusto Silveira',
    type: 'collaborator',
    info: 'Software Architect',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 5,
  },
  {
    id: 10,
    name: 'Shayron Costa',
    type: 'collaborator',
    info: 'Web Developer',
    organogram_id: 1,
    avatar_url: '',
    parent_group_id: 5,
  },
  {
    id: 11,
    name: 'Jhonatan da Costa',
    type: 'collaborator',
    info: 'Analista de Dados',
    organogram_id: 1,
    avatar_url: 'https://avatars.githubusercontent.com/u/39928763?v=4',
    parent_group_id: 6,
  },
  {
    id: 12,
    name: 'Bruno Futema',
    type: 'collaborator',
    info: 'Web Developer',
    organogram_id: 1,
    avatar_url: 'https://avatars.githubusercontent.com/u/46576135?v=4',
    parent_group_id: 6,
  },
];

export const GROUPS_TWO: IGroup[] = [
  {
    id: 2,
    name: 'FINANCEIRO',
    type: 'group',
    info: '',
    organogram_id: 2,
    avatar_url: '',
    parent_group_id: 0,
  },
  {
    id: 22,
    name: 'Viviane Santos',
    type: 'collaborator',
    info: '',
    organogram_id: 2,
    avatar_url: '',
    parent_group_id: 2,
  },
];

export const GROUPS_THREE: IGroup[] = [
  {
    id: 3,
    name: 'MARKETING',
    type: 'group',
    info: '',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 0,
  },
  {
    id: 13,
    name: 'Eventos',
    type: 'subgroup',
    info: '',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 3,
  },
  {
    id: 14,
    name: 'Ads',
    type: 'subgroup',
    info: '',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 3,
  },
  {
    id: 15,
    name: 'Mayara Toledo',
    type: 'collaborator',
    info: 'Marketing Comercial',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 13,
  },
  {
    id: 16,
    name: 'Victor Borges de Toledo',
    type: 'subordinate',
    info: 'Redator',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 13,
  },
  {
    id: 17,
    name: 'Caio Cesar Crespo de Araujo',
    type: 'subordinate',
    info: 'Ads Manager',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 13,
  },
  {
    id: 18,
    name: 'Pedro Reis',
    type: 'collaborator',
    info: 'Redator',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 13,
  },
  {
    id: 19,
    name: 'Márcia Costa',
    type: 'collaborator',
    info: 'Marketing',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 14,
  },
  {
    id: 20,
    name: 'Victor de Moraes',
    type: 'collaborator',
    info: 'Marketing',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 14,
  },
  {
    id: 21,
    name: 'Caique Gomes',
    type: 'collaborator',
    info: 'Marketing',
    organogram_id: 3,
    avatar_url: '',
    parent_group_id: 13,
  },
];

export const GROUPS_FOUR: IGroup[] = [
  {
    id: 23,
    name: 'Design',
    type: 'group',
    info: '',
    organogram_id: 4,
    avatar_url: '',
    parent_group_id: 0,
  },
  {
    id: 24,
    name: 'UI Design',
    type: 'subgroup',
    info: 'UI Designer',
    organogram_id: 4,
    avatar_url: '',
    parent_group_id: 23,
  },
  {
    id: 25,
    name: 'Geovani',
    type: 'collaborator',
    info: 'UI Designer',
    organogram_id: 4,
    avatar_url: '',
    parent_group_id: 24,
  },
];
