import React, { useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { Avatar, Tooltip } from '@components/atoms';
import { IUser } from '@interfaces/IUser';

import { Container, Content } from './styles';

interface IUserTooltipProps {
  user: IUser;
  tooltip: React.ReactNode;
}

interface IUsersTooltipProps {
  users: IUser[];
  tooltip: React.ReactNode;
}

const UserTooltip: React.FC<IUserTooltipProps> = ({ user, tooltip }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const name = useMemo(() => {
    const nameArr = user.name.split(' ');

    const firstName = nameArr[0];
    const lastNameFirstLetter = nameArr[nameArr.length - 1].charAt(0);

    return `${firstName} ${lastNameFirstLetter}.`;
  }, [user.name]);

  return (
    <Container
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <Tooltip direction="BOTTOM" isVisible={isVisible}>
        <Content>
          <Avatar
            key={v4()}
            name="avatar_url"
            avatar_url={user.avatar_url}
            avatarSize={{ width: 40, height: 40 }}
            iconSize={20}
            placeholder={user.name.substring(0, 1)}
            readOnly
          />

          <div>
            <strong>{name}</strong>
            {/* <span>{user.email}</span> */}
          </div>
        </Content>
      </Tooltip>

      {tooltip}
    </Container>
  );
};

const UsersTooltip: React.FC<IUsersTooltipProps> = ({ users, tooltip }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Container
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <Tooltip direction="BOTTOM" isVisible={isVisible}>
        {users.map(user => {
          const nameArr = user.name.split(' ');

          const firstName = nameArr[0];
          const lastNameFirstLetter = nameArr[nameArr.length - 1].charAt(0);

          const name = `${firstName} ${lastNameFirstLetter}.`;

          return (
            <Content>
              <Avatar
                key={v4()}
                name="avatar_url"
                avatar_url={user.avatar_url}
                avatarSize={{ width: 40, height: 40 }}
                iconSize={20}
                placeholder={user.name.substring(0, 1)}
                readOnly
              />

              <div>
                <strong>{name}</strong>
              </div>
            </Content>
          );
        })}
      </Tooltip>

      {tooltip}
    </Container>
  );
};

export { UserTooltip, UsersTooltip };
