import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useEffect,
} from 'react';

import { format } from 'date-fns';

import { useToast } from '@hooks/useToast';
import { IOrganogram } from '@interfaces/IOrganogram';
import { IUser } from '@interfaces/IUser';
import {
  createGroup,
  deleteGroup,
  manageCollaborators,
  updateGroup,
} from '@services/apis/GroupsService';
import {
  deleteOrganogram,
  getOrganograms,
  updateOrganogram,
} from '@services/apis/OrganogramsService';
import { UsersService } from '@services/apis/UsersService';
import { getFirstAndLastDatesFromDate } from '@utils/getFirstAndLastDatesFromDate';

interface IAddSubgroup {
  organogram_id: number;
  parent_group_id: number;
  name: string;
  user_ids?: number[];
}

interface IUpdateSubgroup {
  id: number;
  name?: string;
  user_ids?: number[];
}

interface IFilters {
  startDate: string;
  endDate: string;
}

export interface IOrganogramContext {
  organograms: IOrganogram[];
  setOrganograms: React.Dispatch<React.SetStateAction<IOrganogram[]>>;
  onUpdateSubGroup: (data: IUpdateSubgroup) => void;
  onChangeColor: (organogram_id: number, color: string) => void;
  updateCollaborators: (group_id: number, user_ids: number[]) => void; // APAGAR
  onAddSubGroup: (data: IAddSubgroup) => void;
  onDeleteOrganogram: (organogram_id: number) => void;
  onDeleteSubGroup: (organogram_id: number, subgroup_id: number) => void;
  users: IUser[];
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  loadOrganograms: () => Promise<void>;
}

const OrganogramContext = createContext<IOrganogramContext>(
  {} as IOrganogramContext,
);

const OrganogramProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [organograms, setOrganograms] = useState<IOrganogram[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [filters, setFilters] = useState<IFilters>(() => {
    const [first, last] = getFirstAndLastDatesFromDate(new Date());

    return {
      startDate: format(first, 'yyyy-MM-dd'),
      endDate: format(last, 'yyyy-MM-dd'),
    };
  });

  useEffect(() => {
    async function loadUsers() {
      const { data } = await UsersService.list({
        query: {
          q: [
            {
              property: 'enabled',
              operator: 'EQUAL',
              value: '1',
              rule: 'AND',
              entity: 'users',
            },
          ],
          page: 1,
          limit: 0,
          sort: [{ order: 'ASC', property: 'name' }],
        },
        relations: [],
      });

      if (data) {
        setUsers(data);
      }
    }

    loadUsers();
  }, []);

  const loadOrganograms = useCallback(async () => {
    const data = await getOrganograms(filters);

    setOrganograms(data);
  }, [filters]);

  const onChangeColor = useCallback(
    async (organogram_id: number, color: string) => {
      await updateOrganogram({ id: organogram_id, color });

      setOrganograms(prevOrganograms => {
        return prevOrganograms.map(organogram => {
          if (organogram.id === organogram_id) return { ...organogram, color };

          return organogram;
        });
      });
    },
    [],
  );

  const onUpdateSubGroup = useCallback(
    async (formData: IUpdateSubgroup) => {
      await updateGroup({ ...formData, type: 'subgroup' });

      await loadOrganograms();

      addToast({ title: 'Subgrupo atualizado com sucesso!', type: 'success' });
    },
    [addToast, loadOrganograms],
  );

  const updateCollaborators = useCallback(
    async (group_id: number, user_ids: number[]) => {
      await manageCollaborators(group_id, user_ids);

      await loadOrganograms();
    },
    [loadOrganograms],
  );

  const onAddSubGroup = useCallback(
    async (data: IAddSubgroup) => {
      await createGroup({
        name: data.name,
        type: 'subgroup',
        parent_group_id: data.parent_group_id,
        organogram_id: data.organogram_id,
        user_ids: data.user_ids,
      });

      await loadOrganograms();

      addToast({ title: 'Subgrupo adicionado com sucesso!', type: 'success' });
    },
    [addToast, loadOrganograms],
  );

  const onDeleteOrganogram = useCallback(async (organogram_id: number) => {
    await deleteOrganogram({ id: organogram_id });

    setOrganograms(prevOrganograms =>
      prevOrganograms.filter(organogram => organogram.id !== organogram_id),
    );
  }, []);

  const onDeleteSubGroup = useCallback(
    async (organogram_id: number, subgroup_id: number) => {
      await deleteGroup({ id: subgroup_id });

      setOrganograms(prevOrganograms =>
        prevOrganograms.map(organogram => {
          if (organogram.id === organogram_id) {
            const childs = organogram.childs.filter(i => i.id !== subgroup_id);

            return { ...organogram, childs };
          }

          return organogram;
        }),
      );
    },
    [],
  );

  const value: IOrganogramContext = useMemo(
    () => ({
      organograms,
      setOrganograms,
      onUpdateSubGroup,
      onChangeColor,
      updateCollaborators,
      onAddSubGroup,
      onDeleteOrganogram,
      onDeleteSubGroup,
      users,
      filters,
      setFilters,
      loadOrganograms,
    }),
    [
      organograms,
      setOrganograms,
      onUpdateSubGroup,
      onChangeColor,
      updateCollaborators,
      onAddSubGroup,
      onDeleteOrganogram,
      onDeleteSubGroup,
      users,
      filters,
      setFilters,
      loadOrganograms,
    ],
  );

  return (
    <OrganogramContext.Provider value={value}>
      {children}
    </OrganogramContext.Provider>
  );
};

export { OrganogramContext, OrganogramProvider };
