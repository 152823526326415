import React from 'react';

import { v4 } from 'uuid';

import { KanBanColumn } from '@components/molecules';
import { IDeliveryStatus } from '@interfaces/IDelivery';

import { Container } from './styles';

export type IItem = {
  id: number;
  title: string;
  description: string;
  status: IDeliveryStatus;
};

const KanBan: React.FC = () => {
  // const data = [
  //   {
  //     IN_PROGRESS: [
  //       {
  //         id: 1,
  //         title: '#168 - Comunicação Black Friday',
  //         description: 'Geovani A. - Polishop',
  //         status: 'IN_PROGRESS',
  //       },
  //       {
  //         id: 2,
  //         title: '#140 - Atualização da API para v3 seguindo normas de LGPD',
  //         description: 'Guilherme F. - Polishop',
  //         status: 'IN_PROGRESS',
  //       },
  //       {
  //         id: 3,
  //         title: '#162 - Criar animação para a Black Friday',
  //         description: 'Caique G. - Polishop',
  //         status: 'IN_PROGRESS',
  //       },
  //       {
  //         id: 4,
  //         title:
  //           '#130 - Alteração do layout no SideMenu (ajustando a UX reduzindo o tamanho)',
  //         description: 'Lucas B. - Polishop',
  //         status: 'IN_PROGRESS',
  //       },
  //     ],
  //     DELAYED: [
  //       {
  //         id: 5,
  //         title: '#138 - Aplicação da API para v3 em stage de teste',
  //         description: 'Bruno F. - Polishop',
  //         status: 'DELAYED',
  //       },
  //       {
  //         id: 6,
  //         title:
  //           '#90 - Implementar a migração do frontend, provavelmente parte do projeto completo',
  //         description: 'Victor V. - Polishop',
  //         status: 'DELAYED',
  //       },
  //     ],
  //     FINISHED: [
  //       {
  //         id: 7,
  //         title: '#93 - Criar o chatbot diário de usuário / projeto',
  //         description: 'Jhonatan C. - Polishop',
  //         status: 'WAITING_FOR_APPROVAL',
  //       },
  //       {
  //         id: 8,
  //         title: '#89 - Melhorar desempenho do drag and drop',
  //         description: 'Bruno F. - Polishop',
  //         status: 'FINISHED',
  //       },
  //     ],
  //   },
  // ];

  const data: {
    title: string;
    color: string;
    order: number;
    items: IItem[];
  }[] = [
    {
      title: 'Em andamento',
      color: '#000000',
      order: 1,
      items: [
        {
          id: 1,
          title: '#168 - Comunicação Black Friday',
          description: 'Geovani A. - Polishop',
          status: 'IN_PROGRESS',
        },
        {
          id: 2,
          title: '#140 - Atualização da API para v3 seguindo normas de LGPD',
          description: 'Guilherme F. - Polishop',
          status: 'IN_PROGRESS',
        },
        {
          id: 3,
          title: '#162 - Criar animação para a Black Friday',
          description: 'Caique G. - Polishop',
          status: 'IN_PROGRESS',
        },
        {
          id: 4,
          title:
            '#130 - Alteração do layout no SideMenu (ajustando a UX reduzindo o tamanho)',
          description: 'Lucas B. - Polishop',
          status: 'IN_PROGRESS',
        },
      ],
    },
    {
      title: 'Atrasadas',
      color: '#000000',
      order: 2,
      items: [
        {
          id: 5,
          title: '#138 - Aplicação da API para v3 em stage de teste',
          description: 'Bruno F. - Polishop',
          status: 'DELAYED',
        },
        {
          id: 6,
          title:
            '#90 - Implementar a migração do frontend, provavelmente parte do projeto completo',
          description: 'Victor V. - Polishop',
          status: 'DELAYED',
        },
      ],
    },
    {
      title: 'Em espera',
      color: '#000000',
      order: 3,
      items: [
        {
          id: 7,
          title: '#93 - Criar o chatbot diário de usuário / projeto',
          description: 'Jhonatan C. - Polishop',
          status: 'WAITING_FOR_APPROVAL',
        },
      ],
    },
    {
      title: 'Finalizadas',
      color: '#000000',
      order: 4,
      items: [
        {
          id: 8,
          title: '#89 - Melhorar desempenho do drag and drop',
          description: 'Bruno F. - Polishop',
          status: 'FINISHED',
        },
      ],
    },
  ];

  return (
    <Container>
      {data.map(item => {
        return <KanBanColumn key={v4()} data={item} />;
      })}
    </Container>
  );
};

export { KanBan };
