import styled, { css } from 'styled-components';

import { IGroupType } from '@interfaces/IGroup';

export const Container = styled.div`
  height: 80px;

  user-select: none;
`;

export const Left = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    > span {
      max-width: 180px;

      color: ${theme.colors.text.title};
      font-size: 16px;
      font-weight: 500;
      text-overflow: ellipsis;

      overflow: hidden;

      transition: all 400ms;
    }

    > strong {
      color: ${theme.colors.text.subtitle};
      font-size: 14px;

      margin-top: 4px;
    }
  `}
`;

export const Cost = styled.div`
  ${({ theme }) => css`
    height: 24px;

    font-size: 14px;

    display: flex;
    gap: 8px;

    > strong {
      align-self: flex-end;

      > span {
        font-weight: normal;
        letter-spacing: -0.8px;

        margin-left: 4px;

        display: inline-block;
      }
    }

    > div {
      align-self: flex-start;

      border-radius: 8px;
      /* background: #1b203c; */
      background: ${theme.colors.background};

      padding: 2px 6px;

      display: flex;
      align-items: center;

      transition: all 400ms;

      svg {
        margin-right: 4px;

        path {
          stroke: ${theme.colors.text.subtitle};
        }
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  `}
`;

export const Hours = styled.div`
  ${({ theme }) => css`
    height: 24px;

    font-size: 14px;

    display: flex;
    gap: 8px;

    > strong {
      align-self: flex-end;

      > span {
        font-weight: normal;
        letter-spacing: -0.8px;

        margin-left: 4px;

        position: relative;

        display: inline-block;
      }
    }

    > div {
      align-self: flex-start;

      border-radius: 8px;
      /* background: #1b203c; */
      background: ${theme.colors.background};

      padding: 2px 6px;

      display: flex;
      align-items: center;

      transition: all 400ms;

      svg {
        margin-right: 4px;

        path {
          stroke: ${theme.colors.text.subtitle}; // #ff6c70 #0ce5a5 #8b9dc3
        }
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  `}
`;

interface IRightProps {
  color?: string;
  type: IGroupType;
}

export const Right = styled.div<IRightProps>`
  height: 100%;
  min-height: 70px;

  position: relative;

  z-index: 4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    border: 0;
    background: none;

    display: flex;
    align-items: center;

    transition: transform 400ms;
  }

  ${({ type, color }) =>
    type === 'collaborator'
      ? css`
          div {
            display: none;
          }
        `
      : css`
          ${type === 'group' &&
          css`
            > div:nth-of-type(1) {
              height: 22px;
              width: 22px;

              border-radius: 2px;
              border: 1px solid #1b203c;
              background: ${color};

              transition: 200ms;

              cursor: pointer;

              &:hover {
                filter: brightness(1.2);
              }
            }
          `}

          svg {
            transition: 200ms;
            cursor: pointer;

            &:hover {
              filter: brightness(0.8);
            }
          }
        `}
`;

export const MoreOptions = styled.div`
  ${({ theme }) => css`
    position: relative;

    > button {
      z-index: 1;

      svg {
        stroke: ${theme.colors.text.title};
      }
    }
  `}
`;
