import React, { useMemo, useEffect } from 'react';
import {
  HiOutlineInformationCircle,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineXCircle,
} from 'react-icons/hi';

import { CSSProperties } from 'styled-components';

import { X } from '@assets/icons';
import { ToastMessage } from '@contexts/ReactToastContext';
import { useTheme } from '@hooks/useTheme';
import { useToast } from '@hooks/useToast';
import { IColors } from '@interfaces/generic/ITheme';

import { Container } from './styles';

interface IToastProps {
  message: ToastMessage;
  style: CSSProperties;
}

const Toast: React.FC<IToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();
  const colors = useTheme<IColors>({ prop: 'colors' });

  const icons = useMemo(() => {
    const props = { size: 32, color: colors.background };

    return {
      info: <HiOutlineInformationCircle {...props} />,
      success: <HiOutlineCheckCircle {...props} />,
      warning: <HiOutlineExclamationCircle {...props} />,
      error: <HiOutlineXCircle {...props} />,
    };
  }, [colors.background]);

  useEffect(() => {
    const timer = setTimeout(() => removeToast(message.id), 3000);

    return () => clearTimeout(timer);
  }, [message.id, removeToast]);

  return (
    <Container key={message.id} type={message.type} style={style}>
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <X />
      </button>
    </Container>
  );
};

export { Toast };
