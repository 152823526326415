import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';

const widthResponsive = css`
  @media (min-width: 0px) and (max-width: 540px) {
    width: 50px;
    min-width: 50px;
    max-width: 50px;

    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
    }

    svg {
      margin: 0;

      display: block;
    }
  }
`;

interface IContainerProps {
  size?: 'medium' | 'large';
  actionType?: 'add' | 'remove' | 'cancel';
  hasIcon?: boolean;
  callToAction?: boolean;
  fullWidth?: boolean;
  outline?: boolean;
}

const buttonSizeVariations = {
  medium: css``,
  large: css``,
  callToAction: css`
    border-radius: 8px;
    background: #00b1ff;
  `,
};

const buttonStyleVariations = (theme: ITheme, outline: boolean) => ({
  add: css`
    width: 166px;
    min-width: 166px;

    border: 1px solid #00b1ff;
    /* background: ${outline ? `#00B1FF66` : '#00B1FF'}; */
    /* background: ${outline ? `transparent` : '#00B1FF'}; */
    background: ${`#00B1FF66`};

    &:hover {
      /* background: ${outline ? `#00B1FF` : '#00B1FF66'}; */
      background: ${`#00B1FFFF`};
    }

    svg {
      display: none;
    }
  `,
  remove: css`
    width: 166px;
    min-width: 166px;

    border: 1px solid #eb7777;
    /* background: ${outline ? `#eb777766` : '#eb7777'}; */
    /* background: ${outline ? `transparent` : '#eb7777'}; */

    background: ${`#eb777766`};

    &:hover {
      /* background: ${outline ? '#eb7777' : `#eb7777FF`}; */
      background: ${`#eb7777FF`};
    }

    svg {
      display: none;
    }
  `,
  cancel: css`
    width: 166px;
    min-width: 166px;

    border: 1px solid #6c87bc;
    /* background: ${outline ? `#6c87bc66` : '#6c87bc'}; */
    /* background: ${outline ? `transparent` : '#6c87bc'}; */

    background: ${`#6c87bc66`};

    /* color: #6c87bc; */

    &:hover {
      /* background: ${outline ? '#6c87bc' : `#6c87bcFF`}; */
      /* color: ${theme.colors.white}; */
      background: ${`#6c87bcFF`};
    }

    svg {
      display: none;
    }
  `,
});

export const Container = styled.button<IContainerProps>`
  ${({
    theme,
    size,
    actionType,
    callToAction,
    hasIcon,
    fullWidth,
    outline = false,
  }) => css`
    width: ${fullWidth ? '100% !important' : '100%'};
    max-width: ${fullWidth ? 'initial' : 'fit-content'};
    min-height: 42px;

    border: 1px solid #00b1ff;
    border-radius: ${theme.borders.radii[100]};
    background: #00b1ff66;

    color: ${theme.colors.white};
    font-size: 16px;
    font-family: Roboto, Poppins, Archivo, sans-serif;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;

    padding: 8px 16px;

    user-select: none;
    cursor: pointer;

    transition: border 400ms, background 300ms, transform 100ms;

    display: flex;
    align-items: center;
    justify-content: ${hasIcon ? 'space-between' : 'center'};

    &:hover {
      background: #00b1ffff;
    }

    &:active {
      transform: scale(0.96);
    }

    ${callToAction && buttonSizeVariations.callToAction}
    ${size && buttonSizeVariations[size]}
    ${actionType && buttonStyleVariations(theme, outline)[actionType]}


    svg {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;

      margin-left: 12px;
    }

    /* ${widthResponsive} */
  `}
`;
