import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { customScroll } from '@styles/global';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      height: calc(100% - 76px);

      padding: 24px;

      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  `,
  CustomHeader: css`
    @media (max-width: 768px) {
      display: none;
    }
  `,
  StaticticInfo: css`
    @media (max-width: 768px) {
      > div span {
        white-space: nowrap;
      }

      > span {
        font-size: 14px;
        text-align: right;
      }
    }
  `,
  CollaboratorsAvatars: css`
    @media (max-width: 768px) {
      > div {
        display: none;

        &:last-of-type {
          display: initial;
        }
      }
    }
  `,
  TagsContainer: css`
    @media (max-width: 768px) {
      padding: 16px;
    }
  `,
  Tags: css`
    @media (max-width: 768px) {
      height: 120px;
    }
  `,
};

export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100% - 92px);

    padding: 24px 48px;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 32px;

    ${customScroll(theme.colors.background)}
    ${responsive.Container}
  `}
`;

interface IInformativeBoxProps {
  isActive?: boolean;
}

export const InformativeBox = styled.div<IInformativeBoxProps>`
  ${({ theme, isActive }) => css`
    display: ${isActive ? 'flex' : 'none'};
    justify-content: space-between;

    background: #ffc4004a;
    border: 2px solid ${theme.colors.activities.warning};
    border-radius: ${theme.borders.radii[500]};

    padding: 16px;

    > svg:last-child {
      cursor: pointer;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      gap: 24px;
      margin: 0px 24px;

      > button {
        height: 42px;
      }
    }
  `}
`;

export const CustomHeader = styled.div`
  ${responsive.CustomHeader}
`;

export const ProjectInfo = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;

    button {
      border: 0;
      background: 0;

      transition: all 400ms;

      &:hover {
        transform: scale(0.9);

        svg {
          stroke: ${theme.colors.activities.info};
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
      }
    }

    > svg {
      color: ${theme.colors.white};

      cursor: pointer;
    }
  `}
`;

export const SwitcherContainer = styled.div`
  ${({ theme }) => css`
    height: 40px;
    border-left: 1px solid ${theme.colors.lines};

    margin-left: 24px;
    padding-left: 24px;

    display: flex;
    align-items: center;
  `}
`;

export const Dashboard = styled(Card)`
  height: 268px;

  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Statistics = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Statistic = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatisticInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.normal};
    }

    ${responsive.StaticticInfo}
  `}
`;

export const StatisticInfoBadge = styled.div`
  ${({ theme }) => css`
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > div {
      color: ${theme.colors.background};
      font-size: ${theme.typography.fontSize.small};
      font-weight: bold;
    }
  `}
`;

interface IStatisticBarProps {
  percentage: number;
  color: string;
  goalPercentage?: number;
}

export const StatisticBar = styled.div<IStatisticBarProps>`
  ${({ theme, color, percentage, goalPercentage }) => css`
    width: 100%;
    height: 4px;

    background: ${theme.colors.background};
    border-radius: ${theme.borders.radii[500]};

    position: relative;

    ${!!goalPercentage &&
    css`
      &:before {
        content: '';

        width: 2px;
        height: 18px;

        background: ${color};
        border-radius: ${theme.borders.radii[500]};

        position: absolute;
        left: ${goalPercentage}%;
        top: 50%;

        transform: translateY(-50%);
      }
    `}

    &:after {
      content: '';

      width: ${percentage > 100 ? 100 : percentage}%;
      height: 6px;

      background: ${color};
      border-radius: ${theme.borders.radii[500]};

      position: absolute;
      left: 0;
      top: -1px;
    }
  `}
`;

export const Collaborators = styled(Card)`
  height: 88px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CollaboratorsTitle = styled.div`
  ${({ theme }) => css`
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: ${theme.typography.fontSize.large};
    }

    span {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.normal};
    }
  `}
`;

export const CollaboratorsAvatars = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;

  ${responsive.CollaboratorsAvatars}
`;

interface ICollaboratorsQuantityProps {
  avatarSize?: { width: number; height: number };
}

export const CollaboratorsQuantity = styled.div<ICollaboratorsQuantityProps>`
  ${({ avatarSize, theme }) => css`
    width: ${avatarSize ? `${avatarSize.width}px` : '64px'};
    min-width: ${avatarSize ? `${avatarSize.width}px` : '64px'};
    height: ${avatarSize ? `${avatarSize.height}px` : '64px'};
    min-height: ${avatarSize ? `${avatarSize.height}px` : '64px'};

    color: ${theme.colors.text.invertedTitle};
    font-weight: ${theme.typography.fontWeight.bold};

    background: ${theme.colors.text.title};
    border-radius: 50%;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    span {
      color: ${theme.colors.text.default};
      font-size: 24px;
      font-weight: bold;
      line-height: 26px;
      letter-spacing: 2.6px;
      text-transform: uppercase;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translateY(-50%) translateX(-50%);
    }
  `}
`;

export const TagsContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.cards};
    border-radius: 16px;

    padding: 24px;

    flex: 1;
    gap: 16px;
    display: flex;
    flex-direction: column;

    ${responsive.TagsContainer}
  `}
`;

export const Graphic = styled(Card)`
  ${({ theme }) => css`
    width: 100%;
    max-height: 600px;
    min-height: 600px;
    height: calc(100vh - 140px);

    padding: 24px;

    gap: 32px;
    display: flex;
    flex-direction: column;

    .apexcharts-legend-text {
      color: ${theme.colors.text.title} !important;
      margin-left: 16px;
    }

    svg {
      path {
        stroke: transparent;
      }
    }
  `}
`;

export const ChartContainer = styled.div`
  ${() => css`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.strong`
  ${({ theme }) => css`
    color: ${theme.colors.text.title};
    font-size: ${theme.typography.fontSize.large};
  `}
`;

export const HeaderRight = styled.div`
  gap: 8px;
  display: flex;
`;

export const Tags = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    height: 70px;

    gap: 12px;

    padding-right: 8px;

    overflow-y: scroll;

    ${customScroll(theme.colors.background)}

    input {
      height: 26px;
      background-color: transparent;
      border: 0;
      color: ${theme.colors.text.title};
    }

    input::placeholder {
      color: ${theme.colors.text.input};
    }

    ${responsive.Tags}
  `}
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    width: fit-content;
    height: fit-content;

    border-radius: ${theme.borders.radii[500]};
    border: 1px solid ${theme.colors.activities.tagsWithAction};
    background: ${theme.colors.activities.tagsWithAction};

    padding: 2px 6px 2px 12px;

    gap: 6px;
    display: flex;
    align-items: center;

    user-select: none;

    transition: all 400ms;

    &:hover {
      background: ${theme.colors.cards};
      border-color: ${theme.colors.text.links};

      cursor: pointer;

      strong {
        color: ${theme.colors.activities.info};
      }
    }

    strong {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.small};
      font-weight: normal;
      white-space: nowrap;
    }

    svg {
      stroke: ${theme.colors.text.links};
      cursor: pointer;

      transition: all 400ms;

      &:hover {
        filter: brightness(1.2);
        transform: scale(1.2);
      }
    }
  `}
`;

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.button<ITabProps>`
  ${({ theme, isActive }) => css`
    border: 1px solid
      ${isActive
        ? theme.colors.activities.info
        : theme.colors.activities.selection};
    border-radius: ${theme.borders.radii[100]};
    background: ${isActive ? theme.colors.activities.info : 'transparent'};

    color: ${isActive ? '#FFFFFF' : theme.colors.activities.selection};
    font-size: ${theme.typography.fontSize.small};
    font-weight: ${theme.typography.fontWeight.normal};

    padding: 8px 16px;

    transition: all 400ms;

    &:hover {
      border-color: ${theme.colors.activities.info};
      background: ${theme.colors.activities.info};

      color: #ffffff;
    }
  `}
`;
