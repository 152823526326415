import React from 'react';

import { IGroupType } from '@interfaces/IGroup';

import { Container } from './styles';

interface IOrganogramLinesProps {
  color: string;
  type: IGroupType;
  belowQuantity: number;
  hasChildren?: boolean;
  lastHasChildren: boolean;
  level: number;
  parentBelowQuantity: number;
  className?: string;
}

const OrganogramLines: React.FC<IOrganogramLinesProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <Container className={className} {...rest}>
      {children}
    </Container>
  );
};

export { OrganogramLines };
