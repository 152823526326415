import styled, { css } from 'styled-components';

// const responsive = css`
//   @media (min-width: 961px) {
//     .organisms-hamburger {
//       display: none;
//     }
//   }
// `;

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      padding: 16px 24px;
    }
  `,
  Left: css`
    @media (max-width: 768px) {
      h1 {
        display: none;
      }
    }
  `,
  Hamburger: css`
    @media (max-width: 768px) {
      border: 0;

      margin: 0;
      padding: 0;

      display: flex;
    }
  `,
  SwitcherContainer: css`
    @media (max-width: 768px) {
      border: 0;

      margin-left: 10px;
      padding-left: 10px;
    }
  `,
  Avatar: css`
    @media (max-width: 768px) {
      div {
        display: none;
      }
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'organisms-header_container',
})`
  width: 100%;

  padding: 24px 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${responsive.Container}
`;

export const Left = styled.div.attrs({
  className: 'organisms-header_left',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${responsive.Left}
`;

export const Hamburger = styled.div`
  ${({ theme }) => css`
    height: 40px;

    border-right: 1px solid ${theme.colors.lines};

    margin-right: 24px;
    padding-right: 24px;

    display: none;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    &:hover {
      filter: brightness(0.8);

      cursor: pointer;
    }

    ${responsive.Hamburger}
  `}
`;

export const SwitcherContainer = styled.div.attrs({
  className: 'organisms-switcher_container',
})`
  ${({ theme }) => css`
    height: 40px;
    border-left: 1px solid ${theme.colors.lines};

    margin-left: 24px;
    padding-left: 24px;

    display: flex;
    align-items: center;

    ${responsive.SwitcherContainer}
  `}
`;

export const Right = styled.div.attrs({
  className: 'organisms-header_right',
})`
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Avatar = styled.div.attrs({
  className: 'organisms-header_avatar',
})`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
      }
    }

    img {
      width: 44px;
      height: 44px;

      border-radius: 50%;

      object-fit: cover;
    }

    ${responsive.Avatar}
  `}
`;

export const Notifications = styled.div.attrs({
  className: 'organisms-header-notifications',
})`
  ${({ theme }) => css`
    button {
      border: 0;
      background: 0;

      position: relative;

      > div {
        width: 8px;
        height: 8px;

        border-radius: 50%;
        background: ${theme.colors.text.links};

        position: absolute;
        top: -4px;
        right: -2px;

        &:before {
          content: '';

          height: 18px;
          width: 18px;

          border-radius: 50%;
          background: ${theme.colors.text.links};

          position: absolute;
          top: -5px;
          left: -5px;

          opacity: 0.4;

          z-index: 11;
        }

        &:after {
          content: '';

          height: 28px;
          width: 28px;

          border-radius: 50%;
          background: ${theme.colors.text.links};

          position: absolute;
          top: -10px;
          left: -10px;

          opacity: 0.2;

          z-index: 10;
        }
      }

      svg {
        stroke: ${theme.colors.text.title};
      }
    }
  `}
`;
