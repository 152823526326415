import React from 'react';
import { HiOutlineChatAlt2, HiOutlineX } from 'react-icons/hi';

import { IAlert } from '../types';

import { Container, Overlay, Header, MessagesList } from './styles';

interface IMessagesProps {
  alert: IAlert;
  isOpen: boolean;
  alwaysOpen: boolean;
  messages: JSX.Element[];
  onClose: () => void;
}

const Messages: React.FC<IMessagesProps> = ({
  alert,
  isOpen,
  alwaysOpen,
  messages,
  onClose,
}) => {
  return (
    <Container isOpen={alwaysOpen || isOpen} alwaysOpen={alwaysOpen}>
      <Overlay
        isOpen={alwaysOpen || isOpen}
        alwaysOpen={alwaysOpen}
        onClick={onClose}
      />

      <Header alert={alert}>
        <div>
          <HiOutlineChatAlt2 size={24} />
          <strong>Daily Status</strong>
        </div>

        <button type="button" onClick={onClose}>
          <HiOutlineX size={24} />
        </button>
      </Header>

      <MessagesList id="chat-bot-messages">
        {messages.map(message => message)}
      </MessagesList>
    </Container>
  );
};

export { Messages };
