import React, { useRef } from 'react';

import { v4 } from 'uuid';
// import React, { useRef, useCallback } from 'react';
// import { HiOutlineCube } from 'react-icons/hi';

import { ScreenshotsGrid } from '@components/organisms';
import { IDrawerRef } from '@components/organisms/Drawer';
import { ScreenshotDrawer } from '@components/templates';

import { Container, ScreenshotsContainer } from './styles';

const Screenshots: React.FC = () => {
  const screenshotDrawerRef = useRef<IDrawerRef>(null);

  // const handleOpenDrawer = useCallback(() => {
  //   screenshotDrawerRef.current?.open();
  // }, []);

  return (
    <Container>
      <ScreenshotsContainer>
        <ScreenshotsGrid key={v4()} />
      </ScreenshotsContainer>

      <ScreenshotDrawer
        // projectId={projectId}
        // setProjectId={setProjectId}
        // setProjects={setProjects}
        screenshotDrawerRef={screenshotDrawerRef}
      />
    </Container>
  );
};

export { Screenshots };
