import styled, { css } from 'styled-components';

import { IGroupType } from '@interfaces/IGroup';

const verticalLine = css`
  height: var(--line-height);

  position: absolute;
  top: 0;

  content: '';
  box-sizing: border-box;
`;

interface IContainerProps {
  color: string;
  type: IGroupType;
  level: number;
  belowQuantity: number;
  parentBelowQuantity: number;
  hasChildren?: boolean;
  lastHasChildren?: boolean;
}

const containerVariations = (color: string) => {
  return {
    levelOne: css`
      border-left: var(--line-width) solid ${color};
    `,
    minorOfLevelOne: css`
      border-right: 1px solid ${color};
      left: 1px;
    `,
    majorOfLevelOne: css`
      width: 45%;
      height: 168px;

      border-left: var(--line-width) solid ${color};

      top: -64px;
    `,
    firstMinorOfLevelOne: css`
      border: 0 none;
    `,
    firstMajorOfLevelOne: css`
      width: 40%;
      height: 60%;

      border-top: var(--line-width) solid ${color} !important;
      border-left: var(--line-width) solid ${color} !important;
      border-radius: 8px 0 0 0 !important;

      top: -1px;
      right: 55%;
    `,
    lastMinorOfLevelOne: css`
      border-right: var(--line-width) solid ${color};
      border-radius: 0 var(--line-border-radius) 0 0;

      left: 0;
    `,
    lastMajorOfLevelOne: css`
      width: 45%;
      height: 168px;

      border-left: var(--line-width) solid ${color};

      top: -64px;
    `,
    withoutPadding: css`
      padding: 0;
    `,
  };
};

export const Container = styled.li<IContainerProps>`
  ${({ level, color, type, ...rest }) => css`
    ${level > 2 && containerVariations(color).withoutPadding}

    &:before, &:after {
      ${verticalLine}

      width: 50%;

      border-top: var(--line-width) solid ${color};

      right: 50%;
    }

    &:after {
      ${level <= 1 && containerVariations(color).levelOne}

      left: 50%;
    }

    &:only-of-type {
      ${level === 0 && containerVariations(color).withoutPadding}
    }

    &:before {
      ${level > 1
        ? containerVariations(color).majorOfLevelOne
        : containerVariations(color).minorOfLevelOne}
    }

    &:first-of-type {
      &:before {
        ${level > 1
          ? containerVariations(color).firstMajorOfLevelOne
          : containerVariations(color).firstMinorOfLevelOne}
      }

      &:after {
        border-radius: var(--line-border-radius) 0 0 0;

        left: calc(50% + 1px);
      }
    }

    &:last-of-type {
      &:before {
        ${level <= 1
          ? containerVariations(color).lastMinorOfLevelOne
          : containerVariations(color).lastMajorOfLevelOne}

        ${type === 'collaborator' &&
        level === 2 &&
        rest.parentBelowQuantity === 1 &&
        css`
          width: 40%;
          height: 60%;

          top: -1px;
        `}

        ${level === 4 &&
        css`
          width: 142px;
          height: 128px;

          border-right: var(--line-width) solid ${color};
          border-left: var(--line-width) solid ${color};

          top: -10px;
          right: calc(50% - 20px);
        `}

        ${level === 2 &&
        rest.hasChildren &&
        css`
          height: 60%;
          width: 40%;

          top: 0;
        `}

        ${rest.lastHasChildren &&
        level === 2 &&
        css`
          height: 110px !important;
        `}
      }

      &:after {
        border: 0 none;
      }
    }

    > ul {
      ::before {
        width: 0;

        ${level === 0 &&
        rest.belowQuantity === 1 &&
        css`
          height: 80px !important;
        `}

        ${level === 0
          ? css`
              border-left: var(--line-width) solid ${color};
            `
          : css`
              width: 16px;
              top: 0.5px !important;
              border-right: var(--line-width) solid ${color};
              border-bottom: var(--line-width) solid ${color};
              border-radius: 0 0 8px 0 !important;

              transform: translateX(-16px);
            `}

          ${level > 1 &&
        css`
          width: 16px;
          top: 0.5px !important;
          border-right: var(--line-width) solid ${color};
          border-bottom: 0;
          border-radius: 0 0 0 0 !important;
          left: 58% !important;

          transform: translateX(-16px);
        `}

          left: 50%;

        ${verticalLine}
      }
    }

    &:first-child::before {
      ${level > 2
        ? css`
            content: '';

            height: 74%;
            width: 40%;

            border-top: 0 !important;
            border-left: var(--line-width) solid ${color} !important;
            border-radius: 0 0 0 0 !important;

            position: absolute;
            top: -16px;
            right: 148px;

            transition: 0;

            ${!rest.lastHasChildren &&
            level > 4 &&
            css`
              display: none;
            `}
          `
        : css`
            border: 0 none;
          `}

      ${rest.lastHasChildren &&
      level > 2 &&
      css`
        display: none;
      `}
    }

    ${level === 4 &&
    type === 'collaborator' &&
    css`
      padding-top: var(--line-height);
    `}
  `}
`;
