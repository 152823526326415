import { transparentize } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 40px;

  border-radius: 4px;
  background: #0a192f;
  background: ${({ theme }) => theme.colors.background};

  position: relative;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  transition: all 400ms;

  z-index: 100;

  &:hover {
    cursor: pointer;

    > input {
      border-color: #00b0ff;

      &::placeholder {
        color: #00b0ff;
      }
    }

    > svg {
      stroke: #00b0ff;
    }
  }

  > svg {
    stroke: ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};

    position: absolute;
    right: 0.75rem;

    transition: all 400ms;
  }
`;

interface IInputProps {
  isOpen: boolean;
}

export const Input = styled.input<IInputProps>`
  height: 42px;

  border: 1px solid ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};
  border-radius: 4px;
  background: #0a192f;
  background: ${({ theme }) => theme.colors.background};

  padding: 0 8px;

  cursor: pointer;

  transition: all 400ms;

  &::placeholder {
    color: ${({ isOpen }) => (isOpen ? '#00b0ff' : '#6c87bf')};
    font-size: 0.875rem;

    transition: all 400ms;
  }
`;

interface IFilterSelectorProps {
  isOpen: boolean;
}

export const FilterSelector = styled.div<IFilterSelectorProps>`
  width: 100%;

  border: 1px solid #00b0ff;
  border-radius: 4px;
  background: #0a192f;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 0px 24px ${({ theme }) => theme.colors.shadow};

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};

  position: absolute;
  top: ${({ isOpen }) => (isOpen ? 'calc(100% + 8px)' : '100%')};
  right: 0px;

  cursor: initial;

  display: flex;
  flex-direction: column;

  transition: all 400ms;
`;

export const List = styled.ul`
  max-height: 195px;

  border-radius: 4px;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #334b7a;
    border-left: 3.5px solid #0a192f;
    border-right: 3.5px solid #0a192f;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00bfff;
    border-radius: 7px;
    border-left: 2px solid #0a192f;
    border-right: 2px solid #0a192f;
  }
`;

interface IItemProps {
  color: string;
}

export const Item = styled.li<IItemProps>`
  width: 100%;
  height: 40px;

  border-radius: 4px;

  position: relative;

  cursor: pointer;

  &:hover {
    &.hover {
      background: ${transparentize(0.6, '#334B7A')};
    }

    cursor: pointer;

    &::before {
      opacity: 1;
    }
  }

  input {
    display: none;

    &:checked {
      & ~ label:before {
        border-color: #00bfff;
        background: #00bfff;
      }

      & ~ label:after {
        content: ' ';

        width: 18px;
        height: 18px;

        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+);
        background-repeat: no-repeat;
        background-size: 8px 8px;
        background-position: center center;
        background-color: transparent;

        font-size: 10px;
        text-align: center;

        margin-left: 0px;

        position: absolute;
        top: 11px;
        left: 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: all 400ms;
      }
    }

    &:disabled {
      & ~ label:before {
        border-color: ${transparentize(0.4, '#ffffff')};
        background: ${transparentize(0.4, '#0a192f')};
      }

      & ~ label {
        cursor: not-allowed;

        span {
          color: ${transparentize(0.4, '#ffffff')};
        }
      }
    }
  }

  label {
    width: 100%;
    max-width: 220px;
    height: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;

    position: absolute;
    top: 50%;
    left: 16px;

    display: flex;
    align-items: center;

    overflow: hidden;

    cursor: pointer;

    transform: translateY(-50%);

    transition: all 400ms;

    &:before {
      content: ' ';

      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;

      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.text.title};
      border-radius: 4px;
      background-color: transparent;
      box-shadow: none;

      margin-right: 8px;

      display: inline-block;
      vertical-align: middle;

      transition: all 400ms;
    }

    span {
      max-width: 220px;

      color: #ffffff;
      color: ${({ color }) => color};
      text-overflow: ellipsis;
      white-space: nowrap;

      overflow: hidden;
    }
  }
`;
