import React, { useMemo, useState, useCallback } from 'react';

import { useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { useToast } from '@hooks/useToast';
import { ChatBotService } from '@services/apis/ChatBotService';
import { toHM } from '@utils/toHM';
import { toHMS } from '@utils/toHMS';

import { IComponentsProps } from '../../types';

import { ChatButton, Message } from '../../../Messages/styles';

const ThenYouWorked: React.FC<IComponentsProps> = ({
  response: { activities, answers },
  onAnswer,
}) => {
  const { addToast } = useToast();

  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onYes = useCallback(async () => {
    setIsLoading(true);

    try {
      const summary_activities_ids = activities.map(item => item.id);

      const question = `Então você trabalhou: ${activities.map(
        ({ original_worked_hours, project: p }) => {
          return `${toHMS(original_worked_hours)} no projeto ${p.name}`;
        },
      )} Essa informação está correta?`;

      await ChatBotService.confirmAdjustedWorkedHours({
        summary_activities_ids,
        question,
        answer: 'Sim',
        worked_date,
      });

      onAnswer({ dailyState: 'CONFIRMED_ADJUSTED_WORKED_HOURS' });
      // setLastStateAnswer('CONFIRMED_ADJUSTED_WORKED_HOURS');
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao confirmar suas horas trabalhadas!',
      });
    } finally {
      setIsLoading(false);
    }
  }, [activities, addToast, onAnswer, worked_date]);

  const onNo = useCallback(async () => {
    setIsLoading(true);

    try {
      const summary_activities_ids = activities.map(activity => {
        return activity.id;
      });

      await ChatBotService.cancelAdjustedWorkedHours({
        summary_activities_ids,
        worked_date,
      });

      onAnswer({ dailyState: 'NOT_CONFIRMED_WORKED_HOURS' });
      // if (sessionStorage.openDailyChat === 'true') window.location.reload();
    } catch {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao tentar corrigir suas horas trabalhadas!',
      });
    } finally {
      setIsLoading(false);
    }
  }, [activities, addToast, onAnswer, worked_date]);

  const answer = useMemo(() => {
    const adjusted = answers.find(
      ({ flow_stopped }) => flow_stopped === 'CONFIRMED_ADJUSTED_WORKED_HOURS',
    );

    if (adjusted) return adjusted.flow_stopped;

    return null;
  }, [answers]);

  return (
    <Message.Others>
      <div>
        <p>Então você trabalhou:</p>

        {activities.map(activity => {
          return (
            <p key={v4()}>
              <b>
                {toHM(Number(activity.checked_worked_hours))
                  .replace(':', 'h ')
                  .replace(':', 'm ')}
                min
              </b>{' '}
              no projeto
              <b> {activity.project.name}</b>
            </p>
          );
        })}

        <br />

        <p>Essa alteração está correta?</p>
      </div>

      <div style={{ marginTop: '16px', display: 'flex', gap: '16px' }}>
        <ChatButton
          disabled={!!answer || isLoading}
          variation={
            answer === 'CONFIRMED_ADJUSTED_WORKED_HOURS'
              ? 'SELECTED'
              : 'NOT_SELECTED'
          }
          style={{ maxWidth: '120px' }}
          onClick={onYes}
        >
          <span>Sim</span>
        </ChatButton>

        <ChatButton
          disabled={!!answer || isLoading}
          variation={
            answer === 'NOT_CONFIRMED_WORKED_HOURS'
              ? 'SELECTED'
              : 'NOT_SELECTED'
          }
          style={{ maxWidth: '120px' }}
          onClick={onNo}
        >
          <span>Não</span>
        </ChatButton>
      </div>
    </Message.Others>
  );
};

export { ThenYouWorked };
