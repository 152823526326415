import React from 'react';

import { Container } from './styles';

interface IRowProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  columns?: number;
}

const Row: React.FC<IRowProps> = ({
  className,
  children,
  columns = 1,
  ...rest
}) => {
  return (
    <Container className={className} columns={columns} {...rest}>
      {children}
    </Container>
  );
};

const Component = React.memo(Row);

export { Component as Row };
