import styled, { css } from 'styled-components';

interface IContainerProps {
  columns: number;
}

export const Container = styled.div<IContainerProps>`
  ${({ columns }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    gap: 32px;
  `}
`;
