/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
function toHM(sec: number): string {
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  
    // @ts-ignore
    if (hours   < 10) {hours   = "0"+hours;}
    // @ts-ignore
    if (minutes < 10) {minutes = "0"+minutes;}
  
    return hours+':'+minutes; // Return is HH : MM
  }
  
  export { toHM };
  