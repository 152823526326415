import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { OrganogramLines } from '@components/quarks/Organogram/Lines';
import { IGroupType } from '@interfaces/IGroup';

export const Container = styled(OrganogramLines)`
  text-align: center;

  padding: var(--line-height) var(--node-padding) 0 var(--node-padding);

  position: relative;

  flex: auto;
`;

interface IContentProps {
  color: string;
  type: IGroupType;
  level: number;
  isOpen: boolean;
  parentGroupParentId?: number;
}

const contentVariations = (theme: any, level: number) => {
  return {
    opened: css`
      &:hover {
        background: ${darken(0.05, theme.colors.cards)};
      }
    `,
    closed: css`
      /* background: #373d5d; */
      background: ${theme.colors.cards};

      &:hover {
        background: ${darken(0.02, theme.colors.cards)};
      }
    `,
    contentType: {
      enterprise: css``,
      group: css`
        width: 398px;

        border: 0;
      `,
      subgroup: css`
        width: 258px;
      `,
      collaborator: css`
        width: 218px;

        margin-left: 38px !important;

        ${level === 1 &&
        css`
          margin-left: 0 !important;
        `}
      `,
      subordinate: css`
        width: 218px;

        margin-left: 38px;
      `,
    },
    contentResetDirectChild: css`
      margin-left: 0;
    `,
  };
};

export const Content = styled.div<IContentProps>`
  ${({ theme, level, color, type, isOpen, parentGroupParentId }) => css`
    border: 1px solid ${color};
    border-radius: 6px;
    /* background: #334b7a; */
    background: ${theme.colors.cards};
    box-shadow: 0 0 32px 0 ${theme.colors.shadow};

    padding: 16px;

    position: relative;

    display: inline-block;

    cursor: pointer;

    transition: all 400ms;

    white-space: nowrap;

    ${isOpen &&
    css`
      box-shadow: 0 6px 6px ${theme.colors.shadow};
    `}

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ${contentVariations(theme, level).contentType[type]}
    ${parentGroupParentId === 0 &&
    contentVariations(theme, level).contentResetDirectChild}
    ${isOpen
      ? contentVariations(theme, level).opened
      : contentVariations(theme, level).closed}
  `}
`;

interface IOpenButtonProps {
  isOpen?: boolean;
  color: string;
}

const openButtonCss = {
  show: css`
    &:before {
      visibility: visible;
    }

    &:after {
      visibility: visible;
    }
  `,
};

export const OpenButton = styled.button<IOpenButtonProps>`
  ${({ theme, isOpen, color }) => css`
    height: 24px;
    width: 24px;

    border: 0;
    border-radius: 50%;
    background: ${color};

    position: absolute;
    bottom: -16px;
    left: calc(50% - 12px);

    transition: all 600ms;

    display: flex;
    align-items: center !important;
    justify-content: center !important;

    z-index: 3;

    box-shadow: 0 6px 6px ${theme.colors.shadow};

    &:hover {
      background: ${lighten(0.1, color)};
    }

    &:before {
      content: '';

      height: 40px;
      width: 40px;

      border-radius: 50%;
      background: ${color};

      position: absolute;

      opacity: 0.2;
      visibility: hidden;

      z-index: -1;
    }

    &:after {
      content: '';

      height: 32px;
      width: 32px;

      border-radius: 50%;
      background: ${color};

      position: absolute;

      opacity: 0.4;
      visibility: hidden;

      z-index: -1;
    }

    ${isOpen && openButtonCss.show}
  `}
`;

interface IChildsProps {
  isOpen?: boolean;
  color: string;
  level?: number;
  hasChildren: boolean;
}

export const Childs = styled.ul<IChildsProps>`
  margin: 0;
  padding-top: var(--line-height);
  padding-inline-start: 0;

  position: relative;

  display: flex;
  justify-content: center;

  ${({ isOpen }) => css`
    opacity: ${isOpen ? 1 : 0};
    visibility: ${isOpen ? 'visible' : 'hidden'};
    position: fixed;

    ${isOpen &&
    css`
      transition: opacity 600ms, visibility 100ms;
      position: relative;
    `}
  `}
`;
