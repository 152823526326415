import React, { useMemo, useCallback } from 'react';
import { HiOutlineCheckCircle } from 'react-icons/hi';

import { format, isYesterday, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { v4 } from 'uuid';

import { useTheme } from '@hooks/useTheme';
import { IColors } from '@interfaces/generic/ITheme';
import { ISummaryActivity } from '@interfaces/IProject';

import { IDailyFormData } from '..';

import { DailyStatusStyles } from '../styles';

interface IAccomplishedProps {
  activities: ISummaryActivity[];
  formData: IDailyFormData[];
  setFormData: React.Dispatch<React.SetStateAction<IDailyFormData[]>>;
  isResponding: boolean;
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>;
  projectSelected: ISummaryActivity | undefined;
  setProjectSelected: React.Dispatch<
    React.SetStateAction<ISummaryActivity | undefined>
  >;
  textAreaValue: string;
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>;
  confirmButtonRef: React.RefObject<HTMLButtonElement>;
}

const Accomplished: React.FC<IAccomplishedProps> = ({
  activities,
  formData,
  setFormData,
  isResponding,
  setIsResponding,
  projectSelected,
  setProjectSelected,
  textAreaValue,
  setTextAreaValue,
  confirmButtonRef,
  children,
}) => {
  const colors = useTheme<IColors>({ prop: 'colors' });

  const workedDate = useMemo(() => {
    let date = 'ontem';

    if (activities.length > 0) {
      if (!isYesterday(activities[0].worked_date)) {
        date = `no dia ${format(
          parseISO(String(activities[0].worked_date)),
          'dd/MM',
          { locale },
        )}`;
      }
    }

    return date;
  }, [activities]);

  const title = useMemo(() => {
    if (isResponding) {
      return (
        <p style={{ maxWidth: '180px' }}>
          O que você concluiu <b>{workedDate}</b> no projeto{' '}
          <b>{projectSelected?.project.name}</b> ?
        </p>
      );
    }

    return (
      <p>
        Ok, vamos dar continuidade a sua <b>daily</b>.
        <br />
        Responda o que você fez ontem nos projetos abaixo:
      </p>
    );
  }, [isResponding, projectSelected?.project.name, workedDate]);

  const onSelect = useCallback(
    (index: number) => {
      const foundAnswer = formData[index];
      const project = activities[index];

      setProjectSelected(project);
      setIsResponding(true);
      setTextAreaValue(foundAnswer?.accomplished || '');
    },
    [
      activities,
      formData,
      setIsResponding,
      setProjectSelected,
      setTextAreaValue,
    ],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaValue(e.target.value);
      setFormData(prevFormData => {
        return prevFormData.map(prevFormDataItem => {
          if (prevFormDataItem.project_id === projectSelected?.project_id) {
            return {
              accomplished: e.target.value,
              planned: prevFormDataItem.planned,
              impediment: prevFormDataItem.impediment,
              project_id: prevFormDataItem.project_id,
              summary_activity_id: prevFormDataItem.summary_activity_id,
            };
          }

          return prevFormDataItem;
        });
      });
    },
    [projectSelected?.project_id, setFormData, setTextAreaValue],
  );

  return (
    <DailyStatusStyles.Content>
      {children}

      {title}

      <DailyStatusStyles.Projects>
        {activities.map((activity, index) => {
          const isFilled = !!formData[index].accomplished;

          return (
            <DailyStatusStyles.Project
              key={v4()}
              type="button"
              isFilled={isFilled}
              isSelected={projectSelected?.id === activity.id}
              color={colors.activities.success}
              onClick={() => onSelect(index)}
            >
              <p style={{ maxWidth: isFilled ? '67px' : 'initial' }}>
                {activity.project.name}
              </p>

              {isFilled && (
                <HiOutlineCheckCircle color={colors.white} size={22} />
              )}
            </DailyStatusStyles.Project>
          );
        })}
      </DailyStatusStyles.Projects>

      <DailyStatusStyles.ContentTextArea
        value={textAreaValue}
        onChange={onChange}
        onKeyDown={e =>
          e.ctrlKey && e.keyCode === 13 && confirmButtonRef.current?.click()
        }
        autoFocus
      />
    </DailyStatusStyles.Content>
  );
};

export { Accomplished };
