import React, { useMemo } from 'react';

import { OrganogramCard } from '@components/molecules';
import { IOrganogram } from '@interfaces/IOrganogram';

import { Container } from './styles';

interface IOrganogramProps {
  organogram: IOrganogram;
  parentId?: number;
  level?: number;
}

const Organogram: React.FC<IOrganogramProps> = ({
  organogram,
  parentId = 0,
  level = 0,
}) => {
  const items = useMemo(() => {
    return organogram?.childs?.filter(
      ({ parent_group_id, organogram_id }) =>
        parent_group_id === parentId && organogram_id === organogram.id,
    );
  }, [parentId, organogram]);

  if (level === 4) return null;

  return (
    <Container level={level}>
      {items?.map(item => {
        return (
          <OrganogramCard
            key={item.id}
            organogram={organogram}
            group={item}
            level={level}
          >
            <Organogram
              organogram={organogram}
              parentId={item.id}
              level={level + 1}
            />
          </OrganogramCard>
        );
      })}
    </Container>
  );
};

export { Organogram };
