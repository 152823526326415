import styled, { css } from 'styled-components';

interface ISelectedColorProps {
  color: string;
}

export const SelectedColor = styled.div<ISelectedColorProps>`
  ${({ theme, color }) => css`
    width: 25px;
    height: 25px;

    border: 1px solid ${theme.colors.cards};
    border-radius: 3px;
    background-color: ${color || '#FFF'};

    position: relative;

    cursor: pointer;

    z-index: 2;
  `}
`;

export const BoxSelectColor = styled.ul`
  ${({ theme }) => css`
    width: 200px;
    height: 102px;

    border-radius: 10px;
    background-color: ${theme.colors.cards};
    box-shadow: 0 6px 6px ${theme.colors.shadow};

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -7px;
    right: -10px;

    z-index: 999 !important;
  `}
`;

type IBoxSelectColorItem = ISelectedColorProps;

export const BoxSelectColorItem = styled.li<IBoxSelectColorItem>`
  ${({ theme, color }) => css`
    width: 22px;
    height: 22px;

    border: 1px solid ${theme.colors.background};
    background-color: ${color};
    border-radius: 3px;

    margin: 5px;

    cursor: pointer;

    &.selected {
      border: 1px solid #fff;
    }
  `}
`;
