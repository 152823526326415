import styled, { css } from 'styled-components';

import { IDrawerPosition } from '.';

const responsive = css`
  @media (max-width: 768px) {
    @media (min-height: 568px) {
      .organisms-drawer-header {
        height: fit-content;
        min-height: fit-content;

        padding: 24px 32px;

        gap: 16px;
      }

      .organisms-drawer-content {
        height: 68.2%;
        min-height: 68.2%;

        form {
          max-height: 520px;

          padding: 16px 16px 200px 16px;
        }
      }

      .organisms-drawer-footer {
        height: fit-content;
        min-height: fit-content;

        padding: 32px;
      }
    }

    @media (min-height: 640px) {
      .organisms-drawer-header {
        height: fit-content;
        min-height: fit-content;

        padding: 24px 32px;

        gap: 16px;
      }

      .organisms-drawer-content {
        height: 72%;
        min-height: 72%;

        form {
          max-height: 520px;

          padding: 16px 16px 200px 16px;
        }
      }

      .organisms-drawer-footer {
        height: fit-content;
        min-height: fit-content;

        padding: 32px;
      }
    }

    @media (min-height: 667px) {
      .organisms-drawer-header {
        height: fit-content;
        min-height: fit-content;

        padding: 24px 32px;

        gap: 16px;
      }

      .organisms-drawer-content {
        height: 73%;
        min-height: 73%;

        form {
          max-height: 520px;

          padding: 16px 16px 200px 16px;
        }
      }

      .organisms-drawer-footer {
        height: fit-content;
        min-height: fit-content;

        padding: 32px;
      }
    }

    @media (min-height: 731px) {
      .organisms-drawer-content {
        height: 75.5%;
        min-height: 75.5%;

        form {
          max-height: 562px;

          padding: 32px 32px 200px 32px;
        }
      }
    }

    @media (min-height: 812px) {
      .organisms-drawer-content {
        height: 77.8%;
        min-height: 77.8%;

        form {
          max-height: 652px;

          padding: 32px 32px 200px 32px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    @media (max-height: 731px) {
      .organisms-drawer-content {
        height: 60%;
        min-height: 60%;

        form {
          max-height: 562px;

          padding: 32px 32px 200px 32px;
        }
      }
    }

    @media (max-height: 937px) {
      .organisms-drawer-content {
        height: 67.5%;
        min-height: 67.5%;

        form {
          max-height: 662px;

          padding: 32px 32px 200px 32px;
        }
      }

      .organisms-drawer-footer {
        padding: 8px;
      }
    }
  }

  @media (min-height: 947px) {
    .organisms-drawer-content {
      height: 70.8%;
      min-height: 70.8%;

      form {
        max-height: fit-content;

        padding: 32px 32px 200px 32px;
      }
    }
  }

  @media (min-width: 0px) and (max-width: 768px) {
    min-width: 0;
    max-width: 100%;

    .organisms-drawer-header {
      padding: 16px;
    }

    .organisms-drawer-content {
      form {
        padding: 16px;
      }
    }

    .organisms-drawer-footer {
      padding: 16px;
    }
  }
`;

interface IContainerProps {
  isOpen: boolean;
  position?: IDrawerPosition;
}

const containerVariations = (position: IDrawerPosition) => ({
  top: css`
    width: 100vw;
    min-height: 0;

    top: 0;
    left: 0;
  `,
  right: css`
    height: 100vh;
    min-width: 0;

    top: 0;
    right: 0;

    max-width: 0;
  `,
  bottom: css`
    width: 100vw;
    min-height: 0;

    left: 0;
    bottom: 0;
  `,
  left: css`
    height: 100vh;
    min-width: 0;

    top: 0;
    left: 0;
  `,
  visible: css`
    min-width: 660px;
    max-width: ${position === 'right' || position === 'left'
      ? '660px'
      : '100vw'};
    min-height: 60vh;
    max-height: ${position === 'top' || position === 'bottom'
      ? '60vh'
      : '100vh'};

    opacity: 1;
    visibility: visible;

    * {
      opacity: 1;
      visibility: visible;
    }
  `,
});

export const Container = styled.div.attrs({
  className: 'drawer-container',
})<IContainerProps>`
  ${({ theme, isOpen, position = 'right' }) => css`
    background: ${theme.colors.activities.tagsWithAction};
    box-shadow: 0 0 0 100000px ${theme.colors.shadow};

    position: fixed;

    opacity: 0;
    visibility: hidden;

    transition: all 300ms;

    z-index: 50000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    * {
      opacity: 0;
      visibility: hidden;

      transition: all 300ms;
    }

    footer {
      transition: all 2000ms;

      button {
        display: ${isOpen ? 'flex' : 'none'} !important;
      }

      * {
        transition: ${isOpen ? 'none' : 'all 2000ms'} !important;
      }
    }

    ${containerVariations(position)[position]}
    ${isOpen && containerVariations(position).visible}

    ${responsive}
  `}
`;

interface IHeaderProps {
  hasTabs: boolean;
}

export const Header = styled.header.attrs({
  className: 'organisms-drawer-header',
})<IHeaderProps>`
  ${({ theme, hasTabs }) => css`
    width: 100%;
    height: ${hasTabs ? '20%' : 'fit-content'};
    max-height: 150px;

    border-bottom: 1px solid ${theme.colors.activities.tagsWithoutAction}66;

    padding: 32px;

    gap: 24px;
    display: flex;
    flex-direction: column;
  `}
`;

export const HeaderTitle = styled.div.attrs({
  className: 'organisms-drawer-header_title',
})`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.extraLarge};
    }

    button {
      border: 0;
      background: 0;
    }
  `}
`;

export const HeaderTabs = styled.div.attrs({
  className: 'organisms-drawer-header_tabs',
})`
  min-height: 30px;

  overflow-y: hidden;
  overflow-x: scroll;

  gap: 8px;
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

interface ITabProps {
  isActive: boolean;
}

export const Tab = styled.div.attrs({
  className: 'organisms-drawer-tab',
})<ITabProps>`
  ${({ theme, isActive }) => css`
    height: 30px;

    border-radius: ${theme.borders.radii[100]};
    border: 1px solid
      ${isActive
        ? theme.colors.activities.info
        : theme.colors.activities.selection};
    background: ${isActive ? theme.colors.activities.info : 'transparent'};

    padding: 4px 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    user-select: none;

    > span {
      color: ${isActive
        ? theme.colors.white
        : theme.colors.activities.selection};
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }

    &:hover {
      border-color: ${theme.colors.activities.info};
      background: ${theme.colors.activities.info};

      cursor: pointer;

      > span {
        color: ${theme.colors.text.title};
      }
    }
  `}
`;

export const Content = styled.div.attrs({
  className: 'organisms-drawer-content',
})`
  height: 60%;

  display: flex;

  > div {
    display: flex;
    flex: 1;
  }
`;

export const Footer = styled.footer.attrs({
  className: 'organisms-drawer-footer',
})`
  ${({ theme }) => css`
    height: 20%;
    max-height: 150px;

    /* box-shadow: 0 0 24px ${theme.colors.shadow}; */
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.4);

    padding: 32px;

    gap: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
