import { format } from 'date-fns';

import { IEvent } from '@components/organisms/Screenshots';
import { IScreenshotsRequest } from '@interfaces/IScreenshots';
import { api } from '@services/api';

class ScreenshotsService {
  public async get(query: IScreenshotsRequest): Promise<IEvent[]> {
    let q: String = '?users_ids=';
    if ((query?.users_ids ?? []).length > 0) {
      const ids: string = query?.users_ids?.join(',').toString() ?? '';
      q = `${q}${ids}`;
    } else {
      return [];
    }
    q = `${q}&projects_ids=`;
    if ((query?.projects_ids ?? []).length > 0) {
      const ids: string = query?.projects_ids?.join(',').toString() ?? '';
      q = `${q}${ids}`;
    }
    const dt: Date = new Date();
    let isEmptyStart: boolean = (query?.startDate ?? '').length === 0;
    let isEmptyEnd: boolean = (query?.endDate ?? '').length === 0;
    if (isEmptyStart && isEmptyEnd) {
      query.startDate = `${format(dt, 'Y-M-dd')} 00:00:00`;
      query.endDate = `${format(dt, 'Y-M-dd')} 23:59:59`;
      isEmptyStart = false;
      isEmptyEnd = false;
    } else if (isEmptyStart) {
      const s: Date = new Date((query?.startDate ?? '').toString());
      query.startDate = `${format(s.getTime() - 24 * 3600, 'Y-M-dd')} 23:59:59`;
      isEmptyStart = false;
    } else if (isEmptyEnd) {
      const end: Date = new Date((query?.startDate ?? '').toString());
      query.endDate = `${format(end.getTime() + 24 * 3600, 'Y-M-dd')} 23:59:59`;
      isEmptyEnd = false;
    }

    q = `${q}&startDate=${query?.startDate}&endDate=${query?.endDate}`;

    const { data } = await api.get<IEvent[]>(`/screenshots/${q}`);
    return data;
  }
}

const INSTANCE = new ScreenshotsService();
// eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars, @typescript-eslint/no-unused-vars
export async function getScreenshots(
  query: IScreenshotsRequest,
): Promise<IEvent[]> {
  return INSTANCE.get(query);
}

export { INSTANCE as ScreenshotsService };
