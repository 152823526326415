import React, { useState, useEffect, useCallback } from 'react';

import { useContextSelector } from 'use-context-selector';

import { AUTH_LOCAL_STORAGE_USER } from '@constants/auth';
import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { ChatBotService, IChatResponse } from '@services/apis/ChatBotService';

import { DailyResume } from '../Daily/components/DailyResume';
import { DailyStatus } from '../Daily/components/DailyStatus';
import { IdentifiedWorkedHours } from '../Daily/components/IdentifiedWorkedHours';
import { ThenYouWorked } from '../Daily/components/ThenYouWorked';
import { TimeCorrection } from '../Daily/components/TimeCorrection';
import { IDailyState } from '../Daily/types';
import { getAlert } from '../functions/getAlert';
import { IAlert } from '../types';

import { Message } from '../Messages/styles';

interface IUseDailyProps {
  addMessage: (element: JSX.Element, isInitial?: boolean) => void;
  resetMessages: () => void;
  scrollToDown: () => void;
}

function useDaily({ addMessage, resetMessages, scrollToDown }: IUseDailyProps) {
  const worked_date = useContextSelector(
    ChatbotContext,
    state => state.worked_date,
  );

  const [alert, setAlert] = useState<IAlert>('success');
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  // const [isAlert, setIsAlert] = useState<boolean>(new Date().getHours() >= 12);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [state, setState] = useState<IDailyState>('INIT');

  const getState = useCallback(
    (response: IChatResponse) => {
      const INIT = () => {
        addMessage(
          <>
            <IdentifiedWorkedHours
              response={response}
              onAnswer={props => {
                if (props.dailyState) setState(props.dailyState);

                if (props.scrollToDown) scrollToDown();
              }}
            />
          </>,
        );
      };

      const CONFIRMED_WORKED_HOURS = () => {
        INIT();

        addMessage(
          <Message.Others>
            <div>
              <p>
                Ok, vamos <b>complementar</b> essas informações.
              </p>
            </div>
          </Message.Others>,
        );

        addMessage(
          <Message.Me>
            <div>
              <p>Sim</p>
            </div>
          </Message.Me>,
        );

        addMessage(
          <DailyStatus
            response={response}
            onAnswer={props => {
              if (props.dailyState) setState(props.dailyState);
              if (props.scrollToDown) scrollToDown();
            }}
          />,
        );

        scrollToDown();
      };

      const NOT_CONFIRMED_WORKED_HOURS = () => {
        INIT();
        if (sessionStorage.openDailyChat !== 'true') {
          addMessage(
            <Message.Others>
              <div>
                <p>
                  Ok, vamos <b>corrigir</b> essa informação.
                </p>
              </div>
            </Message.Others>,
          );

          addMessage(
            <Message.Me>
              <div>
                <p>Não</p>
              </div>
            </Message.Me>,
          );
        }

        addMessage(
          <TimeCorrection
            response={response}
            onAnswer={props => {
              if (props.dailyState) setState(props.dailyState);
              if (props.scrollToDown) scrollToDown();
            }}
          />,
        );

        scrollToDown();
      };

      const ADJUSTED_WORKED_HOURS = () => {
        INIT();
        if (sessionStorage.openDailyChat !== 'true') {
          addMessage(
            <Message.Me>
              <div>
                <p>Não</p>
              </div>
            </Message.Me>,
          );

          addMessage(
            <Message.Others>
              <div>
                <p>
                  Ok, vamos <b>corrigir</b> essa informação.
                </p>
              </div>
            </Message.Others>,
          );
        }

        addMessage(
          <ThenYouWorked
            response={response}
            onAnswer={props => {
              if (props.dailyState) setState(props.dailyState);
              if (props.scrollToDown) scrollToDown();
            }}
          />,
        );

        scrollToDown();
      };

      const CONFIRMED_ADJUSTED_WORKED_HOURS = () => {
        ADJUSTED_WORKED_HOURS();

        addMessage(
          <Message.Me>
            <div>
              <p>Sim</p>
            </div>
          </Message.Me>,
        );

        addMessage(
          <Message.Others>
            <div>
              <p>
                Ok, vamos <b>complementar</b> essas informações.
              </p>
            </div>
          </Message.Others>,
        );

        addMessage(
          <DailyStatus
            response={response}
            onAnswer={props => {
              if (props.dailyState) setState(props.dailyState);
              if (props.scrollToDown) scrollToDown();
            }}
          />,
        );

        scrollToDown();
      };

      const DAILY_ANSWERED = () => {
        const flows = response.answers.map(answer => answer.flow_stopped);

        const isNotConfirmed = flows.includes('NOT_CONFIRMED_WORKED_HOURS');

        if (isNotConfirmed) ADJUSTED_WORKED_HOURS();

        addMessage(
          <Message.Me>
            <div>
              <p>Sim</p>
            </div>
          </Message.Me>,
        );

        addMessage(
          <Message.Others>
            <div>
              <p>
                Ok, vamos <b>complementar</b> essas informações.
              </p>
            </div>
          </Message.Others>,
        );

        addMessage(
          <DailyResume
            response={response}
            onAnswer={props => {
              if (props.dailyState) setState(props.dailyState);
              if (props.scrollToDown) scrollToDown();
            }}
          />,
        );

        scrollToDown();
      };

      const CONFIRMED_DAILY_ANSWERED = () => {
        setIsAnswered(true);

        DAILY_ANSWERED();

        const user = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);

        if (user) {
          const { name } = JSON.parse(user);

          addMessage(
            <Message.Me>
              <div>
                <p>Sim</p>
              </div>
            </Message.Me>,
          );

          addMessage(
            <Message.Others>
              <div>
                <p>
                  Valeu <b>{name.split(' ')[0]}</b>! Sua daily está{' '}
                  <b>concluída</b>.
                </p>

                <br />

                <p>Até amanhã!</p>
              </div>
            </Message.Others>,
          );
        }

        scrollToDown();
      };

      return {
        INIT,
        CONFIRMED_WORKED_HOURS,
        NOT_CONFIRMED_WORKED_HOURS,
        ADJUSTED_WORKED_HOURS,
        CONFIRMED_ADJUSTED_WORKED_HOURS,
        DAILY_ANSWERED,
        CONFIRMED_DAILY_ANSWERED,
      };
    },
    [addMessage, scrollToDown],
  );

  const dispareAlert = useCallback((response: IChatResponse) => {
    if (response.daily?.concluded) {
      setAlert('success');
      return;
    }

    if (response.lastAnswer === 'CONFIRMED_DAILY_ANSWERED') {
      setAlert('success');
    } else {
      setAlert(getAlert());
      setIsAlert(true);
    }
  }, []);

  sessionStorage.state = state;

  useEffect(() => {
    async function load() {
      const response = await ChatBotService.identifyWorkedHours({
        worked_date,
      });

      const { lastAnswer } = response;

      const isDailyToAnswer = !response.daily?.concluded;

      if (isDailyToAnswer) {
        sessionStorage.total_worked_Hours = response.total_worked_Hours;
        sessionStorage.worked_date = response.worked_date;

        resetMessages();
        getState(response)[lastAnswer]();
      } else {
        addMessage(
          <Message.Others>
            <div>
              <p>Muito bom! Sua daily está em dia!</p>
            </div>
          </Message.Others>,
        );
      }

      dispareAlert(response);
    }

    load();
  }, [addMessage, getState, dispareAlert, state, resetMessages, worked_date]);

  return { state, alert, isAlert, isAnswered, setIsAlert };
}

export { useDaily };
