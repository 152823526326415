import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'circle_chart-container',
})`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const Svg = styled.svg.attrs({
  className: 'circle_chart-svg',
  xmlns: 'http://www.w3.org/2000/svg/',
})`
  max-width: 80%;
  max-height: 150px;

  display: block;

  transform: rotate(-90deg);
`;

interface ILineProps {
  c?: string;
}

export const Line = styled.circle.attrs({
  className: 'circle_chart-line',
})<ILineProps>`
  ${({ theme, c }) => css`
    fill: none;

    stroke: ${c || theme.colors.background};
    stroke-width: ${c ? 3 : 2};
    stroke-linecap: round;

    animation: ${c ? 'progress 1s ease-out forwards' : 'none'};

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }
  `}
`;
