import styled, { css } from 'styled-components';

interface IContainerProps {
  color?: string;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, color = '#ffffff' }) => css`
    background: ${color};
    border-radius: 16px;

    color: ${theme.colors.white};
    font-size: 12px;
    white-space: nowrap;

    padding: 2px 6px;
  `}
`;
