import React from 'react';

import { getUserLogged } from '@utils/getUserLogged';

import { Dashboard as AdminDash } from './Administrator';
import { Dashboard as CollabDash } from './Collaborator';

const Dashboard: React.FC = () => {
  const { profile } = getUserLogged();

  const isAdmin = profile !== 3;

  return isAdmin ? <AdminDash /> : <CollabDash />;
};

export { Dashboard };
