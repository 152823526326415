import styled, { css } from 'styled-components';

import { Tags } from '@components/atoms/Tags';
import { customScroll } from '@styles/global';

export const ModalContent = styled.div`
  width: 480px;

  position: relative;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${() => css`
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const ProjectInfo = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.normal};
      }
    }
  `}
`;

export const Collaborators = styled.div`
  ${() => css`
    padding: 0 8px 24px 24px;

    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`;

export const CollaboratorsHeader = styled.div`
  ${({ theme }) => css`
    padding-bottom: 16px;

    display: grid;
    grid-template-columns: 400px 1fr 60px;

    strong {
      color: ${theme.colors.text.subtitle};
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;

export const Scroll = styled.div`
  ${() => css`
    max-height: 400px;

    padding-right: 16px;

    overflow-y: scroll;

    ${customScroll()}
  `}
`;

export const Collaborator = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.background};

    padding: 8px;

    display: grid;
    grid-template-columns: 400px 1fr 60px;

    &:last-child {
      border-bottom: 1px solid ${theme.colors.background};
    }

    div {
      display: flex;
      align-items: center;

      p {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }
    }
  `}
`;

export const CollaboratorInfo = styled.div`
  ${({ theme }) => css`
    gap: 16px;
    display: flex;
    align-items: center;

    > div:nth-of-type(2) {
      gap: 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      strong {
        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.normal};
      }

      p {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
      }
    }
  `}
`;

export const CollaboratorAssociatedAt = styled.div`
  ${() => css`
    gap: 16px;
    display: flex;
    align-items: center;
  `}
`;

export const CollaboratorActions = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: none;
      border: none;

      &:hover {
        transform: scale(0.9);
      }
    }
  `}
`;

export const SelectCollaboratorWrapper = styled.div`
  ${() => css`
    width: 220px;
  `}
`;

export const SelectCollaborator = styled(Tags)`
  ${() => css``}
`;
