import styled, { css } from 'styled-components';

interface IContainerProps {
  level: number;
}

const containerVariations = {
  lines: css`
    flex-direction: column;

    li:before {
      border-top: 0 !important;
      border-radius: 0 !important;
    }

    li:after {
      border-top: 0 !important;
      border-radius: 0 !important;
    }
  `,
};

export const Container = styled.div<IContainerProps>`
  ${({ level }) => css`
    --line-height: 40px;
    --line-width: 2px;
    --line-border-radius: 16px;
    --node-padding: 8px;

    margin: 0 0 32px 0;
    padding-inline-start: 0;

    display: flex;

    ${level > 1 && containerVariations.lines}
  `}
`;
