import styled, { css } from 'styled-components';

import { customScroll } from '@styles/global';

export const Container = styled.div.attrs({
  className: 'kan_ban_column-container',
})`
  height: 100%;

  padding: 40px 0 24px 0;

  position: relative;

  gap: 32px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div.attrs({
  className: 'kan_ban_column-header',
})`
  ${({ theme }) => css`
    width: 100%;
    height: 40px;

    position: absolute;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    strong {
      color: ${theme.colors.text.title};
      font-size: ${theme.typography.fontSize.normal};
      font-weight: bold;
      letter-spacing: 0.6px;
    }
  `}
`;

export const Content = styled.div`
  height: calc(100% - 44px);

  padding: 0 0 24px 0;

  gap: 32px;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0.2rem;

    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-track {
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00bfff;
    border-radius: 7px;
  }

  ${customScroll()}
`;
