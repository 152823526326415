import React from 'react';

import { Container } from './styles';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  defaultValue?: any;
  icon?: React.ReactNode;
}

const Input: React.FC<IInputProps> = ({
  label,
  errorMessage,
  icon,
  ...rest
}) => {
  return (
    <Container>
      <label>{label}</label>

      <div>
        <input {...rest} />

        {icon && icon}
      </div>

      <span>{errorMessage}</span>
    </Container>
  );
};

export default Input;
