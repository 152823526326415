import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IDailyStatusStylesProjectProps {
  color: string;
  isFilled: boolean;
  isSelected: boolean;
}

interface IDailyStatusStylesContentTabProps {
  isActive: boolean;
}

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid ${theme.colors.asideMenu};

    padding: 12px 8px;

    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;

export const Content = styled.main`
  padding: 8px;

  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    padding: 16px 0;

    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      min-width: 110px;

      border-radius: ${theme.borders.radii[100]};

      color: ${theme.colors.white};

      padding: 8px 24px;

      transition: all 400ms;

      &.cancel {
        border: 1px solid ${theme.colors.activities.danger};
        background: ${transparentize(0.4, theme.colors.activities.danger)};

        &:hover {
          background: ${theme.colors.activities.danger};
        }
      }

      &.back {
        background: ${transparentize(0.4, theme.colors.activities.selection)};
        border: 1px solid ${theme.colors.activities.selection};

        &:hover {
          background: ${theme.colors.activities.selection};
        }
      }

      &.save {
        background: ${transparentize(0.4, theme.colors.activities.info)};
        border: 1px solid ${theme.colors.activities.info};

        &:hover {
          background: ${theme.colors.activities.info};
        }
      }
    }
  `}
`;

export const DailyStatusStyles = {
  Header: styled.div`
    ${({ theme }) => css`
      max-width: 342px;

      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: 1px solid #e0e0e0;

      padding: 10px 8px;

      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      strong {
        font-size: ${theme.typography.fontSize.small};
      }
    `}
  `,
  Content: styled.div`
    ${({ theme }) => css`
      width: 100%;

      padding: 16px 8px;

      gap: 8px;
      display: flex;
      flex-direction: column;

      p {
        font-size: ${theme.typography.fontSize.small};
        text-align: center;

        margin: 0 auto;
      }
    `}
  `,
  Projects: styled.div`
    padding: 16px 32px 0 32px;

    gap: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `,
  Project: styled.button<IDailyStatusStylesProjectProps>`
    ${({ theme, color, isFilled, isSelected }) => css`
      height: 40px;

      border-radius: ${theme.borders.radii[100]};
      border: 1px solid
        ${isSelected && !isFilled
          ? theme.colors.activities.info
          : isFilled
          ? color
          : theme.colors.activities.selection};

      background: ${isSelected && !isFilled
        ? transparentize(0.6, theme.colors.activities.info)
        : isFilled
        ? color
        : 'transparent'};

      padding: 8px 16px;

      gap: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 400ms;

      &:hover {
        border: 1px solid ${theme.colors.activities.info};
        background: ${theme.colors.activities.info};

        p {
          color: ${theme.colors.white};
        }
      }

      p {
        color: ${isSelected || isFilled
          ? theme.colors.white
          : theme.colors.activities.selection};
        font-weight: ${theme.typography.fontWeight.bold};
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;

        transition: all 400ms;
      }
    `}
  `,
  Associating: styled.div`
    ${({ theme }) => css`
      padding: 8px;

      gap: 8px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input {
        width: 100%;

        border-radius: ${theme.borders.radii[100]};
        border: 1px solid ${theme.colors.activities.info};

        padding: 8px;
        margin: 0 10px;
      }

      textarea {
        width: 100%;
      }
    `}
  `,
  ContentTabs: styled.div`
    ${({ theme }) => css`
      border-radius: ${theme.borders.radii[100]};
      background: ${theme.colors.background};

      padding: 0;
      margin: 0 8px 8px 8px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  `,
  ContentTab: styled.button<IDailyStatusStylesContentTabProps>`
    ${({ theme, isActive }) => css`
      border-radius: ${theme.borders.radii[100]};
      border: none;
      background: ${isActive
        ? theme.colors.activities.selection
        : 'transparent'};

      color: ${isActive
        ? theme.colors.white
        : theme.colors.activities.selection};
      font-weight: ${theme.typography.fontWeight.bold};

      padding: 4px 8px;
    `}
  `,
  ContentTextArea: styled.textarea`
    ${({ theme }) => css`
      height: 106px;

      border-radius: ${theme.borders.radii[100]};
      border: 1px solid ${theme.colors.activities.selection};
      background: ${theme.colors.background};

      color: ${theme.colors.text.title};

      padding: 8px;
      margin: 16px 10px 0 10px;

      resize: none;
    `}
  `,
  Actions: styled.div`
    ${({ theme }) => css`
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      padding: 16px 8px;

      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border-radius: 4px;

        color: ${theme.colors.white};
        font-size: ${theme.typography.fontSize.small};
        font-weight: ${theme.typography.fontWeight.bold};

        padding: 10px 24px;

        transition: all 400ms;

        &.cancel {
          background: ${transparentize(0.4, theme.colors.activities.danger)};
          border: 1px solid ${theme.colors.activities.danger};

          &:hover {
            background: ${theme.colors.activities.danger};
          }
        }

        &.back {
          background: ${transparentize(0.4, theme.colors.activities.selection)};
          border: 1px solid ${theme.colors.activities.selection};

          &:hover {
            background: ${theme.colors.activities.selection};
          }
        }

        &.confirm {
          background: ${transparentize(0.4, theme.colors.activities.info)};
          border: 1px solid ${theme.colors.activities.info};

          &:hover {
            background: ${theme.colors.activities.info};
          }
        }
      }
    `}
  `,
  HasImpediment: styled.div`
    ${({ theme }) => css`
      border-radius: ${theme.borders.radii[100]};
      background: ${theme.colors.background};

      margin: 8px;
      padding: 16px;

      gap: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border-radius: ${theme.borders.radii[100]};

        color: ${theme.colors.white};

        padding: 8px 32px;

        transition: all 400ms;

        &.no {
          border: 1px solid ${theme.colors.activities.danger};
          background: ${transparentize(0.6, theme.colors.activities.danger)};

          &:hover {
            background: ${theme.colors.activities.danger};
          }
        }

        &.yes {
          border: 1px solid ${theme.colors.activities.info};
          background: ${transparentize(0.6, theme.colors.activities.info)};

          &:hover {
            background: ${theme.colors.activities.info};
          }
        }
      }
    `}
  `,
};

export const ProjectsSuggestion = styled.div`
  padding: 8px;

  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ProjectSuggestion = styled.button`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.background};
    border-radius: 4px;
    background: ${theme.colors.background};

    padding: 8px;

    transition: all 400ms;

    &:hover {
      border: 1px solid ${theme.colors.activities.info};

      cursor: pointer;
    }

    p {
      color: ${theme.colors.text.title};
    }
  `}
`;
