import styled, { css } from 'styled-components';

const responsive = {
  Container: css`
    @media (max-width: 768px) {
      padding: 0;

      > div:first-of-type {
        padding: 24px;

        button {
          span {
            display: none;
          }
        }
      }
    }
  `,
};

export const Container = styled.div.attrs({
  className: 'screenshots-container',
})`
  height: calc(100% - 106px);

  ${responsive.Container}
`;

export const ScreenshotsContainer = styled.div.attrs({
  className: 'screenshots-container',
})`
  width: 100%;
  height: calc(100% - 68px);

  padding: 0 48px;

  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
