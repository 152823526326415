import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from '@components/atoms';
import { useHeader } from '@hooks/useHeader';

import {
  Container,
  LoadingContainer,
  NotFound,
  NotFoundContainer,
} from './styles';

interface IScrollListProps {
  message?: string;
  length: number;
  columns?: number;
  columnWidth?: [number, number];
  isLoading?: boolean;
  className?: string;
}

const ScrollList: React.FC<IScrollListProps> = ({
  message = 'NOT_FOUND',
  length,
  columns: columnsPassed = 0,
  columnWidth: cWidth = [240, 260],
  isLoading,
  children,
  className,
}) => {
  const [t] = useTranslation();

  const { dispatch } = useHeader();

  const containerRef = useRef<HTMLDivElement>(null);
  const safeAreaWidthRef = useRef<number>(0);

  const [columns, setColumns] = useState<number>(columnsPassed);

  const handleResponsive = useCallback(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();

      const pWidth = Math.floor(width);

      if (dispatch) safeAreaWidthRef.current = pWidth;

      const safe = safeAreaWidthRef.current;
      const diff = pWidth - safe;

      const calculated =
        safe === 0 && !dispatch
          ? pWidth - 248
          : safe === pWidth && dispatch
          ? pWidth - diff
          : safe !== pWidth && safe !== 0 && !dispatch
          ? pWidth - 248 - diff
          : pWidth - 248;

      const calculatedColumnWidth = pWidth < 2000 ? cWidth[0] : cWidth[1];

      setColumns(
        columnsPassed === 0
          ? Math.floor(calculated / calculatedColumnWidth)
          : columnsPassed,
      );
    }
  }, [columnsPassed, cWidth, dispatch]);

  useEffect(() => {
    handleResponsive();

    window.addEventListener('resize', handleResponsive);
  }, [cWidth, dispatch, handleResponsive, isLoading]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (length === 0)
    return (
      <NotFoundContainer className={className}>
        <NotFound>{t(message)}</NotFound>
      </NotFoundContainer>
    );

  return (
    <Container
      ref={containerRef}
      columns={columns}
      columnWidth={cWidth[0]}
      className={className}
    >
      {children}
    </Container>
  );
};

export { ScrollList };
