import React, { useRef, useState, useEffect, useCallback } from 'react';

import {
  UserForm,
  UserValuesForm,
  UserChangePasswordForm,
} from '@components/molecules';
import { Drawer, IDrawerRef } from '@components/organisms/Drawer';
import { IFormRef } from '@contexts/ReactFormContext';
import { useResetForms } from '@hooks/useResetForms';
import { IUserFormInitialData, IUser } from '@interfaces/IUser';
import { UsersService } from '@services/apis/UsersService';

interface IUserDrawerProps {
  userId?: number;
  setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setUsers?: React.Dispatch<React.SetStateAction<IUser[]>>;
  userDrawerRef: React.RefObject<IDrawerRef>;
}

const UserDrawer: React.FC<IUserDrawerProps> = ({
  userId,
  setUserId,
  setUsers,
  userDrawerRef,
}) => {
  const userFormRef = useRef<IFormRef>(null);
  const userValuesFormRef = useRef<IFormRef>(null);
  const userChangePasswordFormRef = useRef<IFormRef>(null);

  const tabs = userId
    ? ['Dados Pessoais', 'Valores', 'Alterar Senha']
    : ['Dados Pessoais'];

  const [selectedUser, setSelectedUser] = useState<IUserFormInitialData>();

  useEffect(() => {
    async function loadUser(id: number) {
      const user = await UsersService.show({
        id,
        relations: [
          'costs',
          'costs.user_responsible',
          'month_costs',
          'month_costs.user_responsible',
          'users_roles',
          'users_roles.role',
          'users_groups',
          'users_groups.group',
          'projects_users',
          'projects_users.project',
        ],
      });

      const user_role_id = user.users_roles?.map((i: any) => i.role.id) || [];
      const groups_ids = user.groups?.map((i: any) => i.group.id) || [];
      const projects_ids = user.projects?.map((i: any) => i.project.id) || [];

      if (user.costs || user.month_costs) {
        user.month_costs?.forEach(month_cost => {
          if (!user.costs) user.costs = [{ ...month_cost, is_month: true }];
          else user.costs?.push({ ...month_cost, is_month: true });
        });
      }

      setSelectedUser({ ...user, user_role_id, groups_ids, projects_ids });
    }

    if (userId) loadUser(userId);
  }, [userId]);

  const onClose = useCallback(
    () => setUserId(state => (state === 0 ? undefined : 0)),
    [setUserId],
  );

  useResetForms<IUserFormInitialData>({
    refs: [userFormRef, userValuesFormRef, userChangePasswordFormRef],
    trigger: userId,
    set: setSelectedUser,
  });

  return (
    <Drawer
      ref={userDrawerRef}
      title={selectedUser ? selectedUser.name : 'Adicionar usuário'}
      tabs={tabs}
      hasData={!!selectedUser}
      onClose={onClose}
    >
      <UserForm
        ref={userFormRef}
        user={selectedUser}
        setUsers={setUsers}
        closeModal={() => userDrawerRef.current?.close()}
      />

      <UserValuesForm
        ref={userValuesFormRef}
        user={selectedUser}
        setUsers={setUsers}
        closeModal={() => userDrawerRef.current?.close()}
      />

      <UserChangePasswordForm
        ref={userChangePasswordFormRef}
        closeModal={() => userDrawerRef.current?.close()}
      />
    </Drawer>
  );
};

export { UserDrawer };
