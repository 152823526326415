import { transparentize, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { Card } from '@components/quarks';
import { ScrollList } from '@components/templates';
import { ITheme } from '@interfaces/generic/ITheme';
import { customScroll } from '@styles/global';

export const Container = styled(Card)`
  height: 452px;

  background: #29385b;

  padding: 0;

  display: grid;
  grid-template-columns: 376px 1fr;
`;

export const Left = styled.div`
  ${({ theme }) => css`
    height: 452px;

    border-top-left-radius: ${theme.borders.radii[500]};
    border-bottom-left-radius: ${theme.borders.radii[500]};

    padding: 24px 8px 0 24px;

    gap: 24px;
    display: flex;
    flex-direction: column;
  `}
`;

export const List = styled(ScrollList).attrs({
  className: 'components-atoms-charts-delivery-gantt-list',
})`
  height: calc(100% - 62px);
  max-height: calc(100% - 62px);

  padding: 0 16px 0 0;

  ${customScroll()}
`;

export interface IItemProps {
  status: 'DELAYED' | 'IN_PROGRESS';
  info?: string;
}

const itemVariations = (theme: ITheme) => ({
  DELAYED: css`
    color: ${theme.colors.activities.danger};
  `,
  IN_PROGRESS: css`
    color: ${theme.colors.input.normal.border};
  `,
});

export const Item = styled.div<IItemProps>`
  ${({ theme, status }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 64px 258px;

    > div:nth-of-type(2) {
      gap: 6px;
      flex: 1;
      display: flex;
      flex-direction: column;

      strong {
        max-width: 230px;

        color: ${theme.colors.text.title};
        font-size: ${theme.typography.fontSize.small};
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;
      }

      span {
        color: ${theme.colors.text.subtitle};
        font-size: ${theme.typography.fontSize.small};
        font-weight: bold;
        letter-spacing: 0.6px;
      }

      p {
        color: #b1c5dd;
        font-size: ${theme.typography.fontSize.small};

        ${itemVariations(theme)[status]};
      }
    }
  `}
`;

export const Right = styled.div`
  ${({ theme }) => css`
    height: 452px;

    border-top-right-radius: ${theme.borders.radii[500]};
    border-bottom-right-radius: ${theme.borders.radii[500]};
  `}
`;

export const GanttArea = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 452px;

    border-top-right-radius: ${theme.borders.radii[500]};
    border-bottom-right-radius: ${theme.borders.radii[500]};
    background: #292f4d;

    position: relative;
  `}
`;

export const GanttAreaHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 60px;

    border-top-right-radius: ${theme.borders.radii[500]};
  `}
`;

export const GanttAreaTasks = styled.div`
  width: 100%;
  height: calc(452px - 60px - 55px);

  position: relative;

  overflow-x: scroll;
  overflow-y: scroll;

  z-index: 2;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const GanttAreaTasksMarkPoint = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100% - 60px);

    border-bottom-right-radius: ${theme.borders.radii[500]};

    margin-top: -336px;

    position: absolute;

    overflow-x: scroll;
    overflow-y: hidden;

    z-index: 1;

    &::-webkit-scrollbar {
      height: 0;
    }
  `}
`;

interface IGanttTaskProps {
  status: 'DELAYED' | 'IN_PROGRESS';
}

export const GanttTask = styled.div<IGanttTaskProps>`
  ${({ theme }) => css`
    height: 32px;

    border: 2px solid ${theme.colors.activities.info};
    border-radius: ${theme.borders.radii[500]};
    background: ${transparentize(0.5, theme.colors.activities.info)};

    position: absolute;

    display: flex;
    align-items: center;

    z-index: 2;

    transition: 400ms;

    &:hover {
      background: ${lighten(0.005, theme.colors.activities.info)};
      cursor: pointer;
    }

    strong {
      max-width: fit-content;

      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;

      user-select: none;

      overflow: hidden;
    }
  `}
`;

export const GanttBack = styled.div`
  ${({ theme }) => css`
    width: 177px;
    height: 100%;

    position: absolute;

    z-index: 1;

    user-select: none;

    & + div {
      border-left: 1px solid #0a192f;
    }

    span {
      color: ${theme.colors.text.input};
      font-size: ${theme.typography.fontSize.small};
      text-align: center;

      position: absolute;
      left: 50%;
      bottom: 14px;

      transform: translateX(-50%);
    }

    &:after {
      content: '';

      width: 177px;
      height: 55px;

      border-top: 1px solid #0a192f;

      position: absolute;
      bottom: 0;
    }
  `}
`;

export const TodayIndicator = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: calc(100% - 60px);

    background: ${theme.colors.hours.productive};

    position: absolute;
    bottom: 55px;

    z-index: 3;
  `}
`;

export const TodayIndicatorPoint = styled.div`
  ${({ theme }) => css`
    width: 5px;
    height: 5px;

    border-radius: 100%;
    background: ${theme.colors.hours.productive};

    position: absolute;
    left: 50%;
    bottom: -2.5px;

    transform: translateX(-50%);

    z-index: 10;

    &:before {
      content: '';

      width: 9px;
      height: 9px;

      border-radius: 100%;
      background: ${transparentize(0.5, theme.colors.hours.productive)};

      position: absolute;
      left: -2.25px;
      bottom: -2.25px;

      z-index: 9;
    }

    &:after {
      content: '';

      width: 13px;
      height: 13px;

      border-radius: 100%;
      background: ${transparentize(0.7, theme.colors.hours.productive)};

      position: absolute;
      left: -4px;
      bottom: -4px;

      z-index: 8;
    }
  `}
`;
