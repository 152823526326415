import React, { useMemo, useRef } from 'react';
import { useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';

import { ArrowDown, ArrowUp } from '@assets/icons';
import { ColorPicker, OrganogramGroupTooltip } from '@components/atoms';
import { OrganogramSubgroupTooltip } from '@components/atoms/Organogram/SubgroupTooltip';
import { OrganogramCollaboratorCard } from '@components/molecules';
import { IModalRef } from '@components/organisms/Modal';
import { useOrganogram } from '@hooks/useOrganogram';
import { IOrganogram, IOrganogramGroup } from '@interfaces/IOrganogram';
import { toHMS } from '@utils/toHMS';

import { CreateSubGroup } from '../Modals/CreateSubGroup';
import { ManageSubgroup } from '../Modals/ManageSubgroup';

import { Container, Cost, Hours, Left, MoreOptions, Right } from './styles';

interface IOrganogramContentProps {
  organogram: IOrganogram;
  group: IOrganogramGroup;
  color: string;
  level?: number;
  belowQuantity?: number;
  belowCosts?: { current: number; past: number };
  belowHours?: { current: number; past: number };
}

const OrganogramContent: React.FC<IOrganogramContentProps> = ({
  organogram,
  group,
  level = 0,
  belowQuantity = 0,
  belowCosts = { current: 0, past: 0 },
  belowHours = { current: 0, past: 0 },
  color,
}) => {
  const { onChangeColor } = useOrganogram();
  const addGroupModalRef = useRef<IModalRef>(null);
  const manageGroupModalRef = useRef<IModalRef>(null);

  const [moreIsOpen, setMoreIsOpen] = useState(false);

  const parsedName = useMemo(() => {
    const names = group.name.split(' ');
    names[names.length - 1] = names[names.length - 1].slice(0, 1);

    return `${names.join(' ')}.`;
  }, [group.name]);

  const parsedCost = useMemo(() => {
    if (group.type === 'group') {
      return {
        isPositive: belowCosts.current > belowCosts.past,
        cost: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
          .format(belowCosts.current)
          .slice(0, -3),
        percentage:
          belowCosts.past === 0
            ? 0
            : ((belowCosts.current / belowCosts.past - 1) * 100).toFixed(2),
      };
    }

    return {
      isPositive: belowCosts.current > belowCosts.past,
      cost: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(
          group.type === 'collaborator'
            ? group.values.current.cost
            : belowCosts.current,
        )
        .slice(0, -3),
      percentage:
        belowCosts.past === 0
          ? 0
          : ((belowCosts.current / belowCosts.past - 1) * 100).toFixed(2),
    };
  }, [group.type, group.values, belowCosts]);

  const parsedHours = useMemo(() => {
    if (group.type === 'group') {
      return {
        isPositive: belowHours.current > belowHours.past,
        hours: toHMS(belowHours.current),
        percentage:
          belowHours.past === 0
            ? 0
            : ((belowHours.current / belowHours.past - 1) * 100).toFixed(2),
      };
    }

    return {
      isPositive: belowHours.current > belowHours.past,
      hours: toHMS(
        group.type === 'collaborator'
          ? group.values.current.cost
          : belowHours.current,
      ),
      percentage:
        belowHours.past === 0
          ? 0
          : ((belowHours.current / belowHours.past - 1) * 100).toFixed(2),
    };
  }, [group.type, group.values, belowHours]);

  const info = useMemo(() => {
    if (group.type === 'enterprise') {
      return `${belowQuantity} ${belowQuantity === 1 ? 'Grupo' : 'Grupos'}`;
    }

    if (group.type === 'group') {
      return `${belowQuantity} ${
        belowQuantity === 1 ? 'Subgrupo' : 'Subgrupos'
      }`;
    }

    return `${belowQuantity} ${
      belowQuantity === 1 ? 'Colaborador' : 'Colaboradores'
    }`;
  }, [belowQuantity, group.type]);

  if (group.type === 'collaborator' || group.type === 'subordinate') {
    return (
      <OrganogramCollaboratorCard color={color} group={group} level={level} />
    );
  }

  return (
    <Container>
      <Left>
        <span>{level > 1 ? parsedName : group.name}</span>

        <strong>{info}</strong>

        <Cost>
          <strong>
            Custo:
            <span>{parsedCost.cost}</span>
          </strong>

          <div>
            {parsedCost.isPositive ? <ArrowUp /> : <ArrowDown />}
            <span>{parsedCost.percentage}%</span>
          </div>
        </Cost>

        <Hours>
          <strong>
            Horas:
            <span>{parsedHours.hours}</span>
          </strong>

          <div>
            {parsedHours.isPositive ? <ArrowUp /> : <ArrowDown />}
            <span>{parsedHours.percentage}%</span>
          </div>
        </Hours>
      </Left>

      {group.type === 'group' && (
        <Right type={group.type} color={color}>
          <>
            <ColorPicker
              color={organogram.color}
              onClose={(selectedColor: string) =>
                onChangeColor(organogram.id, selectedColor)
              }
            />

            <MoreOptions>
              <button type="button" onClick={() => setMoreIsOpen(!moreIsOpen)}>
                <FiMoreHorizontal size={24} color="#fbfbfb" />
              </button>

              <OrganogramGroupTooltip
                organogram={organogram}
                group={group}
                isOpen={moreIsOpen}
                setMoreIsOpen={setMoreIsOpen}
                subGroupRef={addGroupModalRef}
              />
            </MoreOptions>
          </>
        </Right>
      )}

      {group.type === 'subgroup' && (
        <Right type={group.type}>
          <div />

          <MoreOptions>
            <button type="button" onClick={() => setMoreIsOpen(!moreIsOpen)}>
              <FiMoreHorizontal size={24} color="#fbfbfb" />
            </button>

            <OrganogramSubgroupTooltip
              group={group}
              isOpen={moreIsOpen}
              setMoreIsOpen={setMoreIsOpen}
              editSubgroupRef={manageGroupModalRef}
            />
          </MoreOptions>
        </Right>
      )}

      <>
        <CreateSubGroup addSubGroupModalRef={addGroupModalRef} group={group} />

        <ManageSubgroup
          group={group}
          manageSubgroupModalRef={manageGroupModalRef}
        />
      </>
    </Container>
  );
};

export { OrganogramContent };
