import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid ${theme.colors.asideMenu};

    padding: 12px 8px;

    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-size: ${theme.typography.fontSize.small};
    }
  `}
`;

export const Content = styled.main`
  padding: 8px;

  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    padding: 16px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      padding: 24px 0 0 0;

      gap: 8px;
      display: flex;

      > button {
        min-width: 110px;

        border-radius: ${theme.borders.radii[100]};

        color: ${theme.colors.white};

        padding: 8px 24px;

        transition: all 400ms;

        &.cancel {
          border: 1px solid ${theme.colors.activities.danger};
          background: ${transparentize(0.4, theme.colors.activities.danger)};

          &:not([disabled]):hover {
            background: ${theme.colors.activities.danger};
          }
        }

        &.save {
          border: 1px solid ${theme.colors.activities.info};
          background: ${transparentize(0.4, theme.colors.activities.info)};

          &:not([disabled]):hover {
            background: ${theme.colors.activities.info};
          }
        }
      }
    }
  `}
`;

export const AddProjectButton = styled.button`
  ${({ theme }) => css`
    max-width: fit-content;

    border: none;
    background: transparent;

    color: ${theme.colors.activities.info};
    font-weight: bold;

    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 400ms;

    &:hover {
      color: ${transparentize(0.4, theme.colors.activities.info)};
    }
  `}
`;

export const ConfirmationDialog = styled.div`
  ${({ theme }) => css`
    max-width: 280px;

    border: 1px solid ${theme.colors.lines};
    border-radius: ${theme.borders.radii[300]};
    background: ${theme.colors.background};

    margin: 0 auto;
    padding: 16px 8px;

    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;

    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1000;

    strong {
      text-align: center;

      margin: 0 auto;

      gap: 8px;
      display: flex;

      span {
        color: ${theme.colors.activities.selection};
        font-size: ${theme.typography.fontSize.normal};
      }
    }

    div {
      gap: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border: none;
        border-radius: ${theme.borders.radii[100]};

        color: ${theme.colors.white};

        padding: 8px 24px;

        transition: all 400ms;

        &.yes {
          background: ${theme.colors.activities.info};
        }

        &.no {
          background: ${theme.colors.activities.danger};
        }
      }
    }
  `}
`;
