import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';

import { addMonths, format as f, parseISO } from 'date-fns';

import {
  CalendarContainer,
  ErrorContainer,
  IconContainer,
  LabelContainer,
} from '@components/quarks';
import { useClickAway } from '@hooks/useClickAway';
import { useFieldFeedback } from '@hooks/useFieldFeedback';
import { useUnform } from '@hooks/useUnform';
import { IDate } from '@utils/getCalendarDates';

import { Container } from './styles';

type IFormat = { showFormat: string; returnFormat: string };
type IValue = { original: Date | undefined; formatted: string };

interface IDatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  format?: IFormat;
  defaultValue?: any;
  errorMessage?: string;
  direction?: { vertical: 'UP' | 'DOWN'; horizontal: 'LEFT' | 'RIGHT' };
  theme?: 'light' | 'dark';
}

const DatePicker: React.FC<IDatePickerProps> = ({
  label,
  name,
  format = { showFormat: 'dd/MM/yyyy', returnFormat: 'yyyy-MM-dd' },
  defaultValue,
  errorMessage,
  direction,
  theme = 'light',
  ...rest
}) => {
  const unform = useUnform(name);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<IValue>({
    original: undefined,
    formatted: '',
  });

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );

  const {
    calendarIsOpen,
    setCalendarIsOpen,
    isFocused,
    isFilled,
    onFocus,
    onBlur,
  } = useFieldFeedback({
    name,
    unform,
    type: rest.type,
    disabled: rest.disabled,
    datePicker: { inputRef, selectedDate: value.original },
  });

  const onClose = useCallback(() => {
    inputRef.current?.setAttribute('value', value.formatted);

    inputRef.current?.focus();
    inputRef.current?.blur();

    setCalendarIsOpen(false);

    onBlur();
  }, [onBlur, setCalendarIsOpen, value.formatted]);

  const onSelect = useCallback(
    (day: IDate) => {
      const { showFormat } = format;

      setValue({ original: day.date, formatted: f(day.date, showFormat) });

      if (unform) {
        unform.formRef.current?.setFieldValue(name, f(day.date, showFormat));
      }

      onClose();
    },
    [format, name, onClose, unform],
  );

  useEffect(() => {
    inputRef.current?.setAttribute('value', value.formatted);
  }, [value.formatted, onClose, isFocused]);

  useClickAway(containerRef, () => onClose(), { enabled: calendarIsOpen });

  useEffect(() => {
    if (unform) {
      unform.registerField<any>({
        name: unform.fieldName,
        ref: inputRef.current,
        getValue: (ref: HTMLInputElement) => {
          if (ref.value.length === 0) return '';

          const valueArr = ref.value.split('/');

          const parsedValue = `${valueArr[2]}-${valueArr[1]}-${valueArr[0]}`;

          return f(parseISO(parsedValue), format.returnFormat);
        },
        setValue: (ref, inputValue: string) => {
          if (!inputValue) {
            setValue({ original: undefined, formatted: '' });
            return;
          }

          if (inputValue.includes('.000Z')) {
            const original = parseISO(inputValue.replace('.000Z', ''));

            const formatted = f(original, format.showFormat);

            setValue({ original, formatted });

            ref.value = formatted;
          }

          if (inputValue.includes('/')) {
            const valueArr = inputValue.split('/');

            const date = `${valueArr[2]}-${valueArr[1]}-${valueArr[0]}`;

            const original = parseISO(date);

            const formatted = f(original, format.showFormat);

            setValue({ original, formatted });

            ref.value = formatted;
          }
        },
        clearValue: ref => {
          setValue({ original: undefined, formatted: '' });

          ref.value = '';
        },
      });
    }
  }, [format.returnFormat, format.showFormat, name, unform, value]);

  const feedbacks = useMemo(() => {
    return {
      isErrored: (!!errorMessage || !!unform?.error) && !isFilled,
      isFocused,
      isFilled:
        isFilled ||
        (defaultValue && !!unform?.defaultValue) ||
        !!value.original,
      isDisabled: rest.disabled,
    };
  }, [
    errorMessage,
    unform?.error,
    unform?.defaultValue,
    isFilled,
    isFocused,
    defaultValue,
    value.original,
    rest.disabled,
  ]);

  const props = useMemo(() => {
    return {
      container: { hasLabel: !!label, ...feedbacks },
      label: { label, ...feedbacks },
      error: { message: errorMessage || unform?.error, ...feedbacks },
    };
  }, [errorMessage, feedbacks, label, unform?.error]);

  return (
    <Container cRef={containerRef} {...props.container} className="input">
      <LabelContainer {...props.label} />

      <input
        {...rest}
        ref={inputRef}
        name={name}
        disabled={rest.disabled}
        autoComplete="off"
        readOnly
        placeholder={isFocused ? '' : rest.placeholder}
        defaultValue={unform?.defaultValue}
        onFocus={onFocus}
      />

      <IconContainer icon={HiOutlineCalendar} isVisible />

      <ErrorContainer {...props.error} />

      <CalendarContainer
        calendarIsShow={calendarIsOpen}
        startDate={startDate}
        selectedDate={value.original}
        direction={direction}
        theme={theme}
        onPrev={() => setStartDate(state => addMonths(state, -1))}
        onNext={() => setStartDate(state => addMonths(state, 1))}
        onSelect={onSelect}
      />
    </Container>
  );
};

export { DatePicker };
