import styled, { css } from 'styled-components';

import { ITheme } from '@interfaces/generic/ITheme';

interface IContainerProps {
  direction?: 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM';
  isVisible?: boolean;
  followCursor?: boolean;
}

const containerVariations = (theme: ITheme) => ({
  LEFT: css`
    top: 50%;
    right: calc(100% + 8px + 6px);

    transform: translateY(-50%);

    &:before {
      content: '';

      border: 6px solid transparent;
      border-left: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};
      border-right: 0;

      position: absolute;
      top: 50%;
      left: 100%;

      transform: translateY(-50%);
    }

    &:after {
      content: '';

      border: 6px solid transparent;
      border-left: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};
      border-right: 0;

      position: absolute;
      top: 50%;
      left: 100%;

      transform: translateY(-50%);
    }
  `,
  RIGHT: css`
    top: 50%;
    left: calc(100% + 8px + 6px);

    transform: translateY(-50%);

    &:before {
      content: '';

      border: 6px solid transparent;
      border-left: 0;
      border-right: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};

      position: absolute;
      top: 50%;
      right: 100%;

      transform: translateY(-50%);
    }

    &:after {
      content: '';

      border: 6px solid transparent;
      border-left: 0;
      border-right: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};

      position: absolute;
      top: 50%;
      right: 100%;

      transform: translateY(-50%);
    }
  `,
  TOP: css`
    left: 50%;
    bottom: calc(100% + 8px + 6px);

    transform: translateX(-50%);

    &:before {
      content: '';

      border: 6px solid transparent;
      border-top: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};
      border-bottom: 0;

      position: absolute;
      left: 50%;
      top: 100%;

      transform: translateX(-50%);
    }

    &:after {
      content: '';

      border: 6px solid transparent;
      border-top: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};
      border-bottom: 0;

      position: absolute;
      left: 50%;
      top: 100%;

      transform: translateX(-50%);
    }
  `,
  BOTTOM: css`
    left: 50%;
    top: calc(100% + 8px + 6px);

    transform: translateX(-50%);

    &:before {
      content: '';

      border: 6px solid transparent;
      border-top: 0;
      border-bottom: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};

      position: absolute;
      left: 50%;
      bottom: 100%;

      transform: translateX(-50%);
    }

    &:after {
      content: '';

      border: 6px solid transparent;
      border-top: 0;
      border-bottom: 8px solid
        ${theme.title === 'dark' ? '#FFFFFF' : theme.colors.lines};

      position: absolute;
      left: 50%;
      bottom: 100%;

      transform: translateX(-50%);
    }
  `,
});

export const Container = styled.div.attrs({
  className: 'components-atoms-tooltip-container',
})<IContainerProps>`
  ${({ theme, direction = 'TOP', followCursor = false, isVisible }) => css`
    width: fit-content;
    height: fit-content;

    background: #ffffff;
    border: 1px solid ${theme.colors.lines};
    border-radius: 4px;

    padding: 8px;

    position: ${!followCursor ? 'absolute' : 'fixed'};

    opacity: ${isVisible ? 1 : 0};
    visibility: ${isVisible ? 'visible' : 'hidden'};

    transition: all 400ms;

    z-index: 10000;

    ${containerVariations(theme)[direction]}
  `}
`;
