import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { HiOutlineChatAlt2 } from 'react-icons/hi';

import { useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

import { ChatbotContext } from '@contexts/ReactChatbotContext';
import { delay } from '@utils/delay';

import { Alert } from './Alert';
import { useDaily } from './hooks/useDaily';
import { Messages } from './Messages';

import { Container, ChatButton } from './styles';

interface IChatBotProps {
  me: string;
  alwaysOpen?: boolean;
}

const ChatBot: React.FC<IChatBotProps> = ({ me = '', alwaysOpen = false }) => {
  const isOpen = useContextSelector(ChatbotContext, state => state.isOpen);
  const closeChatbot = useContextSelector(
    ChatbotContext,
    state => state.closeChatbot,
  );
  const chooseWorkedDate = useContextSelector(
    ChatbotContext,
    state => state.chooseWorkedDate,
  );
  const [messages, setMessages] = useState<JSX.Element[]>([]);

  const name = useMemo(() => me.split(' ')[0], [me]);

  const scrollToDown = useCallback(async () => {
    await delay(100);

    const chatMessages = document.getElementById('chat-bot-messages');

    if (chatMessages) chatMessages.scrollTo(0, chatMessages.scrollHeight);
  }, []);

  const handleAddMessage = useCallback(
    (element: JSX.Element, isInitial = false) => {
      setMessages(state => {
        if (isInitial) return [React.cloneElement(element, { key: v4() })];

        return [...state, React.cloneElement(element, { key: v4() })];
      });
    },
    [],
  );

  const handleResetMessages = useCallback(() => setMessages([]), []);

  const { alert, isAlert, isAnswered, setIsAlert } = useDaily({
    addMessage: handleAddMessage,
    resetMessages: handleResetMessages,
    scrollToDown,
  });

  const handleOpenAlert = useCallback(() => {
    if (alert === 'danger') setIsAlert(true);
    else {
      setIsAlert(state => !state);
    }
  }, [alert, setIsAlert]);

  useEffect(() => {
    if (sessionStorage.openDailyChat === 'true') {
      scrollToDown();
      chooseWorkedDate(undefined);
    }
  }, [chooseWorkedDate, scrollToDown]);

  const handleCancel = useCallback(() => {
    closeChatbot();
    setIsAlert(false);
  }, [closeChatbot, setIsAlert]);

  return (
    <Container>
      <Alert
        name={name}
        isAlert={isAlert}
        isAnswered={isAnswered}
        alert={alert}
        onReply={() => {
          scrollToDown();
          chooseWorkedDate(undefined);
        }}
        onCancel={handleCancel}
      />

      <Messages
        alert={alert}
        isOpen={isOpen}
        alwaysOpen={alwaysOpen}
        messages={messages}
        onClose={() => closeChatbot()}
      />

      <ChatButton isAlert={isAlert} alert={alert} onClick={handleOpenAlert}>
        <HiOutlineChatAlt2 color="#0A192F" />
      </ChatButton>
    </Container>
  );
};

export { ChatBot };
