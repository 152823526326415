import React, { useState } from 'react';

import { Tooltip } from '@components/atoms';

import { Container, Svg, Line } from './styles';

interface ICircleChartProps {
  percentage: [number, number, number];
  tooltip?: React.ReactNode;
}

const CircleChart: React.FC<ICircleChartProps> = ({ tooltip, percentage }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Container
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {tooltip && (
        <Tooltip direction="LEFT" isVisible={isVisible}>
          {tooltip}
        </Tooltip>
      )}

      <Svg viewBox="0 0 36 36">
        <g>
          <Line cx="18" cy="18" r="16" />
          <Line cx="18" cy="18" r="11" />
          <Line cx="18" cy="18" r="6" />
        </g>

        <g>
          {percentage && percentage[0] !== 0 && (
            <Line
              cx="18"
              cy="18"
              r="16"
              strokeDasharray={`${percentage[0] || 0}, 100`}
              c={percentage[0] > 100 ? '#FF6C70' : '#15c8ac'}
            />
          )}

          {percentage && percentage[1] !== 0 && (
            <Line
              cx="25.6"
              cy="25.6"
              r="16"
              strokeDasharray={`${percentage[1] || 0}, 100`}
              c={percentage[1] > 100 ? '#FF6C70' : '#7c4dff'}
              style={{ transform: 'scale(0.7)', strokeWidth: 4 }}
            />
          )}

          {percentage && percentage[2] !== 0 && (
            <Line
              cx="45"
              cy="45"
              r="16"
              strokeDasharray={`${percentage[2] || 0}, 100`}
              c="#00b0ff"
              style={{ transform: 'scale(0.4)', strokeWidth: 7 }}
            />
          )}
        </g>
      </Svg>
    </Container>
  );
};

export { CircleChart };
