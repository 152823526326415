import React from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from 'i18next';

import formsBR from '../locales/br/forms.json';
import loginBR from '../locales/br/login.json';
import messagesBR from '../locales/br/messages.json';
import formsUS from '../locales/us/forms.json';
import loginUS from '../locales/us/login.json';
import messagesUS from '../locales/us/messages.json';

const I18NextProvider: React.FC = ({ children }) => {
  const resources = () => {
    return {
      br: {
        translation: {
          ...formsBR,
          ...loginBR,
          ...messagesBR,
        },
      },
      us: {
        translation: {
          ...formsUS,
          ...loginUS,
          ...messagesUS,
        },
      },
    };
  };

  i18n.init({
    resources: resources(),
    lng: process.env.REACT_APP_LANGUAGE || 'br',
    react: { useSuspense: false },
    interpolation: { escapeValue: false },
  });

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export { I18NextProvider };
