import styled, { css } from 'styled-components';

interface IContainerProps {
  isOpen: boolean;
}

const containerVariations = () => ({
  visible: css`
    opacity: 1;
    visibility: visible;

    * {
      opacity: 1;
      visibility: visible;
    }
  `,
});

export const Container = styled.div<IContainerProps>`
  ${({ isOpen }) => css`
    background: rgba(0, 0, 0, 0.6);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: default;

    z-index: 10000;

    * {
      opacity: 0;
      visibility: hidden;

      transition: all 300ms;
    }

    ${isOpen && containerVariations().visible}
  `}
`;

// IF YOU CHOOSE CUSTOM, YOU WILL NEED A DIV AFTER MODAL WITH WIDTH AND HEIGHT DEFINED
interface ICardProps {
  size: 'small' | 'medium' | 'large' | 'custom';
}

const cardVariations = () => ({
  small: css`
    width: fit-content;
    min-width: 350px;
    height: fit-content;
    min-height: 200px;

    > div {
      width: 100%;
      min-height: 200px;
    }
  `,
  medium: css`
    width: fit-content;
    min-width: 814px;
    height: fit-content;
    min-height: 452px;

    > div {
      width: 100%;
      min-height: 452px;
    }
  `,
  large: css`
    width: fit-content;
    min-width: 1100px;
    height: fit-content;
    min-height: 652px;

    > div {
      width: 100%;
      min-height: 652px;
    }
  `,
  custom: css`
    width: fit-content;
    height: fit-content;
  `,
});

export const Card = styled.div<ICardProps>`
  ${({ theme, size }) => css`
    width: fit-content;
    min-width: 350px;
    height: fit-content;
    min-height: 200px;

    background: ${theme.colors.cards};
    border-radius: ${theme.borders.radii[300]};
    box-shadow: 0px 0px 24px ${theme.colors.shadow};

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      min-height: 200px;

      border-radius: ${theme.borders.radii[300]};
    }

    ${cardVariations()[size]}
  `}
`;

export const Arrow = styled.button`
  border: 0;
  background: transparent;

  position: absolute;
`;

export const ArrowLeft = styled(Arrow)`
  left: -50px;
`;

export const ArrowRight = styled(Arrow)`
  right: -50px;
`;
